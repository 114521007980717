import { Avatar, Badge, Box, Button, IconButton, Menu, MenuItem, Modal, Select, Typography, useMediaQuery } from "@mui/material";
// import { styled } from "@mui/system";
import { styled } from "@mui/material";

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { Menu as MenuIcon, Close, ShoppingCart, SignalWifiStatusbarNullTwoTone } from "@mui/icons-material";
import { useWebinarContext } from "../../WebinarContext";
import "./navbar.css";
import { getTotalItems } from "../StateProvider/reducer";
import { useStateValue } from "../StateProvider/stateProvider";
import { grey } from "@mui/material/colors";
import RDService from "../../services/RDService";
import Cookies from "js-cookie";
import axios from "axios";
import LogoutIcon from "@mui/icons-material/Logout";
const AnimatedPlaceholder = styled("Box")(({ theme }) => ({
  position: "absolute",
  bottom: 8,
  left: 10,
  opacity: 0,
  transition: "opacity 0.5s ease-in-out, transform 0.5s ease-in-out",
  transform: "translateY(100%)",
}));

function Navbar() {
  const { webinarTickerExists, setWebinarTickerExists } = useWebinarContext();
  const useStyles = styled((theme) => ({
    menuButton: {
      transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
      "&.menuOpen": {
        transform: "rotate(180deg)", // Rotate 45 degrees more than the default 90 degrees
        opacity: 0.5,
      },
    },
  }));
  const [{ basket, firstName, lastName, email, user_id }, dispatch] = useStateValue();

  const totalItems = getTotalItems(basket);
  const classes = useStyles();
  const navigate = useNavigate();
  const [showEmail, setShowEmail] = useState(false);
  const searchOptions = [
    {
      name: "Mock Interview",
      url: "/MockInterview",
      index: 0,
    },
    {
      name: "SIP Success",
      url: "/SipSuccess",
      index: 1,
    },
    {
      name: "Resume Discussion",
      url: "/ResumeDiscussion",
      index: 2,
    },
    {
      name: "PI Questions from Resume",
      url: "/PIQR",
      index: 3,
    },
    {
      name: "Case Interview",
      url: "/CaseInterview",
      index: 4,
    },
    {
      name: "Expert Connect",
      url: "/ExpertConnect",
      index: 5,
    },
  ];

  const Mobile = useMediaQuery("(max-width:1300px)");
  const tablet = useMediaQuery("(max-width:800px)");

  const [placeholder, setPlaceholder] = useState(0);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [showLoginOptions, setShowLoginOptions] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(false);

  const openMenu = (event) => {
    setMenuAnchor(true);
  };

  const closeMenu = () => {
    setMenuAnchor(false);
  };

  const handleOnClick = () => {
    window.open("https://www.careercarve.in/Authorize", "NewWindow", `height=${500},width=${400},location=0,menubar=0,left=${window.innerWidth / 2 - 200}, top=${window.innerHeight / 2 - 250},resizable=no,status=0,titlebar=0,toolbar=0`);

    window.addEventListener("message", receiveMessageFromChild);
  };

  const receiveMessageFromChild = (event) => {
    // Handle received data from the child window
    console.log("EVENT DATA", event.data);
    if (event.data.type === "AUTH") {
      getUserDetails(event.data.val);
    } else if (event.data.type === "SIGNUP") {
      window.location.href = "https://www.careercarve.in/GetYourFreeAccount";
    }
  };

  const getUserDetails = async (i) => {
    try {
      const headers = {
        Authorization: `Bearer ${i}`,
      };
      Cookies.set("token", i);
      const data = await axios.get(`${process.env.REACT_APP_API_URL}/user/userDetail`, { headers });

      const contextData = {
        firstname: data.data.userDetail.first_name,
        lastname: data.data.userDetail.last_name,
        email: data.data.userDetail.email_id,
        user_id: data.data.userDetail.user_id,
        ph_no: data.data.userDetail.ph_no,
        country: data.data.userDetail.country,
      };
      dispatch({ type: "UPDATE_USER", data: contextData });
    } catch (error) {
      console.log("Some error occurred");
    }
  };

  useEffect(() => {
    if (Cookies.get("token") !== undefined) {
      getUserDetails(Cookies.get("token"));
    }
  }, []);

  useEffect(() => {
    setIsFadingOut(true);
    const timer = setInterval(() => {
      setIsFadingOut(true); // Start fading out the old text
      setTimeout(() => {
        setPlaceholder((prevIndex) => (prevIndex + 1) % searchOptions.length);
        setIsFadingOut(false);
      }, 500);
    }, 3000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleLogout = () => {
    Cookies.remove("token");

    const contextData = {
      firstname: "",
      lastname: "",
      email: "",
      user_id: null,
      ph_no: null,
      country: "",
    };
    dispatch({ type: "UPDATE_USER", data: contextData });
  };

  const location = useLocation().pathname;
  return !Mobile ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        // alignItems: "center",
        padding: "0.5rem 1rem",

        backgroundColor: "#fff",
        boxShadow: " 0px 2px 35px 0px rgba(0, 0, 0, 0.08);",
        position: "fixed",
        top: webinarTickerExists ? "45px" : 0,
        left: 0,
        width: "100%",
        zIndex: "100",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "2rem",
          width: "45%",
          alignItems: "center",
        }}
      >
        <Box sx={{ "&:hover": { cursor: "pointer" } }}>
          <img
            src="../../Career_Carve_Logo__1_-removebg-preview 2.png"
            className="navbar__image"
            alt="careercarve"
            onClick={() => {
              navigate("/");
              window.scrollTo(0, 0);
            }}
          />
        </Box>

        <Select
          IconComponent={SearchIcon}
          renderValue={() => {
            return (
              <Box>
                <AnimatedPlaceholder
                  sx={{
                    fontSize: "14px",
                    color: "#8B8B8B",
                    opacity: isFadingOut ? 0 : 1,
                    transform: isFadingOut ? "translateY(-50%)" : "translateY(0)",
                  }}
                >
                  {searchOptions[placeholder].name}
                </AnimatedPlaceholder>
              </Box>
            );
          }}
          value={searchOptions[placeholder].name}
          onChange={(e) => {
            navigate(e.target.value);
            window.scrollTo(0, 0);
          }}
          sx={{
            // minWidth: "300px",
            // maxWidth: "500px",
            overflow: "hidden",
            width: "100%",
            marginRight: "20px",
            borderRadius: "64px",
            borderWidth: "10px",
            boxShadow: "0px 2px 35px 0px rgba(0, 0, 0, 0.08)",
            backgroundColor: "#E6E6E6",
            "& fieldset": { border: "none" },
            height: "40px",
            "& .MuiSelect-icon": { transform: "none" },
          }}
        >
          {searchOptions.map((option) => (
            <MenuItem uItem key={option.name} value={option.url}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // width: "45%",
          gap: "1.5rem",
        }}
      >
        <a href="/" style={{ textDecoration: "none" }}>
          <Typography
            sx={{
              textDecoration: "none",
              width: "100%",
              position: "relative",
              fontSize: "14px",
              color: location === "/" ? "#1C8EA8" : "#8B8B8B",
              "&:hover": {
                cursor: "pointer",
              },
              "&::before": {
                content: '""',
                position: "absolute",
                width: location === "/" ? "100%" : "0%",
                borderBottom: "2px solid #1C8EA8",
                bottom: "-2px",
                left: 0,
                transition: "width 0.5s ease",
              },
              "&:hover::before": {
                width: location === "/" ? "100%" : "50%",
              },
            }}
          >
            Home
          </Typography>
        </a>
        <a href="/AboutUs" style={{ textDecoration: "none" }}>
          <Typography
            sx={{
              textDecoration: "none",
              position: "relative",
              fontSize: "14px",
              width: "100%",
              color: location === "/AboutUs" ? "#1C8EA8" : "#8B8B8B",
              "&:hover": {
                cursor: "pointer",
              },
              "&::before": {
                content: '""',
                position: "absolute",
                width: location === "/AboutUs" ? "100%" : "0%",
                borderBottom: "2px solid #1C8EA8",
                bottom: "-2px",
                left: 0,
                transition: "width 0.5s ease",
              },
              "&:hover::before": {
                width: location === "/AboutUs" ? "100%" : "50%",
              },
            }}
          >
            About
          </Typography>
        </a>
        <a href="/WebinarEvents" style={{ textDecoration: "none" }}>
          <Typography
            sx={{
              textDecoration: "none",
              position: "relative",
              fontSize: "14px",
              width: "100%",
              color: location === "/WebinarEvents" || location === "/WebinarDetails" ? "#1C8EA8" : "#8B8B8B",
              "&:hover": {
                cursor: "pointer",
              },
              "&::before": {
                content: '""',
                position: "absolute",
                width: location === "/WebinarEvents" || location === "/WebinarDetails" ? "100%" : "0%",
                borderBottom: "2px solid #1C8EA8",
                bottom: "-2px",
                left: 0,
                transition: "width 0.5s ease",
              },
              "&:hover::before": {
                width: location === "/WebinarEvents" || location === "/WebinarDetails" ? "100%" : "50%",
              },
            }}
          >
            Events
          </Typography>
        </a>
        {/* <a href="/Mentors" style={{ textDecoration: "none" }}> */}
        <Typography
          sx={{
            textDecoration: "none",
            position: "relative",

            fontSize: "14px",
            color: location === "/Mentors" ? "#1C8EA8" : "#8B8B8B",
            "&:hover": {
              cursor: "pointer",
            },
            "&::before": {
              content: '""',
              position: "absolute",
              width: location === "/Mentors" ? "100%" : "0%",
              borderBottom: "2px solid #1C8EA8",
              bottom: "-2px",
              left: 0,
              transition: "width 0.5s ease",
            },
            "&:hover::before": {
              width: location === "/Mentors" ? "100%" : "50%",
            },
          }}
          onClick={() => navigate("/Mentors")}
        >
          Mentors
        </Typography>
        {/* </a> */}
        <a href="/Blogs" style={{ textDecoration: "none" }}>
          <Typography
            sx={{
              textDecoration: "none",
              position: "relative",
              width: "100%",
              fontSize: "14px",
              color: location === "/Blogs" ? "#1C8EA8" : "#8B8B8B",
              "&:hover": {
                cursor: "pointer",
              },
              "&::before": {
                content: '""',
                position: "absolute",
                width: location === "/Blogs" ? "100%" : "0%",
                borderBottom: "2px solid #1C8EA8",
                bottom: "-2px",
                left: 0,
                transition: "width 0.5s ease",
              },
              "&:hover::before": {
                width: location === "/Blogs" ? "100%" : "50%",
              },
            }}
          >
            Blogs
          </Typography>
        </a>
        <a href="/Pricing" style={{ textDecoration: "none" }}>
          <Typography
            sx={{
              textDecoration: "none",
              position: "relative",
              width: "100%",
              fontSize: "14px",
              color: location === "/Pricing" ? "#1C8EA8" : "#8B8B8B",
              "&:hover": {
                cursor: "pointer",
              },
              "&::before": {
                content: '""',
                position: "absolute",
                width: location === "/Pricing" ? "100%" : "0%",
                borderBottom: "2px solid #1C8EA8",
                bottom: "-2px",
                left: 0,
                transition: "width 0.5s ease",
              },
              "&:hover::before": {
                width: location === "/Pricing" ? "100%" : "50%",
              },
            }}
          >
            Pricing
          </Typography>
        </a>

        <Typography
          sx={{
            textDecoration: "none",
            position: "relative",
            fontSize: "14px",
            color: location === "/#contactUs" ? "#1C8EA8" : "#8B8B8B",
            "&:hover": {
              cursor: "pointer",
            },
            "&::before": {
              content: '""',
              position: "absolute",
              width: location === "/#contactUs" ? "100%" : "0%",

              borderBottom: "2px solid #1C8EA8",
              bottom: "-2px",
              left: 0,
              transition: "width 0.5s ease",
            },
            "&:hover::before": {
              width: location === "/#contactUs" ? "100%" : "50%",
            },
          }}
          onClick={() => {
            window.scrollTo(0, document.body.scrollHeight - 900);
          }}
        >
          Contact
        </Typography>

        <a href="/BecomeMentor" style={{ textDecoration: "none" }}>
          <Typography
            sx={{
              textDecoration: "none",
              position: "relative",
              width: "100%",
              fontSize: "14px",
              color: location === "/BecomeMentor" ? "#1C8EA8" : "#8B8B8B",
              "&:hover": {
                cursor: "pointer",
              },
              "&::before": {
                content: '""',
                position: "absolute",
                width: location === "/BecomeMentor" ? "100%" : "0%",

                borderBottom: "2px solid #1C8EA8",
                bottom: "-2px",
                left: 0,
                transition: "width 0.5s ease",
              },
              "&:hover::before": {
                width: location === "/BecomeMentor" ? "100%" : "50%",
              },
            }}
          >
            Become a Mentor
          </Typography>
        </a>
        {totalItems > 0 && (
          <Badge
            // badgeContent={storedTempQuantity?.length}
            badgeContent={totalItems}
            sx={{
              // width: "100%",

              "& .MuiBadge-badge": {
                color: "white",
                fontSize: "10px",
                backgroundColor: "#1C8EA8",
              },
            }}
          >
            <ShoppingCart
              sx={{ mt: 0.5 }}
              style={{
                color: "#1C8EA8",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/CartScreen");
              }}
            />
          </Badge>
        )}

        {user_id === null ? (
          // <a href="https://www.careercarve.in/" style={{ textDecoration: "none" }}>
          <Button
            sx={{
              color: "#000",
              border: "1px solid #1C8EA8",
              position: "relative",
              overflow: "hidden",
              borderRadius: "8px",
              textDecoration: "none",
              width: "fit-content",
              transition: "background 3s ease-in-out",
              "&::before": {
                content: '""',
                position: "absolute",
                top: "0",
                left: "-100%",
                width: "100%",
                height: "100%",
                background: "linear-gradient(133deg, #2AA8C4 16.39%, #38C1DF 100%)",
                transition: "left 0.3s ease-in-out",
                color: "#fff",
              },
              "&:hover::before": {
                left: "0",
                color: "#fff",
              },
              "&:hover": {
                color: "#fff",
              },
            }}
            onClick={() => setShowLoginOptions((prev) => !prev)}
          >
            <Typography
              sx={{
                zIndex: 10,
                textTransform: "none",
                textDecoration: "none",
                // "&:hover": { color: "#fff" },
              }}
            >
              Sign up
            </Typography>
          </Button>
        ) : (
          <Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "2px", alignItems: "center", justifyContent: "center" }} onMouseEnter={() => setShowEmail(true)} onMouseLeave={() => setShowEmail(false)}>
              <Avatar sx={{ bgcolor: grey[500] }}>{firstName[0]}</Avatar>
              {showEmail && (
                <Box sx={{ position: "absolute", right: 5, top: 50, padding: "10px", background: "#fff", borderRadius: "10px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px; ", display: "flex", flexDirection: "column", gap: "5px" }}>
                  <Box>
                    <Typography style={{ fontSize: "14px", fontWeight: 600 }}>
                      {firstName} {lastName}
                    </Typography>
                    <Typography style={{ fontSize: "14px" }}>{email}</Typography>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button style={{ width: "fit-content", padding: "2px 5px", textTransform: "none", border: "1px solid #1C8EA8", fontSize: "14px", color: "#1C8EA8" }} onClick={() => handleLogout()}>
                      Logout
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        )}
        {showLoginOptions && (
          <Box sx={{ position: "absolute", width: "200px", background: "#fff", boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.1)", borderRadius: "10px", right: 0, top: "60px", padding: "12px" }}>
            <Typography sx={{ fontSize: "14px", color: "#38C1DF", textAlign: "center" }}>Identify Yourself</Typography>
            <Typography sx={{ fontSize: "14px", textAlign: "center", paddingBlock: "4px", "&:hover": { cursor: "pointer" } }} onClick={() => handleOnClick()}>
              Student
            </Typography>
            <hr style={{ margin: "0px", padding: "0px", color: "rgba(217, 217, 217, 1)" }} />
            <Typography sx={{ fontSize: "14px", textAlign: "center", paddingBlock: "4px", "&:hover": { cursor: "pointer" } }} onClick={() => handleOnClick()}>
              Mentor
            </Typography>
            <hr style={{ margin: "0px", padding: "0px", color: "rgba(217, 217, 217, 1)" }} />

            <a href="https://collegeadmin.careercarve.in" target="_blank" style={{ textDecoration: "none", color: "#000" }}>
              <Typography sx={{ fontSize: "14px", textAlign: "center", paddingBlock: "4px" }}>College Administrator</Typography>
            </a>
          </Box>
        )}
      </Box>
    </Box>
  ) : (
    <Box
      display="flex"
      justifyContent={"space-between"}
      padding={"8px 8px"}
      alignItems={"center"}
      sx={{
        boxShadow: " 0px 2px 35px 0px rgba(0, 0, 0, 0.08);",
        bgcolor: "#fff",
        width: "100%",
        position: "fixed",
        top: !webinarTickerExists ? 0 : Mobile ? "35px" : "45px",
        left: 0,
        zIndex: "100",
      }}
    >
      <Box sx={{ display: "flex", gap: "1rem", alignItems: "center", width: "100%" }}>
        <Box sx={{ "&:hover": { cursor: "pointer" } }}>
          <img src="../../Career_Carve_Logo__1_-removebg-preview 2.png" className="navbar__image" alt="CareerCarve" onClick={() => navigate("/")} style={{ height: "100%", width: "140px" }} />
        </Box>
        {!tablet && (
          <Select
            IconComponent={SearchIcon}
            renderValue={() => {
              return (
                <Box>
                  <AnimatedPlaceholder
                    sx={{
                      fontSize: "14px",
                      color: "#8B8B8B",
                      opacity: isFadingOut ? 0 : 1,
                      transform: isFadingOut ? "translateY(-50%)" : "translateY(0)",
                    }}
                  >
                    {searchOptions[placeholder].name}
                  </AnimatedPlaceholder>
                </Box>
              );
            }}
            value={searchOptions[placeholder].name}
            onChange={(e) => {
              navigate(e.target.value);
              window.scrollTo(0, 0);
            }}
            sx={{
              // minWidth: "300px",
              // maxWidth: "500px",
              overflow: "hidden",
              width: "min(100%, 400px)",
              marginRight: "20px",
              borderRadius: "64px",
              borderWidth: "10px",
              boxShadow: "0px 2px 35px 0px rgba(0, 0, 0, 0.08)",
              backgroundColor: "#E6E6E6",
              "& fieldset": { border: "none" },
              height: "40px",
              "& .MuiSelect-icon": { transform: "none" },
            }}
          >
            {searchOptions.map((option) => (
              <MenuItem uItem key={option.name} value={option.url}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        )}
      </Box>

      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} gap="10px">
        {totalItems > 0 && (
          <Badge
            // badgeContent={storedTempQuantity?.length}
            badgeContent={totalItems}
            sx={{
              // width: "100%",

              "& .MuiBadge-badge": {
                color: "white",
                fontSize: "10px",
                padding: "2px",
                backgroundColor: "#1C8EA8",
              },
            }}
          >
            <ShoppingCart
              sx={{ mt: 0.5 }}
              style={{
                color: "#1C8EA8",
                cursor: "pointer",
                // fontSize: "18px",
              }}
              onClick={() => {
                navigate("/CartScreen");
              }}
            />
          </Badge>
        )}

        {user_id !== null && (
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <Avatar sx={{ bgcolor: grey[500], width: "30px", height: "30px" }}>A</Avatar>
          </Box>
        )}

        <Box sx={{}}>
          <IconButton color="inherit" aria-label="menu" onClick={openMenu} className={`${classes.menuButton} ${menuAnchor ? "menuOpen" : ""}`} sx={{ padding: 0 }}>
            {menuAnchor ? <Close /> : <MenuIcon />}
          </IconButton>

          <Menu
            anchorEl={menuAnchor}
            open={Boolean(menuAnchor)}
            onClose={closeMenu}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            // sx={{ marginTop: "0%", marginLeft: "0%" }}
          >
            {user_id !== null && (
              <MenuItem sx={{}}>
                <Box sx={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
                  <Box sx={{ flex: 2 }}>
                    <Typography style={{ fontSize: "12px", fontWeight: 600 }}>
                      {firstName} {lastName}
                    </Typography>
                    <Typography style={{ fontSize: "12px" }}>{email}</Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "center", flex: 1, flexDirection: "column", alignItems: "flex-end" }}
                    onClick={() => {
                      handleLogout();
                      setMenuAnchor(false);
                    }}
                  >
                    <LogoutIcon />
                  </Box>
                </Box>
              </MenuItem>
            )}
            <a href="/" style={{ textDecoration: "none" }}>
              <MenuItem
                onClick={() => {
                  window.scrollTo(0, 0);
                  closeMenu();
                }}
                sx={{
                  color: location === "/" ? "#1C8EA8" : "#000",
                }}
              >
                Home
              </MenuItem>
            </a>
            <a href="/Mentors" style={{ textDecoration: "none" }}>
              <MenuItem
                onClick={() => {
                  window.scrollTo(0, 0);
                  closeMenu();
                }}
                sx={{ color: location === "/Mentors" ? "#1C8EA8" : "#000" }}
              >
                Mentors
              </MenuItem>
            </a>
            <a href="/WebinarEvents" style={{ textDecoration: "none" }}>
              <MenuItem
                onClick={() => {
                  window.scrollTo(0, 0);
                  closeMenu();
                }}
                sx={{ color: location === "/Mentors" ? "#1C8EA8" : "#000" }}
              >
                Events
              </MenuItem>
            </a>
            <a href="/BecomeMentor" style={{ textDecoration: "none" }}>
              <MenuItem
                onClick={() => {
                  window.scrollTo(0, 0);
                  closeMenu();
                }}
                sx={{
                  color: location === "/BecomeMentor" ? "#1C8EA8" : "#000",
                }}
              >
                Become a Mentor
              </MenuItem>
            </a>
            <a href="/AboutUs" style={{ textDecoration: "none" }}>
              <MenuItem
                onClick={() => {
                  window.scrollTo(0, 0);
                  closeMenu();
                }}
                sx={{ color: location === "/AboutUs" ? "#1C8EA8" : "#000" }}
              >
                About Us
              </MenuItem>
            </a>
            <a href="/Pricing" style={{ textDecoration: "none" }}>
              <MenuItem
                onClick={() => {
                  window.scrollTo(0, 0);
                  closeMenu();
                }}
                sx={{ color: location === "/Pricing" ? "#1C8EA8" : "#000" }}
              >
                Pricing
              </MenuItem>
            </a>
            <MenuItem
              onClick={() => {
                window.scrollTo(0, document.body.scrollHeight - 1500);
                closeMenu();
              }}
              sx={{ color: location === "/ContactUs" ? "#1C8EA8" : "#000" }}
            >
              Contact Us
            </MenuItem>

            {user_id === null && (
              <MenuItem sx={{}} onClick={() => setShowLoginOptions(true)}>
                <Typography>Sign up</Typography>
              </MenuItem>
            )}
          </Menu>
        </Box>
      </Box>
      <Modal open={showLoginOptions} onClose={() => setShowLoginOptions(false)} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Box sx={{ background: "#fff", padding: "16px", borderRadius: "12px" }}>
          <Typography sx={{ fontSize: "14px", color: "#38C1DF", textAlign: "center" }}>Identify Yourself</Typography>
          <Typography sx={{ fontSize: "14px", paddingBlock: "6px", "&:hover": { cursor: "pointer" } }} onClick={() => handleOnClick()}>
            Student
          </Typography>
          <hr style={{ margin: "0px", padding: "0px", color: "rgba(217, 217, 217, 1)" }} />
          <Typography sx={{ fontSize: "14px", paddingBlock: "6px", "&:hover": { cursor: "pointer" } }} onClick={() => handleOnClick()}>
            Mentor
          </Typography>
          <hr style={{ margin: "0px", padding: "0px", color: "rgba(217, 217, 217, 1)" }} />

          <a href="https://collegeadmin.careercarve.in" target="_blank" style={{ textDecoration: "none", color: "#000" }}>
            <Typography sx={{ fontSize: "14px", paddingBlock: "6px" }}>College Administrator</Typography>
          </a>
        </Box>
      </Modal>
    </Box>
  );
}

export default Navbar;
