import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import BottomNavbar from "../Footer/BottomNavbar";
import { useStateValue } from "../../../components/StateProvider/stateProvider";
import ServiceCM from "../../../services/cartmoduleServices";
import { useWebinarContext } from "../../../WebinarContext";
function NewSipSuccess() {
  const navigate = useNavigate();
  const env = `${process.env.REACT_APP_ENVIRONMENT}`;
  const [{ basket }, dispatch] = useStateValue();
  const { webinarTickerExists } = useWebinarContext();
  const services = new ServiceCM();
  const [sku, setSku] = useState([]);
  const handleNavigate = (value) => {
    window.scroll(0, 0);
    navigate(value);
  };

  const Mobile = useMediaQuery("(max-width:800px)");
  const addToCart = () => {
    let temp_cart = JSON.parse(localStorage.getItem("temp_quantity")) ?? [];
    console.log("cart item", temp_cart);
    const id = env === "dev" ? 1 : 1;
    let item = temp_cart.filter((item) => item.id === id);
    if (item.length > 0) {
      temp_cart = temp_cart.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            quantity: item.quantity + 1,
            total_price: item.total_price + item.total_price / item.quantity,
          };
        } else {
          return item;
        }
      });
    } else {
      let find_sku = sku.find((item) => item.id === id);
      console.log("find_sku", find_sku);
      let data = {
        search_tbl: "sku",
        id: id,
        quantity: 1,
        total_price: find_sku.actual_price,
      };
      temp_cart = [...temp_cart, data];
    }

    console.log("cart item", temp_cart);
    dispatch({ type: "UPDATE_BASKET", basket: temp_cart });
    localStorage.setItem("temp_quantity", JSON.stringify(temp_cart));
  };

  const getSku = async () => {
    const res = await services.get("/sku?type=1 X 1");
    setSku(res.data);
  };

  useEffect(() => {
    getSku();
  }, []);
  return (
    <>
      {/* this provides the meta description of each page separately */}
      <Helmet>
        <title>Unlock Your PPO/PPI Potential: Interactive SIP Sessions At CareerCarve</title>
        <meta name="description" content="Gain valuable insights and strategies during CareerCarve's interactive SIP Success Sessions. Boost your chances of PPO/PPI success." />

        <meta property="og:title" content="CareerCarve-Summer Internship Preparation" />
        <meta property="og:image" content="../../Career_Carve_Logo__1_-removebg-preview 2.png" />
        <meta property="og:url" content="https://www.careercarve.com/SipSuccess" />
        <link rel="canonical" href="/SipSuccess" />
      </Helmet>
      <Box sx={{ marginTop: webinarTickerExists && Mobile ? "80px" : !webinarTickerExists && Mobile ? "50px" : webinarTickerExists ? "112px" : "70px" }}>
        {/* <Navbar /> */}
        <Box sx={{ marginBottom: Mobile ? "1.5rem" : "4.5rem" }}>
          <Box>
            <img
              src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/summer-internship-program.jpg"
              alt="Secure Your PPO/PPI: Insights From A Successful SIP"
              style={{
                height: Mobile ? "15vh" : "100%",
                width: "100%",
                marginBottom: Mobile ? "-5%" : "-2%",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              variant={Mobile ? "h1" : "h1"}
              fontSize={Mobile ? "24px" : "36px"}
              sx={{
                zIndex: "20",
                bgcolor: "#FFF",
                fontWeight: "400",
                padding: "8px 20px",
                boxShadow: "0px 6px 49px 0px rgba(0, 0, 0, 0.08)",
              }}
            >
              SIP Success
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingInline: Mobile ? "15px" : "9.5rem",
            gap: Mobile ? "4rem" : "4.5rem",
            paddingBottom: "4.5rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: Mobile ? "0.5rem" : "1.5rem",
            }}
          >
            <Typography variant={Mobile ? "h2" : "h2"} fontSize={Mobile ? "20px" : "24px"} textAlign={Mobile ? "center" : ""}>
              SIP Success
            </Typography>
            <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
              Welcome to CareerCarve's SIP Success Session, a high-impact guide to enhancing your performance during your Summer Internship Program (SIP) and increasing your chances of securing a Pre-Placement Offer (PPO), Pre-Placement Interview (PPI), or an outstanding project outcome. Our specialized session is designed to equip you with the tools and strategies needed to make the most out of your internship experience.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: Mobile ? "column" : "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box width="100%" display="flex" flexDirection="column" gap={Mobile ? "0.5rem" : "1.5rem"}>
              <Typography fontSize={Mobile ? "20px" : "24px"} variant={Mobile ? "h5" : "h4"}>
                Why take a <span style={{ color: "#1C8EA8" }}>SIP Success</span> Session?
              </Typography>
              <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                The SIP Success Session at CareerCarve is a comprehensive and personalized program aimed at helping you excel in your Summer Internship Program. We understand the significance of internships in shaping your career, and our expert-led session aims to provide you with the necessary guidance to make a lasting impression during your internship. With a carefully curated starter kit and ongoing support from industry mentors, you'll be well-prepared to tackle your internship with confidence.
              </Typography>
              <button
                className="button-yellow-hover"
                style={{
                  width: "fit-content",
                  padding: "8px 2rem",
                  alignSelf: Mobile ? "center" : "",
                }}
                onClick={() => {
                  addToCart();
                }}
              >
                <Typography>Add to cart</Typography>
              </button>
            </Box>
            <Box width="100%" display={"flex"} justifyContent={"center"} alignItems={"center"}>
              <img src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/sip+picart.png" alt="MSecure Your PPO/PPI: Benefits Of SIP Success Sessions" style={{ width: "396px", height: "325px" }} />
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
            <Typography fontSize={Mobile ? "20px" : "24px"} variant={Mobile ? "h5" : "h4"}>
              How does it <span style={{ color: "#1C8EA8" }}>benefit</span> me?
            </Typography>
            <Box display="flex" flexDirection="column" gap="2rem">
              <Box display="flex" gap="0.5rem" flexDirection="column" sx={{ borderLeft: "2px solid #1C8EA8", paddingLeft: "1rem" }}>
                <Typography fontSize={Mobile ? "16px" : "20px"} variant={Mobile ? "h6" : "h5"}>
                  Pre-Internship Preparation
                </Typography>
                <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                  Days before your SIP commences, we provide you with a starter kit which will help you understand what to expect, set clear goals, and align your actions for a successful internship.
                </Typography>
              </Box>

              <Box display="flex" gap="0.5rem" flexDirection="column" sx={{ borderLeft: "2px solid #F5A536", paddingLeft: "1rem" }}>
                <Typography fontSize={Mobile ? "16px" : "20px"} variant={Mobile ? "h6" : "h5"}>
                  D-Day of the Internship
                </Typography>
                <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                  On the first day of your internship, you'll receive a planning kit which will guide you in creating a well-thought-out plan that aligns with your objectives and ensures you make the most of your internship.
                </Typography>
              </Box>

              <Box display="flex" gap="0.5rem" flexDirection="column" sx={{ borderLeft: "2px solid #1C8EA8", paddingLeft: "1rem" }}>
                <Typography fontSize={Mobile ? "16px" : "20px"} variant={Mobile ? "h6" : "h5"}>
                  Mid-way through Support
                </Typography>
                <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                  After 2-3 weeks in your internship, you'll have a 1X1 session with an industry mentor to discuss the aim, actions taken so far, future plans, and expected outcomes based on guidelines provided in the kit.
                </Typography>
              </Box>

              <Box display="flex" gap="0.5rem" flexDirection="column" sx={{ borderLeft: "2px solid #F5A536", paddingLeft: "1rem" }}>
                <Typography fontSize={Mobile ? "16px" : "20px"} variant={Mobile ? "h6" : "h5"}>
                  Expert Guidance
                </Typography>
                <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                  Our mentors will provide insights and suggest changes, additional actions, and new parameters to improve your current plan. This will help you maximize the impact of your internship project.
                </Typography>
              </Box>

              <Box display="flex" gap="0.5rem" flexDirection="column" sx={{ borderLeft: "2px solid #1C8EA8", paddingLeft: "1rem" }}>
                <Typography fontSize={Mobile ? "16px" : "20px"} variant={Mobile ? "h6" : "h5"}>
                  Road towards PPO/PPI
                </Typography>
                <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                  The focus will also be on the behavioral aspect, which plays a vital role in securing PPOs/PPIs. They will help you develop overall confidence, making you stand out as a promising candidate.
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexDirection={Mobile ? "column" : "row"} justifyContent="space-between" alignItems="center" gap={"8rem"}>
            <Box display="flex" justifyContent="space-between" gap="1.5rem" flexDirection="column">
              <Typography fontSize={Mobile ? "20px" : "24px"} variant={Mobile ? "h5" : "h4"}>
                Where will the <span style={{ color: "#1C8EA8" }}>session happen</span>?
              </Typography>
              <Typography fontSize="16px" color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                The SIP Success Session will take place online, offering you the convenience of attending from anywhere. The mid journey support session will be for 30 minutes and can be easily scheduled through our user-friendly app. Head to the CareerCarve App and book your SIP Success Training today!
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: Mobile ? "center" : "",
                }}
              >
                {!Mobile && <img src="../../QR_code_sign_up.jpg" style={{ height: "78px", width: "78px" }} alt="careercarve" />}
                <a href="https://play.google.com/store/apps/details?id=com.careercarve&hl=en&gl=US" target="_blank" title="careercarve-mobile-app" rel="noreferrer" style={{ textDecoration: "none" }}>
                  <button
                    className="button-yellow-hover"
                    style={{
                      width: "fit-content",
                      alignSelf: Mobile ? "center" : "",
                    }}
                    href="https://play.google.com/store/apps/details?id=com.careercarve&hl=en&gl=US"
                    target="_blank"
                  >
                    <Typography> Download the App</Typography>
                  </button>
                </a>
              </Box>
            </Box>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                width: Mobile ? "260px" : "385px",
                height: "385px",
                background: "rgba(56, 193, 223, 0.20)",
              }}
              paddingInline={"10rem"}
              borderRadius={"50%"}
            >
              <img
                src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/1+on+1+Mobile.png"
                alt="Secure Your PPO/PPI: Learn How to Attend MBA Placement Sessions"
                title="Prepare for your summer internship with expert guidance"
                style={{
                  width: Mobile ? "220px" : "243px",
                  height: Mobile ? "380px" : "480px",
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: Mobile ? "1.5rem" : "2.5rem",
            }}
          >
            <Typography fontSize={Mobile ? "24px" : "32px"} color="#1C8EA8" textAlign="center">
              View Other 1X1
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                {" "}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "100%",
                    gap: "30px",

                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                    padding: "1rem",
                    height: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                    }}
                  >
                    <Typography fontSize={Mobile ? "20px" : "24px"} variant={Mobile ? "h5" : "h4"}>
                      Mock Interview
                    </Typography>
                    <Typography fontSize={Mobile ? "14px" : "16px"} textAlign={"justify"} color="#8B8B8B" variant={Mobile ? "body2" : "body1"}>
                      Mock interviews are simulated job interviews that closely resemble real-life interview scenarios.
                    </Typography>
                  </Box>
                  <button className="button-yellow-hover" onClick={() => handleNavigate("/MockInterview")}>
                    <Typography>View More</Typography>
                  </button>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "100%",
                    gap: "30px",

                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                    padding: "1rem",
                    height: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                    }}
                  >
                    <Typography fontSize={Mobile ? "20px" : "24px"} variant={Mobile ? "h5" : "h4"}>
                      Expert Connect
                    </Typography>
                    <Typography fontSize={Mobile ? "14px" : "16px"} textAlign={"justify"} color="#8B8B8B" variant={Mobile ? "body2" : "body1"}>
                      The Expert Connect Session at CareerCarve is an open and flexible discussion platform that puts you in touch with a mentor who shares your area of interest.
                    </Typography>
                  </Box>
                  <button className="button-yellow-hover" onClick={() => handleNavigate("/ExpertConnect")}>
                    <Typography>View More</Typography>
                  </button>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "100%",
                    gap: "30px",
                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                    padding: "1rem",
                    height: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                    }}
                  >
                    <Typography fontSize={Mobile ? "20px" : "24px"} variant={Mobile ? "h5" : "h4"}>
                      PI Questions from Resume
                    </Typography>
                    <Typography fontSize={Mobile ? "14px" : "16px"} color="#8B8B8B" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                      The Personal Interview Questions from Resume Session at CareerCarve is a one-on-one consultation with experienced industry mentors.
                    </Typography>
                  </Box>
                  <button className="button-yellow-hover" onClick={() => handleNavigate("/PIQR")}>
                    <Typography>View More</Typography>
                  </button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          background: "linear-gradient(226deg, #1D2C30 0%, #020303 100%)",
        }}
      >
        <BottomNavbar />
      </Box>
    </>
  );
}

export default NewSipSuccess;
