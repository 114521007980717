
import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Box, Grid, Typography, useMediaQuery, CardActionArea } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

export const BlogsCompleteDetailsLoader = (props) => {
  
    return (
      <>
        <Box
          sx={{
            display: "flex",
            padding: props.Mobile ? "1rem" : "0rem 56px 5rem",
            alignItems: "center",
            width: "100%",
            flexDirection: props.Mobile ? "column" : "row",
            gap: props.Mobile ? "2rem" : "0rem"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: props.Mobile ? "100%" : "60%",
              paddingTop: "5rem",
              paddingLeft: props.Mobile ? "1rem" : "3rem",
              gap: "1rem",
            }}
          >
            {/* Blog Heading Placeholder */}
            <Skeleton variant="text" width="80%" height={40} />
  
            {/* Blog Description Placeholder */}
            <Skeleton variant="text" width="100%" height={100} />
  
            {/* Metadata Placeholder */}
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Skeleton variant="text" width="30%" height={20} />
              <Skeleton variant="text" width="10%" height={20} />
            </Box>
  
            {/* Tags Placeholder */}
            <Box sx={{ display: "flex", justifyContent: 'space-between' }}>
              <Skeleton variant="rectangular" width={50} height={20} />
              <Skeleton variant="rectangular" width={50} height={20} />
              <Skeleton variant="rectangular" width={50} height={20} />
            </Box>
  
            {/* Blog Image Placeholder */}
            <Skeleton variant="rectangular" width={props.Mobile ? "100%" : "100%"} height={300} />
  
            {/* Blog Sections Placeholder */}
            {Array.from({ length: 3 }).map((_, index) => (
              <Box key={index}>
                <Skeleton variant="text" width="80%" height={30} />
                <Skeleton variant="text" width="100%" height={100} />
                <Skeleton variant="rectangular" width="100%" height={250} />
              </Box>
            ))}
          </Box>
  
          <Box
            sx={{
              width: props.Mobile ? "100%" : "40%",
              justifyContent: "flex-start", // Change justifyContent to flex-end
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              top: "25%",
              paddingRight: props.Mobile ? "0rem" : "3rem",
              position: props.Mobile ? "relative" : "fixed",  // Change position to fixed
              right: "0",
              height: "100vh",
            }}
          >
            {/* Side Card Placeholder */}
            <Skeleton variant="rectangular" width={props.Mobile ? "100%" : "80%"} height="auto" />
          </Box>
        </Box>
      </>
    );
  };
