import { Star, StarOutline } from "@mui/icons-material";
import ArrowOutwardOutlinedIcon from "@mui/icons-material/ArrowOutwardOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import { Avatar, Box, Button, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../components/StateProvider/stateProvider";

function MentorCard({ mentor }) {
  const { user_id } = useStateValue();
  const env = `${process.env.REACT_APP_ENVIRONMENT}`;
  const Mobile = useMediaQuery("(max-width:800px)");
  const width = Mobile ? window.innerWidth * 0.18 : window.innerWidth * 0.075;
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/CartScreen", {
      state: {
        basket: [{ id: env === "dev" ? 16 : 2, search_tbl: "sku", quantity: 1 }],
        mentor_id: mentor.user_id,
        mentor_name: mentor.name,
        priority_number: 1,
      },
    });
  };
  return (
    <div style={{ boxShadow: "0px 6px 12px 0px #00000014", padding: "16px", borderRadius: "12px", height: "100%" }}>
      <Box sx={{ display: "flex", gap: "16px", "&:hover": { cursor: "pointer" }, zIndex: 1 }}>
        <Box sx={{ flex: 0.8 }} onClick={() => navigate(`/MentorDetails/${mentor.username}`, { state: { mentor_id: mentor.user_id } })}>
          {mentor.url !== null ? <img src={mentor.url} style={{ width: "100%", aspectRatio: "1/1", borderRadius: "50%" }} alt={mentor.name ? mentor.name : "CareerCarve"} /> : <Box sx={{ width: "100%", aspectRatio: "1/1", borderRadius: "50%", background: "#8B8B8B", display: "flex", justifyContent: "center", alignItems: "center", fontSize: Mobile ? "2rem" : "3rem" }}>{mentor.name[0].toUpperCase()}</Box>}
        </Box>

        <Box sx={{ flex: 4, display: "flex", flexDirection: "column", gap: "8px", justifyContent: "space-between" }}>
          <Box onClick={() => navigate(`/MentorDetails/${mentor.username}`, { state: { mentor_id: mentor.username } })} sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Box>
              <Typography sx={{ fontSize: Mobile ? "20px" : `min(${(24 / 1356) * window.innerWidth}px, 28px)`, color: "#4A4A4A" }}>{mentor.name}</Typography>
              <Typography sx={{ fontSize: Mobile ? "14px" : `${(16 / 1356) * window.innerWidth}px`, color: "#8B8B8B" }}>Number of sessions ({mentor.no_of_sessions})</Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "12px", flexWrap: "wrap" }}>
              <Box sx={{ display: "flex", gap: "2px", alignItems: "center" }}>
                <SchoolOutlinedIcon sx={{ fontSize: "20px", color: "#7A7A7A" }} />
                <Typography sx={{ fontSize: Mobile ? "14px" : `${(12 / 1356) * window.innerWidth}px`, color: "#4a4a4a" }}>{mentor.college_name}</Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "2px", alignItems: "center" }}>
                <WorkOutlineOutlinedIcon sx={{ fontSize: "20px", color: "#7A7A7A" }} />
                <Typography sx={{ fontSize: Mobile ? "14px" : `${(12 / 1356) * window.innerWidth}px`, color: "#4a4a4a" }}>
                  {mentor.current_workex.designation} @{mentor.current_workex.company}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
              {mentor.specialization.map((item) => (
                <Typography sx={{ padding: "5px", boxShadow: "0px 6px 12px 0px rgba(0,0,0,0.06)", fontSize: Mobile ? "12px" : `${(12 / 1356) * window.innerWidth}px`, borderRadius: "8px", color: "#7A7A7A" }}>{item}</Typography>
              ))}
            </Box>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
            <Box sx={{ display: "flex", alignItems: "center" }} onClick={() => navigate(`/MentorDetails/${mentor.username}`, { state: { mentor_id: mentor.user_id } })}>
              {[1, 2, 3, 4, 5].map((item) => {
                if (mentor.avg_rating.overall_rating >= item) {
                  return <Star sx={{ fontSize: Mobile ? "16px" : `${(24 / 1356) * window.innerWidth}px`, color: "#1C8EA8" }} />;
                } else {
                  return <StarOutline sx={{ fontSize: Mobile ? "16px" : `${(24 / 1356) * window.innerWidth}px`, color: "#1C8EA8" }} />;
                }
              })}
              {mentor.no_of_rated_exp.overall_count > 0 && <Typography sx={{ color: "#8B8B8B", fontSize: Mobile ? "12px" : `${(14 / 1356) * window.innerWidth}px` }}>({mentor.no_of_rated_exp.overall_count})</Typography>}
            </Box>
            <Button sx={{ fontSize: Mobile ? "12px" : "14px", background: "#1C8EA8", color: "#fff", textTransform: "none", "&:hover": { background: "#1C8EA8", color: "#fff" }, borderRadius: "8px", padding: "8px 12px" }} onClick={() => handleButtonClick()}>
              Book now <ArrowOutwardOutlinedIcon sx={{ ml: 1, fontSize: "18px" }} />
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default MentorCard;
