import React from 'react'
import { Box, List, ListItem, ListItemText, ListItemIcon, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Color } from '../../GlobalStyles';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import HourglassTopRoundedIcon from '@mui/icons-material/HourglassTopRounded';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ShareFeature from './WebinarShare';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import WebinarRegisterNow from './WebinarRegisterNow';
import { makeTextBold } from '../LandingPage/Blogs/RichTextEditor';
import { convertEpochToNormalTime, ddmmmWithYear } from '../../services/specialCharReplace';
import { secondsToMinutesAndHours } from '../../services/specialCharReplace';
import { WebinarCompleteDetailsSkeleton } from './SkeletonLoaders/WebinarDetailsSkeletonLoader';
const WebinarCompleteDetails = (props) => {
    const { webinarDetails, event_id, mode, loading, registered, setEventRegistered, setRegistered, handleWebinarEventsClick, eventRegistered } = props
    console.log("details of webinar", webinarDetails);
    const Mobile = useMediaQuery('(max-width:800px)');

    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth' // Optional, smooth scrolling animation
        });
    };

    return (
        <>
            {loading.details_loader ? <WebinarCompleteDetailsSkeleton Mobile={Mobile} /> : <Box sx={{ display: 'flex', flexDirection: Mobile ? "column" : 'row', gap: '20px', width: Mobile ? "100%" : "80%", margin: "auto", marginTop: Mobile ? "0rem" : "1rem", paddingRight: Mobile ? "1rem" : 0, paddingLeft: Mobile ? "1rem" : 0 }}>
                {/* left part */}
                <Box sx={{ width: "100%", maxWidth: Mobile ? "none" : "55%", display: "flex", flexDirection: "column", gap: ".8rem" }}>
                    <Typography variant={Mobile ? "body1" : "h6"} sx={{ fontWeight: Mobile ? "bold" : "normal" }} gutterBottom>
                        {webinarDetails?.description}
                    </Typography>
                    {Mobile && (mode === "past") &&
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <Typography sx={{ fontStyle: "italic", fontSize: "12px", alignItems: 'center', margin: "auto 0", whiteSpace: "nowrap" }}>
                                {mode === "upcoming" ? "Want to know more about upcoming events?" : "OOPS! This webinar has ended"}
                            </Typography>
                            <button onClick={handleWebinarEventsClick} className="pricingSingleACtivityButton1" style={{ backgroundColor: Color.registerColor, color: Color.white, height: "35px" }}>
                                Browse other webinars
                            </button>
                        </Box>}
                    <Box sx={{ display: "flex", flexDirection: "row", gap: Mobile ? ".5rem" : "1.5rem" }}>
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <CalendarMonthOutlinedIcon sx={{ width: "20px", height: "20px", marginRight: "0.5rem", color: Mobile ? Color.neutralDarkGrey : Color.neutralMidGrey }} />
                            <Typography sx={{ margin: "auto 0", color: Mobile ? Color.neutralDarkGrey : Color.neutralMidGrey }}>
                                {ddmmmWithYear(webinarDetails?.date)}
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "row", alignItem: 'center' }}>
                            <HistoryOutlinedIcon sx={{ width: "20px", height: "20px", marginRight: "0.5rem", color: Mobile ? Color.neutralDarkGrey : Color.neutralMidGrey }} />
                            <Typography variant="body2" color="text.secondary" sx={{ margin: "auto 0", color: Mobile ? Color.neutralDarkGrey : Color.neutralMidGrey }}>
                                {convertEpochToNormalTime(webinarDetails?.date)} IST
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "row", alignItem: 'center' }}>
                            <HourglassTopRoundedIcon sx={{ width: "20px", height: "20px", marginRight: "0.5rem", color: Mobile ? Color.neutralDarkGrey : Color.neutralMidGrey }} />
                            <Typography variant="body2" color="text.secondary" sx={{ margin: "auto 0", color: Mobile ? Color.neutralDarkGrey : Color.neutralMidGrey }}>
                                {secondsToMinutesAndHours(webinarDetails?.duration)}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row", gap: ".5rem" }}>
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", color: Mobile ? Color.neutralDarkGrey : Color.neutralMidGrey }}>
                            <FmdGoodOutlinedIcon sx={{ width: "20px", height: "20px", marginRight: "0.5rem", color: Mobile ? Color.neutralDarkGrey : Color.neutralMidGrey }} />
                            <Typography sx={{ margin: "auto 0" }}>
                                {webinarDetails?.location}
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "row", alignItem: 'center' }}>
                            <CurrencyRupeeOutlinedIcon sx={{ width: "20px", height: "20px", marginRight: "0.5rem", color: Mobile ? Color.neutralDarkGrey : Color.neutralMidGrey }} />
                            <Typography variant="body2" color="text.secondary" sx={{ margin: "auto 0", color: Mobile ? Color.neutralDarkGrey : Color.neutralMidGrey }}>
                                {webinarDetails?.fees === 0 ? "Free" : webinarDetails?.fees}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

                        <Box sx={{ display: "flex", flexDirection: "row", alignItem: 'center' }}>
                            {webinarDetails.registered > 200 && <>
                                <PeopleAltOutlinedIcon sx={{ width: "20px", height: "20px", marginRight: ".5rem", color: Color.webinarTicker }} />
                                <Typography variant="body2" color="text.secondary" sx={{ color: Color.webinarTicker }}>
                                    {webinarDetails?.registered} Attendees
                                </Typography></>}

                        </Box>
                        {!Mobile && <Box sx={{ display: "flex", flexDirection: "row", alignItem: 'right' }}>
                            <Typography variant="body2" color="text.secondary" sx={{ margin: "auto 0", color: Color.primary1 }}>
                                Share
                            </Typography>
                            <ShareFeature webinarDetails={webinarDetails} />
                        </Box>}
                    </Box>
                    {Mobile && (mode !== "past") &&
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            {(eventRegistered || registered) ? <button onClick={handleWebinarEventsClick} className="pricingSingleACtivityButton1" style={{ backgroundColor: Color.registerColor, color: Color.white }}>
                                Browse other webinars
                            </button> : <button onClick={scrollToBottom} className="pricingSingleACtivityButton1" style={{ backgroundColor: Color.registerColor, color: Color.white }}>
                                Register for Free
                            </button>}

                            <Box sx={{ display: "flex", flexDirection: "row", alignItem: 'right' }}>
                                <Typography variant="body2" color="text.secondary" sx={{ margin: "auto 0", color: Color.primary1 }}>
                                    Share
                                </Typography>
                                <ShareFeature webinarDetails={webinarDetails} />
                            </Box>
                        </Box>}
                    <Box sx={{ display: 'flex', flexDirection: "column", gap: "1rem" }}>
                        <Box>
                            <Typography fontSize={Mobile ? 14 : 24} fontWeight={Mobile ? "bold" : 500} sx={{ marginBottom: 1 }}>About the webinar</Typography>
                            <Typography fontSize={Mobile ? 14 : 15} lineHeight={1.5} width={Mobile ? "100%" : 500} color={Mobile ? Color.neutralDarkGrey : Color.neutralMidGrey}>
                                {webinarDetails?.about}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography fontSize={Mobile ? 14 : 24} fontWeight={Mobile ? "bold" : 500} sx={{ marginBottom: 1 }}>
                                {JSON.parse(webinarDetails?.detailed_description_web)?.header}
                            </Typography>
                            <List sx={{ padding: 0, margin: 0, }} color={Mobile ? Color.neutralDarkGrey : Color.neutralMidGrey}>
                                {JSON.parse(webinarDetails?.detailed_description_web)?.bullets.map((item, index) => (
                                    <ListItem key={index} sx={{ padding: 0, margin: 0 }}>
                                        <ListItemIcon sx={{ minWidth: 0 }}>
                                            <DoneOutlinedIcon sx={{ color: Mobile ? Color.neutralDarkGrey : Color.neutralMidGrey, marginRight: 0 }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            variant="body2"
                                            primary={item.text}
                                            primaryTypographyProps={{
                                                style: {
                                                    paddingLeft: 0,
                                                    paddingRight: 0,
                                                    margin: 0,
                                                    color: Mobile ? Color.neutralDarkGrey : Color.neutralMidGrey,
                                                    fontSize: Mobile ? 14 : 15,
                                                    //   ...(Mobile && {whiteSpace:"nowrap"})
                                                },
                                            }}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    </Box>
                </Box>
                {/* right part */}
                {!Mobile && <Box sx={{ width: Mobile ? "100%" : '45%' }}>
                    <WebinarRegisterNow isMobile={false} event_id={event_id} mode={mode} registered={registered} setEventRegistered={setEventRegistered} setRegistered={setRegistered} handleWebinarEventsClick={handleWebinarEventsClick} eventRegistered={eventRegistered} webinarDetails={webinarDetails}/>
                </Box>}

            </Box>}

        </>
    )
}

export default WebinarCompleteDetails;
