import {
  // Accordion,
  // AccordionDetails,
  // AccordionSummary,
  Box,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import ContactUs from "../ContactUs/ContactUs";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { useState } from "react";
function BottomNavbar() {
  const Mobile = useMediaQuery("(max-width:800px)");
  // const [ProductExpanded, setProductExpanded] = useState(false);
  // const [solutionExpanded, setSolutionsExpanded] = useState(false);
  // const [companyExpanded, setCompanyExpanded] = useState(false);
  // const [joinUsExpanded, setJoinUsExpanded] = useState(false);

  // const handleChange = (item) => {
  //   if (item === "Products") {
  //     setProductExpanded((prev) => !prev);
  //   } else if (item === "Solution") {
  //     setSolutionsExpanded((prev) => !prev);
  //   } else if (item === "Company") {
  //     setCompanyExpanded((prev) => !prev);
  //   } else if (item === "Join Us") {
  //     setJoinUsExpanded((prev) => !prev);
  //   }
  // };
  return (
    <Box sx={{}}>
      {Mobile ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "4.5rem",
            marginTop: "1.5rem",
            paddingBlock: "30px",
          }}
        >
          <ContactUs />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
              padding: "30px 20px",
            }}
          >
            {/* <Accordion
            expanded={ProductExpanded}
            onChange={(e) => handleChange("Products")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{ paddingBlock: "5px" }}
            >
              <Typography variant="body1" sx={{ fontSize: "16px" }}>
                Products
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  width: "100%",
                }}
              >
                <a
                  href="/MockInterview"
                  rel="follow"
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "14px", color: "#fff"  }}
                  >
                    Mock Interview
                  </Typography>
                </a>
                <a
                  href="/ResumeDiscussion"
                  rel="follow"
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "14px", color: "#fff"  }}
                  >
                    Resume Discussion
                  </Typography>
                </a>
                <a
                  href="/SipSuccess"
                  rel="follow"
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "14px", color: "#fff"  }}
                  >
                    SIP Success
                  </Typography>
                </a>
                <a
                  href="/ExpertConnect"
                  rel="follow"
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "14px", color: "#fff"  }}
                  >
                    Expert Connect
                  </Typography>
                </a>
                <a
                  href="/CaseInterview"
                  rel="follow"
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "14px", color: "#fff"  }}
                  >
                    Case Interview
                  </Typography>
                </a>
                <a href="/PIQR" rel="follow" style={{ textDecoration: "none" }}>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "14px", color: "#fff"  }}
                  >
                    Personal Interview Questions from Resume
                  </Typography>
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.careercarve&hl=en&gl=US"
                  rel="follow"
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "14px", color: "#fff"  }}
                  >
                    Podcasts and Sessions
                  </Typography>
                </a>
                <a
                  href="https://www.resumes.digital"
                  rel="follow noreferrer"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "14px", color: "#fff"  }}
                  >
                    Resumes.Digital
                  </Typography>
                </a>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={solutionExpanded}
            onChange={(e) => handleChange("Solution")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography variant="body1" sx={{ fontSize: "16px" }}>
                Solutions
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  width: "100%",
                }}
              >
                <a
                  href="/Pricing"
                  rel="follow"
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "14px", color: "#fff"  }}
                  >
                    Online Store
                  </Typography>
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.careercarve&hl=en&gl=US"
                  target="_blank"
                  rel="follow"
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "14px", color: "#fff"  }}
                  >
                    Mobile Application
                  </Typography>
                </a>
                <a href="/" rel="follow" style={{ textDecoration: "none" }}>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "14px", color: "#fff"  }}
                  >
                    Web Application
                  </Typography>
                </a>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={companyExpanded}
            onChange={(e) => handleChange("Company")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography variant="body1" sx={{ fontSize: "16px" }}>
                Company
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  width: "100%",
                }}
              >
                <a
                  href="/AboutUs"
                  rel="follow"
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "14px", color: "#fff"  }}
                  >
                    About Us
                  </Typography>
                </a>
                <a
                  href="#contactus"
                  rel="follow"
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "14px", color: "#fff"  }}
                  >
                    Contact Us
                  </Typography>
                </a>
                <a
                  href="/Mentors"
                  rel="follow"
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "14px", color: "#fff"  }}
                  >
                    About Mentors
                  </Typography>
                </a>
                <a
                  href="/PrivacyPolicy"
                  rel="follow"
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "14px", color: "#fff" }}
                  >
                    Privacy Policy
                  </Typography>
                </a>
                <a
                  href="/TermsOfUse"
                  rel="follow"
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "14px", color: "#fff" }}
                  >
                    Terms and Conditions
                  </Typography>
                </a>
                <a href="/" rel="follow" style={{ textDecoration: "none" }}>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "14px", color: "#fff" }}
                  >
                    Sitemap
                  </Typography>
                </a>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={joinUsExpanded}
            onChange={(e) => handleChange("Join Us")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography variant="body1" sx={{ fontSize: "16px" }}>
                Join Us
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  width: "100%",
                }}
              >
                <a
                  href="/BecomeMentor"
                  rel="follow"
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "14px", color: "#fff" }}
                  >
                    Become a Mentor
                  </Typography>
                </a>
                <a
                  href="/"
                  target="_blank"
                  rel="follow"
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "14px", color: "#fff" }}
                  >
                    Summer Internship Program
                  </Typography>
                </a>
                <a
                  href="/Mentors"
                  rel="follow"
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "14px", color: "#fff" }}
                  >
                    About Mentors
                  </Typography>
                </a>
              </Box>
            </AccordionDetails>
          </Accordion> */}
            <Grid container rowSpacing={5} columnSpacing={5}>
              <Grid item xs={6} md={3}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    width: "100%",
                  }}
                >
                  <Typography variant="body1" sx={{ fontSize: "16px", fontWeight: "600", color: "#fff" }}>
                    Products
                  </Typography>
                  <a href="/MockInterview" rel="follow" style={{ textDecoration: "none" }}>
                    <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                      Mock Interview
                    </Typography>
                  </a>
                  <a href="/ResumeDiscussion" rel="follow" style={{ textDecoration: "none" }}>
                    <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                      Resume Discussion
                    </Typography>
                  </a>
                  <a href="/SipSuccess" rel="follow" style={{ textDecoration: "none" }}>
                    <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                      SIP Success
                    </Typography>
                  </a>
                  <a href="/ExpertConnect" rel="follow" style={{ textDecoration: "none" }}>
                    <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                      Expert Connect
                    </Typography>
                  </a>
                  <a href="/CaseInterview" rel="follow" style={{ textDecoration: "none" }}>
                    <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                      Case Interview
                    </Typography>
                  </a>
                  <a href="/PIQR" rel="follow" style={{ textDecoration: "none" }}>
                    <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                      PI Questions from Resume
                    </Typography>
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.careercarve&hl=en&gl=US" rel="follow" style={{ textDecoration: "none" }}>
                    <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                      Podcasts and Sessions
                    </Typography>
                  </a>
                  <a href="https://www.resumes.digital" rel="follow noreferrer" target="_blank" style={{ textDecoration: "none" }}>
                    <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                      Resumes.Digital
                    </Typography>
                  </a>
                </Box>
              </Grid>
              <Grid item xs={6} md={3}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    width: "100%",
                  }}
                >
                  <Typography variant="body1" sx={{ fontSize: "16px", fontWeight: "600", color: "#fff" }}>
                    Company
                  </Typography>
                  <a href="/AboutUs" rel="follow" style={{ textDecoration: "none" }}>
                    <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                      About Us
                    </Typography>
                  </a>
                  <a href="#contactus" rel="follow" style={{ textDecoration: "none" }}>
                    <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                      Contact Us
                    </Typography>
                  </a>
                  <a href="/Mentors" rel="follow" style={{ textDecoration: "none" }}>
                    <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                      About Mentors
                    </Typography>
                  </a>
                  <a href="/PrivacyPolicy" rel="follow" style={{ textDecoration: "none" }}>
                    <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                      Privacy Policy
                    </Typography>
                  </a>
                  <a href="/TermsOfUse" rel="follow" style={{ textDecoration: "none" }}>
                    <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                      Terms and Conditions
                    </Typography>
                  </a>
                  <a href="/" rel="follow" style={{ textDecoration: "none" }}>
                    <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                      Sitemap
                    </Typography>
                  </a>
                </Box>
              </Grid>
              <Grid item xs={6} md={3}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    width: "100%",
                  }}
                >
                  <Typography variant="body1" sx={{ fontSize: "16px", fontWeight: "600", color: "#fff" }}>
                    Solutions
                  </Typography>
                  <a href="/Pricing" rel="follow" style={{ textDecoration: "none" }}>
                    <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                      Online Store
                    </Typography>
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.careercarve&hl=en&gl=US" target="_blank" rel="follow noreferrer" style={{ textDecoration: "none" }}>
                    <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                      Mobile Application
                    </Typography>
                  </a>
                  <a href="/" rel="follow" style={{ textDecoration: "none" }}>
                    <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                      Web Application
                    </Typography>
                  </a>
                </Box>
              </Grid>
              <Grid item xs={6} md={3}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    width: "100%",
                  }}
                >
                  <Typography variant="body1" sx={{ fontSize: "16px", fontWeight: "600", color: "#fff" }}>
                    Join Us
                  </Typography>
                  <a href="/BecomeMentor" rel="follow" style={{ textDecoration: "none" }}>
                    <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                      Become a Mentor
                    </Typography>
                  </a>
                  {/* <a
                  href="/"
                  target="_blank"
                  rel="follow"
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "14px", color: "#fff" }}
                  >
                    Summer Internship Program
                  </Typography>
                </a> */}
                  <a href="/Mentors" rel="follow" style={{ textDecoration: "none" }}>
                    <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                      About Mentors
                    </Typography>
                  </a>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} sx={{ padding: "20px 15px", gap: "1rem" }}>
            <Box sx={{ "&:hover": { cursor: "pointer" } }}>
              <a href="/">
                <img src="../../careercarve-logo-white.png" className="navbar__image" alt="CareerCarve" width="150px" style={{ objectFit: "fit" }} />
              </a>
            </Box>
            <Typography sx={{ fontSize: "12px", color: "#E6E6E6", textAlign: "center" }}>&copy; 2023 Parivartana Consulting Private Limited. All rights reserved.</Typography>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "4.5rem",
            // marginTop: "5rem",
            padding: "30px 9.5rem",
          }}
        >
          <ContactUs />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                width: "100%",
              }}
            >
              <Typography variant="body1" sx={{ fontSize: "16px", fontWeight: "600", color: "#fff" }}>
                Products
              </Typography>
              <a href="/MockInterview" rel="follow" style={{ textDecoration: "none" }}>
                <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                  Mock Interview
                </Typography>
              </a>
              <a href="/ResumeDiscussion" rel="follow" style={{ textDecoration: "none" }}>
                <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                  Resume Discussion
                </Typography>
              </a>
              <a href="/SipSuccess" rel="follow" style={{ textDecoration: "none" }}>
                <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                  SIP Success
                </Typography>
              </a>
              <a href="/ExpertConnect" rel="follow" style={{ textDecoration: "none" }}>
                <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                  Expert Connect
                </Typography>
              </a>
              <a href="/CaseInterview" rel="follow" style={{ textDecoration: "none" }}>
                <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                  Case Interview
                </Typography>
              </a>
              <a href="/PIQR" rel="follow" style={{ textDecoration: "none" }}>
                <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                  PI Questions from Resume
                </Typography>
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.careercarve&hl=en&gl=US" rel="follow" style={{ textDecoration: "none" }}>
                <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                  Podcasts and Sessions
                </Typography>
              </a>
              <a href="https://www.resumes.digital" rel="follow noreferrer" target="_blank" style={{ textDecoration: "none" }}>
                <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                  Resumes.Digital
                </Typography>
              </a>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                width: "100%",
              }}
            >
              <Typography variant="body1" sx={{ fontSize: "16px", fontWeight: "600", color: "#fff" }}>
                Company
              </Typography>
              <a href="/AboutUs" rel="follow" style={{ textDecoration: "none" }}>
                <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                  About Us
                </Typography>
              </a>
              <a href="#contactus" rel="follow" style={{ textDecoration: "none" }}>
                <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                  Contact Us
                </Typography>
              </a>
              <a href="/Mentors" rel="follow" style={{ textDecoration: "none" }}>
                <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                  About Mentors
                </Typography>
              </a>
              <a href="/PrivacyPolicy" rel="follow" style={{ textDecoration: "none" }}>
                <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                  Privacy Policy
                </Typography>
              </a>
              <a href="/TermsOfUse" rel="follow" style={{ textDecoration: "none" }}>
                <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                  Terms and Conditions
                </Typography>
              </a>
              <a href="/" rel="follow" style={{ textDecoration: "none" }}>
                <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                  Sitemap
                </Typography>
              </a>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                width: "100%",
              }}
            >
              <Typography variant="body1" sx={{ fontSize: "16px", fontWeight: "600", color: "#fff" }}>
                Solutions
              </Typography>
              <a href="/Pricing" rel="follow" style={{ textDecoration: "none" }}>
                <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                  Online Store
                </Typography>
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.careercarve&hl=en&gl=US" target="_blank" rel="follow noreferrer" style={{ textDecoration: "none" }}>
                <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                  Mobile Application
                </Typography>
              </a>
              <a href="/" rel="follow" style={{ textDecoration: "none" }}>
                <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                  Web Application
                </Typography>
              </a>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                width: "100%",
              }}
            >
              <Typography variant="body1" sx={{ fontSize: "16px", fontWeight: "600", color: "#fff" }}>
                Join Us
              </Typography>
              <a href="/BecomeMentor" rel="follow" style={{ textDecoration: "none" }}>
                <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                  Become a Mentor
                </Typography>
              </a>
              {/* <a
                  href="/"
                  target="_blank"
                  rel="follow"
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "14px", color: "#fff"  }}
                  >
                    Summer Internship Program
                  </Typography>
                </a> */}
              <a href="/Mentors" rel="follow" style={{ textDecoration: "none" }}>
                <Typography variant="body2" sx={{ fontSize: "14px", color: "#fff" }}>
                  About Mentors
                </Typography>
              </a>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "2px",
                width: "100%",
              }}
            >
              <Box sx={{ "&:hover": { cursor: "pointer" } }}>
                <a href="/">
                  <img src="../../careercarve-logo-white.png" className="navbar__image" alt="CareerCarve" width="100%" style={{ objectFit: "fit" }} />
                </a>
              </Box>
              <Typography sx={{ fontSize: "12px", color: "#fff" }}>&copy; 2023 Parivartana Consulting Private Limited. All rights reserved.</Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default BottomNavbar;
