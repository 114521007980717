import React, { useEffect, useState } from "react";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Box, useMediaQuery, Typography, Button, Skeleton } from "@mui/material";
import { ArrowOutwardOutlined, ExpandMore, LocationCityOutlined, PlayCircleOutline, Podcasts, SchoolOutlined, Star, StarOutline, VideocamOutlined, Work, WorkHistoryOutlined } from "@mui/icons-material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import RDService from "../../services/RDService";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Fade from "@mui/material/Fade";
import { get_specialCharReplace } from "../../services/specialCharReplace";
import { useWebinarContext } from "../../WebinarContext";
function MentorDetails() {
  const Mobile = useMediaQuery("(max-width:800px)");
  const { webinarTickerExists } = useWebinarContext();
  const queryParams = new URLSearchParams(window.location.search);
  const mentorId = queryParams.get("mentor_id");
  const { username } = useParams();

  const services = new RDService();
  const location = useLocation();
  const navigate = useNavigate();
  const mentor_id = location.state?.mentor_id ? location.state?.mentor_id : mentorId;
  const [expanded, setExpanded] = useState(null);
  const [showFullFAQ, setShowFullFAQ] = useState(false);
  const [mentorDetail, setMentorDetail] = useState({
    user_id: null,
  });
  const [readMore, setReadMore] = useState(false);
  const [faqs, setFaqs] = useState([]);
  const [mentorFeedback, setMentorFeedback] = useState([]);
  const getMentorDetails = async () => {
    const data = await services.get(`mentorModule/mentor/detailByUsername?username=${username}`);

    const roles = await services.get("/superAdmin/role");
    console.log("ANJKfjkd", roles, data);
    const sku = await services.get(`/cartModule/skuByActivity?act_type_id=${JSON.parse(data.data.allowed_activity_type).allowed_activity_type.join(",")}`);
    const faqData = await services.get("/faq?user_type=mentor");
    const roleIds = JSON.parse(data.data.role_type).role_type_ids;
    const allowedRoles = roles.data.filter((item) => roleIds.includes(item.id));
    setFaqs(faqData.data.section_detail);
    setMentorFeedback(data.mentor_feedbacks);

    let temp = {
      name: `${data.data.first_name} ${data.data.last_name}`,
      user_id: data.data.user_id,
      allowed_activity: sku.data,
      url: data.data.url,
      role_type: JSON.parse(data.data.role_type).role_type_ids,
      college_name: data.data.college_name,
      about_mentor: data.data.about_mentor,
      avg_rating: JSON.parse(data.data.avg_rating),
      no_of_rated_exp: JSON.parse(data.data.no_of_rated_exp),
      current_workex: JSON.parse(data.data.current_workex),
      past_workex: JSON.parse(data.data.past_workex).workex ? JSON.parse(data.data.past_workex) : { workex: [] },
      active_flag: data.data.active_flag,
      no_of_sessions: data.session_count,
      specialization: allowedRoles,
    };

    setMentorDetail(temp);
  };

  useEffect(() => {
    window.scroll(0, 0);
    getMentorDetails();
  }, []);

  const colors = [
    { color: "rgba(255, 114, 94, 1)", background: "rgba(255, 114, 94, 0.1)" },
    { color: "rgba(245, 165, 54, 1)", background: "rgba(245, 165, 54, 0.1)" },
    { color: "rgba(8, 171, 63, 1)", background: "rgba(8, 171, 63, 0.1)" },
  ];
  return (
    <Box sx={{ paddingBottom: "25px", marginTop: webinarTickerExists && Mobile ? "80px" : !webinarTickerExists && Mobile ? "50px" : webinarTickerExists ? "112px" : "70px", height: "100%" }}>
      <Box sx={{ marginBottom: Mobile ? "-0.6rem" : "-1.4rem" }}>
        <Box>
          <img
            src="https://du03hetco8xdw.cloudfront.net/landing_page/mentoDetailsBg.jpg"
            alt=" Meet the mentors at CareerCarve, your trusted partners for expert career guidance and mentorship"
            style={{
              height: Mobile ? "15vh" : "250px",
              width: "100%",
              marginBottom: "-2%",
            }}
          />
        </Box>
      </Box>

      {mentorDetail.user_id !== null ? (
        <Box>
          <Box sx={{ paddingInline: Mobile ? "1rem" : "9rem" }}>
            <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
              {[null, ""].includes(mentorDetail.url) ? <Box sx={{ borderRadius: "50%", width: Mobile ? "18%" : "12%", aspectRatio: "1/1", background: "#909090", fontSize: Mobile ? "1.5rem" : "3rem", display: "flex", justifyContent: "center", alignItems: "center" }}>{mentorDetail.name[0]}</Box> : <img src={mentorDetail.url} style={{ width: Mobile ? "18%" : "12%", aspectRatio: "1/1", borderRadius: "50%", border: "1px solid #1C8EA8" }} />}
              <Typography sx={{ mt: Mobile ? "12px" : "36px", fontSize: Mobile ? "12px" : "18px", color: "#F5A536", padding: "0px 8px", background: "rgba(245, 165, 54, 0.3)" }}>
                <VerifiedIcon sx={{ fontSize: Mobile ? "14px" : "20px" }} /> Top Mentor
              </Typography>
            </Box>
          </Box>
          {Mobile ? (
            <Box sx={{ display: "flex", gap: "20px", justifyContent: "space-between", position: "relative", mt: 2 }}>
              <Box sx={{ flex: 1.5, display: "flex", flexDirection: "column", gap: "20px", marginBottom: "2rem" }}>
                <Box sx={{ paddingInline: Mobile ? "1rem" : "9rem" }}>
                  <Typography sx={{ fontSize: Mobile ? "18px" : "36px", fontWeight: "600" }}>{mentorDetail.name}</Typography>
                  <Box sx={{ display: "flex", gap: "5px" }}>
                    <Star sx={{ color: "rgba(245, 165, 54, 1)" }} />
                    <Typography sx={{ color: "rgba(139, 139, 139, 1)", fontSize: Mobile ? "14px" : "16px" }}>
                      {mentorDetail.avg_rating?.overall_rating ? mentorDetail.avg_rating?.overall_rating?.toFixed(2) : 0} ({mentorDetail.no_of_rated_exp?.overall_count ?? 0})
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem", paddingInline: Mobile ? "1rem" : "9rem" }}>
                  {/* <Box sx={{ width: "4px", background: "#1C8EA8", height: "80%", borderRadius: "20px" }}></Box> */}
                  <Typography sx={{ fontSize: Mobile ? "12px" : "16px", color: "#7A7A7A", borderLeft: "2px solid #1C8EA8", paddingLeft: "1rem" }}>
                    <span style={{ color: "#1C8EA8", fontSize: Mobile ? "14px" : "20px" }}>"</span>

                    {mentorDetail.about_mentor.length > 120 && !readMore ? <span>{`${get_specialCharReplace(mentorDetail.about_mentor.slice(0, 120))}...`} </span> : <span>{get_specialCharReplace(mentorDetail.about_mentor)} </span>}

                    <span style={{ color: "#1C8EA8", fontSize: Mobile ? "14px" : "20px", lineHeight: "0px", fontFamily: `"Allerta", sans-serif` }}>"</span>
                    {mentorDetail.about_mentor.length > 120 && (
                      <Typography sx={{ color: "#1C8EA8", display: "inline", "&:hover": { cursor: "pointer" }, marginLeft: "2px", fontSize: Mobile ? "11px" : `${(12 / 1356) * window.innerWidth}px` }} onClick={() => setReadMore((prev) => !prev)}>
                        {readMore ? "Read Less" : "Read More"}
                      </Typography>
                    )}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", paddingInline: Mobile ? "1rem" : "9rem" }}>
                  <Box sx={{ display: "flex", gap: "2px", alignItems: "center" }}>
                    <SchoolOutlined sx={{ fontSize: "22px", color: "#F5A536" }} />
                    <Typography sx={{ fontSize: Mobile ? "12px" : "20px", color: "#7A7A7A" }}>{mentorDetail.college_name}</Typography>
                  </Box>
                  {/* <Box sx={{ display: "flex", gap: "2px", alignItems: "center", paddingInline: Mobile ? "1rem" : "9rem" }}>
                    <WorkHistoryOutlined sx={{ fontSize: "22px", color: "#F5A536" }} />
                    <Typography sx={{ fontSize: Mobile ? "12px" : "20px", color: "#7A7A7A" }}>{mentorDetail.college_name}</Typography>
                  </Box> */}
                  <Box sx={{ display: "flex", gap: "2px", alignItems: "center", paddingInline: Mobile ? "1rem" : "9rem" }}>
                    <VideocamOutlined sx={{ fontSize: "22px", color: "#F5A536" }} />
                    <Typography sx={{ fontSize: Mobile ? "12px" : "20px", color: "#7A7A7A" }}>{mentorDetail.no_of_sessions}+ Sessions</Typography>
                  </Box>
                </Box>
                <Box sx={{ width: "100%", height: "2px", background: "#909090", paddingInline: Mobile ? "1rem" : "9rem" }}></Box>
                <Box sx={{ gap: "16px", display: "flex", flexDirection: "column", paddingInline: Mobile ? "1rem" : "9rem" }}>
                  <Typography sx={{ fontSize: Mobile ? "16px" : "32px", fontWeight: "600" }}>Companies I’ve worked with</Typography>
                  {mentorDetail.past_workex.workex.map((item, index) => (
                    <Box sx={{ padding: "10px", borderRadius: "12px", borderLeft: `2px solid ${colors[index % colors.length].color}`, boxShadow: "0px 6px 12px 0px #00000014", display: "flex", gap: "10px", justifyContent: "space-between", alignItems: "center" }}>
                      <Box sx={{ display: "flex", gap: "20px", alignItems: "center" }}>
                        <Box sx={{ padding: "10px", borderRadius: "10px", background: colors[index % colors.length].background, height: "fit-content" }}>
                          <Work sx={{ color: colors[index % colors.length].color }} />
                        </Box>

                        <Typography sx={{ fontSize: Mobile ? "16px" : "24px", fontWeight: 600 }}>{item.company}</Typography>
                      </Box>

                      <Typography sx={{ fontSize: Mobile ? "16px" : "24px", color: "#7A7A7A" }}>{item.designation}</Typography>
                    </Box>
                  ))}
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "16px", color: "#3A3A3A", paddingInline: Mobile ? "1rem" : "9rem", fontWeight: "600" }}>Sessions conducted by me</Typography>
                  <Box sx={{}}>
                    {mentorDetail.allowed_activity.map((item, index) => (
                      <Box sx={{}}>
                        <Box sx={{ paddingInline: Mobile ? "1rem" : "9rem" }}>
                          <Box sx={{ boxShadow: "0px 6px 12px 0px #00000014", borderRadius: "15px", padding: "12px 8px" }}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "16px" }}>
                              <Box>
                                <Typography sx={{ fontSize: "14px", color: "#4A4A4A" }}>{item.name}</Typography>
                                <Typography sx={{ fontSize: "14px", color: "#1C8EA8" }}>₹{item.price}</Typography>
                              </Box>
                              <Button
                                sx={{ fontSize: Mobile ? "14px" : "16px", background: "#1C8EA8", height: "fit-content", color: "#fff", textTransform: "none", "&:hover": { background: "#1C8EA8", color: "#fff" } }}
                                onClick={() => {
                                  navigate("/CartScreen", {
                                    state: {
                                      basket: [{ id: item.id, search_tbl: "sku", quantity: 1 }],
                                      mentor_id: mentorDetail.user_id,
                                      mentor_name: mentorDetail.name,
                                      priority_number: 1,
                                    },
                                  });
                                }}
                              >
                                Book now <ArrowOutwardOutlined sx={{ ml: 1, fontSize: "14px" }} />
                              </Button>
                            </Box>
                            <Typography sx={{ color: "#7A7A7A", fontSize: "12px" }}>{item.description}</Typography>
                          </Box>
                        </Box>
                        <Box sx={{ width: "100%", height: "1px", background: "#909090", marginBlock: "16px" }} />
                      </Box>
                    ))}
                  </Box>
                </Box>

                <Box sx={{ paddingInline: Mobile ? "1rem" : "9rem" }}>
                  <Typography sx={{ fontSize: Mobile ? "16px" : "32px", marginBottom: "16px" }}>Area of Expertise</Typography>
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                    {mentorDetail.specialization.map((item) => (
                      <Typography sx={{ padding: "5px 10px", borderRadius: "10px", border: "1px solid #1C8EA8", fontSize: Mobile ? "12px" : "24px", color: "#7A7A7A" }}>{item.name}</Typography>
                    ))}
                  </Box>
                </Box>
                {mentorFeedback.length > 0 && (
                  <Box sx={{ paddingInline: Mobile ? "1rem" : "9rem" }}>
                    <Typography sx={{ fontSize: Mobile ? "16px" : "32px", marginTop: "8px", fontWeight: "600" }}>Reviews </Typography>
                    {showFullFAQ
                      ? mentorFeedback
                          .filter((item) => item.first_name !== null)
                          .map((feedback) => (
                            <Box sx={{ padding: "10px", boxShadow: "0px 6px 12px 0px #00000014" }}>
                              <Box sx={{ display: "flex", gap: "10px" }}>
                                <Box>
                                  <Typography sx={{ fontSize: "14px" }}>
                                    {feedback.first_name} {feedback.last_name}
                                  </Typography>

                                  {[1, 2, 3, 4, 5].map((item) => {
                                    if (JSON.parse(feedback.student_feedback).rating >= item) {
                                      return <Star sx={{ fontSize: Mobile ? "16px" : "20px", color: "#1C8EA8" }} />;
                                    } else {
                                      return <StarOutline sx={{ fontSize: Mobile ? "16px" : "20px", color: "#1C8EA8" }} />;
                                    }
                                  })}
                                  <Typography sx={{ fontSize: "12px", color: "#7A7A7A" }}>{JSON.parse(feedback.student_feedback)["good"] ? JSON.parse(feedback.student_feedback)["good"][0].comment : JSON.parse(feedback.student_feedback)["bad"][0].comment}</Typography>
                                </Box>
                              </Box>
                            </Box>
                          ))
                      : mentorFeedback
                          .filter((item) => item.first_name !== null)
                          .slice(0, 5)
                          .map((feedback) => (
                            <Box sx={{ padding: "10px", boxShadow: "0px 6px 12px 0px #00000014" }}>
                              <Box sx={{ display: "flex", gap: "10px" }}>
                                <Box>
                                  <Typography sx={{ fontSize: "14px" }}>
                                    {feedback.first_name} {feedback.last_name}
                                  </Typography>

                                  {[1, 2, 3, 4, 5].map((item) => {
                                    if (JSON.parse(feedback.student_feedback).rating >= item) {
                                      return <Star sx={{ fontSize: Mobile ? "16px" : "20px", color: "#1C8EA8" }} />;
                                    } else {
                                      return <StarOutline sx={{ fontSize: Mobile ? "16px" : "20px", color: "#1C8EA8" }} />;
                                    }
                                  })}
                                  <Typography sx={{ fontSize: "12px", color: "#7A7A7A" }}>{JSON.parse(feedback.student_feedback)["good"] ? JSON.parse(feedback.student_feedback)["good"][0].comment : JSON.parse(feedback.student_feedback)["bad"][0].comment}</Typography>
                                </Box>
                              </Box>
                            </Box>
                          ))}

                    {mentorFeedback.length > 5 && (
                      <Typography sx={{ fontSize: Mobile ? "14px" : "16px", marginTop: "8px", textAlign: "right", color: "#1C8EA8", fontStyle: "italic", "&:hover": { cursor: "pointer" } }} onClick={() => setShowFullFAQ((prev) => !prev)}>
                        {showFullFAQ ? "Show less" : "Show more"}
                      </Typography>
                    )}
                  </Box>
                )}

                <Box sx={{ paddingInline: Mobile ? "1rem" : "9rem" }}>
                  <Typography sx={{ fontSize: Mobile ? "16px" : "24px", marginTop: "8px", fontWeight: "600" }}>My Recordings</Typography>
                  <a href="https://www.careercarve.in/signup" style={{ textDecoration: "none" }}>
                    <Box sx={{ display: "flex", gap: "8px", boxShadow: "0px 6px 12px 0px rgba(0, 0, 0, 0.08)", padding: "16px", borderRadius: "12px", alignItems: "center" }}>
                      <PlayCircleOutline sx={{ color: "#1C8EA8" }} />
                      <Typography sx={{ color: "#4A4A4A", fontSize: "16px" }}>Podcast by me</Typography>
                    </Box>
                  </a>
                  <a href="https://www.careercarve.in/signup" style={{ textDecoration: "none" }}>
                    <Box sx={{ display: "flex", gap: "8px", boxShadow: "0px 6px 12px 0px rgba(0, 0, 0, 0.08)", padding: "16px", borderRadius: "12px", alignItems: "center" }}>
                      <Podcasts sx={{ color: "#1C8EA8" }} />
                      <Typography sx={{ color: "#4A4A4A", fontSize: "16px" }}>Sessions by me</Typography>
                    </Box>
                  </a>
                </Box>

                {faqs.length > 0 && (
                  <Box sx={{ paddingInline: Mobile ? "1rem" : "9rem" }}>
                    <Typography sx={{ fontSize: Mobile ? "16px" : "32px", marginTop: "8px", fontWeight: "bold" }}>FAQs</Typography>
                    {faqs.map((item) => (
                      <Box sx={{ marginBlock: "8px" }}>
                        <Accordion
                          expanded={expanded === item.sub_sec_id ? true : false}
                          onChange={() => (expanded === item.sub_sec_id ? setExpanded(null) : setExpanded(item.sub_sec_id))}
                          slots={{ transition: Fade }}
                          slotProps={{ transition: { timeout: 400 } }}
                          sx={{
                            "& .MuiAccordion-region": { height: expanded === item.sub_sec_id ? "auto" : 0 },
                            "& .MuiAccordionDetails-root": { display: expanded === item.sub_sec_id ? "block" : "none" },
                            boxShadow: "none",
                            borderBottom: "1px solid #E6E6E6",
                          }}
                        >
                          <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1-content" id="panel1-header">
                            <Typography sx={{ fontSize: "14px" }}>{item.qna[0].que}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {(item.qna[0].ans.match(/- /g) || []).length > 2 ? (
                              item.qna[0].ans
                                .split("- ")
                                .filter((ele) => ele.length > 2)
                                .map((ele) => <Typography sx={{ fontSize: "12px", color: "#7A7A7A" }}>- {ele}</Typography>)
                            ) : (
                              <Typography sx={{ fontSize: "12px", color: "#7A7A7A" }}>{get_specialCharReplace(item.qna[0].ans)}</Typography>
                            )}
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            </Box>
          ) : (
            <Box sx={{ display: "flex", gap: "3rem", justifyContent: "space-between", position: "relative", paddingInline: Mobile ? "1rem" : "9rem", mt: 2 }}>
              <Box sx={{ flex: 1.5, display: "flex", flexDirection: "column", gap: "20px", marginBottom: "2rem" }}>
                <Box>
                  <Typography sx={{ fontSize: Mobile ? "18px" : `${(36 / 1356) * window.innerWidth}px` }}>{mentorDetail.name}</Typography>
                  <Box sx={{ display: "flex", gap: "5px" }}>
                    <Star sx={{ color: "rgba(245, 165, 54, 1)" }} />

                    <Typography sx={{ color: "rgba(139, 139, 139, 1)", fontSize: Mobile ? "14px" : "16px" }}>
                      {mentorDetail.avg_rating?.overall_rating ? mentorDetail.avg_rating?.overall_rating?.toFixed(2) : 0} ({mentorDetail.no_of_rated_exp?.overall_count ?? 0} Reviews)
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                  {/* <Box sx={{ width: "4px", background: "#1C8EA8", height: "80%", borderRadius: "20px" }}></Box> */}
                  <Typography sx={{ fontSize: Mobile ? "14px" : `${(12 / 1356) * window.innerWidth}px`, color: "#7A7A7A", lineClamp: 3, borderLeft: "2px solid #1C8EA8", paddingLeft: "1rem" }}>
                    <span style={{ color: "#1C8EA8", fontSize: Mobile ? "16px" : "20px" }}>"</span>

                    {mentorDetail.about_mentor.length > 180 && !readMore ? <span>{`${get_specialCharReplace(mentorDetail.about_mentor.slice(0, 180))}...`} </span> : <span>{get_specialCharReplace(mentorDetail.about_mentor)} </span>}

                    <span style={{ color: "#1C8EA8", fontSize: Mobile ? "16px" : "20px", lineHeight: "0px", fontFamily: `"Allerta", sans-serif` }}>"</span>
                    {mentorDetail.about_mentor.length > 180 && (
                      <Typography sx={{ color: "#1C8EA8", display: "inline", "&:hover": { cursor: "pointer" }, marginLeft: "2px", fontSize: Mobile ? "14px" : `${(12 / 1356) * window.innerWidth}px` }} onClick={() => setReadMore((prev) => !prev)}>
                        {readMore ? "Read Less" : "Read More"}
                      </Typography>
                    )}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Box sx={{ display: "flex", gap: "2px", alignItems: "center" }}>
                    <SchoolOutlined sx={{ fontSize: "28px", color: "#F5A536", strokeWidth: "0.5px" }} />
                    <Typography sx={{ fontSize: Mobile ? "14px" : "20px", color: "#4A4A4A" }}>{mentorDetail.college_name}</Typography>
                  </Box>

                  <Box sx={{ display: "flex", gap: "2px", alignItems: "center" }}>
                    <VideocamOutlined sx={{ fontSize: "28px", color: "#F5A536" }} />
                    <Typography sx={{ fontSize: Mobile ? "14px" : "20px", color: "#4A4A4A" }}>{mentorDetail.no_of_sessions}+ Sessions</Typography>
                  </Box>
                </Box>
                <Box sx={{ width: "100%", height: "1px", background: "#e6e6e6" }}></Box>
                {mentorDetail.past_workex.workex.length > 0 && (
                  <Box sx={{ gap: "16px", display: "flex", flexDirection: "column" }}>
                    <p style={{ fontSize: Mobile ? "20px" : "24px", fontWeight: "600" }}>Companies I've worked with</p>

                    {mentorDetail.past_workex.workex.map((item, index) => (
                      <Box sx={{ paddingInline: "10px 16px", paddingBlock: "12px", borderRadius: "12px", borderLeft: `3px solid ${colors[index % colors.length].color}`, boxShadow: "0px 6px 12px 0px #00000014", display: "flex", gap: "10px", justifyContent: "space-between", alignItems: "center" }}>
                        <Box sx={{ display: "flex", gap: "10px", justifyContent: "space-between", alignItems: "center" }}>
                          <Box sx={{ padding: "6px", borderRadius: "4px", background: colors[index % colors.length].background, height: "fit-content" }}>
                            <Work sx={{ color: colors[index % colors.length].color, fontSize: "22px" }} />
                          </Box>
                          <Typography sx={{ fontSize: Mobile ? "12px" : "18px", fontWeight: "medium" }}>{item.company}</Typography>
                        </Box>

                        <Typography sx={{ fontSize: Mobile ? "12px" : "18px", color: "#7A7A7A" }}>{item.designation}</Typography>
                      </Box>
                    ))}
                  </Box>
                )}
                <Box>
                  <Typography sx={{ fontSize: Mobile ? "16px" : "24px", marginBottom: "16px", fontWeight: "600" }}>Area of Expertise</Typography>
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                    {mentorDetail.specialization.map((item) => (
                      <Typography sx={{ padding: "5px 10px", borderRadius: "10px", border: "1px solid #1C8EA8", fontSize: Mobile ? "16px" : "18px", color: "#7A7A7A" }}>{item.name}</Typography>
                    ))}
                  </Box>
                </Box>
                {mentorFeedback.filter((item) => item.first_name !== null).length > 0 && (
                  <Box>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                      <Typography sx={{ fontSize: Mobile ? "20px" : "24px", marginTop: "8px", fontWeight: "600" }}>Reviews </Typography>
                      {mentorFeedback.length > 5 && (
                        <Typography sx={{ fontSize: Mobile ? "14px" : "16px", marginTop: "8px", textAlign: "right", color: "#1C8EA8", fontStyle: "italic", "&:hover": { cursor: "pointer" } }} onClick={() => setShowFullFAQ((prev) => !prev)}>
                          {showFullFAQ ? "Show less" : "Show more"}
                        </Typography>
                      )}
                    </Box>
                    {showFullFAQ
                      ? mentorFeedback
                          .filter((item) => item.first_name !== null)
                          .map((feedback) => (
                            <Box sx={{ padding: "10px", boxShadow: "0px 6px 12px 0px rgba(0, 0, 0, 0.08)", borderRadius: "8px" }}>
                              <Box>
                                <Box sx={{ marginBottom: "12px" }}>
                                  <Typography sx={{ fontSize: "18px" }}>
                                    {feedback.first_name} {feedback.last_name}
                                  </Typography>

                                  {[1, 2, 3, 4, 5].map((item) => {
                                    if (JSON.parse(feedback.student_feedback).rating >= item) {
                                      return <Star sx={{ fontSize: Mobile ? "16px" : "20px", color: "#1C8EA8" }} />;
                                    } else {
                                      return <StarOutline sx={{ fontSize: Mobile ? "16px" : "20px", color: "#1C8EA8" }} />;
                                    }
                                  })}
                                </Box>

                                <Typography sx={{ fontSize: "16px", color: "#7A7A7A" }}>{JSON.parse(feedback.student_feedback)["good"] ? JSON.parse(feedback.student_feedback)["good"][0].comment : JSON.parse(feedback.student_feedback)["bad"][0].comment}</Typography>
                              </Box>
                            </Box>
                          ))
                      : mentorFeedback
                          .filter((item) => item.first_name !== null)
                          .slice(0, 4)
                          .map((feedback) => (
                            <Box sx={{ padding: "10px", boxShadow: "0px 6px 12px 0px rgba(0, 0, 0, 0.08)", borderRadius: "8px" }}>
                              <Box>
                                <Box sx={{ marginBottom: "12px" }}>
                                  <Typography sx={{ fontSize: "18px" }}>
                                    {feedback.first_name} {feedback.last_name}
                                  </Typography>

                                  {[1, 2, 3, 4, 5].map((item) => {
                                    if (JSON.parse(feedback.student_feedback).rating >= item) {
                                      return <Star sx={{ fontSize: Mobile ? "16px" : "20px", color: "#1C8EA8" }} />;
                                    } else {
                                      return <StarOutline sx={{ fontSize: Mobile ? "16px" : "20px", color: "#1C8EA8" }} />;
                                    }
                                  })}
                                </Box>

                                <Typography sx={{ fontSize: "16px", color: "#7A7A7A" }}>{JSON.parse(feedback.student_feedback)["good"] ? JSON.parse(feedback.student_feedback)["good"][0].comment : JSON.parse(feedback.student_feedback)["bad"][0].comment}</Typography>
                              </Box>
                            </Box>
                          ))}
                  </Box>
                )}
                <Box>
                  <Typography sx={{ fontSize: Mobile ? "20px" : "24px", marginTop: "8px", fontWeight: "600" }}>My Recordings</Typography>
                  <a href="https://www.careercarve.in/signup" style={{ textDecoration: "none" }}>
                    <Box sx={{ display: "flex", gap: "8px", boxShadow: "0px 6px 12px 0px rgba(0, 0, 0, 0.08)", padding: "16px", borderRadius: "12px", alignItems: "center" }}>
                      <PlayCircleOutline sx={{ color: "#1C8EA8" }} />
                      <Typography sx={{ color: "#4A4A4A", fontSize: "18px" }}>Podcast by me</Typography>
                    </Box>
                  </a>

                  <a href="https://www.careercarve.in/signup" style={{ textDecoration: "none" }}>
                    <Box sx={{ display: "flex", gap: "8px", boxShadow: "0px 6px 12px 0px rgba(0, 0, 0, 0.08)", padding: "16px", borderRadius: "12px", alignItems: "center" }}>
                      <Podcasts sx={{ color: "#1C8EA8" }} />
                      <Typography sx={{ color: "#4A4A4A", fontSize: "18px" }}>Sessions by me</Typography>
                    </Box>
                  </a>
                </Box>
                {faqs.length > 0 && (
                  <Box>
                    <Typography sx={{ fontSize: Mobile ? "20px" : "24px", marginTop: "8px", fontWeight: "600" }}>FAQs</Typography>

                    {faqs.map((item) => (
                      <Box sx={{ marginBlock: "16px" }}>
                        <Accordion
                          expanded={expanded === item.sub_sec_id ? true : false}
                          onChange={() => (expanded === item.sub_sec_id ? setExpanded(null) : setExpanded(item.sub_sec_id))}
                          slots={{ transition: Fade }}
                          slotProps={{ transition: { timeout: 400 } }}
                          sx={{
                            "& .MuiAccordion-region": { height: expanded === item.sub_sec_id ? "auto" : 0 },
                            "& .MuiAccordionDetails-root": { display: expanded === item.sub_sec_id ? "block" : "none" },
                            boxShadow: "none",
                            borderBottom: "1px solid #E6E6E6",
                          }}
                        >
                          <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1-content" id="panel1-header">
                            <Typography sx={{ fontSize: "18px", color: "#4a4a4a" }}>{item.qna[0].que}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {(item.qna[0].ans.match(/- /g) || []).length > 2 ? (
                              item.qna[0].ans
                                .split("- ")
                                .filter((ele) => ele.length > 2)
                                .map((ele) => <Typography sx={{ fontSize: "16px", color: "#7A7A7A" }}>- {ele}</Typography>)
                            ) : (
                              <Typography sx={{ fontSize: "16px", color: "#7A7A7A" }}>{get_specialCharReplace(item.qna[0].ans)}</Typography>
                            )}
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
              <Box sx={{ height: "fit-content", position: "sticky", flex: 1, top: webinarTickerExists && Mobile ? "80px" : !webinarTickerExists && Mobile ? "50px" : webinarTickerExists ? "112px" : "70px", boxShadow: "0px 6px 12px 0px #00000014", padding: "20px", borderRadius: "20px" }}>
                <Typography sx={{ fontSize: "24px", fontWeight: "600" }}>Sessions conducted by me</Typography>

                <Box>
                  {mentorDetail.allowed_activity.map((item, index) => (
                    <Box sx={{ paddingBlock: "32px", borderBottom: index !== mentorDetail.allowed_activity.length - 1 ? "1px solid #e6e6e6" : "" }}>
                      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Box sx={{ flex: 2 }}>
                          <Typography sx={{ fontSize: "18px", color: "#4A4A4A" }}>{item.name}</Typography>
                          <Typography sx={{ fontSize: "16px", color: "#1C8EA8" }}>₹{item.price}</Typography>
                        </Box>
                        <Button
                          sx={{ flex: 1, borderRadius: "8px", fontSize: Mobile ? "14px" : "16px", background: "#1C8EA8", color: "#fff", textTransform: "none", "&:hover": { background: "#1C8EA8", color: "#fff" }, width: "fit-content" }}
                          onClick={() => {
                            navigate("/CartScreen", {
                              state: {
                                basket: [{ id: item.id, search_tbl: "sku", quantity: 1 }],
                                mentor_id: mentorDetail.user_id,
                                mentor_name: mentorDetail.name,
                                priority_number: 1,
                              },
                            });
                          }}
                        >
                          Book now <ArrowOutwardOutlined sx={{ ml: 1 }} />
                        </Button>
                      </Box>
                      <Typography sx={{ color: "#7A7A7A", fontSize: "14px" }}> {item.description.length > 120 ? `${item.description.slice(0, 120)}...` : item.description.slice(0, 120)}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      ) : (
        <LoaderSkeletion />
      )}
    </Box>
  );
}

export default MentorDetails;

const LoaderSkeletion = () => {
  const Mobile = useMediaQuery("(max-width:800px)");

  return (
    <Box sx={{ width: "100vw", marginTop: "30px" }}>
      <Skeleton variant="rectangular" width={"100vw"} height={"100px"} />
      {!Mobile ? (
        <Box sx={{ display: "flex", justifyContent: "space-between", paddingInline: "2rem", gap: "1rem", marginBlock: "1rem" }}>
          <Skeleton variant="rectangular" width={"100vw"} height={"100vh"} />
          <Skeleton variant="rectangular" width={"100vw"} height={"100vh"} />
        </Box>
      ) : (
        <Skeleton variant="rectangular" width={"100vw"} height={"10rem"} />
      )}
    </Box>
  );
};
