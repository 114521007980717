import React, { useEffect } from "react";
import CCAppBar from "../../components/AppBar/AppBar";
import "../PrivacyPolicy/PrivacyPolicy.css";
const CancellationAndRefundpolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="login-Container" style={{ height: "auto", width: "100%" }}>
      <div style={{ justifyContent: "center", width: "80%", margin: "100px auto auto auto" }}>
        <p className="pricingCareerCrave1">Cancellation and Refund Policy</p>
        <div className="privacyPolicymainConatiner">
          <p>
            <strong style={{ fontSize: "16px" }}>Cancellation Policy:</strong>{" "}
          </p>
          <p>As a general rule, once a user buys a bundle or individual activity, it cannot be cancelled for any reason.</p>
        </div>
        <div>
          <p>
            <strong style={{ fontSize: "16px" }}>Refund Policy:</strong>
          </p>
          <p>The user cannot request for a refund once the bundle or individual activity has been bought.</p>
          <p>In case an activity was not delivered to the satisfaction of the user, the user can open a support ticket in the mobile application. The CareerCarve team will investigate the matter and if required reschedule that activity if they deem it necessary. The decision to reschedule the activity lies entirely with the CareerCarve team.</p>
        </div>
      </div>
    </div>
  );
};

export default CancellationAndRefundpolicy;
