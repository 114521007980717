import { Box, Button, Divider, Typography, useMediaQuery } from "@mui/material";

import { CheckCircleOutline, Close } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useWebinarContext } from "../../WebinarContext";
import { bundlesGetter, skuGetter } from "../../api/cartModules";
import { getPayableAmount } from "../../components/StateProvider/reducer";
import { Color } from "../../GlobalStyles";

function PurchaseStatusConfirm({ props }) {
  const Mobile = useMediaQuery("(max-width:800px)");
  const navigate = useNavigate();
  const location = useLocation();

  const { webinarTickerExists } = useWebinarContext();
  const purchasedData = location.state.sku;

  const txn_status = location.state.data.status;
  const txn_id = location.state.data.txnid;

  const couponCode = location.state.couponCode;
  const [orderData, setOrderData] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const [GrandTotal, setGrandTotal] = useState(0);
  const [amountWithoutDiscount, setAmountWithOutDiscount] = useState(0);
  const [itemAmount, setItemAmount] = useState(0);
  const [couponDiscount, setCouponDiscount] = useState(0);

  const getSkuData = async () => {
    const skuData = await skuGetter();
    const bunderData = await bundlesGetter();

    let purchasedSku = purchasedData.filter((item) => item.search_tbl === "sku");
    let purchasedBundle = purchasedData.filter((item) => item.search_tbl === "bundle");

    let orders = [];
    let skus = purchasedSku.map((item) => {
      let x = skuData.find((sku) => sku.id === item.id);
      console.log("objects: ", x);
      if (![null, undefined, -1].includes(x)) {
        x = {
          ...x,
          quantity: item.quantity,
          search_tbl: "sku",
        };
        orders.push(x);
      }
    });

    let bundles = purchasedBundle.map((item) => {
      let x = bunderData.find((sku) => sku.id === item.sku_id);
      if (![null, undefined, -1].includes(x)) {
        x = {
          ...x,
          quantity: item.quantity,
          search_tbl: "bundle",
        };
        orders.push(x);
      }
    });

    setOrderData(orders);
    const basketData = orders.map((item) => {
      return {
        id: item.id,
        quantity: item.quantity,
        search_tbl: item.search_tbl,
      };
    });
    const data = await getPayableAmount(basketData, couponCode);

    setSubTotal(data.actualAmount);
    setTaxes(data.taxAmount);
    setGrandTotal(data.totalBillingAmount);
    setItemAmount(data.itemAmount);
    setCouponDiscount(data.couponDiscount);
    setAmountWithOutDiscount(data.amountWithoutDiscount);
  };

  useEffect(() => {
    getSkuData();
  }, []);

  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "90vh" }}>
      <div style={{ marginTop: "100px", flex: 1 }}>
        {txn_status === "success" ? (
          <CheckCircleOutline
            style={{
              color: "#1C8EA8",
              height: "80px",

              justifyContent: "center",
              margin: "0 auto",
              width: "100%",
            }}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                padding: Mobile ? "8px" : "8px",
                background: "rgba(255, 0, 0, 1)",
                borderRadius: "50%",
              }}
            >
              <Close
                style={{
                  color: "#FFF",
                  height: Mobile ? "40px" : "70px",
                  width: Mobile ? "40px" : "70px",
                  justifyContent: "center",
                  margin: "0 auto",
                }}
              />
            </Box>
          </Box>
        )}
        {txn_status === "success" ? <p className="pricingCareerCrave1">Thank you for your order!</p> : <Typography sx={{ fontSize: Mobile ? "14px" : "24px", color: "red", textAlign: "center" }}>Payment Unsuccessful</Typography>}

        {txn_status === "success" ? (
          <Typography sx={{ textAlign: "center", fontSize: Mobile ? "12px" : "18px", color: "#8B8B8B" }}>
            Your order details have been sent to your mail.
            <br /> Kindly check your mail for further details
          </Typography>
        ) : (
          <Typography sx={{ textAlign: "center", fontSize: Mobile ? "12px" : "18px", color: "#8B8B8B" }}>
            Unfortunately, the bank has declined your transaction.
            <br />
            If funds were debited, they will be refunded in 3-5 business days
          </Typography>
        )}
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Button sx={{ background: Color.primary1, color: Color.white, fontSize: Mobile ? "14px" : "16px", textTransform: "none", borderRadius: "10px", "&:hover": { background: Color.primary1, color: Color.white }, padding: "8px 16px" }} onClick={() => navigate("/Pricing")}>
            View our 1:1s
          </Button>
        </Box>
      </div>

      {/* <Typography sx={{ fontSize: Mobile ? "14px" : "22px", textAlign: "center", marginBlock: Mobile ? "1rem" : "2rem", fontWeight: "500" }}>Order Summary</Typography> */}
      {/* <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", padding: "20px", borderRadius: "12px", boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.08)", border: "1px solid #e6e6e6e", width: Mobile ? "90%" : "60%" }}>
          <Box>
       
            <Typography sx={{ fontSize: Mobile ? "14px" : "20px" }}>Order ID: {txn_id} </Typography>
            <Typography sx={{ fontSize: Mobile ? "14px" : "20px" }}>Order Date: {`${new Date().toDateString()}`} </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ flex: 1.5, fontSize: Mobile ? "12px" : "16px", color: "#545454" }}>Item</Typography>
            <Typography sx={{ flex: 1, fontSize: Mobile ? "12px" : "16px", color: "#545454", textAlign: "center" }}>Price</Typography>
            <Typography sx={{ flex: 1, fontSize: Mobile ? "12px" : "16px", color: "#545454", textAlign: "center" }}>Quantity</Typography>
            <Typography sx={{ flex: 1, fontSize: Mobile ? "12px" : "16px", color: "#545454", textAlign: "center" }}>Total</Typography>
          </Box>
          <Divider sx={{ color: "rgb(224, 224, 224)" }} />
          {orderData.map((item) => (
            <Box>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ flex: 1.5, fontSize: Mobile ? "14px" : "18px" }}>{item.name}</Typography>
                <Typography sx={{ flex: 1, fontSize: Mobile ? "12px" : "16px", textAlign: "center" }}>{item.actual_price}</Typography>
                <Typography sx={{ flex: 1, fontSize: Mobile ? "12px" : "16px", textAlign: "center" }}>{item.quantity}</Typography>
                <Typography sx={{ flex: 1, fontSize: Mobile ? "12px" : "16px", textAlign: "center" }}>{item.actual_price * item.quantity}</Typography>
              </Box>
              <Divider sx={{ color: "rgb(224, 224, 224)", mt: 2 }} />
            </Box>
          ))}
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box sx={{ width: Mobile ? "100%" : "40%", display: "flex", flexDirection: "column", gap: "8px" }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{ fontSize: Mobile ? "12px" : "16px" }}>Total value</Typography>
                <Typography sx={{ fontSize: Mobile ? "12px" : "16px" }}> &#x20b9;{subTotal}</Typography>
              </Box>
              <Divider sx={{ color: "rgb(224, 224, 224)" }} />
              {subTotal - amountWithoutDiscount > 0 && (
                <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography sx={{ fontSize: Mobile ? "12px" : "16px" }}>Discount</Typography>
                    <Typography sx={{ fontSize: Mobile ? "12px" : "16px", color: "green" }}>- &#x20b9;{subTotal - amountWithoutDiscount}</Typography>
                  </Box>
                  <Divider sx={{ color: "rgb(224, 224, 224)" }} />
                </Box>
              )}

              {couponDiscount > 0 && (
                <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography sx={{ fontSize: Mobile ? "12px" : "16px" }}>Coupon Discount</Typography>
                    <Typography sx={{ fontSize: Mobile ? "12px" : "16px", color: "green" }}>- &#x20b9;{couponDiscount}</Typography>
                  </Box>
                  <Divider sx={{ color: "rgb(224, 224, 224)" }} />
                </Box>
              )}
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{ fontSize: Mobile ? "12px" : "16px" }}>Subtotal</Typography>
                <Typography sx={{ fontSize: Mobile ? "12px" : "16px" }}>&#x20b9;{itemAmount}</Typography>
              </Box>
              <Divider sx={{ color: "rgb(224, 224, 224)" }} />
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{ fontSize: Mobile ? "12px" : "16px" }}>GST(18%)</Typography>
                <Typography sx={{ fontSize: Mobile ? "12px" : "16px" }}>&#x20b9;{taxes}</Typography>
              </Box>

              <Divider sx={{ color: "rgb(224, 224, 224)" }} />
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{ fontSize: Mobile ? "12px" : "16px" }}>Total paid</Typography>
                {txn_status === "success" ? <Typography sx={{ fontSize: Mobile ? "12px" : "16px", color: "green" }}>&#x20b9;{GrandTotal}</Typography> : <Typography sx={{ fontSize: Mobile ? "12px" : "16px", color: "red" }}>Payment Failed</Typography>}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box> */}
    </Box>
  );
}

export default PurchaseStatusConfirm;
