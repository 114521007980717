// A function to replace special characters in a string for safe transmission or storage
export const send_specialCharReplace = text => {
  const replacedText = text
    ?.replace(/"/g, "%%") // Replace all double quotes (") with the string "%%"
    .replace(/'/g, "@@") // Replace all single quotes (') with the string "@@"
    .replace(/\n/g, "||n") // Replace all newlines (\n) with the string "||n"
    .replace(/\//g, "[?]") // Replace all forward slashes (/) with the string "[?]"
    .replace(/\\/g, "||"); // Replace all backslashes (\) with the string "||"
  // Return the modified string
  return replacedText;
};

// A function to replace the special characters back to their original form
export const get_specialCharReplace = text => {
  const replacedText = text
    ?.replace(/%%/g, '"') // Replace all occurrences of the string "%%" with double quotes (")
    .replace(/@@/g, "'") // Replace all occurrences of the string "@@" with single quotes (')
    .replace(/\|\|n/g, "\n") // Replace all occurrences of the string "||n" with a newline (\n)
    .replace(/\[\?\]/g, "/") // Replace all occurrences of the string "[?]" with a forward slash (/)
    .replace(/\|\|/g, "\\"); // Replace all occurrences of the string "||" with a backslash (\)
  // Return the modified string]
  console.log({ replacedText })
  return replacedText;
};

export function calculateEpochTimeInLocalTime(selectedDate) {
  const date = new Date(selectedDate);
  const selectedTime = date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
  return selectedTime;
}

export function extractDateFromDateString(dateString) {
  console.log("date passed", dateString);
  const inputDate = new Date(dateString);
  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, "0");
  const day = String(inputDate.getDate()).padStart(2, "0");
  console.log("what's returning", year, month, day);
  return `${year}-${month}-${day}`;
}

export function convertLocalDateTimeToEpoch(localDate, localTime) {
  // Concatenate the date and time strings into a single string
  const dateTimeString = localDate + " " + localTime;
  // Create a Date object from the concatenated string
  const dateTime = new Date(dateTimeString);
  // Get the epoch time in milliseconds
  const epochTime = dateTime.getTime();
  return epochTime;
}

export function convertEpochToNormalTime(epochTime) {
  // Create a new Date object with the epoch time in milliseconds
  const date = new Date(epochTime);

  // Define the days of the week and months
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  // Get the day of the week, month, and time from the date object
  const dayOfWeek = daysOfWeek[date.getDay()];
  const month = months[date.getMonth()];
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Determine if it's AM or PM
  const amOrPm = hours < 12 ? 'AM' : 'PM';

  // Convert hours to 12-hour format
  const formattedHours = hours % 12 || 12;

  // Construct the formatted date string
  const formattedDate = `${formattedHours}:${minutes.toString().padStart(2, '0')} ${amOrPm}`;

  // Return the formatted date
  return formattedDate;
}

export function ddmmm(time) {
  // Create a new Date object with the epoch time in milliseconds
  const date = new Date(new Date(time));
  // Define the days of the week and months
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  // Get the day of the week, month, and time from the date object
  const dayOfWeek = daysOfWeek[date.getDay()];
  const month = months[date.getMonth()];
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Determine if it's AM or PM
  const amOrPm = hours < 12 ? 'AM' : 'PM';

  // Convert hours to 12-hour format
  const formattedHours = hours % 12 || 12;

  // Construct the formatted date string
  const formattedDate = `${date.getDate()} ${month}`;

  // Return the formatted date
  return formattedDate;
}

// ddmmm with year
export function ddmmmWithYear(time) {
  // Create a new Date object with the epoch time in milliseconds
  const date = new Date(new Date(time));
  // Define the days of the week and months
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  // Get the day of the week, month, and time from the date object
  const dayOfWeek = daysOfWeek[date.getDay()];
  const month = months[date.getMonth()];
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Determine if it's AM or PM
  const amOrPm = hours < 12 ? 'AM' : 'PM';

  // Convert hours to 12-hour format
  const formattedHours = hours % 12 || 12;

  // Construct the formatted date string with year
  const formattedDate = `${date.getDate()} ${month}, ${date.getFullYear()}`;

// Return the formatted date
return formattedDate;
}
export function secondsToMinutesAndHours(milliseconds) {
  // Convert milliseconds to seconds
  const seconds = Math.floor(milliseconds / 1000);
  // Convert seconds to minutes
  const minutes = Math.floor(seconds / 60);
  // Calculate remaining seconds
  const remainingSeconds = seconds % 60;
  // Convert minutes to hours
  const hours = Math.floor(minutes / 60);
  // Calculate remaining minutes
  const remainingMinutes = minutes % 60;

  if (hours === 0) {
    return `${minutes} minute(s)`;
  } else if (hours === 1 && remainingMinutes === 0) {
    return `${hours} hour`;
  } else if (hours === 1) {
    return `${hours} hour and ${remainingMinutes} minute(s)`;
  } else {
    if (remainingMinutes === 0) {
      return `${hours} hours`;
    } else {
      return `${hours} hours and ${remainingMinutes} minute(s)`;
    }
  }
}

