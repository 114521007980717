import { Box, Typography } from "@mui/material";
import React from "react";

function ResumesDigital({ Mobile }) {
  // const [selectedTab, setSelectedTab] = useState(0);
  // const items = [
  //   {
  //     name: "Resume Digital",
  //     description: "Probably the most random thing you have ever seen!",
  //   },
  //   {
  //     name: "Podcasts",
  //     description: "Hello World!",
  //   },
  //   // {
  //   //   name: "Webniar",
  //   //   description: "Probably the most random thing you have ever seen!",
  //   // },
  // ];

  // const RenderCrouselItem = ({ item, index }) => {
  //   if (index === 0) {
  //     return (
  //       <Box
  //         sx={{
  //           display: "flex",
  //           justifyContent: "space-between",
  //           alignItems: "center",
  //         }}
  //       >
  //         <Box
  //           sx={{
  //             display: "flex",
  //             flexDirection: "column",
  //             width: "100%",
  //             gap: "1rem",
  //             padding: "36px 5rem",
  //           }}
  //         >
  //           <Typography fontSize="45px" color="#1C8EA8">
  //             AI Powered Resume Builder
  //           </Typography>
  //           <Box
  //             sx={{
  //               width: "131px",
  //               height: "1px",
  //               backgroundColor: "#000",
  //             }}
  //           ></Box>
  //           <Box sx={{ display: "flex" }}>
  //             <img
  //               src="../../OurServices/RDLogo.png"
  //               alt="RD"
  //               style={{ width: "232px" }}
  //             />
  //           </Box>
  //           <Typography fontSize="16px" color="#8B8B8B">
  //             Get access to the most sophisticated AI-powered resume building
  //             tool. The AI engine offers superb suggestions to perfect each
  //             resume point. You can access the portal throughout the year to
  //             update your resumes with new points and maintain multiple versions
  //             for different job applications.
  //           </Typography>
  //           <a href="https://www.resumes.digital/" target="_blank">
  //             <Button
  //               sx={{
  //                 borderRadius: "30px",
  //                 border: "1px solid #F5A536",
  //                 backgroundColor: "#F5A536",
  //                 width: "fit-content",
  //                 padding: "8px 16px",
  //                 fontSize: "14px",
  //                 alignItems: "center",
  //                 color: "#000",
  //                 textTransform: "none",
  //                 "&:hover": {
  //                   background:
  //                     "linear-gradient(135deg, #F5A536 0%, #FFC87A 100%)",
  //                 },
  //               }}
  //             >
  //               Make your resume
  //             </Button>
  //           </a>
  //         </Box>
  //         <Box sx={{ width: "100%" }}>
  //           <img
  //             src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/RDImage.png"
  //             alt="Resume Digital"
  //             style={{ width: "100%" }}
  //           />
  //         </Box>
  //       </Box>
  //     );
  //   } else if (index === 1) {
  //     return (
  //       <Box
  //         sx={{
  //           display: "flex",
  //           flexDirection: "column",
  //           gap: "1rem",
  //           padding: "36px 9.5rem",
  //         }}
  //       >
  //         <Typography fontSize="45px" color="#1C8EA8">
  //           Podcasts and Sessions
  //         </Typography>
  //         <Box
  //           sx={{
  //             width: "131px",
  //             height: "1px",
  //             backgroundColor: "#000",
  //           }}
  //         ></Box>
  //         <Typography fontSize="16px" color="#8B8B8B">
  //           Gain exclusive access to podcasts and sessions available only on the
  //           CareerCarve app. Our mentors create content specifically for the
  //           CareerCarve community, offering deep dives into MBA roles, sectors,
  //           and preparation requirements. The content is conveniently organized
  //           into byte-sized pieces for easy search and consumption.
  //         </Typography>
  //         <Box sx={{ display: "flex", justifyContent: "space-between" }}>
  //           <img
  //             src="../../OurServices/finance.png"
  //             alt="RD"
  //             style={{ width: "116px" }}
  //           />
  //           <img
  //             src="../../OurServices/marketing.png"
  //             alt="RD"
  //             style={{ width: "116px" }}
  //           />
  //           <img
  //             src="../../OurServices/HR.png"
  //             alt="RD"
  //             style={{ width: "116px" }}
  //           />
  //           <img
  //             src="../../OurServices/operations.png"
  //             alt="RD"
  //             style={{ width: "116px" }}
  //           />
  //           <img
  //             src="../../OurServices/consulting.png"
  //             alt="RD"
  //             style={{ width: "116px" }}
  //           />
  //           <img
  //             src="../../OurServices/sipRoles.png"
  //             alt="RD"
  //             style={{ width: "116px" }}
  //           />
  //           <img
  //             src="../../OurServices/sipSelection.png"
  //             alt="RD"
  //             style={{ width: "116px" }}
  //           />
  //           <img
  //             src="../../OurServices/multipleSips.png"
  //             alt="RD"
  //             style={{ width: "116px" }}
  //           />
  //           <img
  //             src="../../OurServices/linkedin.png"
  //             alt="RD"
  //             style={{ width: "116px" }}
  //           />
  //         </Box>
  //         <Button
  //           sx={{
  //             borderRadius: "30px",
  //             border: "1px solid #F5A536",
  //             backgroundColor: "#F5A536",
  //             width: "fit-content",
  //             padding: "8px 16px",
  //             fontSize: "14px",
  //             alignItems: "center",
  //             color: "#000",
  //             textTransform: "none",
  //             textDecoration: "none",
  //             "&:hover": {
  //               background: "linear-gradient(135deg, #F5A536 0%, #FFC87A 100%)",
  //             },
  //           }}
  //           href="https://play.google.com/store/apps/details?id=com.careercarve&hl=en&gl=US"
  //           target="_blank"
  //         >
  //           Download the App
  //         </Button>
  //       </Box>
  //     );
  //   } else if (index === 2) {
  //     return (
  //       <Box
  //         sx={{
  //           display: "flex",
  //           flexDirection: "column",
  //           gap: "1rem",
  //           padding: "36px 9.5rem",
  //         }}
  //       >
  //         <Typography fontSize="45px" color="#1C8EA8">
  //           Webinar Sessions
  //         </Typography>
  //         <Box
  //           sx={{
  //             width: "131px",
  //             height: "1px",
  //             backgroundColor: "#000",
  //           }}
  //         ></Box>
  //         <Typography fontSize="16px" color="#8B8B8B">
  //           Participate in webinar sessions that provide valuable insights into
  //           various career options and offer in-depth analysis of job
  //           descriptions (JDs). This access allows you to make informed
  //           decisions about your career paths and understand the specific
  //           requirements of different roles.
  //         </Typography>
  //         <Box>
  //           <img
  //             src="../../OurServices/Webinar.png"
  //             alt="RD"
  //             style={{ width: "100%" }}
  //           />
  //         </Box>
  //         <Button
  //           sx={{
  //             borderRadius: "30px",
  //             border: "1px solid #F5A536",
  //             backgroundColor: "#F5A536",
  //             width: "fit-content",
  //             padding: "8px 16px",
  //             fontSize: "14px",
  //             alignItems: "center",
  //             color: "#000",
  //             textTransform: "none",
  //             "&:hover": {
  //               background: "linear-gradient(135deg, #F5A536 0%, #FFC87A 100%)",
  //             },
  //           }}
  //         >
  //           View Upcoming Webinar
  //         </Button>
  //       </Box>
  //     );
  //   }
  // };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        // gap: "56px",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {/* Podcast and Sessions */}

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "56px 5rem",
          alignItems: "center",
          gap: "1.5rem",
          background: "rgba(194, 184, 178, 0.30)",
        }}
      >
        {/* <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "1rem",
            }}
          >
            <img
              className="animate__image animation-delay "
              src="../../OurServices/finance.png"
              alt="RD"
              style={{ width: "116px" }}
            />
            <img
              className="animate__image"
              src="../../OurServices/marketing.png"
              alt="RD"
              style={{ width: "116px" }}
            />
            <img
              className="animate__image"
              src="../../OurServices/HR.png"
              alt="RD"
              style={{ width: "116px" }}
            />
            <img
              className="animate__image"
              src="../../OurServices/operations.png"
              alt="RD"
              style={{ width: "116px" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "1rem",
            }}
          >
            <img
              src="../../OurServices/sipRoles.png"
              alt="RD"
              style={{ width: "116px" }}
            />
            <img
              src="../../OurServices/sipSelection.png"
              alt="RD"
              style={{ width: "116px" }}
            />
            <img
              src="../../OurServices/multipleSips.png"
              alt="RD"
              style={{ width: "116px" }}
            />
            <img
              src="../../OurServices/linkedin.png"
              alt="RD"
              style={{ width: "116px" }}
            />
          </Box>
        </Box> */}

        <Box sx={{ width: "100%" }}>
          <video
            autoPlay
            muted
            loop
            // controls
            style={{ width: "100%", height: "100%", borderRadius: "1rem" }}
          >
            <source src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/podcast-and-sessions-careercarve.mp4" type="video/mp4" />
          </video>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            width: "100%",
          }}
        >
          <Box>
            <Typography varaint="h2" fontSize="45px" color="#1C8EA8">
              Podcasts and Sessions
            </Typography>
            <Typography sx={{ fontSize: "22px", marginBottom: "10px" }}>for Expert guidance on the go!</Typography>
            <Box
              sx={{
                width: "131px",
                height: "1px",
                backgroundColor: "#000",
              }}
            ></Box>
          </Box>
          <Typography fontSize="16px" color="#8B8B8B" variant="body1" textAlign={"justify"}>
            Gain exclusive access to podcasts and sessions available only on the CareerCarve app. Our mentors create content specifically for the CareerCarve community, offering deep dives into MBA roles, sectors, and preparation requirements. The content is conveniently organized into byte-sized pieces for easy search and consumption.
          </Typography>
          <a href="https://play.google.com/store/apps/details?id=com.careercarve&hl=en&gl=US" target="_blank" title="careercarve-mobile-app" rel="noreferrer" style={{ textDecoration: "none" }}>
            <button className="button-yellow-hover" style={{ width: "fit-content" }}>
              <Typography> Download the App</Typography>
            </button>
          </a>
        </Box>
      </Box>

      {/* Resume Digital */}
      <Box
        sx={{
          display: "flex",
          // justifyContent: "space-between",
          // gap: "1rem",
          padding: "56px 5rem",
          alignItems: "center",
          // background:
          //   "linear-gradient(0deg, rgba(56, 193, 223, 0.08) 0%, rgba(56, 193, 223, 0.08) 100%)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "1rem",
          }}
        >
          <Box>
            <Typography variant="h2" fontSize="45px">
              Make <span style={{ color: "#1C8EA8" }}>Professional Resumes</span>{" "}
            </Typography>

            <Typography sx={{ fontSize: "22px", marginBottom: "10px" }}>Using our AI powered Resume Builder</Typography>
            <Box
              sx={{
                width: "131px",
                height: "1px",
                backgroundColor: "#000",
              }}
            ></Box>
          </Box>

          {/* <Box sx={{ display: "flex" }}>
            <img
              src="../../OurServices/RDLogo.png"
              alt="RD"
              style={{ width: "232px" }}
            />
          </Box> */}
          <Typography varaint="body1" fontSize="16px" color="#8B8B8B">
            Get access to the most sophisticated AI-powered resume building tool. The AI engine offers superb suggestions to perfect each resume point. You can access the portal throughout the year to update your resumes with new points and maintain multiple versions for different job applications.
          </Typography>
          <a href="https://www.resumes.digital/" target="_blank" style={{ width: "fit-content" }} rel="noreferrer">
            <button className="button-yellow-hover" style={{ width: "fit-content" }}>
              <Typography>Make your resume</Typography>
            </button>
          </a>
        </Box>
        <Box
          sx={{
            width: "100%",
            padding: "30px",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <img src="../../OurServices/RDLogo.png" alt="RD" style={{ width: "232px" }} />
          </Box>
          <img
            // src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/RDImage.png"
            src="https://ccicons.s3.amazonaws.com/landing_page/RD+image.webp"
            alt="MBA Placements Made Easy: Instant Resume Builder"
            style={{ width: "100%", height: "400px" }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default ResumesDigital;
