import React, { useState, useEffect } from 'react';
import { Container, IconButton, List, ListItem, ListItemIcon, useMediaQuery } from '@mui/material';
import { LinkedIn, Facebook, Twitter, MailOutline, Sms, Share } from '@mui/icons-material';
import { Color } from '../../GlobalStyles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ToastSnackbar from '../LandingPage/Blogs/ToastSnakbar';
const ShareFeature = (props) => {
    const [menuActive, setMenuActive] = useState(false);
    const currentPath = window.location.href;
    const [toastOpen, setToastOpen] = useState(false);
    const { webinarDetails } = props
    const Mobile = useMediaQuery('(max-width:800px)');

    useEffect(() => {
        // Update meta tags when component mounts
        updateMetaTags();
    }, []);

    const handleToastClose = () => {
        setToastOpen(false);
    }

    const copyToClipboard = () => {
        console.log("copy link is clicked");
        navigator.clipboard.writeText(currentPath)
        setToastOpen(true);
    };

    const updateMetaTags = () => {
        // Dynamically update meta tags
        const pageTitle = `${webinarDetails?.title}`;
        const pageSubtitle = `${webinarDetails?.sub_title}`;
        const pageImage = `${webinarDetails?.thumbnail}`;

        document.title = pageTitle;

        const metaTags = document.getElementsByTagName('meta');
        for (let i = 0; i < metaTags.length; i++) {
            if (metaTags[i].getAttribute('property') === 'og:title') {
                metaTags[i].setAttribute('content', pageTitle);
            }
            if (metaTags[i].getAttribute('property') === 'og:description') {
                metaTags[i].setAttribute('content', pageSubtitle);
            }
            if (metaTags[i].getAttribute('property') === 'og:image') {
                metaTags[i].setAttribute('content', pageImage);
            }
            if (metaTags[i].getAttribute('property') === 'og:url') {
                metaTags[i].setAttribute('content', currentPath);
            }
            if (metaTags[i].getAttribute('name') === 'twitter:title') {
                metaTags[i].setAttribute('content', pageTitle);
            }
            if (metaTags[i].getAttribute('name') === 'twitter:description') {
                metaTags[i].setAttribute('content', pageSubtitle);
            }
            if (metaTags[i].getAttribute('name') === 'twitter:image') {
                metaTags[i].setAttribute('content', pageImage);
            }
            if (metaTags[i].getAttribute('name') === 'twitter:url') {
                metaTags[i].setAttribute('content', currentPath);
            }
        }
    };

    const handleToggleMenu = () => {
        setMenuActive(!menuActive);
    };
    

    // Social sharing options
    const socials = [
        {
            outlet: 'Copy Link',
            background: '#0a66c2',
            color: 'white',
            icon: <ContentCopyIcon />,
            action: copyToClipboard
        },
        {
            outlet: 'LinkedIn',
            href: 'https://www.linkedin.com/sharing/share-offsite/?url=https://localhost:3000/WebinarDetails?event_id=4&mode=upcoming',
            background: '#0a66c2',
            color: 'white',
            icon: <LinkedIn />
        },
        {
            outlet: 'Facebook',
            href: `https://www.facebook.com/sharer.php?u=${encodeURIComponent(currentPath)}&quote=${encodeURIComponent(`${webinarDetails?.title}`)}&picture=${encodeURIComponent(`${webinarDetails?.thumbnail}`)}`,
            background: '#3b5898',
            color: 'white',
            icon: <Facebook />
        },
        {
            outlet: 'Twitter',
            href: `https://twitter.com/intent/tweet?url=${encodeURIComponent(currentPath)}&text=${encodeURIComponent(`${webinarDetails?.title}`)}: ${encodeURIComponent(`${webinarDetails?.sub_title}`)}&media=${encodeURIComponent("Direct link to image file")}`,
            background: '#00aced',
            color: 'white',
            icon: <Twitter />
        },
        {
            outlet: 'Email',
            href: `https://mail.google.com/mail/?view=cm&fs=1&su=${encodeURIComponent(webinarDetails?.title)}&body=${encodeURIComponent(`Check out this webinar: ${currentPath}\n\nTitle: ${webinarDetails?.title}\nSubtitle: ${webinarDetails?.sub_title}`)}`,
            background: '#dd4b39',
            color: 'white',
            icon: <MailOutline />
        }
    ];

    return (
        <Container style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', background: 'transparent' }}>
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <IconButton
                    style={{
                        display: 'flex',
                        zIndex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '100%',
                        transition: 'all 0.2s, transform 0.2s 0.2s',
                        color: menuActive ? Color.neutralMidGrey : Color.neutralMidGrey,
                        transform: menuActive ? 'scale(0.8)' : 'scale(1.0)',
                        padding: "0px"
                    }}
                    aria-label="Share Button"
                    aria-expanded={menuActive}
                    role="button"
                    onClick={handleToggleMenu}
                >
                    <Share sx={{ width: "20px", height: "20px", color: Color.primary1 }} />
                </IconButton>
                {menuActive && <>
                    <List sx={{ right: Mobile ? "100px" : "55px" }}>
                        {socials.map((social, index) => (
                            <ListItem
                                component="a"
                                href={social.href}
                                onClick={social.action ? social.action : undefined}
                                target="_blank"
                                rel="noreferrer"
                                aria-label={social.outlet}
                                role="button"
                                style={{
                                    position: 'absolute',
                                    zIndex: 0,
                                    transition: 'top 0.2s',
                                    justifyContent: "center",
                                    boxShadow: menuActive ? '0 4px 10px 0 gray' : '0',
                                    left: menuActive ? `${(-1) ** index * Math.ceil(index / 2) * 40}px` : '0',
                                    top: menuActive ? '50px' : '0',
                                    transitionDelay: `${index * 50}ms, ${index * 50}ms`,
                                    display: 'flex',
                                    alignItems: 'left',
                                    right: menuActive ? "100px" : 0
                                }}
                                key={index}
                            >
                                <ListItemIcon sx={{ justifyContent: "center" }}>
                                    {social.icon}
                                </ListItemIcon>
                            </ListItem>
                        ))}
                    </List>

                </>}
                <ToastSnackbar open={toastOpen} message={'URL Copied to Clipboard'} handleClose={handleToastClose} />
            </div>
        </Container>
    );
};

export default ShareFeature;
