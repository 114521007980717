/* fonts */

// import { Dimensions } from "react-native";

// const dimensions = Dimensions.get('window')
export const FontFamily = {
  heading4: "Inter",
  sFProDisplaySemibold: "Inter",
  subheading2: "Inter",
};
/* font sizes */
export const FontSize = {
  overline_size: 10,
  bodySmallRoboto121604_size: 12,
  size_2xs: 12,
  size_xs: 13,
  size_sm: 14,
  subheading4_size: 14,
  bodyLargeRoboto162405_size: 16,
  size_xl: 18,
  size_2xl: 20,
  size_3xl: 22,
  headlineSmallRoboto24320_size: 24,
  heading4_size: 32,
  heading5: 20,
};
/* Colors */
export const Color = {
  white: "#fff",
  neutralMidGrey: "#8b8b8b",
  gray_200: "#808080",
  blueBlack: "#172326",
  gray_400: "rgba(255, 255, 255, 0.01)",
  gray_500: "rgba(255, 255, 255, 0)",
  primary1: "#1c8ea8",
  neutralBlack: "#000",
  green: "#08AB3F",
  primary3: "#38c1df",
  neutralLightGrey: "#e6e6e6",
  gainsboro_200: "#d9d9d9",
  gainsboro_300: "rgba(230, 230, 230, 0.01)",
  gainsboro_400: "rgba(217, 217, 217, 0.1)",
  accent: "#f5a536",
  neutralDarkGrey: "#545454",
  darkslategray: "rgba(65, 65, 65, 0.6)",
  red: "red",
  btnColor: "#2AA8C4",
  webinarTicker:"#FDDB52",
  registerColor:"rgba(28, 142, 168, 1)"
};
/* Paddings */
export const Padding = {
  p_2xs: 6,
  p_xs: 8,
  p_sm: 10,
  p_md: 16,
  p_lg: 24,
  p_xl: 32,
};
/* Margins */
export const Margin = {
  m_7xs: -10,
  m_6xs: -2,
  m_5xs: 0,
  m_4xs: 2,
  m_3xs: 4,
  m_2xs: 6,
  m_xs: 8,
  m_sm: 10,
  // m_md: dimensions.width * .041,    //16
  // m_lg: dimensions.width * .0615,   //24
  m_xl: 32,
  m_2xl: 57,
  m_3xl: 79,
  m_4xl: 192,
  m_5xl: 221,
  m_6xl: 233,
  m_7xl: 257,
};
/* border radiuses */
export const Border = {
  br_3xs: 10,
  br_2xs: 11,
  br_xs: 15,
  br_sm: 17,
  br_md: 20,
  br_lg: 24,
  br_xl: 31,
  br_2xl: 32,
};

export const DivCenter = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}



