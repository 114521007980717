import React from "react";
import "./horizontalScroll.css";
function HorizontalScroll({ images, size }) {
  return (
    <div className="logo">
      <div class="logos-slide">
        {images.map((item) => (
          <img src={item} style={{ height: size, margin: "0px 30px" }} alt="Trusted by Top B-Schools: Power Your MBA Success with Proven Training" />
        ))}
      </div>
      <div class="logos-slide">
        {images.map((item) => (
          <img src={item} style={{ height: size, margin: "0px 30px" }} alt="Trusted by Top B-Schools: Power Your MBA Success with Proven Training" />
        ))}
      </div>
    </div>
  );
}

export default HorizontalScroll;
