import React, { useRef, useState, useEffect, useContext, Fragment } from "react";
import { Box, Grid, Typography, useMediaQuery, Button, CardActionArea, CardActions } from "@mui/material";
import BottomNavbar from "../Footer/BottomNavbar";
import { Helmet } from "react-helmet-async";
import HorizontalScroll from "../../Mentor/HorizontalScroll";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import {} from "@mui/material";
import { Color } from "../../../GlobalStyles";
import ShareIcon from "@mui/icons-material/Share";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import RDService from "../../../services/RDService";
import { extractDateFromDateString, formatConvertermonthfirst } from "../../../formatCovertermonthfirst";
import CategoryBasedBlogs from "./CategoryBasedBlogs";
import { useWebinarContext } from "../../../WebinarContext";
const Blogs = () => {
  const Mobile = useMediaQuery("(max-width:800px)");
  const service = new RDService();
  const [category, setCategory] = useState([]);
  const skeletonLoading = true;
  const [countElement, setCountElement] = useState(1);
  const { webinarTickerExists } = useWebinarContext();
  const categoryDataGetter = async () => {
    try {
      const res = await service.get(`/blog/category`);
      console.log("category data response", res.blog_category);
      return res.blog_category;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const handelEffect = async () => {
      const categoryData = await categoryDataGetter();
      setCategory(categoryData);
      console.log("bhoomika category data getter", categoryData);
    };
    handelEffect();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop = window.scrollY;
      console.log("elemnt reached Bottom", countElement);
      // Check if the user has scrolled to the bottom of the page
      if (windowHeight + scrollTop + 700 > documentHeight) {
        // Increment the count by one when the user reaches the bottom
        setCountElement((prev) => prev + 1);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [countElement]);

  return (
    <>
      {/* this provides the meta description of each page separately */}
      <Helmet>
        <title>Blogs | CareerCarve</title>
        <meta name="description" content="Discover the experienced mentors behind CareerCarve's success. Learn who are our mentors that guide you towards a brighter future." />

        <meta property="og:title" content="CareerCarve-Blogs" />
        <meta property="og:url" content="https://www.careercarve.com/Blogs" />
        <meta property="og:image" content="../../Career_Carve_Logo__1_-removebg-preview 2.png" />
        <link rel="canonical" href="/Blogs" />
      </Helmet>
      <Box sx={{ marginTop: webinarTickerExists && Mobile ? "80px" : !webinarTickerExists && Mobile ? "50px" : webinarTickerExists ? "112px" : "70px" }}>
        <Box sx={{ marginBottom: Mobile ? "1.5rem" : "4.5rem" }}>
          <Box>
            <img
              // src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/MentorAbout.png"
              src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/pexels-mart-production-7643735.png"
              alt=" Meet the mentors at CareerCarve, your trusted partners for expert career guidance and mentorship"
              style={{
                height: Mobile ? "15vh" : "250px",
                width: "100%",
                marginBottom: "-2%",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h1"
              fontSize={Mobile ? "24px" : "36px"}
              sx={{
                zIndex: "20",
                bgcolor: "#FFF",
                fontWeight: "400",
                padding: "8px 46px",
                boxShadow: "0px 6px 49px 0px rgba(0, 0, 0, 0.08)",
              }}
            >
              Blogs
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: Mobile ? "1.5rem" : "2vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              // alignItems: "center",
            }}
          >
            {/* <Typography
              variant={Mobile ? "h5" : "h4"}
              fontSize={Mobile ? "20px" : "20px"}
              color={"#545454"}
              sx={{
                textAlign: "center",
                fontWeight: "400",
                paddingInline: Mobile ? "15px" : "9.5rem",
              }}
            >
              <span style={{ color: "#1C8EA8" }}> Click to Apply filters</span>
            </Typography> */}
            <Box></Box>
          </Box>
        </Box>
      </Box>
      <Fragment>
        <div>
          <div>{category.length > 0 && category.map((item, index) => countElement >= index && <CategoryBasedBlogs category={item} countElement={countElement} setCountElement={setCountElement} skeletonLoading={skeletonLoading} />)}</div>
        </div>
      </Fragment>
      {/* <CategoryBasedBlogs /> */}
      <Box
        sx={{
          marginTop: Mobile ? "15px" : "4rem",
          background: "linear-gradient(226deg, #1D2C30 0%, #020303 100%)",
        }}
      >
        <BottomNavbar />
      </Box>
    </>
  );
};

export default Blogs;
