import React from 'react';
import { Avatar, Typography, Grid, Box } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { Color } from '../../GlobalStyles';
import { WebinarBannerSkeleton } from './SkeletonLoaders/WebinarDetailsSkeletonLoader';
const WebinarBanner = (props) => {
    const Mobile = useMediaQuery('(max-width:800px)');
    const { webinarDetails, loading } = props
    console.log("webinarDetails inside banner", webinarDetails);
    return (
        <>
            {loading.details_loader ? <WebinarBannerSkeleton Mobile={Mobile}/> : <div style={{ position: 'relative' }}>
                <img
                    src={Mobile ? webinarDetails?.app_poster : webinarDetails?.web_poster}
                    style={{ width: "100%", height: "auto", maxHeight:Mobile ? "360px" : "min-content", margin: "0 auto" ,objectFit: "cover"}}
                />
                {!Mobile && <Box
                    sx={{
                        position: 'absolute',
                        bottom: Mobile ? -20 : -40,
                        left: Mobile ? 20 : 125,
                        zIndex: 20,
                        bgcolor: "#FFF",
                        padding: Mobile ? "6px 20px" : "6px 10px",
                        boxShadow: "0px 6px 49px 0px rgba(0, 0, 0, 0.08)",
                        borderRadius: "12px",
                        display: 'flex',
                        flexDirection: Mobile ? "column" : "row",
                        alignItems: Mobile ? "flex-start" : "center",
                        justifyContent: 'center'
                    }}
                >
                    <Typography
                        variant="h3"
                        fontSize={Mobile ? "28px" : "36px"}
                        sx={{
                            fontWeight: 400,
                            textAlign: Mobile ? "center" : "left",
                            marginBottom: Mobile ? "8px" : 0,
                        }}
                    >
                        {webinarDetails?.title}
                    </Typography>
                    <Typography variant="h5" sx={{}}>
                        {webinarDetails.sub_title ? ": " + webinarDetails.sub_title : ""}
                    </Typography>
                </Box>}

            </div>}

        </>
    );
};

export default WebinarBanner;
