import React, { useRef, useState, useEffect, useContext } from "react";
import { Box, Grid, Typography, useMediaQuery, Button, CardActionArea, CardActions } from "@mui/material";
import BottomNavbar from '../Footer/BottomNavbar'
import { Helmet } from "react-helmet-async";
import HorizontalScroll from '../../Mentor/HorizontalScroll';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { } from '@mui/material';
import { Color } from "../../../GlobalStyles";
import ShareIcon from '@mui/icons-material/Share';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import RDService from "../../../services/RDService";
import { extractDateFromDateString, formatConvertermonthfirst } from "../../../formatCovertermonthfirst";
import { NavigateBefore } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import BlogsLoader from './BlogsLoader'
// import ToastSnackbar from '../Reusable_functions/AlertFunctions/ToastSnackbar';
import ShareButton from "./ShareButton";
const CategoryBasedBlogs = (props) => {
    const navigate = useNavigate()
    const Mobile = useMediaQuery("(max-width:800px)");
    const service = new RDService()
    const [blogsDetails, setBlogsDetails] = useState([])
    const [tagsDetails, setTagDetails] = useState([])
    const [loading, setLoading] = useState(true)
    const [blogsAccordingToCategory, setBlogsAccordingToCategory] = useState([])
    const { skeletonLoading, setCountElement, countElement, category } = props
    const [showAllBlogs, setShowAllBlogs] = useState(false);
    const [dialogue, setDialogue] = useState({ open: false, content: '', actions: {} });
    const [shareModal, setShareModal] = useState({ open: false, });
    const [shareUrl, setShareUrl] = useState('');

    const handleCloseShareableUrlModal = () => {
        setShareModal((prev) => ({ ...prev, open: false }));
    }

    const closeAlert = () => {
        setDialogue((prev) => ({ ...prev, open: false }));
    };

    const handleCopyButton = async (e, blog_id) => {
        e.stopPropagation();
        let currentUrl = window.location.href;
        let temp_url = currentUrl.replace(/\/[^/]*$/, `/BlogsEntireDetails?blog_id=${blog_id}`);
        console.log("temp_url", temp_url);
        if (temp_url) {
            setShareUrl(temp_url);
            setShareModal((prev) => ({ ...prev, open: true }));
        } else {
            temp_url = '/demo';
            setDialogue((prev) => ({
                ...prev,
                open: true,
                content: 'Failed to generate URL',
                actions: { ...prev.actions, handler: () => closeAlert() }
            }));
        }
    };

    const blogsDataGetter = async () => {
        try {
            const res = await service.get(`/blog/blog/all?platform=CareerCarve`);
            console.log("blogs data response", res.blog_category);
            return res.blog_category;
        } catch (err) {
            console.log(err);
        }
    };

    const tagsDataGetter = async () => {
        try {
            const res = await service.get(`/blog/tag`);
            console.log("blogs data response", res.tags);
            return res.tags;
        } catch (err) {
            console.log(err);
        }
    };

    const blogsAccordingToCategoryDataGetter = async () => {
        try {
            const res = await service.get(`/blog/category/blog?category_id=${category.id}`);
            console.log("category data response", res.blogs);
            return res.blogs;
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        const handelEffect = async () => {
            const blogsData = await blogsDataGetter();
            const tagsData = await tagsDataGetter()
            const blogsAccordingToCategory = await blogsAccordingToCategoryDataGetter()
            let temp_blogsData = blogsData.filter(category => category.blogs);
            setBlogsDetails(temp_blogsData);
            setTagDetails(tagsData)
            setBlogsAccordingToCategory(blogsAccordingToCategory)
            console.log("bhoomika all data getter", temp_blogsData, tagsData, blogsAccordingToCategory);

            setLoading(false)
        };
        handelEffect();
    }, [])

    const handleEntireDetailsNavigation = (blog_id) => {
        console.log("blog id here", blog_id);
        navigate(`/BlogsEntireDetails?blog_id=${blog_id}`, { state: { blog_id: blog_id, tagsDetails: tagsDetails } })
    }

    const handleViewAll = () => {
        setShowAllBlogs(true);
    };

    return (
        <div>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "90%"
                }}
            >
                <Typography
                    variant={Mobile ? "h5" : "h4"}
                    fontSize={Mobile ? "20px" : "24px"}
                    color={"#545454"}
                    sx={{
                        fontWeight: "400",
                        paddingInline: Mobile ? "15px" : "9.5rem",
                        textAlign: "left",
                        marginBottom: "1.5rem"
                    }}
                >
                    {category.name}
                </Typography>
                {blogsAccordingToCategory.length > 3 && !showAllBlogs && (
                    <button style={{ background: "transparent", border: "none", color: Color.primary1, marginBottom: "1.5rem" }} onClick={handleViewAll}>
                        View All
                    </button>
                )}
            </Box>
            {loading ? <>
                <div style={{ display: "flex", paddingInline: Mobile ? "15px" : "9.5rem", flexDirection: "row", gap: "5rem", background: "transparent" }}>
                    <BlogsLoader />
                    <BlogsLoader />
                    <BlogsLoader />
                </div>
            </> : <>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "left",
                        paddingInline: Mobile ? "15px" : "9.5rem",
                        flexDirection: "column  ",
                        gap: "2rem",
                    }}
                >
                    <Grid container spacing={1}>
                        {blogsAccordingToCategory?.slice(0, showAllBlogs ? blogsAccordingToCategory?.length : 3).map((blogs, index) => {
                            return (
                                <>

                                    <Grid item xs={12} sm={6} md={4} mb={5} onClick={() => handleEntireDetailsNavigation(blogs.id)}>
                                        <Card sx={{ maxWidth: 345, padding: "15px", borderRadius: "40px", background: "#FFF", boxShadow: "0px 10px 93.2px 0px rgba(0, 0, 0, 0.10)" }}>
                                            <CardActionArea >
                                                <CardMedia
                                                    component="img"
                                                    height="269"
                                                    image={blogs.image}
                                                    alt="green iguana"
                                                    sx={{
                                                        // height: "269px",
                                                        alignSelf: "stretch",
                                                        borderRadius: "40px"
                                                    }}
                                                />
                                                <CardContent>
                                                    <Box sx={{ display: "flex", justifyContent: 'space-between' }}>
                                                        <Box sx={{ display: "flex", justifyContent: 'space-equally' }}>
                                                            {tagsDetails
                                                                .filter((tag) => (blogs?.tag_ids?.tag_ids)?.includes(tag.id)) // Filter to include only the tags with matching IDs
                                                                .slice(0, 2) // Take only the first two elements after filtering
                                                                .map((tag, index) => (
                                                                    <Typography
                                                                        key={index}
                                                                        size="small"
                                                                        color={Color.neutralDarkGrey}
                                                                        style={{
                                                                            borderRadius: "61px",
                                                                            border: "1px solid #1C8EA8",
                                                                            background: "#EBF9FC",
                                                                            paddingLeft: "10px",
                                                                            paddingRight: "10px",
                                                                            wordWrap: "break-word",
                                                                            fontSize: "10px",
                                                                            justifyContent: "center",
                                                                            marginRight: ".2rem",
                                                                            paddingTop: "2px",
                                                                            alignItems: "center"
                                                                        }}
                                                                        gutterBottom
                                                                    >
                                                                        {tag.name.length > 10 ? `${tag.name.slice(0, 10)}...` : tag.name}
                                                                    </Typography>
                                                                ))}


                                                        </Box>

                                                        <ShareIcon
                                                            onClick={(e) => handleCopyButton(e, blogs.id)}
                                                        />
                                                    </Box>
                                                    <Typography gutterBottom variant="h5" component="div" style={{ wordWrap: "break-word", overflowWrap: "break-word", maxWidth: "100%" }}>
                                                        {blogs?.heading.length > 23
                                                            ? `${blogs?.heading.slice(0, 20)}...`
                                                            : blogs?.heading}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary" gutterBottom>
                                                        {/* {blogs.description} */}
                                                        {blogs?.description?.length > 30
                                                            ? `${blogs?.description.slice(0, 30)}...`
                                                            : blogs?.description}
                                                    </Typography>
                                                    <Box sx={{ display: "flex" , flexDirection:blogs.description ? "row" : "column"}} >
                                                        <Typography variant="body2" color={Color.neutralDarkGrey} gutterBottom style={{ marginRight: ".5rem" }}>
                                                            {formatConvertermonthfirst(
                                                                extractDateFromDateString(blogs.upload_time)
                                                            )}
                                                        </Typography>
                                                        {blogs.description && <FiberManualRecordIcon style={{ width: ".3rem", paddingBottom: ".3rem", marginRight: ".5rem" }} />}
                                                        <Typography variant="body2" color={Color.neutralDarkGrey}>{blogs.reading_time} mins read</Typography>
                                                    </Box>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                </>
                            )
                        })}
                    </Grid>
                </Box>
            </>}
            <ShareButton open={shareModal.open} handleClose={() => handleCloseShareableUrlModal()} url={shareUrl} />
            {/* <ToastSnackbar open={toastOpen} message={'URL Copied to Clipboard'} handleClose={handleToastClose} /> */}
        </div>
    )
}

export default CategoryBasedBlogs
