import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../../components/StateProvider/stateProvider";
import BottomNavbar from "../Footer/BottomNavbar";
import { useWebinarContext } from "../../../WebinarContext";
function NewMockInterview() {
  const navigate = useNavigate();
  const [{ basket }, dispatch] = useStateValue();
  const Mobile = useMediaQuery("(max-width:800px)");
  const { webinarTickerExists } = useWebinarContext();
  const handleNavigate = (value) => {
    window.scroll(0, 0);
    navigate(value);
  };

  const addToCart = (value) => {
    let temp_cart = JSON.parse(localStorage.getItem("temp_quantity")) ?? [];
    console.log("cart item", temp_cart);
    const env = `${process.env.REACT_APP_ENVIRONMENT}`;
    const id = env === "dev" ? (value === 45 ? 15 : 17) : value === 45 ? 7 : 6;
    let item = temp_cart.filter((item) => item.id === id);
    if (item.length > 0) {
      temp_cart = temp_cart.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            quantity: item.quantity + 1,
            total_price: item.total_price + item.total_price / item.quantity,
          };
        } else {
          return item;
        }
      });
    } else {
      let data = {
        search_tbl: "sku",
        id: id,
        quantity: 1,
        total_price: 1999,
      };
      temp_cart = [...temp_cart, data];
    }

    console.log("cart item", temp_cart);
    dispatch({ type: "UPDATE_BASKET", basket: temp_cart });
    localStorage.setItem("temp_quantity", JSON.stringify(temp_cart));
  };
  return (
    <>
      {/* this provides the meta description of each page separately */}
      <Helmet>
        <title>The Ultimate MBA Interview Prep | Simulated Interview Experience</title>
        <meta name="description" content="Don't leave your MBA interviews to chance. CareerCarve's mock interviews provide valuable feedback and help you stand out from the crowd." />

        <meta property="og:title" content="CareerCarve-Mock Interview" />
        <meta property="og:image" content="/public/logo_welcome.png" />
        <meta property="og:url" content="https://www.careercarve.com/MockInterview" />
        <link rel="canonical" href="/MockInterview" />
      </Helmet>
      <Box sx={{ marginTop: webinarTickerExists && Mobile ? "80px" : !webinarTickerExists && Mobile ? "50px" : webinarTickerExists ? "112px" : "70px" }}>
        {/* <Navbar /> */}
        <Box sx={{ marginBottom: Mobile ? "1.5rem" : "4.5rem" }}>
          <Box>
            <img
              src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/MockInterview.png"
              alt="Expert Mock Interviews: Get Feedback from Industry Professionals"
              style={{
                height: Mobile ? "15vh" : "100%",
                width: "100%",
                marginBottom: Mobile ? "-5%" : "-2%",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              variant={Mobile ? "h1" : "h1"}
              fontSize={Mobile ? "24px" : "36px"}
              sx={{
                zIndex: "20",
                bgcolor: "#FFF",
                fontWeight: "400",
                padding: "8px 46px",
                boxShadow: "0px 6px 49px 0px rgba(0, 0, 0, 0.08)",
              }}
            >
              Mock Interview
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingInline: Mobile ? "15px" : "9.5rem",
            gap: Mobile ? "4rem" : "4.5rem",

            paddingBottom: "4.5rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: Mobile ? "0.5rem" : "1.5rem",
            }}
          >
            <Typography variant={Mobile ? "h2" : "h2"} fontSize={Mobile ? "20px" : "24px"} textAlign={Mobile ? "center" : ""}>
              Get Interviewed by
              <span style={{ color: "#1C8EA8" }}> Industry Experts</span>
            </Typography>
            <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
              Welcome to CareerCarve's Mock Interview Preparation for MBA students! We understand the importance of interview skills and the impact they have on your career trajectory. Our comprehensive program is designed to help you master the art of interviews and land your dream job with confidence. Get inteviewed by domain experts with 11+ years fo experience.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: Mobile ? "column" : "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box width="100%" display="flex" flexDirection="column" gap={Mobile ? "0.5rem" : "1.5rem"}>
              <Typography fontSize={Mobile ? "20px" : "24px"} variant={Mobile ? "h5" : "h4"}>
                Why take a <span style={{ color: "#1C8EA8" }}>Mock Interview</span>?
              </Typography>
              <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                Mock interviews are simulated job interviews that closely resemble real-life interview scenarios. They provide you with an opportunity to practice and refine your interview skills in a controlled and supportive environment. Our mock interviews are tailored specifically for MBA students, taking into account the unique requirements and expectations of top companies in the business world.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                }}
              >
                <button
                  className="button-yellow-hover"
                  style={{
                    width: "fit-content",
                    fontSize: Mobile ? "14px" : "15px",
                  }}
                  onClick={() => addToCart(30)}
                >
                  <Typography>Book 30 mins session</Typography>
                </button>
                <button
                  className="button-yellow-hover"
                  style={{
                    fontSize: Mobile ? "14px" : "15px",
                    width: "fit-content",
                  }}
                  onClick={() => addToCart(45)}
                >
                  <Typography> Book 45 mins session</Typography>
                </button>
              </Box>
            </Box>
            <Box width="100%" display={"flex"} justifyContent={"center"} alignItems={"center"}>
              <img
                src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/Mock+interview+picart.png"
                alt="Ready For Your MBA Interview? Start Your Mock Interview Today!"
                style={{
                  width: Mobile ? "250px" : "396px",
                  height: Mobile ? "100%" : "325px",
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: Mobile ? "0.5rem" : "1.5rem",
            }}
          >
            <Typography fontSize={Mobile ? "20px" : "24px"} variant={Mobile ? "h5" : "h4"}>
              How does it <span style={{ color: "#1C8EA8" }}>benefit</span> me?
            </Typography>
            <Box display="flex" flexDirection="column" gap="2rem">
              <Box display="flex" gap="0.5rem" flexDirection="column" sx={{ borderLeft: "2px solid #1C8EA8", paddingLeft: "1rem" }}>
                <Typography fontSize={Mobile ? "16px" : "20px"} variant={Mobile ? "h6" : "h5"}>
                  Gain Confidence
                </Typography>
                <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                  Repeatedly practicing interviews will boost your confidence and reduce anxiety, ensuring you perform your best when it matters most.
                </Typography>
              </Box>
              <Box display="flex" gap="0.5rem" flexDirection="column" sx={{ borderLeft: "2px solid #F5A536", paddingLeft: "1rem" }}>
                <Typography fontSize={Mobile ? "16px" : "20px"} variant={Mobile ? "h6" : "h5"}>
                  Learn Interview Strategies
                </Typography>
                <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                  Mentors will share valuable tips and strategies. You'll learn how to frame your answers and demonstrate your value proposition effectively.
                </Typography>
              </Box>

              <Box display="flex" gap="0.5rem" flexDirection="column" sx={{ borderLeft: "2px solid #1C8EA8", paddingLeft: "1rem" }}>
                <Typography fontSize={Mobile ? "16px" : "20px"} variant={Mobile ? "h6" : "h5"}>
                  Know Employer Expectations
                </Typography>
                <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                  Our mock interviews are designed to mirror real-world job interviews, providing you with insights allowing you to align your responses accordingly.
                </Typography>
              </Box>
              <Box display="flex" gap="0.5rem" flexDirection="column" sx={{ borderLeft: "2px solid #F5A536", paddingLeft: "1rem" }}>
                <Typography fontSize={Mobile ? "16px" : "20px"} variant={Mobile ? "h6" : "h5"}>
                  Instant Actionable Feedback
                </Typography>
                <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                  Our experienced mentors will provide constructive feedback, highlighting your strengths and pinpointing areas where you can enhance your performance.
                </Typography>
              </Box>

              <Box display="flex" gap="0.5rem" flexDirection="column" sx={{ borderLeft: "2px solid #1C8EA8", paddingLeft: "1rem" }}>
                <Typography fontSize={Mobile ? "16px" : "20px"} variant={Mobile ? "h6" : "h5"}>
                  Last Minute Interview Prep
                </Typography>
                <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                  Along with a Mock Interview session with an industry expert, also get access to 100's of podcasts and sessions to help you prepare better.
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexDirection={Mobile ? "column" : "row"} justifyContent="space-between" alignItems="center" gap="8rem">
            <Box display="flex" justifyContent="space-between" gap="1.5rem" flexDirection="column">
              <Typography fontSize={Mobile ? "20px" : "24px"} variant={Mobile ? "h6" : "h5"}>
                Where will the <span style={{ color: "#1C8EA8" }}>session happen</span>?
              </Typography>
              <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                Our mock interviews are conducted online and can be scheduled on the CareerCarve App , allowing you to participate from the comfort of your own home or any location that suits you best. Each mock interview lasts approximately 30 minutes.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: Mobile ? "center" : "",
                }}
              >
                {!Mobile && <img src="../../QR_code_sign_up.jpg" style={{ height: "78px", width: "78px" }} alt="CareerCarve" />}
                <a href="https://play.google.com/store/apps/details?id=com.careercarve&hl=en&gl=US" target="_blank" title="careercarve-mobile-app" rel="noreferrer" style={{ textDecoration: "none" }}>
                  <button className="button-yellow-hover" style={{ width: "fit-content" }}>
                    <Typography> Download the App</Typography>
                  </button>
                </a>
              </Box>
            </Box>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                width: Mobile ? "200px" : "385px",
                height: Mobile ? "350px" : "385px",
                background: "rgba(56, 193, 223, 0.20)",
              }}
              paddingInline={"10rem"}
              borderRadius={"50%"}
            >
              <img
                src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/1+on+1+Mobile.png"
                alt="Book Your MBA Mock Interview Now! Easy Scheduling & Details Inside"
                title="Prepare for your interview with expert guidance"
                style={{
                  width: Mobile ? "220px" : "243px",
                  height: Mobile ? "380px" : "480px",
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: Mobile ? "1.5rem" : "2.5rem",
            }}
          >
            <Typography fontSize={Mobile ? "24px" : "32px"} color="#1C8EA8" textAlign="center">
              View Other 1X1
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                {" "}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "100%",
                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                    padding: "1rem",
                    height: "272px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                    }}
                  >
                    <Typography fontSize={Mobile ? "20px" : "24px"} variant={Mobile ? "h5" : "h4"}>
                      Resume Discussion
                    </Typography>
                    <Typography fontSize={Mobile ? "14px" : "16px"} color="#8B8B8B" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                      The SIP Success Session at CareerCarve is a comprehensive and personalized program aimed at helping you excel in your Summer Internship Program.
                    </Typography>
                  </Box>
                  <button className="button-yellow-hover" onClick={() => handleNavigate("/ResumeDiscussion")}>
                    <Typography>View More</Typography>
                  </button>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "100%",
                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                    padding: "1rem",
                    height: "272px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                    }}
                  >
                    <Typography fontSize={Mobile ? "20px" : "24px"} variant={Mobile ? "h5" : "h4"}>
                      PI Questions from Resume
                    </Typography>
                    <Typography fontSize={Mobile ? "14px" : "16px"} color="#8B8B8B" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                      The Personal Interview Questions from Resume Session at CareerCarve is a one-on-one consultation with experienced industry mentors.
                    </Typography>
                  </Box>
                  <button className="button-yellow-hover" onClick={() => handleNavigate("/PIQR")}>
                    <Typography>View More</Typography>
                  </button>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "100%",
                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                    padding: "1rem",
                    height: "272px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                    }}
                  >
                    <Typography fontSize={Mobile ? "20px" : "24px"} variant={Mobile ? "h5" : "h4"}>
                      Case Interview
                    </Typography>
                    <Typography fontSize={Mobile ? "14px" : "16px"} color="#8B8B8B" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                      The Case Interview Session at CareerCarve is a personalized consultation with experienced industry mentors who have a strong background in consulting and business strategy.
                    </Typography>
                  </Box>
                  <button className="button-yellow-hover" onClick={() => handleNavigate("/CaseInterview")}>
                    <Typography>View More</Typography>
                  </button>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {/* <Box display="flex" justifyContent="space-between" gap="1.5rem">

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "100%",
              boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
              padding: "1rem",
              height: "272px",
            }}
          >
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
            >
              <Typography fontSize="24px">Resume Discussion</Typography>
              <Typography fontSize="16px" color="#8B8B8B">
                The SIP Success Session at CareerCarve is a comprehensive and
                personalized program aimed at helping you excel in your Summer
                Internship Program.
              </Typography>
            </Box>
            <Button
              sx={{
                borderRadius: "8px",
                border: "1px solid #F5A536",
                backgroundColor: "#F5A536",
                width: "100%",
                padding: "8px 16px",
                fontSize: "14px",
                color: "#000",
                "&:hover": {
                  background:
                    "linear-gradient(135deg, #F5A536 0%, #FFC87A 100%)",
                },
              }}
              onClick={() => handleNavigate("/ResumeDiscussion")}
            >
              View More
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "100%",
              boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
              padding: "1rem",
              height: "272px",
            }}
          >
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
            >
              <Typography fontSize="22px">PI Questions from Resume</Typography>
              <Typography fontSize="16px" color="#8B8B8B">
                The Personal Interview Questions from Resume Session at
                CareerCarve is a one-on-one consultation with experienced
                industry mentors.
              </Typography>
            </Box>
            <Button
              sx={{
                borderRadius: "8px",
                border: "1px solid #F5A536",
                backgroundColor: "#F5A536",
                width: "100%",
                padding: "8px 16px",
                fontSize: "14px",
                color: "#000",
                "&:hover": {
                  background:
                    "linear-gradient(135deg, #F5A536 0%, #FFC87A 100%)",
                },
              }}
              onClick={() => handleNavigate("/PIQR")}
            >
              View More
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "100%",
              boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
              padding: "1rem",
              height: "272px",
            }}
          >
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
            >
              <Typography fontSize="22px">Case Interview</Typography>
              <Typography fontSize="16px" color="#8B8B8B">
                The Case Interview Session at CareerCarve is a personalized
                consultation with experienced industry mentors who have a strong
                background in consulting and business strategy.
              </Typography>
            </Box>
            <Button
              sx={{
                borderRadius: "8px",
                border: "1px solid #F5A536",
                backgroundColor: "#F5A536",
                width: "100%",
                padding: "8px 16px",
                fontSize: "14px",
                color: "#000",
                "&:hover": {
                  background:
                    "linear-gradient(135deg, #F5A536 0%, #FFC87A 100%)",
                },
              }}
              onClick={() => handleNavigate("/CaseInterview")}
            >
              View More
            </Button>
          </Box>
        </Box> */}
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: Mobile ? "10px" : "",
          background: "linear-gradient(226deg, #1D2C30 0%, #020303 100%)",
        }}
      >
        <BottomNavbar />
      </Box>
    </>
  );
}

export default NewMockInterview;
