import { ThemeProvider } from "@mui/material";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { WebinarProvider } from "./WebinarContext";
import Errorpage from "./components/Errorpage";
import Navbar from "./components/Navbar/Navbar";
import WebinarTicker from "./components/WebinarTicker/WebinarTicker";
import AboutUs from "./screens/About Us/AboutUs";
import CancellationAndRefundpolicy from "./screens/CancellationAndRefundPolicy/CancellationAndRefundpolicy";
import CartOrderCancelled from "./screens/CartOrderConfirmation/CartOrderCancelled";
import CartOrderFailed from "./screens/CartOrderConfirmation/CartOrderFailed";
import BookingPage from "./screens/CartScreens/BookingPage";
import PriorityBooking from "./screens/CartScreens/PriorityBooking";
import Blogs from "./screens/LandingPage/Blogs/Blogs";
import BlogsEntireDetails from "./screens/LandingPage/Blogs/BlogsEntireDetails";
import NewCaseInterview from "./screens/LandingPage/CaseInterview/NewCaseInterview";
import NewExpertConnect from "./screens/LandingPage/ExpertConnect/NewExpertConnect";
import Home from "./screens/LandingPage/Home";
import NewMockInterview from "./screens/LandingPage/MockInterview/NewMockInterview";
import PIQR from "./screens/LandingPage/PIQR/PIQR";
import NewResumeDiscussion from "./screens/LandingPage/ResumesDigital/NewResumeDiscussion";
import NewSipSuccess from "./screens/LandingPage/SipSuccess/NewSipSuccess";
import BecomeMentor from "./screens/Mentor/BecomeMentor";
import Mentor from "./screens/Mentor/Mentor";
import MentorDetails from "./screens/Mentor/MentorDetails";
import NewAllMentorPage from "./screens/Mentor/NewAllMentorPage";
import Pricing from "./screens/Pricing/Pricing";
import PrivacyPolicy from "./screens/PrivacyPolicy/PrivacyPolicy";
import PurchaseStatus from "./screens/PurchaseStatus/PurchaseStatus";
import PurchaseStatusConfirm from "./screens/PurchaseStatus/PurchaseStatusConfirm";
import TermsOfUse from "./screens/TermsOfUse/TermsOfUse";
import WebinarBanner from "./screens/WebinarEvents/WebinarBanner";
import WebinarDetails from "./screens/WebinarEvents/WebinarDetails";
import WebinarEvents from "./screens/WebinarEvents/WebinarEvents";
import { theme } from "./theme";
function App() {
  const helmetContext = {};

  return (
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <WebinarProvider>
          <BrowserRouter>
            <WebinarTicker />
            <Navbar />

            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/AboutUs" element={<AboutUs />} />
              <Route path="/WebinarEvents" element={<WebinarEvents />} />
              <Route path="/BecomeMentor" element={<BecomeMentor />} />
              <Route path="/MockInterview" element={<NewMockInterview />} />
              <Route path="/SipSuccess" element={<NewSipSuccess />} />
              <Route path="/PIQR" element={<PIQR />} />
              <Route path="/WebinarBanner" element={<WebinarBanner />} />
              <Route path="/WebinarDetails" element={<WebinarDetails />} />
              <Route path="/ResumeDiscussion" element={<NewResumeDiscussion />} />
              <Route path="/CaseInterview" element={<NewCaseInterview />} />
              <Route path="/ExpertConnect" element={<NewExpertConnect />} />
              <Route path="/Mentors" element={<Mentor />} />
              <Route path="/AllMentors" element={<NewAllMentorPage />} />
              <Route path="/MentorDetails/:username" element={<MentorDetails />} />
              <Route path="/PriorityBooking" element={<PriorityBooking />} />
              <Route path="/Mentors" element={<Mentor />} />
              <Route path="/Pricing" element={<Pricing />} />
              <Route path="/CartScreen" element={<BookingPage />} />
              <Route path="/CartPaymentFailed" element={<CartOrderFailed />} />
              <Route path="/CartPaymentCancelled" element={<CartOrderCancelled />} />
              <Route path="/Blogs" element={<Blogs />} />
              <Route path="/BlogsEntireDetails" element={<BlogsEntireDetails />} />
              {/* <Route path="/CartPaymentConfirmed" element={<CartOrderConfirmation />} /> */}
              <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
              <Route path="/CancellationAndRefundpolicy" element={<CancellationAndRefundpolicy />} />
              <Route path="/TermsOfUse" element={<TermsOfUse />} />
              <Route path="/OrderHistory" element={<PurchaseStatus />} />
              <Route path="/CartPaymentConfirmed" element={<PurchaseStatusConfirm />} />
              <Route path="*" element={<Errorpage />} />
            </Routes>
          </BrowserRouter>
        </WebinarProvider>
      </HelmetProvider>
    </ThemeProvider>
  );
}

export default App;
