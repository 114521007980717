import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Box, Grid, Typography, useMediaQuery, CardActionArea } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

const BlogsLoader = () => {
  return (
    <div>
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ minWidth: 345, padding: "15px", borderRadius: "40px", background: "transparent", boxShadow: "0px 10px 93.2px 0px rgba(0, 0, 0, 0.10)" }}>
            <Skeleton variant="rectangular" width="100%" height="250px" sx={{ borderRadius: "40px", margin:"0 auto" }} animation="wave" />
            <CardContent>
              <Box style={{ marginBottom: ".5rem" }}>
              <Skeleton variant="rectangular" width={"100%"} height="20px" sx={{  borderRadius: "5px" }} animation="wave" />
              </Box>
              <Box style={{marginBottom:".5rem"}}>
              <Skeleton variant="rectangular" width={"100%"} height="20px" sx={{ borderRadius: "5px" }} animation="wave" />
              </Box>
              <Box style={{marginBottom:".5rem"}}>
              <Skeleton variant="rectangular" width={"100%"} height="20px" sx={{ borderRadius: "5px" }} animation="wave" />
              </Box>
              <Box sx={{ display: "flex",marginBottom:".5rem" }}>
                <Skeleton variant="rectangular" width={"100%"} height="20px" sx={{ borderRadius: "5px" }} animation="wave" />
              </Box>
            </CardContent>
          </Card>
      </Grid>
    </div>
  )
}

export default BlogsLoader