import React, { useEffect, useState } from 'react'
import { Box, Typography, useMediaQuery } from "@mui/material";
import { Color } from '../../GlobalStyles';
import RDService from '../../services/RDService';
import { useNavigate } from "react-router-dom";
import { useWebinarContext } from '../../WebinarContext';
const WebinarTicker = () => {
    const { webinarTickerExists, setWebinarTickerExists } = useWebinarContext();
    const service = new RDService()
    const navigate = useNavigate()
    const Mobile = useMediaQuery("(max-width:800px)");
    const [onGoingWebinarDetails, setOngoingWebinarDetails] = useState({})
    useEffect(() => {
        const handleEffect = async () => {
            const ongoingWebinar = await onGoingWebinarGetter()
            setOngoingWebinarDetails(ongoingWebinar)
        }
        handleEffect()
    }, [])

    const onGoingWebinarGetter = async () => {
        try {
            const res = await service.get(`/user/event/banner`)
            const temp_event_exists = res.display_till;
            console.log("getting applicants data response:", res, temp_event_exists,webinarTickerExists);
            const webinarIsOngoing = Date.now() > temp_event_exists;
            setWebinarTickerExists(false);
            let temp_change_short_list = res
            return temp_change_short_list;
        } catch (err) {
            console.log(err);
        }
    }

    const handleEventDetailsClick = () => {
        navigate(`/WebinarDetails?event_id=${onGoingWebinarDetails?.event_id}&mode=upcoming`)
    }

    return (
        <>
            {webinarTickerExists && <Box
                sx={{
                    height: Mobile ? "35px" : "45px",
                    color: "blueBlack",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    backgroundColor: Color.webinarTicker,
                    width: "100%",
                    fontSize: Mobile ? "10px" : "16px",
                    fontWeight: 500,
                    zIndex: "100",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer"
                }}
                onClick={() => handleEventDetailsClick()}
            >
                <Typography sx={{ fontWeight: 550, fontSize: Mobile ? "8px" : "16px", }}>{onGoingWebinarDetails?.header}</Typography>
            </Box>}
        </>
    )
}

export default WebinarTicker
