import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import BottomNavbar from "../Footer/BottomNavbar";
import { useStateValue } from "../../../components/StateProvider/stateProvider";
import ServiceCM from "../../../services/cartmoduleServices";
import { useWebinarContext } from "../../../WebinarContext";
function PIQR() {
  const navigate = useNavigate();
  const env = `${process.env.REACT_APP_ENVIRONMENT}`;
  const [{ basket }, dispatch] = useStateValue();
  const { webinarTickerExists } = useWebinarContext();
  const services = new ServiceCM();
  const [sku, setSku] = useState([]);
  const handleNavigate = (value) => {
    window.scroll(0, 0);
    navigate(value);
  };

  const addToCart = () => {
    let temp_cart = JSON.parse(localStorage.getItem("temp_quantity")) ?? [];
    console.log("cart item", temp_cart);
    const id = env === "dev" ? 4 : 4;
    let item = temp_cart.filter((item) => item.id === id);
    if (item.length > 0) {
      temp_cart = temp_cart.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            quantity: item.quantity + 1,
            total_price: item.total_price + item.total_price / item.quantity,
          };
        } else {
          return item;
        }
      });
    } else {
      let find_sku = sku.find((item) => item.id === id);

      let data = {
        search_tbl: "sku",
        id: id,
        quantity: 1,
        total_price: find_sku.actual_price,
      };
      temp_cart = [...temp_cart, data];
    }

    console.log("cart item", temp_cart);
    dispatch({ type: "UPDATE_BASKET", basket: temp_cart });
    localStorage.setItem("temp_quantity", JSON.stringify(temp_cart));
  };

  const getSku = async () => {
    const res = await services.get("/sku?type=1 X 1");
    setSku(res.data);
  };

  useEffect(() => {
    getSku();
  }, []);

  const Mobile = useMediaQuery("(max-width:800px)");
  return (
    <>
      {/* this provides the meta description of each page separately */}
      <Helmet>
        <title>Don't Let Your Resume Trip You Up! Prepare For PI Questions Now</title>
        <meta name="description" content="Resume Under the Spotlight: Turn PI Questions into Interview Win" />

        <meta property="og:title" content="CareerCarve-Interview questions from Resume" />
        <meta property="og:image" content="../../Career_Carve_Logo__1_-removebg-preview 2.png" />
        <meta property="og:url" content="https://www.careercarve.com/PIQR" />
        <link rel="canonical" href="/PIQR" />
      </Helmet>
      <Box sx={{ marginTop: webinarTickerExists && Mobile ? "80px" : !webinarTickerExists && Mobile ? "50px" : webinarTickerExists ? "112px" : "70px" }}>
        {/* <Navbar /> */}
        <Box sx={{ marginBottom: Mobile ? "1.5rem" : "4.5rem" }}>
          <Box>
            <img
              src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/PIQR.png"
              alt="Short on Time? Conquer PI Questions with Your Resume"
              style={{
                height: Mobile ? "15vh" : "100%",
                width: "100%",
                marginBottom: Mobile ? "-5%" : "-2%",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h1"
              fontSize={Mobile ? "24px" : "36px"}
              sx={{
                zIndex: "20",
                bgcolor: "#FFF",
                fontWeight: "400",
                padding: "8px 46px",
                boxShadow: "0px 6px 49px 0px rgba(0, 0, 0, 0.08)",
              }}
            >
              {Mobile ? "PIQR" : "PI Questions from Resume"}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingInline: Mobile ? "15px" : "9.5rem",
            gap: Mobile ? "4rem" : "4.5rem",
            paddingBottom: "4.5rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: Mobile ? "0.5rem" : "1.5rem",
            }}
          >
            <Typography variant={Mobile ? "h2" : "h2"} fontSize={Mobile ? "20px" : "24px"} textAlign={Mobile ? "center" : ""}>
              Personal Interview Questions from Resume
            </Typography>
            <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
              Welcome to CareerCarve's Personal Interview Questions from Resume Session, an interactive and tailored experience that will help you master the art of answering interview questions based on your resume. We understand that personal interviews can be nerve-wracking, but with our expert-led sessions, you'll gain the confidence and skills needed to excel in any interview setting.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: Mobile ? "column" : "row",
              alignItems: "center",
            }}
          >
            <Box width="100%" display="flex" flexDirection="column" gap={Mobile ? "0.5rem" : "1.5rem"}>
              <Typography fontSize={Mobile ? "20px" : "24px"} variant={Mobile ? "h5" : "h4"}>
                Why take a <span style={{ color: "#1C8EA8" }}>PIQR</span> Session?
              </Typography>
              <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                The Personal Interview Questions from Resume Session at CareerCarve is a one-on-one consultation with experienced industry mentors. During this session, our mentors will carefully review your resume and curate a list of potential interview questions tailored to your unique experiences and qualifications. We will focus on questions that are commonly asked by employers during interviews that can help you prepare well-structured, authentic, and impressive responses.
              </Typography>
              <button
                className="button-yellow-hover"
                style={{
                  width: "fit-content",
                  padding: "8px 2rem",
                  alignSelf: Mobile ? "center" : "",
                }}
                onClick={() => {
                  addToCart();
                }}
              >
                <Typography>Add to cart</Typography>
              </button>
            </Box>
            <Box width="100%" display={"flex"} justifyContent={"center"} alignItems={"center"}>
              <img
                src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/PIQR+picart.png"
                alt="Conquer PI Anxiety: Powerful Answers to Resume-Based Interview Questions"
                style={{
                  width: Mobile ? "250px" : "396px",
                  height: Mobile ? "100%" : "325px",
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: Mobile ? "0.5rem" : "1.5rem",
            }}
          >
            <Typography fontSize={Mobile ? "20px" : "24px"} variant={Mobile ? "h5" : "h4"}>
              How does it <span style={{ color: "#1C8EA8" }}>benefit</span> me?
            </Typography>
            <Box display="flex" flexDirection="column" gap="2rem">
              <Box display="flex" gap="0.5rem" flexDirection="column" sx={{ borderLeft: "2px solid #1C8EA8", paddingLeft: "1rem" }}>
                <Typography fontSize={Mobile ? "16px" : "20px"} variant={Mobile ? "h6" : "h5"}>
                  Targeted Preparation
                </Typography>
                <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                  The targeted resume preparation approach in this session will ensure you are well-prepared for the interviews that matter most to you.
                </Typography>
              </Box>

              <Box display="flex" gap="0.5rem" flexDirection="column" sx={{ borderLeft: "2px solid #F5A536", paddingLeft: "1rem" }}>
                <Typography fontSize={Mobile ? "16px" : "20px"} variant={Mobile ? "h6" : "h5"}>
                  Question identification
                </Typography>
                <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                  In this session, the mentor reads through your resume and provides you with a in-depth understanding of the kind of questions that are driven from your resume.
                </Typography>
              </Box>

              <Box display="flex" gap="0.5rem" flexDirection="column" sx={{ borderLeft: "2px solid #1C8EA8", paddingLeft: "1rem" }}>
                <Typography fontSize={Mobile ? "16px" : "20px"} variant={Mobile ? "h6" : "h5"}>
                  Question Bank from Resume
                </Typography>
                <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                  At the end of the session, you will have an exhaustive list of questions picked from your resume to prepare from for your interview.
                </Typography>
              </Box>

              <Box display="flex" gap="0.5rem" flexDirection="column" sx={{ borderLeft: "2px solid #F5A536", paddingLeft: "1rem" }}>
                <Typography fontSize={Mobile ? "16px" : "20px"} variant={Mobile ? "h6" : "h5"}>
                  Confidence Building
                </Typography>
                <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                  By practicing answering interview questions with our mentors, you'll build confidence in articulating your experiences, skills, and achievements effectively.
                </Typography>
              </Box>

              <Box display="flex" gap="0.5rem" flexDirection="column" sx={{ borderLeft: "2px solid #1C8EA8", paddingLeft: "1rem" }}>
                <Typography fontSize={Mobile ? "16px" : "20px"} variant={Mobile ? "h6" : "h5"}>
                  Interview Tips 101
                </Typography>
                <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" variant={Mobile ? "body2" : "body1"}>
                  Along with a Resume Discussion session with an industry expert, also get access to 100's of podcasts and sessions to help you prepare better.
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" flexDirection={Mobile ? "column" : "row"} alignItems="center" gap="8rem">
            <Box display="flex" justifyContent="space-between" gap="1.5rem" flexDirection="column">
              <Typography fontSize={Mobile ? "20px" : "24px"} variant={Mobile ? "h5" : "h4"}>
                Where will the <span style={{ color: "#1C8EA8" }}>session happen</span>?
              </Typography>
              <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                Our Resume Discussion Sessions are conducted online, providing you with the flexibility to participate from anywhere. During a 30 mins session our mentor will read through your resume line-by-line. To schedule a session, head to the CareerCarve App now and select a convinient slot!
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: Mobile ? "center" : "",
                }}
              >
                {!Mobile && <img src="../../QR_code_sign_up.jpg" style={{ height: "78px", width: "78px" }} alt="careercarve" />}
                <a href="https://play.google.com/store/apps/details?id=com.careercarve&hl=en&gl=US" target="_blank" title="careercarve-mobile-app" rel="noreferrer" style={{ textDecoration: "none" }}>
                  <button
                    className="button-yellow-hover"
                    style={{
                      width: "fit-content",
                      alignSelf: Mobile ? "center" : "",
                    }}
                    href="https://play.google.com/store/apps/details?id=com.careercarve&hl=en&gl=US"
                    target="_blank"
                  >
                    <Typography> Download the App</Typography>
                  </button>
                </a>
              </Box>
            </Box>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                width: Mobile ? "200px" : "385px",
                height: Mobile ? "350px" : "385px",
                background: "rgba(56, 193, 223, 0.20)",
              }}
              paddingInline={"10rem"}
              borderRadius={"50%"}
            >
              <img
                src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/1+on+1+Mobile.png"
                alt="PI Prep Session: Sharpen Your MBA Interview Skills Now"
                title="Prepare to answer all interview questions with expert guidance"
                style={{
                  width: Mobile ? "220px" : "243px",
                  height: Mobile ? "380px" : "480px",
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: Mobile ? "1.5rem" : "2.5rem",
            }}
          >
            <Typography fontSize={Mobile ? "24px" : "32px"} color="#1C8EA8" textAlign="center">
              View Other 1X1
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "100%",
                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                    padding: "1rem",
                    height: "272px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                    }}
                  >
                    <Typography ontSize={Mobile ? "20px" : "24px"} variant={Mobile ? "h5" : "h4"}>
                      Resume Discussion
                    </Typography>
                    <Typography fontSize={Mobile ? "14px" : "16px"} color="#8B8B8B" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                      The SIP Success Session at CareerCarve is a comprehensive and personalized program aimed at helping you excel in your Summer Internship Program.
                    </Typography>
                  </Box>
                  <button className="button-yellow-hover" onClick={() => handleNavigate("/ResumeDiscussion")}>
                    <Typography>View More</Typography>
                  </button>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "100%",
                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                    padding: "1rem",
                    height: "272px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                    }}
                  >
                    <Typography ontSize={Mobile ? "20px" : "24px"} variant={Mobile ? "h5" : "h4"}>
                      Expert Connect
                    </Typography>
                    <Typography fontSize={Mobile ? "14px" : "16px"} textAlign={"justify"} color="#8B8B8B" variant={Mobile ? "body2" : "body1"}>
                      The Expert Connect Session at CareerCarve is an open and flexible discussion platform that puts you in touch with a mentor who shares your area of interest.
                    </Typography>
                  </Box>
                  <button className="button-yellow-hover" onClick={() => handleNavigate("/ExpertConnect")}>
                    <Typography>View More</Typography>
                  </button>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "100%",
                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                    padding: "1rem",
                    height: "272px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                    }}
                  >
                    <Typography ontSize={Mobile ? "20px" : "24px"} variant={Mobile ? "h5" : "h4"}>
                      SIP Success
                    </Typography>
                    <Typography fontSize={Mobile ? "14px" : "16px"} color="#8B8B8B" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                      The SIP Success Session at CareerCarve is a comprehensive and personalized program aimed at helping you excel in your Summer Internship Program.
                    </Typography>
                  </Box>
                  <button className="button-yellow-hover" onClick={() => handleNavigate("/SIPSuccess")}>
                    <Typography>View More</Typography>
                  </button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          background: "linear-gradient(226deg, #1D2C30 0%, #020303 100%)",
        }}
      >
        <BottomNavbar />
      </Box>
    </>
  );
}

export default PIQR;
