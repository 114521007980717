import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

function Errorpage() {
  const navigate = useNavigate();
  return (
    <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column", paddingTop: "5rem" }}>
      <Box sx={{ display: "flex", justifyContent: "center", height: "100%" }}>
        <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/errorpage.webp" style={{ width: "min(90%,500px)", aspectRatio: "1/1" }} alt="Page not found" />
        {/* <Typography sx={{ fontSize: "7rem", background: "linear-gradient(116deg, #2AA8C4 47.67%, #455A64 93.78%)", WebkitBackgroundClip: "text", backgroundClip: "text", color: "transparent", letterSpacing: "6px", fontWeight: "bold" }}>404</Typography> */}
      </Box>
      {/* <Typography sx={{ textAlign: "center", fontSize: "1.5rem" }}></Typography> */}
      <Typography sx={{ fontSize: "1.5rem", background: "linear-gradient(116deg, #2AA8C4 47.67%, #455A64 93.78%)", WebkitBackgroundClip: "text", backgroundClip: "text", color: "transparent", fontWeight: "600" }}>Oops! The page you are looking for doesn't exist </Typography>
      <button
        className="button-yellow-hover"
        style={{
          width: "fit-content",
          fontSize: "1rem",
          marginTop: "8px",
        }}
        onClick={() => navigate("/")}
      >
        <Typography>Home</Typography>
      </button>
    </Box>
  );
}

export default Errorpage;
