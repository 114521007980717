import { CheckCircleOutline } from "@mui/icons-material";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { Box, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ServiceCM from "../../services/cartmoduleServices";
// import Service from "../../services/httpService";
import { Helmet } from "react-helmet-async";
import { useWebinarContext } from "../../WebinarContext";
import { useStateValue } from "../../components/StateProvider/stateProvider";
import BottomNavbar from "../LandingPage/Footer/BottomNavbar";
import "../Pricing/Buy1x1/Buy1x1.css";
import "../Pricing/OurPackages/OurPackages.css";

const Pricing = () => {
  const Mobile = useMediaQuery("(max-width:800px)");
  const { webinarTickerExists } = useWebinarContext();
  const colors = [
    {
      text: "#E48A0B",
      background: "linear-gradient(0deg, rgba(245, 165, 54, 0.20) 0%, rgba(245, 165, 54, 0.20) 100%), #FFF",
    },
    {
      text: "#004440",
      background: "#EFFCF6",
    },
    {
      text: "#035388",
      background: "#E3F8FF",
    },
    {
      text: "#620042",
      background: "#FFE3EC",
    },
  ];
  const navigate = useNavigate();
  // const service = new Service();
  const serviceCM = new ServiceCM();
  const [skuInfo, setSkuInfo] = useState([]);
  const [bundleInfo, setBundleInfo] = useState([]);
  const [{ basket }, dispatch] = useStateValue();
  const [skuIds, setSkuIds] = useState([]);
  // const [tempQuantity, setTempQuantity] = useState({});
  const storedTempQuantity = JSON.parse(localStorage.getItem("temp_quantity"));
  console.log("storedTempQuantity", storedTempQuantity);
  const [temp_quantity, setTemp_quantity] = useState(() => {
    return storedTempQuantity || [{ search_tbl: "", id: null, quantity: 0, price: 0, total_price: 0 }]; // return default values if no data is found
  });

  //   get request of skus
  const SKUGetter = async () => {
    try {
      const res = await serviceCM.get("/sku?type=1 X 1");

      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  // const { isLoading, data, isFetching } = useQuery("skuData", SKUData, { cacheTime: 5000 });
  // if (!isFetching) {
  //   let skuData = data.data.filter((item) => item.type !== "RDS");
  //   skuData?.forEach((element) => {
  //     element.quantity = 0;
  //     element.id = element.id;
  //     element.search_tbl = "sku";
  //     element.addToCart = false;
  //     element.price = element.price;
  //   });

  //   setSkuInfo(skuData);
  // }

  useEffect(() => {
    console.log("Brother");
    const handleEffect = async () => {
      const filteredTempQuantity = temp_quantity.filter((item) => item.quantity > 0);
      localStorage.setItem("temp_quantity", JSON.stringify(filteredTempQuantity));

      let sku = await SKUGetter();

      let bundle = await bundleGetter();

      bundle?.forEach((element) => {
        element.quantity = 0;
        element.id = element.id;
        element.search_tbl = "bundle";
        element.addToCart = false;
        element.price = element.discounted_price;
      });
      if (`${process.env.REACT_APP_ENVIRONMENT}` !== "dev") {
        let temp = bundle.find((item) => item.id === 5);
        if (![null, undefined].includes(temp)) {
          bundle = bundle.filter((item) => item.id !== 5);
          bundle = [temp, ...bundle];
        }

        let temp_sku = sku.find((item) => item.id === 1);

        if (![null, undefined].includes(temp_sku)) {
          sku = sku.filter((item) => item.id !== 1);
          sku = [temp_sku, ...sku];
        }
      }

      sku = sku.filter((item) => item.type !== "RDS");
      // Set default values for sku items
      sku?.forEach((element) => {
        element.quantity = 0;
        element.id = element.id;
        element.search_tbl = "sku";
        element.addToCart = false;
        element.price = element.price;
      });

      // Update with stored quantity if available
      if (filteredTempQuantity && filteredTempQuantity.length > 0) {
        console.log("TEmp Quant", bundle);
        filteredTempQuantity.forEach((item) => {
          bundle
            .filter((bundleItem) => bundleItem.id === item.id && item.search_tbl === "bundle")
            .forEach((element) => {
              element.quantity = item.quantity ? item.quantity : 0;
              element.id = element.id;
              element.search_tbl = "bundle";
              element.addToCart = item.quantity > 0 ? true : false;
              element.price = element.discounted_price;
            });
        });
        filteredTempQuantity.forEach((item) => {
          sku
            .filter((skuItem) => skuItem.id === item.id && item.search_tbl === "sku")
            .forEach((element) => {
              element.quantity = item.quantity ? item.quantity : 0;
              element.id = element.id;
              element.search_tbl = "sku";
              element.addToCart = item.quantity > 0 ? true : false;
              element.price = element.price;
            });
        });
      }

      // Set the state with updated values
      setSkuInfo(sku);
      setBundleInfo(bundle);

      // data_manupulator_sku(sku);
      data_manupulator_bundle(bundle);

      console.log("odonil", { sku }, { bundle });
    };

    handleEffect();
  }, [temp_quantity]);
  // handle sku quality increment and decrement
  const handleSkuIncrement = (key, index, price) => {
    // let temp_sku_info = JSON.parse(JSON.stringify(skuInfo));
    // console.log("click", JSON.parse(JSON.stringify(skuInfo)));
    // console.log("click", [...skuInfo]);
    // console.log("before click", temp_sku_info[index].quantity);
    // temp_sku_info[index].quantity = temp_sku_info[index].quantity + 1;
    // console.log("after click", temp_sku_info[index].quantity);

    // setSkuInfo(temp_sku_info);
    // setTemp_quantity(
    //   temp_quantity?.map(item => {
    //     if (item.id === key && item.search_tbl === "sku") {
    //       return {
    //         ...item,
    //         quantity: temp_sku_info[index].quantity,
    //         total_price: price * temp_sku_info[index].quantity,
    //       };
    //     }
    //     return item;
    //   })
    // );
    setTemp_quantity((prevQuantity) => {
      const updatedQuantity = prevQuantity.map((item) => {
        if (item.id === key && item.search_tbl === "sku") {
          return {
            ...item,
            quantity: item.quantity + 1,
            total_price: price * (item.quantity + 1),
          };
        }
        return item;
      });

      dispatch({ type: "UPDATE_BASKET", basket: updatedQuantity });
      localStorage.setItem("temp_quantity", JSON.stringify(updatedQuantity));
      return updatedQuantity;
    });

    setSkuInfo((prevBundleInfo) => {
      const updatedBundleInfo = [...prevBundleInfo];
      updatedBundleInfo[index] = {
        ...updatedBundleInfo[index],
        quantity: updatedBundleInfo[index].quantity + 1,
      };
      return updatedBundleInfo;
    });

    // let temp_sku_info = [...skuInfo];
    // temp_sku_info[index].quantity = temp_sku_info[index].quantity + 1;

    // setSkuInfo(temp_sku_info);
    // console.log("after click", temp_sku_info[index].quantity);

    // setTemp_quantity(prevTempQuantity =>
    //   prevTempQuantity.map(item => {
    //     if (item.id === key && item.search_tbl === "sku") {
    //       return {
    //         ...item,
    //         quantity: temp_sku_info[index].quantity,
    //         total_price: price * temp_sku_info[index].quantity,
    //       };
    //     }
    //     return item;
    //   })
    // );
    // setSkuInfo(prevSkuInfo => {
    //   const temp_sku_info = [...prevSkuInfo];
    //   const currentQuantity = temp_sku_info[index].quantity;
    //   const updatedQuantity = currentQuantity + 1;
    //   temp_sku_info[index].quantity = updatedQuantity;

    //   return temp_sku_info;
    // });

    // setTemp_quantity(prevTempQuantity => {
    //   return prevTempQuantity.map(item => {
    //     if (item.id === key && item.search_tbl === "sku") {
    //       const updatedQuantity = skuInfo[index].quantity;
    //       const updatedTotalPrice = price * updatedQuantity;
    //       return {
    //         ...item,
    //         quantity: updatedQuantity,
    //         total_price: updatedTotalPrice,
    //       };
    //     }
    //     return item;
    //   });
    // });
  };

  const handleSkuDecrement = (key, index, price, quantity) => {
    if (quantity > 0) {
      setTemp_quantity((prevQuantity) => {
        const updatedQuantity = prevQuantity.map((item) => {
          if (item.id === key && item.search_tbl === "sku") {
            return {
              ...item,
              quantity: item.quantity - 1,
              total_price: price * (item.quantity - 1),
            };
          }
          return item;
        });
        dispatch({ type: "UPDATE_BASKET", basket: updatedQuantity });
        localStorage.setItem("temp_quantity", JSON.stringify(updatedQuantity));
        return updatedQuantity;
      });

      setSkuInfo((prevBundleInfo) => {
        const updatedBundleInfo = [...prevBundleInfo];
        updatedBundleInfo[index] = {
          ...updatedBundleInfo[index],
          quantity: updatedBundleInfo[index].quantity - 1,
        };
        return updatedBundleInfo;
      });
      console.log("key and index", { key }, { index });
    }
  };

  // handle bundle increment and decrement
  const handleBundleIncrement = (key, index, price) => {
    setTemp_quantity((prevQuantity) => {
      const updatedQuantity = prevQuantity.map((item) => {
        if (item.id === key && item.search_tbl === "bundle") {
          return {
            ...item,
            quantity: item.quantity + 1,
            total_price: price * (item.quantity + 1),
          };
        }
        return item;
      });
      dispatch({ type: "UPDATE_BASKET", basket: updatedQuantity });
      localStorage.setItem("temp_quantity", JSON.stringify(updatedQuantity));
      return updatedQuantity;
    });

    setBundleInfo((prevBundleInfo) => {
      const updatedBundleInfo = [...prevBundleInfo];
      updatedBundleInfo[index] = {
        ...updatedBundleInfo[index],
        quantity: updatedBundleInfo[index].quantity + 1,
      };
      return updatedBundleInfo;
    });
  };

  const handleBundleDecrement = (key, index, price, quantity) => {
    if (quantity > 0) {
      setTemp_quantity((prevQuantity) => {
        const updatedQuantity = prevQuantity.map((item) => {
          if (item.id === key && item.search_tbl === "bundle") {
            return {
              ...item,
              quantity: item.quantity - 1,
              total_price: price * (item.quantity - 1),
            };
          }
          return item;
        });
        dispatch({ type: "UPDATE_BASKET", basket: updatedQuantity });
        localStorage.setItem("temp_quantity", JSON.stringify(updatedQuantity));
        return updatedQuantity;
      });

      setBundleInfo((prevBundleInfo) => {
        const updatedBundleInfo = [...prevBundleInfo];
        updatedBundleInfo[index] = {
          ...updatedBundleInfo[index],
          quantity: updatedBundleInfo[index].quantity - 1,
        };
        return updatedBundleInfo;
      });
    }
  };
  // bundle buy now and add to cart click

  const handleBundleAddToCartClick = async (key, index, price) => {
    let temp_bundle_info = JSON.parse(JSON.stringify(bundleInfo));
    console.log("checking if bundle updated", temp_bundle_info);
    temp_bundle_info[index].addToCart = true;
    temp_bundle_info[index].quantity = temp_bundle_info[index].quantity + 1;
    setBundleInfo(temp_bundle_info);
    console.log("add to cart key id clicked", key);
    console.log("temp quantity clicked", temp_bundle_info[index].quantity);
    let bundle_temp_quantity = {
      search_tbl: "bundle",
      id: key,
      quantity: temp_bundle_info[index].quantity,
      total_price: price * temp_bundle_info[index].quantity,
    };
    let updatedTempQuantity = [...temp_quantity, bundle_temp_quantity];
    setTemp_quantity(updatedTempQuantity);
    dispatch({ type: "UPDATE_BASKET", basket: updatedTempQuantity });
    localStorage.setItem("temp_quantity", JSON.stringify(updatedTempQuantity));
  };

  const handleSkuAddToCartClick = async (key, index, price) => {
    let temp_bundle_info = JSON.parse(JSON.stringify(skuInfo));
    console.log("checking if bundle updated", temp_bundle_info);
    temp_bundle_info[index].addToCart = true;
    temp_bundle_info[index].quantity = temp_bundle_info[index].quantity + 1;
    setSkuInfo(temp_bundle_info);
    console.log("add to cart key id clicked", key);
    console.log("temp quantity clicked", temp_bundle_info[index].quantity);
    let bundle_temp_quantity = {
      search_tbl: "sku",
      id: key,
      quantity: temp_bundle_info[index].quantity,
      total_price: price * temp_bundle_info[index].quantity,
    };
    let updatedTempQuantity = [...temp_quantity, bundle_temp_quantity];
    setTemp_quantity(updatedTempQuantity);
    dispatch({ type: "UPDATE_BASKET", basket: updatedTempQuantity });
    localStorage.setItem("temp_quantity", JSON.stringify(updatedTempQuantity));
    // setSkusGroupButton(true);
    console.log("key id for sku add to cart", { key }, { index });
  };

  // get request for bundle
  const bundleGetter = async () => {
    try {
      const res = await serviceCM.get("/bundle");
      console.log("bundle RESPONSE:", res.data);
      // setBundleInfo([...res.data]);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const data_manupulator_bundle = (bundle) => {
    console.log("bundle data:", bundle);
    let temp_rowsData = JSON.parse(JSON.stringify(bundle));

    console.log("temp rows:", temp_rowsData);
    const temp_skuIds = [];
    console.log("bundleInfo available", skuIds);
    temp_rowsData.map((cell) => {
      const skusObj = JSON.parse(cell.skus);
      console.log("skus obj", skusObj);
      const ids = skusObj.skus.map((sku) => sku.id);
      console.log("skus ids", ids);
      temp_skuIds.push(...ids);
    });
    console.log("temp sku ids", temp_skuIds);
    setSkuIds(temp_skuIds);
  };

  return (
    <>
      <Helmet>
        <title>Pricing | Invest in your Career| CareerCarve</title>
        <meta name="description" content="Explore our competitive pricing options for personalized placement training at CareerCarve. Invest in your professional goals to achieve career success." />
        <meta property="og:image" content="/public/logo_welcome.png" />
        <link rel="canonical" href="/ExpertConnect" />
        <script src="https://ebz-static.s3.ap-south-1.amazonaws.com/easecheckout/easebuzz-checkout.js"></script>
        <script></script>
      </Helmet>
      <div className="login-Container" style={{ marginTop: webinarTickerExists && Mobile ? "80px" : !webinarTickerExists && Mobile ? "50px" : webinarTickerExists ? "112px" : "70px", width: "100vw" }}>
        <Typography variant="h1" sx={{ fontSize: Mobile ? "24px" : "57px", textAlign: "center", color: "#243B53" }}>
          Pick the plan <span style={{ background: "linear-gradient(116deg, #2AA8C4 47.67%, #455A64 93.78%)", WebkitBackgroundClip: "text", backgroundClip: "text", color: "transparent" }}>that's right for you </span>{" "}
        </Typography>
        <Typography sx={{ color: "#486581", fontSize: Mobile ? "14px" : "18px", textAlign: "center", marginBlock: Mobile ? "8px" : "15px" }}>Join millions of other customers on CareerCarve</Typography>
        <Grid container spacing={3} sx={{ paddingInline: Mobile ? "15px" : "5rem" }}>
          {bundleInfo.map((item, index) => (
            <Grid item xs={12} sm={12} md={6} lg={3} sx={{}}>
              <Box sx={{ display: "flex", flexDirection: "column", boxShadow: "0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)", borderRadius: "24px", padding: "24px", gap: Mobile ? "12px" : "12px", height: "525px" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant={Mobile ? "body2" : "body1"} sx={{ fontSize: Mobile ? "12px" : "14px", padding: "2px 12px", color: colors[index % colors.length].text, width: "fit-content", background: colors[index % colors.length].background, borderRadius: "20px" }}>
                    {item.name}
                  </Typography>
                  {[undefined, "undefined", null, "null"].includes(item.header_desc) ? (
                    <></>
                  ) : (
                    <>
                      <Box sx={{ padding: "2px 15px", display: "flex", gap: "6px", background: "linear-gradient(92deg, #F5A536 23.38%, #FFF 174.92%)", borderRadius: "10px", width: "fit-content", color: "#000", alignItems: "center", height: "fit-content" }}>
                        <StarBorderIcon fontSize="10px" />
                        <Typography variant={Mobile ? "h5" : "h4"} fontSize={Mobile ? "8px" : "10px"} sx={{}}>
                          BESTSELLER
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>

                <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                  {item.actual_price ? (
                    <Typography variant={Mobile ? "h2" : "h3"} sx={{ fontSize: Mobile ? "16px" : "20px", color: "#8B8B8B", width: "fit-content", borderRadius: "20px", fontWeight: "200", textDecoration: "line-through" }}>
                      &#x20b9;{item.actual_price}
                    </Typography>
                  ) : (
                    <></>
                  )}
                  <Typography variant={Mobile ? "h2" : "h3"} sx={{ fontSize: Mobile ? "18px" : "30px", color: "#243B53", width: "fit-content", borderRadius: "20px", fontWeight: "600" }}>
                    &#x20b9;{item.price}
                  </Typography>
                </Box>

                <Typography variant={Mobile ? "h5" : "h4"} fontSize={Mobile ? "18px" : "24px"} sx={{}}>
                  {item.name}
                </Typography>

                <Box sx={{ height: "20%" }}>
                  <Typography variant={Mobile ? "h5" : "h4"} fontSize={Mobile ? "9px" : "10px"} sx={{ color: "#486581", textAlign: "justify", lineHeight: "18px", marginTop: "18px" }}>
                    {JSON.parse(item.footer_desc).footer_desc[0]}
                  </Typography>
                </Box>
                <Box sx={{ height: "12%", display: "flex", justifyContent: "center" }}>
                  {item.addToCart ? (
                    <ButtonGroup style={{ justifyContent: "center" }} className="pricingSingleACtivityOrder1" size="small" aria-label="small outlined button group">
                      <Button
                        className="buttonGroupColor"
                        sx={{
                          borderRadius: "11px 0 0 11px",
                          backgroundColor: "#2AA8C4",
                          fontSize: "18px",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#2AA8C4",
                          },
                        }}
                        onClick={() => handleBundleDecrement(item?.id, index, item.price, item?.quantity)}
                      >
                        -
                      </Button>

                      <Button disabled sx={{ border: "1px solid #2AA8C4 !important" }}>
                        {item?.quantity}
                      </Button>

                      <Button
                        className="buttonGroupColor"
                        sx={{
                          borderRadius: "0 11px 11px 0",
                          backgroundColor: "#2AA8C4",
                          fontSize: "18px",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#2AA8C4",
                          },
                        }}
                        onClick={() => handleBundleIncrement(item?.id, index, item.price)}
                      >
                        +
                      </Button>
                    </ButtonGroup>
                  ) : (
                    <button
                      onClick={() => {
                        handleBundleAddToCartClick(item?.id, index, item.discounted_price);
                      }}
                      style={{ background: "#1C8EA8", color: "#FFF", border: "none", padding: "8px 16px", borderRadius: "10px", fontSize: Mobile ? "16px" : "20px", marginInline: "10px", height: "fit-content" }}
                    >
                      Add to Cart
                    </button>
                  )}
                </Box>

                <Box sx={{ borderTop: "1px solid #F0F4F8", padding: "10px", display: "flex", flexDirection: "column", gap: "10px", marginTop: "10px" }}>
                  {JSON.parse(item.skus).skus.map((ele) => {
                    let skuItem = skuInfo.find((sku) => ele.id === JSON.stringify(sku.id));

                    if (skuItem !== undefined) {
                      return (
                        <>
                          <Typography variant={Mobile ? "body2" : "body1"} sx={{ fontSize: Mobile ? "12px" : "14px", color: "#486581" }}>
                            <CheckCircleOutline /> {skuItem?.name ? skuItem?.name : ""} {process.env.REACT_APP_ENVIRONMENT === "dev" ? (["15", "17"].includes(ele.id) ? "" : `(${skuItem?.duration})`) : ["7", "6"].includes(ele.id) ? "" : `(${skuItem?.duration})`}
                          </Typography>
                        </>
                      );
                    }
                  })}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>

        <div className="buy1x1EntireDiv">
          <Typography variant="h4" className="PricingBuyText" style={{ marginBottom: ".2rem", fontSize: "24px" }}>
            Buy 1X1 Separately
          </Typography>
          <div className="ourpackagesline" style={{ marginBottom: ".5rem" }}></div>
          <p className="PricingPackageText" style={{ width: Mobile ? "90%" : "100%" }}>
            Can’t find what you’re looking for in our packages? Make your own package!
          </p>
          <Box
            sx={{
              display: "flex",
              JustifyContent: "center",
              marginInline: Mobile ? "15px" : "15%",
              flexDirection: "column",
              gap: Mobile ? "2rem" : "2rem",
            }}
          >
            {skuInfo?.map((description, i) => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: Mobile ? "column" : "row",
                  // alignItems: "center",
                  gap: Mobile ? "2rem" : "",
                  paddingBlock: Mobile ? "1rem" : "2rem",
                  justifyContent: "space-between",
                  // alignContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: Mobile ? "100%" : "70%",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: Mobile ? "0.5rem" : "1.5rem",
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "space-between", gap: "8px" }}>
                    <Box>
                      <Typography variant="h5" sx={{ fontSize: "20px" }}>
                        {description.name}
                      </Typography>
                      <Typography variant="body1" className="pricingSingleACtivityDescription">
                        {description.description}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant={Mobile ? "h2" : "h3"} sx={{ fontSize: Mobile ? "16px" : "20px", color: "#8B8B8B", width: "fit-content", borderRadius: "20px", fontWeight: "200", textDecoration: "line-through" }}>
                        &#x20b9;{description.actual_price}
                      </Typography>
                      <Typography style={{ fontSize: "20px", textAlign: "center" }}>&#x20b9;{description.price}</Typography>
                    </Box>
                  </Box>
                </Box>
                <div
                  className="pricingSingleActivityInfo1"
                  // style={{ width: "30%" }}
                >
                  {!description.addToCart && (
                    <button key={description.id} onClick={() => handleSkuAddToCartClick(description?.id, i, description?.price)} className="pricingSingleACtivityButton1">
                      Add to Cart
                    </button>
                  )}
                  {description.addToCart && (
                    <ButtonGroup style={{ justifyContent: "center" }} className="pricingSingleACtivityOrder" size="small" aria-label="small outlined button group">
                      <Button
                        className="buttonGroupColor"
                        sx={{
                          borderRadius: "11px 0 0 11px",
                          backgroundColor: "#2AA8C4",
                          fontSize: "18px",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#2AA8C4",
                          },
                        }}
                        onClick={() => handleSkuDecrement(description?.id, i, description.price, description?.quantity)}
                      >
                        -
                      </Button>

                      <Button disabled sx={{ border: "1px solid #2AA8C4 !important" }}>
                        {description.quantity}
                      </Button>

                      <Button
                        className="buttonGroupColor"
                        sx={{
                          borderRadius: "0 11px 11px 0",
                          backgroundColor: "#2AA8C4",
                          fontSize: "18px",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#2AA8C4",
                          },
                        }}
                        onClick={() => handleSkuIncrement(description?.id, i, description?.price)}
                      >
                        +
                      </Button>
                    </ButtonGroup>
                  )}
                </div>
              </Box>
            ))}
          </Box>
        </div>

        <Box
          sx={{
            background: "linear-gradient(226deg, #1D2C30 0%, #020303 100%)",
            marginTop: "3rem",
          }}
        >
          <BottomNavbar />
        </Box>
      </div>
    </>
  );
};

export default Pricing;
