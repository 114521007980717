import { useMediaQuery } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useStateValue } from "../../components/StateProvider/stateProvider";
import { Box, Button, IconButton, MenuItem, Modal, Select, Skeleton, TextField, Typography } from "@mui/material";
import { bundlesGetter, skuGetter } from "../../api/cartModules";
import { useLocation, useNavigate } from "react-router-dom";
import { useWebinarContext } from "../../WebinarContext";
import { Color } from "../../GlobalStyles";
import { Country, State } from "country-state-city";
import { Close, Delete, Sell, SellOutlined, SevenKPlus } from "@mui/icons-material";
import { getPayableAmount } from "../../components/StateProvider/reducer";
import RDService from "../../services/RDService";

function BookingPage() {
  const Mobile = useMediaQuery("(max-width:800px)");
  const location = useLocation();
  const service = new RDService();
  const navigate = useNavigate();
  const allCountry = Country.getAllCountries();
  const priority_number = location.state?.priority_number ? location.state?.priority_number : 3;
  const mentorid = location.state?.mentor_id ? location.state?.mentor_id : null;
  const mentorName = location.state?.mentor_name ? location.state?.mentor_name : "";
  const environment = process.env.REACT_APP_EASEBUZZ_ENV;
  const { webinarTickerExists } = useWebinarContext();
  const [{ basket, firstName, lastName, email, ph_no, country, user_id }, dispatch] = useStateValue();
  const basketData = priority_number === 1 ? location.state?.basket : basket;

  const [cartData, setCartData] = useState([]);
  const [allState, setAllState] = useState(State.getStatesOfCountry("IN"));
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phno: "",
    country: "",
    state: "",
  });
  const [applyCouponModal, setApplyCouponModal] = useState(false);
  const [billingAmount, setBillingAmount] = useState({
    itemAmount: 0,
    actualAmount: 0,
    taxAmount: 0,
    totalBillingAmount: 0,
    couponDiscountAmount: 0,
    amountWithoutDiscountAmount: 0,
  });
  const [loading, setLoading] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [couponError, setCouponError] = useState("");
  const [error, setError] = useState("");
  const getData = async () => {
    setLoading(true);
    const skus = await skuGetter();
    const bundles = await bundlesGetter();

    const modifiedCartData = basketData.map((item) => {
      if (item.search_tbl === "sku") {
        const skuData = skus.find((sku) => sku.id === item.id);
        return {
          ...skuData,
          quantity: item.quantity,
          discounted_price: skuData.price ? skuData.price : 999,
          search_tbl: "sku",
        };
      } else {
        const bundleData = bundles.find((bundle) => bundle.id === item.id);
        return {
          ...bundleData,
          quantity: item.quantity,
          search_tbl: "bundle",
        };
      }
    });

    setCartData(modifiedCartData);
    setLoading(false);
  };
  const updateUserData = () => {
    if (user_id !== null) {
      let savedCountry = allCountry.find((c) => c.name === country);

      let states = State.getStatesOfCountry(savedCountry?.isoCode ? savedCountry.isoCode : "IN");

      setAllState(states);

      setFormData((prev) => {
        return {
          firstName: firstName,
          lastName: lastName,
          email: email,
          phno: [null, "null"].includes(ph_no) ? "" : ph_no,
          country: savedCountry?.name ? savedCountry.name : allCountry.find((c) => c.name === "India"),
          state: "",
        };
      });
    } else {
      let states = State.getStatesOfCountry("IN");

      setAllState(states);
      setFormData((prev) => {
        return {
          firstName: "",
          lastName: "",
          email: "",
          phno: "",
          country: "",
          state: "",
        };
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setError("");
    if (name === "country") {
      let temp_value = { ...formData };
      temp_value[name] = value;
      setFormData(temp_value);
      let country = allCountry.filter((country) => country.name === value)[0];
      let states = State.getStatesOfCountry(country.isoCode);
      setAllState(states);
    } else {
      let temp = { ...formData };
      temp[name] = value;
      setFormData(temp);
    }
  };

  const handleChange = (e, ele, index, type) => {
    let temp_cartData = [...cartData];
    temp_cartData = temp_cartData.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          quantity: type === "increase" ? item.quantity + 1 : item.quantity - 1,
        };
      } else {
        return {
          ...item,
        };
      }
    });
    temp_cartData = temp_cartData.filter((item) => item.quantity > 0);
    setCartData(temp_cartData);
    let temp_basket = basketData.map((item) => {
      if (item.id === ele.id && item.search_tbl === ele.search_tbl) {
        return {
          ...item,
          quantity: type === "increase" ? item.quantity + 1 : item.quantity - 1,
        };
      } else {
        return item;
      }
    });
    temp_basket = temp_basket.filter((item) => item.quantity > 0);
    dispatch({ type: "UPDATE_BASKET", basket: temp_basket });

    localStorage.setItem("temp_quantity", JSON.stringify(temp_basket));
  };

  const getBillingAmount = async (coupon = couponCode) => {
    const payableAmount = await getPayableAmount(cartData, coupon);

    setBillingAmount({
      itemAmount: payableAmount.itemAmount,
      actualAmount: payableAmount.actualAmount,
      taxAmount: payableAmount.taxAmount,
      totalBillingAmount: payableAmount.totalBillingAmount,
      couponDiscountAmount: payableAmount.couponDiscount,
      amountWithoutDiscountAmount: payableAmount.amountWithoutDiscount,
    });
  };

  const handleApplyCoupon = async () => {
    const payableAmount = await getPayableAmount(cartData, couponCode);

    setBillingAmount({
      itemAmount: payableAmount.itemAmount,
      actualAmount: payableAmount.actualAmount,
      taxAmount: payableAmount.taxAmount,
      totalBillingAmount: payableAmount.totalBillingAmount,
      couponDiscountAmount: payableAmount.couponDiscount,
      amountWithoutDiscountAmount: payableAmount.amountWithoutDiscount,
    });

    if (payableAmount.couponDiscount === 0) {
      setCouponError(payableAmount.couponStatus);
    } else {
      setApplyCouponModal(false);
    }
  };

  const validation = () => {
    let flag = true;
    if (formData.firstName === "" || formData.lastName === "" || formData.phno === "" || formData.email === "" || formData.country === "" || formData.state === "") {
      setError("Please fill all the required fields");
      flag = false;
    }
    return flag;
  };

  const handleCheckOut = async () => {
    if (validation()) {
      if (user_id === null) {
        try {
          const userDetailsPost = await service.post(`/purchase/preLogin/fetchUser`, {
            email: formData.email,
            first_name: formData.firstName,
            last_name: formData.lastName === "" ? null : formData.lastName,
            ph_no: "+91" + formData.phno,
            state: formData.state,
            country: formData.country,
            signup_platform: "CareerCarve",
            device_type: "Web",
          });

          if (userDetailsPost.status === 200) {
            const user_id = userDetailsPost.data.user_id;
            let body;
            if (couponCode === "") {
              body = {
                platform: "Web",
                first_name: formData.firstName,
                last_name: formData.lastName,
                state: formData.state,
                ph_no: formData.phno,
                country: formData.country,
                user_id: user_id,
                items: cartData.map((item) => ({
                  item_id: item.id,
                  search_tbl: item.search_tbl,
                  quantity: item.quantity,
                  priority_no: priority_number,
                })),
              };
            } else {
              body = {
                platform: "Web",
                first_name: formData.firstName,
                last_name: formData.lastName,
                state: formData.state,
                ph_no: formData.phno,
                country: formData.country,
                user_id: user_id,
                coupon_code: couponCode,
                items: cartData.map((item) => ({
                  item_id: item.id,
                  search_tbl: item.search_tbl,
                  quantity: item.quantity,
                  priority_no: priority_number,
                })),
              };
            }

            const redirectPayment = await service.post("/purchase/app/paymentURL", body);
            if (redirectPayment.status === 200) {
              var easebuzzCheckout = new window.EasebuzzCheckout(process.env.REACT_APP_EASEBUZZ_KEY, environment);
              var options = {
                access_key: redirectPayment.data.createOrderResponse.data,
                onResponse: (response) => {
                  if (response.status === "success") {
                    localStorage.removeItem("temp_quantity");
                    dispatch({ type: "EMPTY_BASKET" });
                    navigate("/CartPaymentConfirmed", { state: { data: response, sku: cartData, couponCode: couponCode } });
                  } else {
                    navigate("/OrderHistory", { state: { data: response, sku: cartData, couponCode: couponCode } });
                  }
                },
                theme: "#1C8EA8",
              };
              easebuzzCheckout.initiatePayment(options);
            }
          }
        } catch (error) {
          console.log("Some Error occurred");
        }
      } else {
        let body;
        if (couponCode === "") {
          body = {
            platform: "Web",
            first_name: formData.firstName,
            last_name: formData.lastName,
            state: formData.state,
            ph_no: formData.phno,
            country: formData.country,
            user_id: user_id,
            items: cartData.map((item) => ({
              item_id: item.id,
              search_tbl: item.search_tbl,
              quantity: item.quantity,
              priority_no: priority_number,
            })),
          };
        } else {
          body = {
            platform: "Web",
            first_name: formData.firstName,
            last_name: formData.lastName,
            state: formData.state,
            ph_no: formData.phno,
            country: formData.country,
            user_id: user_id,
            coupon_code: couponCode,
            items: cartData.map((item) => ({
              item_id: item.id,
              search_tbl: item.search_tbl,
              quantity: item.quantity,
              priority_no: priority_number,
            })),
          };
        }

        const redirectPayment = await service.post("/purchase/app/paymentURL", body);
        if (redirectPayment.status === 200) {
          var easebuzzCheckout = new window.EasebuzzCheckout(process.env.REACT_APP_EASEBUZZ_KEY, environment);
          var options = {
            access_key: redirectPayment.data.createOrderResponse.data,
            onResponse: (response) => {
              if (response.status === "success") {
                localStorage.removeItem("temp_quantity");
                dispatch({ type: "EMPTY_BASKET" });
                navigate("/CartPaymentConfirmed", { state: { data: response, sku: cartData, couponCode: couponCode } });
              } else {
                navigate("/OrderHistory", { state: { data: response, sku: cartData, couponCode: couponCode } });
              }
            },
            theme: "#1C8EA8",
          };
          easebuzzCheckout.initiatePayment(options);
        }
      }
    }
  };

  const handlePriorityBooking = async () => {
    if (validation()) {
      if (user_id === null) {
        try {
          const userDetailsPost = await service.post(`/purchase/preLogin/fetchUser`, {
            email: formData.email,
            first_name: formData.firstName,
            last_name: formData.lastName === "" ? null : formData.lastName,
            ph_no: "+91" + formData.phno,
            state: formData.state,
            country: formData.country,
            signup_platform: "CareerCarve",
            device_type: "Web",
          });

          if (userDetailsPost.status === 200) {
            const user_id = userDetailsPost.data.user_id;
            let body;
            if (couponCode === "") {
              body = {
                platform: "Web",
                first_name: formData.firstName,
                last_name: formData.lastName,
                state: formData.state,
                ph_no: formData.phno,
                country: formData.country,
                user_id: user_id,
                items: cartData.map((item) => ({
                  item_id: item.id,
                  search_tbl: item.search_tbl,
                  quantity: item.quantity,
                  priority_no: priority_number,
                  mentor_id: mentorid,
                })),
              };
            } else {
              body = {
                platform: "Web",
                first_name: formData.firstName,
                last_name: formData.lastName,
                state: formData.state,
                ph_no: formData.phno,
                country: formData.country,
                user_id: user_id,
                coupon_code: couponCode,
                items: cartData.map((item) => ({
                  item_id: item.id,
                  search_tbl: item.search_tbl,
                  quantity: item.quantity,
                  priority_no: priority_number,
                  mentor_id: mentorid,
                })),
              };
            }

            const redirectPayment = await service.post("/purchase/app/paymentURL", body);
            if (redirectPayment.status === 200) {
              var easebuzzCheckout = new window.EasebuzzCheckout(process.env.REACT_APP_EASEBUZZ_KEY, environment);
              var options = {
                access_key: redirectPayment.data.createOrderResponse.data,
                onResponse: (response) => {
                  if (response.status === "success") {
                    navigate("/CartPaymentConfirmed", { state: { data: response, sku: cartData, couponCode: couponCode } });
                  } else {
                    navigate("/OrderHistory", { state: { data: response, sku: cartData, couponCode: couponCode } });
                  }
                },
                theme: "#1C8EA8",
              };
              easebuzzCheckout.initiatePayment(options);
            }
          }
        } catch (error) {
          console.log("Some Error occurred");
        }
      } else {
        let body;
        if (couponCode === "") {
          body = {
            platform: "Web",
            first_name: formData.firstName,
            last_name: formData.lastName,
            state: formData.state,
            ph_no: formData.phno,
            country: formData.country,
            user_id: user_id,
            items: cartData.map((item) => ({
              item_id: item.id,
              search_tbl: item.search_tbl,
              quantity: item.quantity,
              priority_no: priority_number,
              mentor_id: mentorid,
            })),
          };
        } else {
          body = {
            platform: "Web",
            first_name: formData.firstName,
            last_name: formData.lastName,
            state: formData.state,
            ph_no: formData.phno,
            country: formData.country,
            user_id: user_id,
            coupon_code: couponCode,
            items: cartData.map((item) => ({
              item_id: item.id,
              search_tbl: item.search_tbl,
              quantity: item.quantity,
              priority_no: priority_number,
              mentor_id: mentorid,
            })),
          };
        }

        const redirectPayment = await service.post("/purchase/app/paymentURL", body);
        if (redirectPayment.status === 200) {
          var easebuzzCheckout = new window.EasebuzzCheckout(process.env.REACT_APP_EASEBUZZ_KEY, environment);
          var options = {
            access_key: redirectPayment.data.createOrderResponse.data,
            onResponse: (response) => {
              if (response.status === "success") {
                navigate("/CartPaymentConfirmed", { state: { data: response, sku: cartData, couponCode: couponCode } });
              } else {
                navigate("/OrderHistory", { state: { data: response, sku: cartData, couponCode: couponCode } });
              }
            },
            theme: "#1C8EA8",
          };
          easebuzzCheckout.initiatePayment(options);
        }
      }
    }
  };

  useEffect(() => {
    getBillingAmount();
  }, [cartData]);

  useEffect(() => {
    updateUserData();
  }, [user_id]);
  return (
    <Box
      padding={{
        padding: Mobile ? "20px 15px" : "2rem 3rem",
        marginTop: webinarTickerExists && Mobile ? "80px" : !webinarTickerExists && Mobile ? "50px" : webinarTickerExists ? "112px" : "70px",
        width: Mobile ? "" : "max(1000px, 100%)",
      }}
    >
      {!loading ? (
        <Box>
          {cartData.length > 0 ? (
            <Box>
              <Typography sx={{ fontSize: Mobile ? "20px" : "32px", textAlign: Mobile ? "center" : "left", marginBottom: "2rem" }}>Let's make your checkout smooth!</Typography>
              <Box sx={{ display: "flex", flexDirection: Mobile ? "column" : "row", gap: Mobile ? "2rem" : "5rem" }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem", flex: 1 }}>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: Mobile ? "18px" : "24px",
                      }}
                    >
                      Billing Details
                    </Typography>
                    <Box sx={{ background: Color.primary1, height: Mobile ? "2px" : "3px", width: Mobile ? "110px" : "180px" }}></Box>
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: Mobile ? "column" : "row", gap: Mobile ? "1rem" : "2rem" }}>
                    <Box sx={{ flex: 1 }}>
                      <Typography sx={{ fontSize: Mobile ? "12px" : "16px", marginBottom: "8px" }}>First Name</Typography>
                      <TextField name="firstName" sx={{ borderRadius: "0px", width: "100%" }} InputProps={{ style: { borderRadius: "0px", height: "48px" } }} autoComplete="off" value={formData.firstName} onChange={(e) => handleFormChange(e)} />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <Typography sx={{ fontSize: Mobile ? "12px" : "16px", marginBottom: "8px" }}>Last Name</Typography>
                      <TextField name="lastName" sx={{ borderRadius: "0px", width: "100%" }} InputProps={{ style: { borderRadius: "0px", height: "48px" } }} autoComplete="off" value={formData.lastName} onChange={(e) => handleFormChange(e)} />
                    </Box>
                  </Box>

                  <Box sx={{}}>
                    <Typography sx={{ fontSize: Mobile ? "12px" : "16px", marginBottom: "8px" }}>Email address</Typography>
                    <TextField name="email" sx={{ borderRadius: "0px", width: "100%" }} InputProps={{ style: { borderRadius: "0px", height: "48px" } }} autoComplete="off" value={formData.email} onChange={(e) => handleFormChange(e)} disabled={user_id === null ? false : true} />
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: Mobile ? "column" : "row", gap: Mobile ? "1rem" : "2rem" }}>
                    <Box sx={{ flex: 1 }}>
                      <Typography sx={{ fontSize: Mobile ? "12px" : "16px", marginBottom: "8px" }}>Phone number</Typography>
                      <TextField name="phno" sx={{ borderRadius: "0px", width: "100%" }} InputProps={{ style: { borderRadius: "0px", height: "48px" } }} autoComplete="off" value={formData.phno} onChange={(e) => handleFormChange(e)} />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <Typography sx={{ fontSize: Mobile ? "12px" : "16px", marginBottom: "8px" }}>Country</Typography>
                      <Select sx={{ width: "100%" }} labelId="country" name="country" value={formData.country} label="Country" onChange={(e) => handleFormChange(e)}>
                        {allCountry.map((country) => (
                          <MenuItem value={country.name}>{country.name}</MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Box>

                  <Box sx={{}}>
                    <Typography sx={{ fontSize: Mobile ? "12px" : "16px", marginBottom: "8px" }}>State</Typography>
                    <Select name="state" value={formData.state} sx={{ width: "100%" }} onChange={(e) => handleFormChange(e)}>
                      {allState.map((state) => (
                        <MenuItem value={state.name}>{state.name}</MenuItem>
                      ))}
                    </Select>
                  </Box>
                  {error !== "" && <Typography sx={{ color: Color.red, fontSize: Mobile ? "12px" : "14px", textAlign: "center" }}>{error}</Typography>}
                </Box>

                <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem", flex: 1 }}>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: Mobile ? "18px" : "24px",
                      }}
                    >
                      Your Shopping Bag
                    </Typography>
                    <Box sx={{ background: Color.primary1, height: Mobile ? "2px" : "3px", width: Mobile ? "150px" : "210px" }}></Box>
                  </Box>
                  <Box>
                    {cartData.map((item, index) => (
                      <Box sx={{ paddingBlock: "1rem", borderBottom: "1px solid #E6E6E6", display: "flex", justifyContent: "space-between" }}>
                        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                          <Box sx={{ padding: "5px", borderRadius: "12px", border: "1px solid #545454", position: "relative" }}>
                            <img src={`https://du03hetco8xdw.cloudfront.net/landingpage+Icons/${item.name?.split(" - ")[0].split(" (")[0].replaceAll(" ", "_").toLowerCase()}.svg`} style={{ width: "30px", aspectRatio: "1:1" }} alt={item.name} />
                            <Typography sx={{ fontSize: "8px", padding: "2px 5px", borderRadius: "50%", color: "#fff", background: "#545454", position: "absolute", top: -5, right: -5 }}>{item.quantity}</Typography>
                          </Box>
                          <Box>
                            <Typography sx={{ fontSize: Mobile ? "12px" : "16px" }}>
                              {item.name} {item.duration && <span style={{ color: "#999999" }}>({item.duration})</span>}
                            </Typography>
                            {priority_number === 1 && <Typography sx={{ fontSize: Mobile ? "12px" : "14px", color: "#999999" }}>with {mentorName}</Typography>}
                          </Box>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                          <Typography sx={{ fontSize: Mobile ? "12px" : "14px" }}>
                            <span style={{ color: "#999999", textDecoration: "line-through" }}>&#x20B9;{item.actual_price}</span> &#x20B9;{item.discounted_price}
                          </Typography>
                          {priority_number === 3 && (
                            <Box>
                              <TextField
                                value={item.quantity}
                                sx={{ width: "100px", textAlign: "center", fontSize: "12px" }}
                                InputProps={{
                                  startAdornment: (
                                    <Box sx={{ padding: "2px 10px", borderRight: "1px solid #999999", borderRadius: "2px", "&:hover": { cursor: "pointer" } }} onClick={(e) => handleChange(e, item, index, "decrease")}>
                                      -
                                    </Box>
                                  ),
                                  endAdornment: (
                                    <Box sx={{ padding: "2px 10px", borderLeft: "1px solid #999999", borderRadius: "2px", "&:hover": { cursor: "pointer" } }} onClick={(e) => handleChange(e, item, index, "increase")}>
                                      +
                                    </Box>
                                  ),
                                  style: { height: "30px", padding: 0, textAlign: "center" },
                                }}
                                inputProps={{ style: { textAlign: "center" } }}
                              />
                            </Box>
                          )}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                  {billingAmount.couponDiscountAmount === 0 ? (
                    <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }} onClick={() => setApplyCouponModal(true)}>
                      <Sell sx={{ color: Color.primary1, transform: "rotate(90deg)" }} />
                      <Typography sx={{ color: Color.primary1 }}>Add coupon</Typography>
                    </Box>
                  ) : (
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <Typography sx={{ color: Color.green, fontSize: Mobile ? "12px" : "14px" }}>Coupon applied</Typography>
                      <Box sx={{ padding: "2px 5px", background: Color.green, display: "flex", alignItems: "center", gap: "5px", borderRadius: "5px" }}>
                        <Sell sx={{ color: Color.white, transform: "rotate(90deg)", fontSize: "20px" }} />
                        <Typography sx={{ color: Color.white }}>{couponCode}</Typography>
                        <IconButton
                          sx={{ padding: 0 }}
                          onClick={() => {
                            setCouponCode("");
                            getBillingAmount("");
                          }}
                        >
                          <Close sx={{ color: Color.white, fontSize: "20px" }} />
                        </IconButton>
                      </Box>
                    </Box>
                  )}

                  <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <Typography sx={{ fontSize: Mobile ? "12px" : "14px", color: "#545454" }}>Item Total</Typography>
                      <Typography>&#x20B9;{billingAmount.actualAmount}</Typography>
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <Typography sx={{ fontSize: Mobile ? "12px" : "14px", color: Color.green }}>Discount</Typography>
                      <Typography sx={{ color: Color.green }}>-&#x20B9;{billingAmount.actualAmount - billingAmount.amountWithoutDiscountAmount}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <Typography sx={{ fontSize: Mobile ? "12px" : "14px", color: "#545454" }}>Subtotal</Typography>
                      <Typography>&#x20B9;{billingAmount.itemAmount}</Typography>
                    </Box>
                    {billingAmount.couponDiscountAmount > 0 && (
                      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography sx={{ fontSize: Mobile ? "12px" : "14px", color: Color.green }}>Coupon Discount</Typography>
                        <Typography sx={{ color: Color.green }}>-&#x20B9;{billingAmount.couponDiscountAmount}</Typography>
                      </Box>
                    )}
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <Typography sx={{ fontSize: Mobile ? "12px" : "14px", color: "#545454" }}>GST(18%)</Typography>
                      <Typography>&#x20B9;{billingAmount.taxAmount}</Typography>
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingBlock: "8px", borderTop: "1px solid #E6E6E6" }}>
                      <Typography sx={{ fontSize: Mobile ? "12px" : "16px", color: "#545454", fontWeight: "600" }}>Grand Total</Typography>
                      <Typography>&#x20B9;{billingAmount.totalBillingAmount}</Typography>
                    </Box>
                  </Box>
                  <Button sx={{ background: Color.primary1, color: Color.white, fontSize: Mobile ? "14px" : "16px", textTransform: "none", borderRadius: "10px", "&:hover": { background: Color.primary1, color: Color.white } }} onClick={() => (priority_number === 1 ? handlePriorityBooking() : handleCheckOut())}>
                    Check Out
                  </Button>
                  <Typography sx={{ fontSize: Mobile ? "12px" : "14px", display: "inline" }}>
                    By ordering, you agree to our &nbsp;
                    <Typography sx={{ color: Color.primary1, textDecoration: "underline", display: "inline", "&:hover": { cursor: "pointer" } }} onClick={() => navigate("/TermsOfUse")}>
                      Terms of use
                    </Typography>{" "}
                    and{" "}
                    <Typography sx={{ color: Color.primary1, textDecoration: "underline", display: "inline", "&:hover": { cursor: "pointer" } }} onClick={() => navigate("/PrivacyPolicy")}>
                      Privacy Policy
                    </Typography>
                    .
                  </Typography>

                  <Typography>
                    View our cancellation and refund policy{" "}
                    <Typography sx={{ color: Color.primary1, textDecoration: "underline", display: "inline", "&:hover": { cursor: "pointer" } }} onClick={() => navigate("/CancellationAndRefundpolicy")}>
                      here
                    </Typography>
                    .
                  </Typography>
                </Box>
              </Box>

              <Modal
                sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                open={applyCouponModal}
                onClose={() => {
                  setApplyCouponModal(false);
                  setCouponError("");
                }}
              >
                <Box sx={{ background: "white", height: "fit-content", width: "300px", borderRadius: "20px", padding: "20px", display: "flex", flexDirection: "column", gap: "20px", alignItems: "center" }}>
                  <SellOutlined sx={{ color: "#1C8EA8", fontSize: "100px" }} />
                  <Typography sx={{ fontWeight: "600", textAlign: "center" }}>Get maximum discount on your Orders!!!</Typography>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                    <TextField
                      sx={{ width: "100%" }}
                      value={couponCode}
                      onChange={(e) => {
                        setCouponCode(e.target.value);
                        setCouponError("");
                      }}
                      placeholder="Add your coupon here..."
                      inputProps={{ style: { height: "10px" } }}
                      InputProps={{
                        endAdornment: billingAmount.couponDiscountAmount !== 0 && (
                          <IconButton
                            onClick={() => {
                              setCouponCode("");
                              getPayableAmount();
                            }}
                          >
                            <Delete sx={{ color: "#b90e0a" }} />
                          </IconButton>
                        ),
                      }}
                    />
                    {couponError !== "" && <Typography sx={{ textAlign: "center", fontSize: "14px", color: "red" }}>{couponError}</Typography>}

                    <Button
                      sx={{ textTransform: "none", border: "1px solid #1C8EA8", color: "#1C8EA8", padding: "10px", width: "100%", "&:hover": { color: "#1C8EA8", background: "rgba(28, 142, 168, 0.2)" } }}
                      onClick={() => {
                        setApplyCouponModal(false);
                        setCouponError("");
                      }}
                    >
                      Cancel
                    </Button>
                    <Button sx={{ textTransform: "none", background: "#1C8EA8", color: "#fff", padding: "10px", width: "100%", "&:hover": { background: "#1C8EA8", color: "#fff" } }} onClick={() => couponCode !== "" && handleApplyCoupon()}>
                      Apply
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </Box>
          ) : (
            <Box>
              <Typography sx={{ fontSize: Mobile ? "20px" : "24px" }}>Your Shopping Bag</Typography>
              <Box sx={{ background: Color.primary1, height: "3px", width: Mobile ? "120px" : "200px", marginTop: "5px" }}></Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <img src="https://ccicons.s3.amazonaws.com/cc_com_landing/emptyCartScreen.webp" style={{ width: Mobile ? "80%" : "50%", aspectRatio: Mobile ? "1/1" : "1.5/1" }} alt="CareerCarve - Empty Cart" />
              </Box>
              <Typography sx={{ fontSize: Mobile ? "14px" : "20px", color: Color.primary1, textAlign: "center", marginBlock: "8px" }}>Looks like you have not added anything</Typography>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button sx={{ background: Color.primary1, color: Color.white, fontSize: Mobile ? "14px" : "16px", textTransform: "none", borderRadius: "10px", "&:hover": { background: Color.primary1, color: Color.white } }} onClick={() => navigate("/Pricing")}>
                  View our 1:1s
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      ) : (
        <Loader />
      )}
    </Box>
  );
}

export default BookingPage;

const Loader = () => {
  const Mobile = useMediaQuery("(max-width:800px)");
  return (
    <Box sx={{ display: "flex", flexDirection: Mobile ? "column" : "row", justifyContent: "space-between" }}>
      <Skeleton height={"80vh"} width={"45vw"} />
      <Skeleton height={"80vh"} width={"45vw"} />
    </Box>
  );
};
