import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CloseIcon from '@mui/icons-material/Close';
import { propTypes } from 'react-bootstrap/esm/Image';
import { Color, DivCenter } from '../../../GlobalStyles';
import { Alert, Box, Snackbar, TextField, Typography } from '@mui/material';
import ToastSnackbar from './ToastSnakbar';

function ShareButton({ open, handleClose, url }) {
    console.log("url being accepted", url);
    const [toastOpen, setToastOpen] = useState(false);

    const handleToastClose = () => {
        setToastOpen(false);
    }

    const copyToClipboard = () => {
        navigator.clipboard.writeText(url)
        setToastOpen(true);
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose} sx={{ borderRadius: '80px' }}>
                <Box sx={{ width: 500, height: 230, borderRadius: '80px', padding: 0, margin: "auto 0" }}>
                    <DialogTitle sx={{ ...DivCenter, height: "25%", justifyContent: 'space-between' }}>
                        Share
                        <IconButton onClick={handleClose} sx={{ color: Color.neutralBlack }}><CloseIcon /></IconButton>
                    </DialogTitle>
                    <DialogContent sx={{ ...DivCenter, width: '100%', flexDirection: "column", justifyContent: 'space-between', gap: "1.3rem" }}>
                        <Box sx={{ ...DivCenter, width: '100%', justifyContent: 'flex-start', gap: "2rem" }}>
                            <Typography>Link to share</Typography>
                        </Box>
                        <Box sx={{
                            ...DivCenter,
                            justifyContent: 'flex-start',
                            flexDirection: 'column',
                            width: '100%',
                            gap: "2rem",
                            padding: '0 10px 0 10px'
                        }}>
                            <Box sx={{ ...DivCenter, justifyContent: 'center', width: '100%', gap: "2rem" }}>
                                <TextField
                                    id="standard-read-only-input"
                                    value={url}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="standard"
                                    sx={{ width: '100%' }}
                                />
                            </Box>
                            <Box sx={{ ...DivCenter, justifyContent: 'center', width: '100%' }}>
                                <Button onClick={copyToClipboard} sx={{ color: '#1a73e8' }}>
                                    Copy URl
                                </Button>
                            </Box>
                            <ToastSnackbar open={toastOpen} message={'URL Copied to Clipboard'} handleClose={handleToastClose} />
                        </Box>
                    </DialogContent>
                </Box>
            </Dialog>
        </div>
    );
}

ShareButton.defaultProps = {
    url: '/BlogsEntireDetails',
};


ShareButton.propTypes = {
    url: propTypes.string,
};

export default ShareButton;
