import React from 'react';
import { Skeleton } from '@mui/material';
import { Box, Card, CardContent, Grid } from '@mui/material';
export function WebinarBannerSkeleton({Mobile}) {
    return (
        <div style={{ position: 'relative' }}>
            <Skeleton variant="rectangular" width="100%" height="320px" />
            <div
                style={{
                    position: 'absolute',
                    bottom: '-40px',
                    left: '60px',
                    zIndex: 20,
                    backgroundColor: '#FFF',
                    padding: '6px 46px',
                    boxShadow: '0px 6px 49px 0px rgba(0, 0, 0, 0.08)',
                    borderRadius: '12px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Skeleton variant="text" width="300px" height="36px" />
                <Skeleton variant="text" width="200px" height="24px" />
            </div>
        </div>
    );
};



export function WebinarCompleteDetailsSkeleton({Mobile}) {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', width: '80%', margin: 'auto', marginTop: '1rem' }}>
            {/* Left part */}
            <Box sx={{ width: '100%', maxWidth: '60%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <Skeleton variant="text" height={40} />
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '.5rem' }}>
                    <Skeleton variant="circular" width={20} height={20} />
                    <Skeleton variant="text" width={100} />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '.5rem' }}>
                    <Skeleton variant="circular" width={20} height={20} />
                    <Skeleton variant="text" width={100} />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '.5rem' }}>
                    <Skeleton variant="circular" width={20} height={20} />
                    <Skeleton variant="text" width={100} />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '.5rem' }}>
                    <Skeleton variant="circular" width={20} height={20} />
                    <Skeleton variant="text" width={100} />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Skeleton variant="text" width={100} />
                    <Skeleton variant="text" width={100} />
                </Box>
                <Skeleton variant="rectangular" height={100} />
                <Skeleton variant="rectangular" height={100} />
            </Box>
            {/* Right part */}
            <Box sx={{ width: '40%' }}>
                <Skeleton variant="rectangular" height={400} />
            </Box>
        </Box>
    );
};




export function ExpertPanelSkeleton({Mobile}) {
    return (
        <Grid container spacing={2}>
            {[1, 2, 3].map((index) => (
                <Grid item key={index} xs={12} sm={6} md={4}>
                    <Card
                        sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            gap: '.8rem',
                            borderRadius: '12px',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.08)',
                            padding: '16px', // Added padding
                        }}
                    >
                        <CardContent sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: '.8rem' }}>
                            <Skeleton variant="circular" width={80} height={80} />
                            <Skeleton variant="text" width={120} height={40} />
                            <Skeleton variant="text" width={200} height={80} />
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};








