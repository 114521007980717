import React from 'react';
import { Card, CardContent, Typography, Box, Skeleton } from '@mui/material';
import { Color } from '../../../GlobalStyles';

const WebinarSkeletonLoader = ({ mode, Mobile }) => (
  <Card
    sx={{
      minWidth: 275,
      background: mode === "past" ? Color.neutralLightGrey : "#EBF9FC",
      border: "1px solid #E6E6E6",
      boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.08)",
      borderRadius: "12px",
      marginBottom: "20px",
      cursor: 'pointer',
      position: 'relative',
    }}
  >
    <CardContent>
      <Box className="upcomingBookingContent" style={{ height: mode === "past" ? "150px" : "200px", display: "flex", flexDirection: 'row', gap: "2rem" }}>
        <Box className="upcomingBookingVideo">
          <Skeleton variant="rectangular" width={320} height={mode === "past" ? 150 : 200} style={{ borderRadius: "11px" }} />
        </Box>
        <Box className="upcomingBookingDescription" style={{ display: "flex", flexDirection: "column", gap: ".5rem", justifyContent: "center" }}>
          <Typography variant="h5">
            <Skeleton variant="text" width="80%" />
          </Typography>
          <Typography sx={{ fontSize: 18 }} color="#000000" gutterBottom>
            <Skeleton variant="text" width="100%" />
          </Typography>
          {mode === "upcoming" && (
            <>
              <Box sx={{ display: "flex", flexDirection: "row", gap: ".5rem" }}>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Skeleton variant="circle" width={20} height={20} />
                  <Typography sx={{ margin: "auto 0", color: "#000000" }}>
                    <Skeleton variant="text" width="80%" />
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Skeleton variant="circle" width={20} height={20} />
                  <Typography variant="body2" color="text.secondary" sx={{ margin: "auto 0", color: Color.green }}>
                    <Skeleton variant="text" width="40%" />
                  </Typography>
                </Box>
              </Box>
              <button className="pricingSingleACtivityButton1">
                <Skeleton variant="text" width="100px" />
              </button>
              <Box sx={{ display: "flex", flexDirection: "row", alignItem: 'center' }}>
                <Skeleton variant="circle" width={20} height={20} />
                <Typography variant="body2" color="text.secondary" sx={{ margin: "auto 0" }}>
                  <Skeleton variant="text" width="80%" />
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </CardContent>
  </Card>
);

export default WebinarSkeletonLoader;
