import { Box, Button, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import HorizontalScroll from "../../Mentor/HorizontalScroll";
import "../ContactUs/ContactUs.css";
import ImageScroll from "../ImagesInfiniteScroll/ImagesInfiniteScroll";
function CollegeTieUps() {
  const navigate = useNavigate();
  const Mobile = useMediaQuery("(max-width:800px)");
  const logos = ["https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/imt.jpg", "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/tampi.jpg", "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/iim_visakhapatnam.jpg", "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/iimu.jpg", "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/mica.jpg", "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/gim.jpg", "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/iim+kashipur.jpg", "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/iim+trichy.jpg"];
  return (
    <Box>
      {Mobile ? (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "30px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem",
              width: "100%",
            }}
          >
            <Typography variant="h2" fontSize="24px" fontWeight="400" sx={{ textAlign: "center" }}>
              Trusted by
              <br />
              <span style={{ color: "#1C8EA8" }}>Top B-Schools </span>
              in India
            </Typography>
            <Box
              sx={{
                width: "45px",
                height: "2px",
                backgroundColor: "#000",
                marginTop: "-10px",
                alignSelf: "center",
              }}
            />

            <Typography align="left" fontSize="14px" color="#545454">
              We have worked with Top NIRF B-Schools over the past 12 years. More than 52,000 students from 40+ Business Schools, have benefited from our services. What are you waiting for?
            </Typography>
            <Button
              sx={{
                borderRadius: "8px",
                border: "1px solid #F5A536",
                backgroundColor: "#F5A536",
                width: "fit-content",
                padding: "8px 16px",
                fontSize: "14px",
                alignItems: "center",
                alignSelf: "center",
                color: "#000",
                textTransform: "none",
                "&:hover": {
                  background: "linear-gradient(135deg, #F5A536 0%, #FFC87A 100%)",
                },
              }}
              onClick={() => [window.scroll(0, 0), navigate("/Pricing")]}
            >
              View Training Plans
            </Button>
          </Box>
          {/* <ImageScroll /> */}
          <HorizontalScroll images={logos} size={Mobile ? "120px" : "150px"} />

          {/* </Container> */}
        </Box>
      ) : (
        <Box
          // className="landing-container"
          sx={{
            width: Mobile ? "max(100%, 800px)" : "100%",
            flexDirection: Mobile ? "column" : "row",
            display: "flex",
            justifyContent: "space-between", // center horizontally
            alignItems: "center", // center vertically
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
              width: "100%",
            }}
          >
            <Box display={"flex"} flexDirection={"column"} gap={"1rem"}>
              <Typography variant="h2" fontSize="45px" fontWeight="400px" align="left" lineHeight="50px">
                Trusted by
                <br />
                <span style={{ color: "#1C8EA8" }}>Top B-Schools </span>
                in India
              </Typography>
              <Box sx={{ width: "101px", height: "1px", backgroundColor: "#000" }} />
            </Box>
            <Typography align="left" fontSize="16px" color="#545454">
              We have worked with Top NIRF B-Schools over the past 12 years. More than 52,000 students from 40+ Business Schools, have benefited from our services. What are you waiting for?
            </Typography>
            <button className="button-yellow-hover" style={{ width: "fit-content" }} onClick={() => [window.scroll(0, 0), navigate("/Pricing")]}>
              <Typography>View Training Plans</Typography>
            </button>
          </Box>

          <Box sx={{ width: "100%" }}>
            <ImageScroll />
          </Box>
          {/* </Container> */}
        </Box>
      )}
    </Box>
  );
}

export default CollegeTieUps;
