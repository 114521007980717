import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../../components/StateProvider/stateProvider";
import ServiceCM from "../../../services/cartmoduleServices";
import BottomNavbar from "../Footer/BottomNavbar";
import { useWebinarContext } from "../../../WebinarContext";
function NewExpertConnect() {
  const navigate = useNavigate();
  const env = `${process.env.REACT_APP_ENVIRONMENT}`;
  console.log("env", env);
  const Mobile = useMediaQuery("(max-width:800px)");
  const [{ basket }, dispatch] = useStateValue();
  const { webinarTickerExists } = useWebinarContext();
  const services = new ServiceCM();
  const [sku, setSku] = useState([]);
  const handleNavigate = (value) => {
    window.scroll(0, 0);
    navigate(value);
  };

  const addToCart = () => {
    let temp_cart = JSON.parse(localStorage.getItem("temp_quantity")) ?? [];
    console.log("cart item", temp_cart);
    const id = env === "dev" ? 16 : 2;
    let item = temp_cart.filter((item) => item.id === id);
    if (item.length > 0) {
      temp_cart = temp_cart.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            quantity: item.quantity + 1,
            total_price: item.total_price + item.total_price / item.quantity,
          };
        } else {
          return item;
        }
      });
    } else {
      let find_sku = sku.find((item) => item.id === id);
      console.log("find_sku", find_sku);
      let data = {
        search_tbl: "sku",
        id: id,
        quantity: 1,
        total_price: find_sku.actual_price,
      };
      temp_cart = [...temp_cart, data];
    }

    console.log("cart item", temp_cart);
    dispatch({ type: "UPDATE_BASKET", basket: temp_cart });
    localStorage.setItem("temp_quantity", JSON.stringify(temp_cart));
  };

  const getSku = async () => {
    const res = await services.get("/sku?type=1 X 1");
    setSku(res.data);
  };

  useEffect(() => {
    getSku();
  }, []);
  return (
    <>
      {/* this provides the meta description of each page separately */}
      <Helmet>
        <title>Expert Connect: Your Shortcut to Industry Insights for MBA Success</title>
        <meta name="description" content="Take your MBA prep to the next level! See how our Expert Connect program connects you with valuable mentors" />

        <meta property="og:title" content="CareerCarve-Connect with an Expert" />
        <meta property="og:image" content="/public/logo_welcome.pngg" />
        <meta property="og:url" content="https://www.careercarve.com/ExpertConnect" />
        <link rel="canonical" href="/ExpertConnect" />
      </Helmet>
      <Box sx={{ marginTop: webinarTickerExists && Mobile ? "80px" : !webinarTickerExists && Mobile ? "50px" : webinarTickerExists ? "112px" : "70px" }}>
        {/* <Navbar /> */}
        <Box sx={{ marginBottom: Mobile ? "1.5rem" : "4.5rem" }}>
          <Box>
            <img
              src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/ExpertConnect.png"
              alt="Gain a Competitive Edge: Expert Connect for MBA Students"
              style={{
                height: Mobile ? "15vh" : "100%",
                width: "100%",
                marginBottom: Mobile ? "-5%" : "-2%",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h1"
              fontSize={Mobile ? "24px" : "36px"}
              sx={{
                zIndex: "20",
                bgcolor: "#FFF",
                fontWeight: "400",
                padding: "8px 46px",
                boxShadow: "0px 6px 49px 0px rgba(0, 0, 0, 0.08)",
              }}
            >
              Expert Connect
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingInline: Mobile ? "15px" : "9.5rem",
            gap: Mobile ? "4rem" : "4.5rem",
            paddingBottom: "4.5rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: Mobile ? "0.5rem" : "1.5rem",
            }}
          >
            <Typography variant={Mobile ? "h2" : "h2"} fontSize={Mobile ? "20px" : "24px"} textAlign={Mobile ? "center" : ""}>
              Expert Connect
            </Typography>
            <Typography variant={Mobile ? "body2" : "body1"} fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"}>
              Welcome to CareerCarve's Expert Connect Session, an open-ended discussion that allows you to connect with a mentor from your area of interest. This unique session empowers you to explore your areas of interest, seek advice, and gain valuable insights from experienced industry mentors. Whether you have specific career-related questions or simply need to destress before an important process, the Expert Connect Session is designed to cater to your needs and expectations.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: Mobile ? "column" : "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box width="100%" display="flex" flexDirection="column" gap={Mobile ? "0.5rem" : "1.5rem"}>
              <Typography variant={Mobile ? "h5" : "h4"} fontSize={Mobile ? "20px" : "24px"}>
                Why take a <span style={{ color: "#1C8EA8" }}>Expert Connect</span> Session?
              </Typography>
              <Typography fontSize={Mobile ? "14px" : "16px"} variant={Mobile ? "body2" : "body1"} color="#545454" textAlign={"justify"}>
                The Expert Connect Session at CareerCarve is an open and flexible discussion platform that puts you in touch with a mentor who shares your area of interest. This session allows you to freely engage in conversations related to your career, role, resume, interview preparation, group discussions, and any other aspects you seek guidance on. It is a space for open dialogue, enabling you to learn from the experiences of industry experts and gain clarity about your career path.
              </Typography>
              <button
                className="button-yellow-hover"
                style={{
                  width: "fit-content",
                  padding: "8px 20px",
                  alignSelf: Mobile ? "center" : "",
                }}
                onClick={() => {
                  addToCart();
                }}
              >
                <Typography>Add to cart</Typography>
              </button>
            </Box>
            <Box width="100%" display={"flex"} justifyContent={"center"} alignItems={"center"}>
              <img
                src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/Expert+connect+picart.png"
                alt="Connect with Experts: Boost Your MBA Placements with Expert Connect"
                style={{
                  width: Mobile ? "200px" : "396px",
                  height: Mobile ? "100%" : "325px",
                }}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
            <Typography fontSize={Mobile ? "20px" : "24px"} variant={Mobile ? "h5" : "h4"}>
              How does it <span style={{ color: "#1C8EA8" }}>benefit</span> me?
            </Typography>
            <Box display="flex" flexDirection="column" gap="2rem">
              <Box display="flex" gap="0.5rem" flexDirection="column" sx={{ borderLeft: "2px solid #1C8EA8", paddingLeft: "1rem" }}>
                <Typography variant={Mobile ? "h6" : "h5"} fontSize={Mobile ? "16px" : "20px"} textAlign={"left"}>
                  Customized Mentorship
                </Typography>
                <Typography variant={Mobile ? "body2" : "body1"} fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"}>
                  You get connected to a mentor who aligns with your specific area of interest or career aspirations. This personalized mentorship ensures that you receive relevant and tailored advice.
                </Typography>
              </Box>

              <Box display="flex" gap="0.5rem" flexDirection="column" sx={{ borderLeft: "2px solid #F5A536", paddingLeft: "1rem" }}>
                <Typography variant={Mobile ? "h6" : "h5"} fontSize={Mobile ? "16px" : "20px"} textAlign={"left"}>
                  Comprehensive Discussion
                </Typography>
                <Typography variant={Mobile ? "body2" : "body1"} fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"}>
                  The session covers a wide range of topics, including clarifying doubts, seeking career guidance, refining your resume, preparing for interviews, and even discussing the interview process.
                </Typography>
              </Box>

              <Box display="flex" gap="0.5rem" flexDirection="column" sx={{ borderLeft: "2px solid #1C8EA8", paddingLeft: "1rem" }}>
                <Typography variant={Mobile ? "h6" : "h5"} fontSize={Mobile ? "16px" : "20px"} textAlign={"left"}>
                  Confidence Building
                </Typography>
                <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                  This session can also be utilized as a platform to destress before an important process or interview. Talking to someone from a similar background can boost your confidence and ease anxieties.
                </Typography>
              </Box>

              <Box display="flex" gap="0.5rem" flexDirection="column" sx={{ borderLeft: "2px solid #F5A536", paddingLeft: "1rem" }}>
                <Typography fontSize={Mobile ? "16px" : "20px"} textAlign={"left"} variant={Mobile ? "h6" : "h5"}>
                  Unstructured Discussion
                </Typography>
                <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                  The discussion evolves based on your questions, areas of interest, and expectations. This allows you to address your concerns and explore various career aspects during the discussion.
                </Typography>
              </Box>
            </Box>
            {/* <Box sx={{ display: "flex", alignItems: "center" }}>
            {!Mobile && (
              <img
                src="../../QR_code_sign_up.jpg"
                style={{ height: "78px", width: "78px" }}
                alt="careercarve"
              />
            )}
            <Button
              sx={{
                borderRadius: "8px",
                border: "1px solid #F5A536",
                backgroundColor: "#F5A536",
                width: "200px",
                height: "40px",
                padding: "8px 16px",
                fontSize: "14px",
                color: "#000",
                "&:hover": {
                  background:
                    "linear-gradient(135deg, #F5A536 0%, #FFC87A 100%)",
                },
              }}
            >
              Download the App
            </Button>
          </Box> */}
          </Box>
          <Box display="flex" flexDirection={Mobile ? "column" : "row"} justifyContent="space-between" alignItems="center" gap={"8rem"}>
            <Box display="flex" justifyContent="space-between" gap={Mobile ? "0.5rem" : "1.5rem"} flexDirection="column">
              <Typography fontSize={Mobile ? "20px" : "24px"}>
                Where will the <span style={{ color: "#1C8EA8" }}>session happen</span>?
              </Typography>
              <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454">
                The Expert Connect Session will take place online, providing you with the convenience to have the discussion from any location. You can easily schedule a 30 mins session through our user-friendly app. Head to the CareerCarve App and book your Expert Connect session and get clarity!
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: Mobile ? "center" : "",
                }}
              >
                {!Mobile && <img src="../../QR_code_sign_up.jpg" style={{ height: "78px", width: "78px" }} alt="careercarve" />}
                <a href="https://play.google.com/store/apps/details?id=com.careercarve&hl=en&gl=US" target="_blank" title="careercarve-mobile-app" rel="noreferrer" style={{ textDecoration: "none" }}>
                  <button
                    className="button-yellow-hover"
                    style={{
                      width: "fit-content",
                      alignSelf: Mobile ? "center" : "",
                    }}
                    href="https://play.google.com/store/apps/details?id=com.careercarve&hl=en&gl=US"
                    target="_blank"
                  >
                    <Typography> Download the App</Typography>
                  </button>
                </a>
              </Box>
            </Box>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                width: Mobile ? "200px" : "385px",
                height: Mobile ? "350px" : "385px",
                background: "rgba(56, 193, 223, 0.20)",
              }}
              paddingInline={"10rem"}
              borderRadius={"50%"}
            >
              <img
                src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/1+on+1+Mobile.png"
                alt="Unlock Your Potential: Connect with Expert Mentors for MBA Placements"
                style={{
                  width: Mobile ? "220px" : "243px",
                  height: Mobile ? "400px" : "480px",
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: Mobile ? "1.5rem" : "2.5rem",
            }}
          >
            <Typography fontSize={Mobile ? "24px" : "32px"} color="#1C8EA8" textAlign="center">
              View Other 1X1
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "100%",
                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                    padding: "1rem",
                    height: "272px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                    }}
                  >
                    <Typography fontSize={Mobile ? "20px" : "24px"} variant={Mobile ? "h5" : "h4"}>
                      Mock Interview
                    </Typography>
                    <Typography fontSize={Mobile ? "14px" : "16px"} color="#8B8B8B" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                      Mock interviews are simulated job interviews that closely resemble real-life interview scenarios.
                    </Typography>
                  </Box>
                  <button className="button-yellow-hover" onClick={() => handleNavigate("/MockInterview")}>
                    <Typography>View More</Typography>
                  </button>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "100%",
                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                    padding: "1rem",
                    height: "272px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                    }}
                  >
                    <Typography fontSize={Mobile ? "20px" : "24px"} variant={Mobile ? "h5" : "h4"}>
                      Case Interview
                    </Typography>
                    <Typography fontSize={Mobile ? "14px" : "16px"} color="#8B8B8B" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                      The Case Interview Session at CareerCarve is a personalized consultation with experienced industry mentors who have a strong background in consulting and business strategy.
                    </Typography>
                  </Box>
                  <button className="button-yellow-hover" onClick={() => handleNavigate("/CaseInterview")}>
                    <Typography>View More</Typography>
                  </button>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "100%",
                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                    padding: "1rem",
                    height: "272px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                    }}
                  >
                    <Typography fontSize={Mobile ? "20px" : "24px"} variant={Mobile ? "h5" : "h4"}>
                      PI Questions from Resume
                    </Typography>
                    <Typography fontSize={Mobile ? "14px" : "16px"} color="#8B8B8B" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                      The Personal Interview Questions from Resume Session at CareerCarve is a one-on-one consultation with experienced industry mentors.
                    </Typography>
                  </Box>
                  <button className="button-yellow-hover" onClick={() => handleNavigate("/PIQR")}>
                    <Typography>View More</Typography>
                  </button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          background: "linear-gradient(226deg, #1D2C30 0%, #020303 100%)",
        }}
      >
        <BottomNavbar />
      </Box>
    </>
  );
}

export default NewExpertConnect;
