import { Typography } from "@mui/material";
import React from "react";
import { Color } from "../../GlobalStyles";
import useMediaQuery from "@mui/material/useMediaQuery";

const NoEventsMessage = (props) => {
  const Mobile = useMediaQuery("(max-width:800px)");

  const { mode } = props;
  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          width: "100%",
          display: "flex", // Sets the display mode to flex
          justifyContent: "center", // Centers the child elements horizontally
          alignItems: "center", // Centers the child elements vertically (optional here)
        }}
      >
        <img src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/No+data-cuate+2.jpg" style={{ width: Mobile ? "150px" : "auto", height: Mobile ? "130px" : "auto" }} alt="CareerCarve-No Events" />
      </div>
      <Typography sx={{ fontSize: Mobile ? "16px" : "22px", fontStyle: "italic", fontWeight: "normal", color: Color.neutralMidGrey, textAlign: "center" }}>
        {mode === "upcoming"
          ? ["No upcoming events scheduled at the moment.", "Check back later for updates!"].map((text, index, arr) => (
              <React.Fragment key={index}>
                {text}
                {index < arr.length - 1 && <br />}
              </React.Fragment>
            ))
          : "No past events to display. Stay tuned for future events!"}
      </Typography>
    </div>
  );
};

export default NoEventsMessage;
