export function ValidateNameEmailPhone(values, phone) {
    const errors = {};
    const regex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!values.fullname || values.fullname?.trim() === "") {
        errors.firstname = "First name is required!";
    }
    if (!values.email || values.email?.trim() === "") {
        errors.email = "Email is required!";
    } else if (!regex.test(values.email.trim())) {
        errors.email = "This is not a valid email format!";
    }

    if (!phone || phone.length < 7) {
        errors.phone = "Phone number must be at least 7 characters long";
    }
    return errors;
};