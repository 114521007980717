import ServiceCM from "../services/cartmoduleServices";

const serviceCM = new ServiceCM();

export const skuGetter = async () => {
  try {
    const res = await serviceCM.get("/sku?type=1 X 1");

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const bundlesGetter = async () => {
  try {
    const res = await serviceCM.get("/bundle");

    return res.data;
  } catch (err) {
    console.log(err);
  }
};
