import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useWebinarContext } from "../../WebinarContext";
import BottomNavbar from "../LandingPage/Footer/BottomNavbar";
function BecomeMentor() {
  const Mobile = useMediaQuery("(max-width:800px)");
  const { webinarTickerExists } = useWebinarContext();
  return (
    <>
      {/* this provides the meta description of each page separately */}
      <Helmet>
        <title>Empower Students to Soar to Success - Your Ultimate Mentorship Guide</title>
        <meta name="description" content="JJoin us as a mentor and take on the challenge of shaping young minds for a brighter future. Become the missing piece in students' lives today!" />
        <meta property="og:title" content="CareerCarve-Become a Mentor" />
        <meta property="og:image" content="/public/logo_welcome.png" />
        <meta property="og:url" content="https://www.careercarve.com/BecomeMentor" />
        <link rel="canonical" href="/BecomeMentor" />
      </Helmet>
      <Box sx={{ marginTop: webinarTickerExists && Mobile ? "80px" : !webinarTickerExists && Mobile ? "50px" : webinarTickerExists ? "112px" : "70px" }}>
        {/* <Navbar /> */}
        <Box sx={{ marginBottom: Mobile ? "1.5rem" : "4.5rem" }}>
          <Box>
            <img
              src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/Get-trained-by-industry-experts.png"
              alt="Mentor MBAs & Be Rewarded: Join the CareerCarve Community"
              style={{
                height: Mobile ? "15vh" : "300px",
                // width: "fit-content",
                width: "100%",
                marginBottom: "-2%",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              variant={Mobile ? "h4" : "h3"}
              fontSize={Mobile ? "24px" : "36px"}
              sx={{
                zIndex: "20",
                bgcolor: "#FFF",
                fontWeight: "400",
                padding: "8px 46px",
                boxShadow: "0px 6px 49px 0px rgba(0, 0, 0, 0.08)",
              }}
            >
              Become a Mentor
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: Mobile ? "1.5rem" : "10vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "1rem",
              paddingInline: Mobile ? "15px" : "9.5rem",
            }}
          >
            <Typography variant="h2" fontSize={Mobile ? "20px" : "32px"} textAlign={Mobile ? "center" : ""} fontWeight={"400"}>
              Shape
              <span style={{ color: "#1C8EA8" }}> Careers</span>, Empower <span style={{ color: "#1C8EA8" }}>Futures</span>
            </Typography>
            <Typography
              variant={Mobile ? "body2" : "body1"}
              sx={{
                color: "#545454",
                fontSize: Mobile ? "14px" : "16px",
                textAlign: "justify",
              }}
            >
              Are you an experienced professional looking to make a significant impact in the lives of aspiring individuals? Do you possess valuable insights and a passion for guiding others along their career paths? Join the CareerCarve mentorship community and become a catalyst for personal and professional growth.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: Mobile ? "column" : "row",
              justifyContent: "space-between",
              alignItems: "center",
              paddingInline: Mobile ? "15px" : "9.5rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0.7rem",
                width: "100%",
              }}
            >
              <Typography variant={Mobile ? "h5" : "h4"} sx={{ fontSize: Mobile ? "20px" : "24px" }} textAlign={Mobile ? "center" : ""} fontWeight={"400"}>
                Become a Mentor: <span style={{ color: "#1C8EA8" }}>Share Your Wisdom</span>{" "}
              </Typography>
              <Box
                sx={{
                  width: "50px",
                  height: "2px",
                  backgroundColor: "#000",
                  alignSelf: Mobile ? "center" : "",
                }}
              />
              {/* <Typography color={"#545454"} fontSize={"16px"}>
              Our mission is simple yet ambitious to achieve{" "}
            </Typography> */}

              <Typography variant={Mobile ? "body2" : "body1"} fontSize={Mobile ? "14px" : "16px"} color={"#545454"} textAlign={"justify"}>
                CareerCarve is dedicated to fostering a vibrant mentorship ecosystem where experienced individuals like you can share their expertise and shape the next generation of professionals. By becoming a mentor, you'll have the opportunity to inspire, guide, and empower mentees as they navigate their career journeys.
              </Typography>
            </Box>
            <Box width={"100%"} display={"flex"} justifyContent={"center"}>
              <img
                src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/become-a-mentor.png"
                alt="Empower MBA Students: See the Benefits of Becoming a Mentor"
                style={{
                  height: Mobile ? "100%" : "400px",
                  width: Mobile ? "200px" : "420px",
                }}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingInline: Mobile ? "15px" : "9.5rem",
              flexDirection: Mobile ? "column-reverse" : "row",
            }}
          >
            <Box width={"100%"} display={"flex"} justifyContent={"center"} sx={{ paddingInline: Mobile ? "15px" : "" }}>
              <img
                src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/careercarve-community.jpg"
                alt="More Than Mentorship: The CareerCarve Community for MBA Professionals"
                style={{
                  height: Mobile ? "100%" : "400px",
                  width: Mobile ? "200px" : "420px",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0.6rem",
                width: "100%",
              }}
            >
              <Typography variant={Mobile ? "h5" : "h4"} fontSize={Mobile ? "20px" : "24px"} fontWeight={"400"} textAlign={Mobile ? "center" : ""}>
                Grow with the
                <span style={{ color: "#1C8EA8" }}> CareerCarve Community</span>
              </Typography>
              <Box
                sx={{
                  width: "50px",
                  height: "2px",
                  alignSelf: Mobile ? "center" : "",
                  backgroundColor: "#000",
                }}
              />
              <Typography
                variant={Mobile ? "body2" : "body1"}
                sx={{
                  textAlign: "justify",
                  fontSize: Mobile ? "14px" : "16px",
                  color: "#545454",
                }}
              >
                When you become a mentor with CareerCarve, you're not just imparting knowledge – you're also gaining access to a dynamic community of fellow mentors. Engage in meaningful conversations, exchange ideas, and collaborate with other seasoned professionals who share your commitment to nurturing talent.
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem",
              paddingInline: Mobile ? "15px" : "9.5rem",
            }}
          >
            <Typography fontSize={Mobile ? "20px" : "24px"} fontWeight={"400"} variant={Mobile ? "h5" : "h4"}>
              Perks of Being a Part of the <span style={{ color: "#1C8EA8" }}> CareerCarve Community</span>
            </Typography>
            <Typography sx={{ fontSize: Mobile ? "14px" : "16px", color: "#8B8B8B" }} variant={Mobile ? "body2" : "body1"}>
              As a valued member of the CareerCarve mentorship community, you'll enjoy a range of benefits, including:
            </Typography>
            <Box display="flex" flexDirection="column" gap="2rem">
              <Box display="flex" gap="0.5rem" flexDirection="column" sx={{ borderLeft: "2px solid #1C8EA8", paddingLeft: "1rem" }}>
                <Typography fontSize={Mobile ? "16px" : "20px"} textAlign={"left"} variant={Mobile ? "h6" : "h5"}>
                  Networking Opportunities
                </Typography>
                <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" variant={Mobile ? "body2" : "body1"}>
                  Connect with a diverse group of mentors from various industries and backgrounds, expanding your professional network.
                </Typography>
              </Box>

              <Box display="flex" gap="0.5rem" flexDirection="column" sx={{ borderLeft: "2px solid #F5A536", paddingLeft: "1rem" }}>
                <Typography fontSize={Mobile ? "16px" : "20px"} variant={Mobile ? "h6" : "h5"}>
                  Impactful Legacy
                </Typography>
                <Typography fontSize={Mobile ? "14px" : "16px"} variant={Mobile ? "body2" : "body1"} color="#545454">
                  By imparting your knowledge and insights, you'll leave an indelible mark on the professional trajectories of your mentees. Witness their growth and success, knowing you played a pivotal role in their achievements.
                </Typography>
              </Box>

              <Box display="flex" gap="0.5rem" flexDirection="column" sx={{ borderLeft: "2px solid #1C8EA8", paddingLeft: "1rem" }}>
                <Typography fontSize={Mobile ? "16px" : "20px"} textAlign={"left"} variant={Mobile ? "h6" : "h5"}>
                  Recognition
                </Typography>
                <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" variant={Mobile ? "body2" : "body1"}>
                  Gain visibility as a mentor, showcasing your expertise and commitment to community growth.
                </Typography>
              </Box>

              <Box display="flex" gap="0.5rem" flexDirection="column" sx={{ borderLeft: "2px solid #F5A536", paddingLeft: "1rem" }}>
                <Typography fontSize={Mobile ? "16px" : "20px"} variant={Mobile ? "h6" : "h5"}>
                  Guidance & Inspiration
                </Typography>
                <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" variant={Mobile ? "body2" : "body1"}>
                  Provide practical insights and advice to mentees, helping them navigate challenges and make informed career decisions and help them overcome obstacles and pursue their goals with confidence.
                </Typography>
              </Box>
              <a className="button-yellow-hover" style={{ width: "fit-content", textDecoration: "none" }} href="https://www.careercarve.in/MentorInterestForm" target="_blank">
                <Typography style={{ color: "#000" }}> Join the CareerCarve Community</Typography>
              </a>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            marginTop: Mobile ? "20px" : "5rem",
            background: "linear-gradient(226deg, #1D2C30 0%, #020303 100%)",
          }}
        >
          <BottomNavbar />
        </Box>
      </Box>
    </>
  );
}

export default BecomeMentor;
