import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Modal, Select, TextField, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStateValue } from "../../components/StateProvider/stateProvider";
import Cookies from "js-cookie";
import ServiceCM from "../../services/cartmoduleServices";
import RDService from "../../services/RDService";
import { getPayableAmount, getTotalPrice } from "../../components/StateProvider/reducer";
import { Link, useNavigate, useLocation } from "react-router-dom";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import { Country, State, City } from "country-state-city";
import { Delete } from "@mui/icons-material";
import { useWebinarContext } from "../../WebinarContext";
import axios from "axios";
const PriorityBooking = () => {
  const Mobile = useMediaQuery("(max-width:800px)");
  const [{ first_name }, dispatch] = useStateValue();
  const location = useLocation();
  const { mentor_id, sku_id, mentorName } = location.state.bookingValue;
  const [basket, setBasket] = useState([{ id: sku_id, quantity: 1, search_tbl: "sku" }]);
  const { webinarTickerExists } = useWebinarContext();
  const allCountry = Country.getAllCountries();
  const [allState, setAllState] = useState([]);
  const [formInput, setFormInput] = useState({
    user_id: "",
    first_name: "",
    last_name: "",
    ph_no: "",
    email: "",
    state: "",
    country: "",
  });
  const [applyCouponModal, setApplyCouponModal] = useState(false);
  const [formError, setFormErrors] = useState({
    first_name: "",
    last_name: "",
    ph_no: "",
    email: "",
    state: "",
    country: "",
  });

  const [cartBundleData, setCartBundleData] = useState([]);
  const [cartSKUData, setCartSKUData] = useState([]);
  const [couponCode, setCouponCode] = useState("");
  const navigate = useNavigate();
  const serviceCM = new ServiceCM();
  const service = new RDService();
  const [actualAmount, setActualAmount] = useState(0);
  const [totalBillingAmount, setTotalBillingAmount] = useState(0);
  const [amountWithoutDiscount, setAmountWithOutDiscount] = useState(0);

  const [taxAmount, setTaxAmount] = useState(0);
  const [itemAmount, setItemAmount] = useState(0);
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [couponError, setCouponError] = useState("");
  const getAmountData = async () => {
    const data = await getPayableAmount(basket, couponCode);
    setItemAmount(data.itemAmount);
    setActualAmount(data.actualAmount);
    setTaxAmount(data.taxAmount);
    setTotalBillingAmount(data.totalBillingAmount);
    setCouponDiscount(data.couponDiscount);
    setAmountWithOutDiscount(data.amountWithoutDiscount);
  };

  const bundleGetter = async () => {
    try {
      const res = await serviceCM.get("/bundle");
      console.log("Duration RESPONSE:", res.data);
      // setBundleData([...res.data]);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const SKUGetter = async () => {
    try {
      const res = await serviceCM.get("/sku?type=1 X 1");
      console.log("Duration RESPONSE:", res.data);
      // setSkuData([...res.data]);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const data_manupulator_bundle = (bundle) => {
    console.log("SKU data:", bundle);
    let temp_rowsData = JSON.parse(JSON.stringify(bundle));
    temp_rowsData = temp_rowsData.map((row) => {
      delete row.is_deleted;
      delete row.skus;
      delete row.footer_desc;
      delete row.header_desc;
      return row;
    });
    console.log("temp rows:", temp_rowsData);
  };

  //FIXME: DATA MANAGEMENT
  const handleSKUDecrement = (id, index) => {
    let tempData = cartSKUData.map((item, i) => {
      if (item.id === id && index === i) {
        return {
          ...item,
          quantity: item.quantity - 1,
          total_price: item.price * (item.quantity - 1),
        };
      } else {
        return item;
      }
    });

    tempData = tempData.filter((item) => item.quantity > 0);
    setCartSKUData(tempData);

    let temp_basket = basket.map((item) => {
      if (item.id === id && item.search_tbl === "sku") {
        return {
          ...item,
          quantity: item.quantity - 1,
          total_price: item.total_price - item.total_price / item.quantity,
        };
      } else {
        return item;
      }
    });
    temp_basket = temp_basket.filter((item) => item.quantity > 0);
    dispatch({ type: "UPDATE_BASKET", basket: temp_basket });

    localStorage.setItem("temp_quantity", JSON.stringify(temp_basket));
  };

  const handleSKUIncrement = (id, index) => {
    let tempData = cartSKUData.map((item, i) => {
      if (item.id === id && index === i) {
        return {
          ...item,
          quantity: item.quantity + 1,
          total_price: item.price * (item.quantity + 1),
        };
      } else {
        return item;
      }
    });

    tempData = tempData.filter((item) => item.quantity > 0);
    setCartSKUData(tempData);

    let temp_basket = basket.map((item) => {
      if (item.id === id && item.search_tbl === "sku") {
        return {
          ...item,
          quantity: item.quantity + 1,
          total_price: item.total_price + item.total_price / item.quantity,
        };
      } else {
        return item;
      }
    });
    temp_basket = temp_basket.filter((item) => item.quantity > 0);
    dispatch({ type: "UPDATE_BASKET", basket: temp_basket });

    localStorage.setItem("temp_quantity", JSON.stringify(temp_basket));
  };

  const handleBundleDecrement = (id, index) => {
    let tempData = cartBundleData.map((item, i) => {
      if (item.id === id && index === i) {
        return {
          ...item,
          quantity: item.quantity - 1,
          total_price: item.price * (item.quantity - 1),
        };
      } else {
        return item;
      }
    });

    tempData = tempData.filter((item) => item.quantity > 0);
    setCartBundleData(tempData);

    let temp_basket = basket.map((item) => {
      if (item.id === id && item.search_tbl === "bundle") {
        return {
          ...item,
          quantity: item.quantity - 1,
          total_price: item.total_price - item.total_price / item.quantity,
        };
      } else {
        return item;
      }
    });
    temp_basket = temp_basket.filter((item) => item.quantity > 0);
    dispatch({ type: "UPDATE_BASKET", basket: temp_basket });

    localStorage.setItem("temp_quantity", JSON.stringify(temp_basket));
  };

  const handleBundleIncrement = (id, index) => {
    let tempData = cartBundleData.map((item, i) => {
      if (item.id === id && index === i) {
        return {
          ...item,
          quantity: item.quantity + 1,
          total_price: item.price * (item.quantity + 1),
        };
      } else {
        return item;
      }
    });

    tempData = tempData.filter((item) => item.quantity > 0);
    setCartBundleData(tempData);

    let temp_basket = basket.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          quantity: item.quantity + 1,
          total_price: item.total_price + item.total_price / item.quantity,
        };
      } else {
        return item;
      }
    });
    temp_basket = temp_basket.filter((item) => item.quantity > 0);
    dispatch({ type: "UPDATE_BASKET", basket: temp_basket });

    localStorage.setItem("temp_quantity", JSON.stringify(temp_basket));
  };

  const handleFormChange = (e) => {
    setFormErrors({
      first_name: "",
      last_name: "",
      ph_no: "",
      email: "",
      state: "",
      country: "",
    });
    const { name, value } = e.target;
    console.log("nameVAlue", name, value);
    if (name === "country") {
      let temp_value = { ...formInput };
      temp_value[name] = value;
      setFormInput(temp_value);

      let country = allCountry.filter((country) => country.name === value)[0];

      let states = State.getStatesOfCountry(country.isoCode);

      setAllState(states);
    } else {
      let temp_value = { ...formInput };
      temp_value[name] = value;
      setFormInput(temp_value);
    }
  };

  const validation = () => {
    let flag = true;
    if (formInput.first_name === "") {
      setFormErrors((prev) => {
        return {
          ...prev,
          first_name: "First name is Required",
        };
      });
      flag = false;
    }
    if (formInput.last_name === "") {
      setFormErrors((prev) => {
        return {
          ...prev,
          last_name: "Last name is Required",
        };
      });
      flag = false;
    }
    if (formInput.email === "") {
      setFormErrors((prev) => {
        return {
          ...prev,
          email: "Email is Required",
        };
      });
      flag = false;
    }
    if (formInput.ph_no === "" || formInput.ph_no.length !== 10) {
      setFormErrors((prev) => {
        return {
          ...prev,
          ph_no: "Phone Number is Required",
        };
      });
      flag = false;
    }
    if (formInput.state === "") {
      setFormErrors((prev) => {
        return {
          ...prev,
          state: "State is Required",
        };
      });
      flag = false;
    }
    if (formInput.country === "") {
      setFormErrors((prev) => {
        return {
          ...prev,
          country: "country is Required",
        };
      });
      flag = false;
    }

    return flag;
  };

  const handleSubmit = async () => {
    if (validation()) {
      if (formInput.user_id === "") {
        try {
          const userDetailsPost = await service.post(`/purchase/preLogin/fetchUser`, {
            email: formInput.email,
            first_name: formInput.first_name,
            last_name: formInput.last_name === "" ? null : formInput.last_name,
            ph_no: "+91" + formInput.ph_no,
            state: formInput.state,
            country: formInput.country,
            signup_platform: "CareerCarve",
            device_type: "Web",
          });
          if (userDetailsPost.status === 200) {
            const user_id = userDetailsPost.data.user_id;
            console.log("New user Id", user_id);

            let body;
            if (couponCode === "") {
              body = {
                platform: "Web",
                user_id: user_id,
                first_name: formInput.first_name,
                last_name: formInput.last_name,
                state: formInput.state,
                ph_no: formInput.ph_no,
                country: formInput.country,

                items: basket.map((item) => ({
                  item_id: item.id,
                  search_tbl: item.search_tbl,
                  quantity: item.quantity,
                  priority_no: 3,
                })),
              };
            } else {
              body = {
                platform: "Web",
                user_id: user_id,
                first_name: formInput.first_name,
                last_name: formInput.last_name,
                state: formInput.state,
                ph_no: formInput.ph_no,
                country: formInput.country,
                coupon_code: couponCode,
                items: basket.map((item) => ({
                  item_id: item.id,
                  search_tbl: item.search_tbl,
                  quantity: item.quantity,
                  priority_no: 3,
                })),
              };
            }

            const redirectPayment = await service.post("/purchase/app/paymentURL", body);

            if (redirectPayment.status === 200) {
              // window.location.replace(
              //   `https://pay.easebuzz.in/pay/${redirectPayment.data.createOrderResponse.data}`
              // );

              var easebuzzCheckout = new window.EasebuzzCheckout("3BDTHUSFEA", "prod");
              var options = {
                access_key: redirectPayment.data.createOrderResponse.data,
                onResponse: (response) => {
                  navigate("/OrderHistory", { state: { data: response, sku: [{ id: sku_id, quantity: 1, search_tbl: "sku" }], couponCode: couponCode } });
                },
                theme: "#1C8EA8",
              };
              easebuzzCheckout.initiatePayment(options);
            }

            // try {
            //   const cartOrdering = await service.post(
            //     `/purchase/preLogin/purchase`,
            //     {
            //       user_id: user_id,
            //       items: items,
            //       sub_total: parseInt(subTotal),
            //       grand_total: parseInt(GrandTotal),
            //       gst: parseInt(taxes),
            //     }
            //   );
            //   if (cartOrdering.status === 200) {
            //     navigate("/cartOrderConfirmation");
            //   }

            //   console.log(cartOrdering);
            // } catch (err) {
            //   console.log(err);
            //   console.log(err?.response.data);

            //   setErrMsg(err?.response.data.message);
            // }
          }

          console.log(userDetailsPost);
        } catch (err) {}
      } else {
        try {
          // const items = basket.map((item) => ({
          //   item_id: item.id,
          //   search_table: item.search_tbl,
          //   quantity: item.quantity,
          // }));
          // const cartOrdering = await service.post(`/purchase/preLogin/purchase`, {
          //   user_id: formInputs.user_id,
          //   items: items,
          //   sub_total: parseInt(subTotal),
          //   grand_total: parseInt(GrandTotal),
          //   gst: parseInt(taxes),
          // });
          // if (cartOrdering.status === 200) {
          //   navigate("/cartOrderConfirmation");
          // }

          let body;
          if (couponCode === "") {
            body = {
              platform: "Web",
              first_name: formInput.first_name,
              last_name: formInput.last_name,
              state: formInput.state,
              ph_no: formInput.ph_no,
              country: formInput.country,
              user_id: formInput.user_id,
              items: basket.map((item) => ({
                item_id: item.id,
                search_tbl: item.search_tbl,
                quantity: item.quantity,
                priority_no: 1,
                mentor_id: mentor_id,
              })),
            };
          } else {
            body = {
              platform: "Web",
              first_name: formInput.first_name,
              last_name: formInput.last_name,
              state: formInput.state,
              ph_no: formInput.ph_no,
              country: formInput.country,
              user_id: formInput.user_id,
              coupon_code: couponCode,
              items: basket.map((item) => ({
                item_id: item.id,
                search_tbl: item.search_tbl,
                quantity: item.quantity,
                priority_no: 1,
                mentor_id: mentor_id,
              })),
            };
          }

          const redirectPayment = await service.post("/purchase/app/paymentURL", body);

          // if (redirectPayment.status === 200) {
          //   window.location.replace(
          //     `https://pay.easebuzz.in/pay/${redirectPayment.data.createOrderResponse.data}`
          //   );
          // }

          if (redirectPayment.status === 200) {
            // window.location.replace(
            //   `https://pay.easebuzz.in/pay/${redirectPayment.data.createOrderResponse.data}`
            // );

            var easebuzzCheckout = new window.EasebuzzCheckout("3BDTHUSFEA", "prod");
            var options = {
              access_key: redirectPayment.data.createOrderResponse.data, // access key received via Initiate Payment
              onResponse: (response) => {
                // if (response.status === "success") {
                //   navigate("/OrderHistory", { state: { data: response, sku: [{ sku_id: sku_id, quantity: 1, table: "sku" }], couponCode: couponCode } });
                // } else if (response.status === "failure") {
                //   // navigate("/CartPaymentFailed", { state: { data: response, sku: sku_id, couponCode: couponCode } });
                //   navigate("/OrderHistory", { state: { data: response, sku: [{ sku_id: sku_id, quantity: 1, table: "sku" }], couponCode: couponCode } });
                // } else if (response.status === "userCancelled") {
                //   // navigate("/CartPaymentCancelled", { state: { data: response, sku: sku_id, couponCode: couponCode } });
                //   navigate("/OrderHistory", { state: { data: response, sku: [{ sku_id: sku_id, quantity: 1, table: "sku" }], couponCode: couponCode } });
                // }
                navigate("/OrderHistory", { state: { data: response, sku: [{ id: sku_id, quantity: 1, search_tbl: "sku" }], couponCode: couponCode } });
              },
              theme: "#1C8EA8", // color hex
            };
            easebuzzCheckout.initiatePayment(options);
          }
        } catch (err) {
          console.log(err);
          console.log(err?.response.data);
        }
      }

      // }
    }
  };

  useEffect(() => {
    const handleEffect = async () => {
      const headers = {
        Authorization: `Bearer ${Cookies.get("token")}`,
      };

      if (Cookies.get("token") !== undefined) {
        try {
          const data = await axios.get(`${process.env.REACT_APP_API_URL}/user/userDetail`, { headers });

          const preUser = data.data;
          setFormInput({
            user_id: [null, undefined, "null", "undefined"].includes(preUser.userDetail.user_id) ? "" : preUser.userDetail.user_id,
            first_name: [null, undefined, "null", "undefined"].includes(preUser.userDetail.first_name) ? "" : preUser.userDetail.first_name,
            last_name: [null, undefined, "null", "undefined"].includes(preUser.userDetail.last_name) ? "" : preUser.userDetail.last_name,
            ph_no: [null, undefined, "null", "undefined"].includes(preUser.userDetail.ph_no) ? "" : preUser.userDetail.ph_no,
            email: [null, undefined, "null", "undefined"].includes(preUser.userDetail.email_id) ? "" : preUser.userDetail.email_id,
            state: [null, undefined, "null", "undefined"].includes(preUser.userDetail.state) ? "" : preUser.userDetail.state,
            country: [null, undefined, "null", "undefined"].includes(preUser.userDetail.country) ? "" : preUser.userDetail.country,
          });
        } catch (error) {
          console.log("ErrorOccured", error);
        }
      }

      let sku = await SKUGetter();
      sku?.forEach((element) => {
        const storedItem = basket?.find((item) => item.id === element.id && item.search_tbl === "sku");
        if (storedItem) {
          element.quantity = storedItem.quantity;
          element.total_price = storedItem.total_price;
        }
      });

      // sku info
      let temp_cart_skus = sku?.map((testing) => ({
        id: testing.id,
        name: testing.name,
        price: testing.price,
        quantity: testing.quantity,
        total_price: testing.total_price,
        actualPrice: testing.actual_price,
      }));

      let temp_sku_cart_filtering = temp_cart_skus.filter((item, i) => item.id === sku_id);
      setCartSKUData(temp_sku_cart_filtering);
    };
    // subtotal
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    handleEffect();
  }, []);

  const handleCouponDiscount = async () => {
    const data = await getPayableAmount(basket, couponCode);
    // if (couponDiscount !== 0) {
    //   setTotalBillingAmount(data.totalBillingAmount);
    //   setTaxAmount(data.taxAmount);
    //   setItemAmount(data.itemAmount);
    //   setCouponDiscount(data.couponDiscount);
    //   setApplyCouponModal(false);
    // } else

    if (data.couponDiscount === 0) {
      setCouponError("Oops! it seems this coupon isn't valid on your order.");
      setCouponCode("");
    } else {
      setItemAmount(data.itemAmount);
      setActualAmount(data.actualAmount);
      setTaxAmount(data.taxAmount);
      setTotalBillingAmount(data.totalBillingAmount);
      setCouponDiscount(data.couponDiscount);
      setAmountWithOutDiscount(data.amountWithoutDiscount);
      setApplyCouponModal(false);
    }
  };

  const removeCoupon = async () => {
    const data = await getPayableAmount(basket, "");
    setCouponCode("");
    setItemAmount(data.itemAmount);
    setActualAmount(data.actualAmount);
    setTaxAmount(data.taxAmount);
    setTotalBillingAmount(data.totalBillingAmount);
    setCouponDiscount(data.couponDiscount);
    setAmountWithOutDiscount(data.amountWithoutDiscount);
  };

  useEffect(() => {
    getAmountData();
  }, [cartSKUData]);

  return (
    <Box
      padding={{
        padding: Mobile ? "20px 15px" : "5rem 3rem",
        marginTop: webinarTickerExists && Mobile ? "80px" : !webinarTickerExists && Mobile ? "32px" : webinarTickerExists ? "80px" : "30px",
        width: Mobile ? "" : "max(1000px, 100%)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h2" sx={{ fontSize: "34px" }}>
          Checkout
        </Typography>
        <Box
          sx={{
            width: "80px",
            height: "2px",
            backgroundColor: "#1C8EA8",
          }}
        />
      </Box>

      <Box
        display={{
          display: "flex",
          flexDirection: Mobile ? "column" : "row",
          gap: Mobile ? "0.5rem" : "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
            width: "100%",
            padding: "20px",
            marginBlock: Mobile ? "1rem" : "3rem",
            border: "1px solid #E6E6E6",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "2px",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <Typography variant="h4" sx={{ fontSize: "24px" }}>
              Billing Details
            </Typography>

            <Box
              sx={{
                width: "80px",
                height: "2px",
                backgroundColor: "#1C8EA8",
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: Mobile ? "column" : "row",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <TextField value={formInput.first_name} error={formError.first_name !== "" ? true : false} helperText={formError.first_name} label="First Name" name="first_name" onChange={(e) => handleFormChange(e)} fullWidth sx={{ marginBlock: "10px" }} />
            <TextField value={formInput.last_name} error={formError.last_name !== "" ? true : false} helperText={formError.last_name} label="Last Name" onChange={(e) => handleFormChange(e)} name="last_name" fullWidth sx={{ marginBlock: "10px" }} />
          </Box>
          <TextField type="phone" value={formInput.ph_no} error={formError.ph_no !== "" ? true : false} helperText={formError.ph_no} label="Phone Number" onChange={(e) => handleFormChange(e)} name="ph_no" fullWidth sx={{ marginBlock: "10px" }} />
          <TextField value={formInput.email} error={formError.email !== "" ? true : false} helperText={formError.email} disabled={formInput.user_id === "" ? false : true} label="Email" onChange={(e) => handleFormChange(e)} name="email" fullWidth sx={{ marginBlock: "10px" }} />
          {/* <TextField
            value={formInput.country}
            error={formError.country !== "" ? true : false}
            helperText={formError.country}
            label="Country"
            onChange={(e) => handleFormChange(e)}
            fullWidth
            name="country"
            sx={{ marginBlock: "10px" }}
          /> */}
          <FormControl>
            <InputLabel id="country">Country</InputLabel>
            <Select labelId="country" name="country" error={formError.country !== "" ? true : false} value={formInput.country} label="Country" onChange={(e) => handleFormChange(e)}>
              {allCountry.map((country) => (
                <MenuItem value={country.name}>{country.name}</MenuItem>
              ))}
            </Select>
            {formError.country !== "" && <p style={{ fontSize: "12px", color: "red" }}>{formError.country}</p>}
          </FormControl>
          <FormControl sx={{ marginBlock: "10px" }}>
            <InputLabel id="state">State</InputLabel>
            <Select name="state" value={formInput.state} error={formError.state !== "" ? true : false} labelId="state" label="State" onChange={(e) => handleFormChange(e)}>
              {allState.map((state) => (
                <MenuItem value={state.name}>{state.name}</MenuItem>
              ))}
            </Select>
            <p style={{ fontSize: "12px", color: "red" }}>{formError.state}</p>
          </FormControl>
          {/* <TextField
            value={formInput.state}
            error={formError.state !== "" ? true : false}
            helperText={formError.state}
            label="State"
            onChange={(e) => handleFormChange(e)}
            name="state"
            fullWidth
            sx={{ marginBlock: "10px" }}
          /> */}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
            width: "100%",
            padding: "20px",
            marginBlock: "3rem",
            border: "1px solid #E6E6E6",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "2px",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <Typography variant="h4" sx={{ fontSize: "24px" }}>
              Your Shopping Bag
            </Typography>
            <Box
              sx={{
                width: "80px",
                height: "2px",
                backgroundColor: "#1C8EA8",
              }}
            />
          </Box>

          {Mobile ? (
            <>
              <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                {cartBundleData.length > 0 &&
                  cartBundleData.map((item, index) => (
                    <Box sx={{ borderBottom: "1px solid #E6E6E6" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: Mobile ? "16px" : "20px",
                            width: "70%",
                          }}
                        >
                          {item.name}
                        </Typography>

                        <Typography
                          sx={{
                            fontSize: Mobile ? "16px" : "20px",
                            textAlign: "end",
                            width: "30%",
                          }}
                        >
                          &#x20b9;{item.actualPrice}
                        </Typography>
                      </Box>
                      <Box sx={{ marginBlock: "10px" }}>
                        {/* <Grid
                          sx={{ border: "none" }}
                          container
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Grid sx={{ border: "none" }} item>
                            <ButtonGroup
                              className="pricingSingleACtivityOrder"
                              style={{ marginLeft: "0px" }}
                              size="small"
                              sx={{ borderRadius: 11 }}
                              aria-label="small outlined button group"
                            >
                              <Button
                                className="buttonGroupColor"
                                sx={{
                                  borderRadius: "11px 0 0 11px",
                                  backgroundColor: "#2AA8C4",
                                  fontSize: "14px",
                                  color: "white",

                                  "&:hover": {
                                    backgroundColor: "#2AA8C4",
                                  },
                                }}
                                onClick={() =>
                                  handleBundleDecrement(item.id, index)
                                }
                              >
                                -
                              </Button>

                              <Button
                                disabled
                                sx={{
                                  fontSize: "14px",
                                  border: "1px solid #2AA8C4 !important",
                                }}
                              >
                                {item.quantity}
                              </Button>

                              <Button
                                className="buttonGroupColor"
                                sx={{
                                  borderRadius: "0 11px 11px 0",
                                  backgroundColor: "#2AA8C4",
                                  fontSize: "14px",
                                  color: "white",
                                  "&:hover": {
                                    backgroundColor: "#2AA8C4",
                                  },
                                }}
                                onClick={() =>
                                  handleBundleIncrement(item.id, index)
                                }
                              >
                                +
                              </Button>
                            </ButtonGroup>
                          </Grid>
                        </Grid> */}
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Box
                            sx={{
                              background: "#2AA8C4",
                              color: "#fff",
                              padding: "5px 20px",
                              borderRadius: "11px 0px 0px 11px",
                              borderBlock: "1px solid #2AA8C4",
                            }}
                            onClick={() => handleBundleDecrement(item.id, index)}
                          >
                            -
                          </Box>
                          <Box
                            sx={{
                              // background: "#2AA8C4",
                              // color: "#fff",
                              padding: "5px 15px",
                              borderBlock: "1px solid #2AA8C4",
                            }}
                          >
                            {item.quantity}
                          </Box>
                          <Box
                            sx={{
                              background: "#2AA8C4",
                              color: "#fff",
                              padding: "5px 20px",
                              borderRadius: "0px 11px 11px 0px",
                              borderBlock: "1px solid #2AA8C4",
                            }}
                            onClick={() => handleBundleIncrement(item.id, index)}
                          >
                            +
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ))}
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                {cartSKUData.length > 0 &&
                  cartSKUData.map((item, index) => (
                    <Box sx={{ borderBottom: "1px solid #E6E6E6" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              fontSize: Mobile ? "16px" : "20px",
                            }}
                          >
                            {item.name}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: Mobile ? "14px" : "20px",
                              color: "#4a4a4a",
                            }}
                          >
                            with {mentorName}
                          </Typography>
                        </Box>

                        <Typography
                          sx={{
                            fontSize: Mobile ? "16px" : "20px",

                            textAlign: "end",
                          }}
                        >
                          &#x20b9;{item.actualPrice * item.quantity}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
              </Box>
              <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "5px" }}>
                <SellOutlinedIcon sx={{ color: "#1C8EA8", fontSize: "20px" }} />
                <Typography sx={{ color: "#1C8EA8", "&:hover": { cursor: "pointer" } }} onClick={() => setApplyCouponModal(true)}>
                  Add Coupon
                </Typography>
              </Box>

              {/* Billing Details */}
              <Box sx={{ marginTop: "10px", marginLeft: "20%" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ fontSize: "16px" }}>Total value</Typography>
                  <Typography sx={{ fontSize: "16px" }}>&#x20b9;{actualAmount}</Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    height: "1px",
                    background: "#E6E6E6",
                    marginBlock: "10px",
                  }}
                />

                {actualAmount - amountWithoutDiscount !== 0 && (
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ fontSize: "16px" }}>Discount</Typography>
                      <Typography sx={{ fontSize: "16px", color: "#08AB3F" }}>- &#x20b9;{actualAmount - amountWithoutDiscount}</Typography>
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        height: "1px",
                        background: "#E6E6E6",
                        marginBlock: "10px",
                      }}
                    />
                  </Box>
                )}

                {couponDiscount > 0 && (
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ fontSize: "16px" }}>Coupon Discount</Typography>
                      <Typography sx={{ fontSize: "16px", color: "#08AB3F" }}>- &#x20b9;{couponDiscount}</Typography>
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        height: "1px",
                        background: "#E6E6E6",
                        marginBlock: "10px",
                      }}
                    />
                  </Box>
                )}

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ fontSize: "16px" }}>Subtotal</Typography>
                  <Typography sx={{ fontSize: "16px" }}>&#x20b9;{itemAmount}</Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    height: "1px",
                    background: "#E6E6E6",
                    marginBlock: "10px",
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ fontSize: "16px" }}>GST(18%)</Typography>
                  <Typography sx={{ fontSize: "16px" }}>&#x20b9;{taxAmount}</Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  height: "2px",
                  background: "#E6E6E6",
                  marginBlock: "10px",
                }}
              />

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>To Pay</Typography>
                <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>&#x20b9;{totalBillingAmount}</Typography>
              </Box>

              <button
                type="button"
                className="Rephrasebutton"
                style={{
                  border: "none",
                  textAlign: "center",
                  marginBottom: "1.5rem",
                  marginTop: "1.5rem",
                  width: "100%",
                }}
                onClick={(e) => handleSubmit(e)}
              >
                Check out
              </button>
            </>
          ) : (
            <>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  sx={{
                    fontSize: Mobile ? "14px" : "16px",
                    color: "#545454",
                  }}
                >
                  Item
                </Typography>

                <Typography
                  sx={{
                    fontSize: Mobile ? "14px" : "16px",
                    color: "#545454",

                    textAlign: "center",
                  }}
                >
                  Total
                </Typography>
              </Box>

              {cartBundleData.length > 0 && (
                <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                  {cartBundleData.map((item, index) => (
                    <Box
                      sx={{
                        display: "flex",
                        paddingBlock: "15px",
                        alignItems: "center",
                        borderBottom: "1px solid #E6E6E6",
                      }}
                    >
                      {console.log("CARTITESM", item)}
                      <Typography
                        sx={{
                          fontSize: Mobile ? "16px" : "20px",

                          width: "30%",
                        }}
                      >
                        {item.name}
                      </Typography>

                      {/* <Box
                        sx={{
                          width: "30%",
                        }}
                      >
                        <ButtonGroup
                          className="pricingSingleACtivityOrder"
                          size="small"
                          sx={{
                            borderRadius: 11,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          aria-label="small outlined button group"
                        >
                          <Button
                            className="buttonGroupColor"
                            sx={{
                              borderRadius: "11px 0 0 11px",
                              backgroundColor: "#2AA8C4",
                              fontSize: "18px",
                              color: "white",
                              "&:hover": {
                                backgroundColor: "#2AA8C4",
                              },
                            }}
                            onClick={() =>
                              handleBundleDecrement(item.id, index)
                            }
                          >
                            -
                          </Button>

                          <Button
                            disabled
                            sx={{
                              border: "1px solid #2AA8C4 !important",
                            }}
                          >
                            {item.quantity}
                          </Button>

                          <Button
                            className="buttonGroupColor"
                            sx={{
                              borderRadius: "0 11px 11px 0",
                              backgroundColor: "#2AA8C4",
                              fontSize: "18px",
                              color: "white",
                              "&:hover": {
                                backgroundColor: "#2AA8C4",
                              },
                            }}
                            onClick={() =>
                              handleBundleIncrement(item.id, index)
                            }
                          >
                            +
                          </Button>
                        </ButtonGroup>
                      </Box> */}

                      {/* <Box
                        sx={{
                          display: "flex",
                          width: "30%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          sx={{
                            background: "#2AA8C4",
                            color: "#fff",
                            padding: "5px 20px",
                            borderRadius: "11px 0px 0px 11px",
                            borderBlock: "1px solid #2AA8C4",
                            "&:hover": {
                              cursor: "pointer",
                            },
                          }}
                          onClick={() => handleBundleDecrement(item.id, index)}
                        >
                          -
                        </Box>
                        <Box
                          sx={{
                            // background: "#2AA8C4",
                            // color: "#fff",
                            padding: "5px 15px",
                            borderBlock: "1px solid #2AA8C4",
                          }}
                        >
                          {item.quantity}
                        </Box>
                        <Box
                          sx={{
                            background: "#2AA8C4",
                            color: "#fff",
                            padding: "5px 20px",
                            borderRadius: "0px 11px 11px 0px",
                            borderBlock: "1px solid #2AA8C4",
                            "&:hover": {
                              cursor: "pointer",
                            },
                          }}
                          onClick={() => handleBundleIncrement(item.id, index)}
                        >
                          +
                        </Box>
                      </Box> */}

                      <Typography
                        sx={{
                          fontSize: Mobile ? "16px" : "20px",
                          textAlign: "center",
                          width: "20%",
                        }}
                      >
                        &#x20b9;{item.actualPrice * item.quantity}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              )}

              {cartSKUData.length > 0 && (
                <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                  {cartSKUData.map((item, index) => (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        paddingBlock: "15px",
                        borderBottom: "1px solid #E6E6E6",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{}}>
                        <Typography
                          sx={{
                            fontSize: Mobile ? "16px" : "20px",
                          }}
                        >
                          {item.name}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: Mobile ? "14px" : "16px",
                            color: "#4a4a4a",
                          }}
                        >
                          with {mentorName}
                        </Typography>
                      </Box>

                      <Typography
                        sx={{
                          fontSize: Mobile ? "16px" : "20px",
                          textAlign: "center",
                        }}
                      >
                        &#x20b9;{item.actualPrice * item.quantity}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              )}

              <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "5px" }}>
                <SellOutlinedIcon sx={{ color: "#1C8EA8", fontSize: "20px" }} />
                <Typography sx={{ color: "#1C8EA8", "&:hover": { cursor: "pointer" } }} onClick={() => setApplyCouponModal(true)}>
                  Add Coupon
                </Typography>
              </Box>

              {/* BILLING DETAILS */}
              <Box sx={{ marginLeft: "40%" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ fontSize: "16px" }}>Total value</Typography>
                  <Typography sx={{ fontSize: "16px" }}>&#x20b9;{actualAmount}</Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    height: "1px",
                    background: "#E6E6E6",
                    marginBlock: "10px",
                  }}
                />

                {actualAmount - amountWithoutDiscount !== 0 && (
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ fontSize: "16px" }}>Discount</Typography>
                      <Typography sx={{ fontSize: "16px", color: "#08AB3F" }}>- &#x20b9;{actualAmount - amountWithoutDiscount}</Typography>
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        height: "1px",
                        background: "#E6E6E6",
                        marginBlock: "10px",
                      }}
                    />
                  </Box>
                )}

                {couponDiscount > 0 && (
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ fontSize: "16px" }}>Coupon Discount</Typography>
                      <Typography sx={{ fontSize: "16px", color: "#08AB3F" }}>- &#x20b9;{couponDiscount}</Typography>
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        height: "1px",
                        background: "#E6E6E6",
                        marginBlock: "10px",
                      }}
                    />
                  </Box>
                )}

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ fontSize: "16px" }}>Subtotal</Typography>
                  <Typography sx={{ fontSize: "16px" }}>&#x20b9;{itemAmount}</Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    height: "1px",
                    background: "#E6E6E6",
                    marginBlock: "10px",
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ fontSize: "16px" }}>GST(18%)</Typography>
                  <Typography sx={{ fontSize: "16px" }}>&#x20b9;{taxAmount}</Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  height: "2px",
                  background: "#E6E6E6",
                }}
              />

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingLeft: "20px",
                }}
              >
                <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>To Pay</Typography>
                <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>&#x20b9;{totalBillingAmount}</Typography>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <button
                  type="button"
                  className="Rephrasebutton"
                  id="ebz-checkout-btn"
                  style={{
                    border: "none",
                    textAlign: "center",
                    // marginBottom: "1.5rem",
                    // marginTop: ".5rem",
                    marginTop: "1.5rem",

                    width: "400px",
                    // float: "right",
                  }}
                  onClick={(e) => handleSubmit(e)}
                >
                  Check out
                </button>
              </Box>

              <Box>
                <div
                  style={{
                    marginTop: "1.5rem",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  By placing an order, you agree to our
                  <button className="signup-button" onClick={() => navigate("/TermsOfUse")} style={{ marginLeft: 0 }}>
                    Terms of use
                  </button>
                  and
                  <button onClick={() => navigate("/PrivacyPolicy")} style={{ marginLeft: 0 }} className="signup-button">
                    Privacy Policy
                  </button>
                </div>
                <div
                  style={{
                    marginTop: ".5rem",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  View our cancellation and refund policy
                  <button onClick={() => navigate("/CancellationAndRefundpolicy")} className="signup-button" style={{ marginLeft: 0 }}>
                    here
                  </button>
                </div>
              </Box>
            </>
          )}
        </Box>
      </Box>
      <Modal
        sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        open={applyCouponModal}
        onClose={() => {
          setApplyCouponModal(false);
          setCouponError("");
        }}
      >
        <Box sx={{ background: "white", height: "fit-content", width: "300px", borderRadius: "20px", padding: "20px", display: "flex", flexDirection: "column", gap: "20px", alignItems: "center" }}>
          <SellOutlinedIcon sx={{ color: "#1C8EA8", fontSize: "100px" }} />
          <Typography sx={{ fontWeight: "600", textAlign: "center" }}>Get maximum discount on your Orders!!!</Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <TextField
              sx={{ width: "100%" }}
              value={couponCode}
              onChange={(e) => {
                setCouponCode(e.target.value);
                setCouponError("");
              }}
              placeholder="Add your coupon here..."
              inputProps={{ style: { height: "10px" } }}
              InputProps={{
                endAdornment: couponDiscount !== 0 && (
                  <IconButton onClick={() => removeCoupon()}>
                    <Delete sx={{ color: "#b90e0a" }} />
                  </IconButton>
                ),
              }}
            />
            {couponError !== "" && <Typography sx={{ textAlign: "center", fontSize: "14px", color: "red" }}>{couponError}</Typography>}

            <Button
              sx={{ textTransform: "none", border: "1px solid #1C8EA8", color: "#1C8EA8", padding: "10px", width: "100%", "&:hover": { color: "#1C8EA8", background: "rgba(28, 142, 168, 0.2)" } }}
              onClick={() => {
                setApplyCouponModal(false);
                setCouponError("");
              }}
            >
              Cancel
            </Button>
            <Button sx={{ textTransform: "none", background: "#1C8EA8", color: "#fff", padding: "10px", width: "100%", "&:hover": { background: "#1C8EA8", color: "#fff" } }} onClick={() => handleCouponDiscount()}>
              Apply
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default PriorityBooking;
