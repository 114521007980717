import React, { useState, useEffect } from 'react';
import WebinarBanner from './WebinarBanner';
import { Helmet } from 'react-helmet-async';
import { Box, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import ExpertPanel from './ExpertPanel';
import WebinarCompleteDetails from './WebinarCompleteDetails';
import { useLocation, useSearchParams } from 'react-router-dom';
import RDService from '../../services/RDService';
import WebinarRegisterNow from './WebinarRegisterNow';
import { useNavigate } from "react-router-dom";
import { useWebinarContext } from '../../WebinarContext';
const WebinarDetails = () => {
    const Mobile = useMediaQuery('(max-width:800px)');
    const { webinarTickerExists, setWebinarTickerExists } = useWebinarContext();
    const location = useLocation();
    const service = new RDService()
    const [searchParams, setSearchParams] = useSearchParams();
    let event_id = location.state?.event_id || searchParams.get("event_id")
    let mode = searchParams.get("mode")
    console.log("mode of mode", mode);
    const [webinarDetails, setWebinarDetails] = useState([])
    const [loading, setLoading] = useState({ details_loader: false });
    const [registered, setRegistered] = useState(false);
    const [eventRegistered, setEventRegistered] = useState(false);
    const navigate = useNavigate();

    const upcomingListGetter = async () => {
        setLoading((prev) => ({ ...prev, details_loader: true }))
        try {
            const res = await service.get(`/user/event/event?event_id=${event_id}`)
            console.log("getting applicants data response:", res.data)
            let temp_change_short_list = res.data
            setLoading((prev) => ({ ...prev, details_loader: false }));
            return temp_change_short_list[0];
        } catch (err) {
            console.log(err);
            setLoading((prev) => ({ ...prev, details_loader: false }));
        }
    }

    useEffect(() => {
        const registeredEvents = JSON.parse(localStorage.getItem('registeredEvents')) || [];
        setRegistered(registeredEvents.includes(parseInt(event_id)));
    }, [event_id]);

    useEffect(() => {
        console.log("Brother")
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        const handleEffect = async () => {

            const upcomingData = await upcomingListGetter();
            console.log("batch data", upcomingData);
            setWebinarDetails(upcomingData)
            console.log("odonil", { upcomingData })
            setLoading((prev) => ({ ...prev, details_loader: false }))
        }
        handleEffect();
    }, [])

    const handleWebinarEventsClick = () => {
        navigate("/WebinarEvents");
    }


    return (
        <>
            <Helmet>
                <title>Events | Invest in your Career | CareerCarve</title>
                <meta name="description" content="Explore our competitive pricing options for personalized placement training at CareerCarve. Invest in your professional goals to achieve career success." />
                <meta property="og:image" content="/public/logo_welcome.png" />
                <link rel="canonical" href="/ExpertConnect" />
                <script src="https://ebz-static.s3.ap-south-1.amazonaws.com/easecheckout/easebuzz-checkout.js"></script>
            </Helmet>
            <Box className="login-Container" style={{ marginTop:webinarTickerExists && Mobile ? "80px" : !webinarTickerExists && Mobile ? "50px" : webinarTickerExists ? "112px" : "56px" , width: '100%', gap:Mobile ? "1rem" : "2rem", display: "flex", flexDirection: "column" }}>
                <WebinarBanner event_id={event_id} webinarDetails={webinarDetails} loading={loading} registered={registered} eventRegistered={eventRegistered} setRegistered={setRegistered} setEventRegistered={setEventRegistered}/>
                {Object.keys(webinarDetails).length > 0 && <WebinarCompleteDetails handleWebinarEventsClick={handleWebinarEventsClick} event_id={event_id} webinarDetails={webinarDetails} mode={mode} loading={loading} registered={registered} eventRegistered={eventRegistered} setRegistered={setRegistered} setEventRegistered={setEventRegistered}/>}
                <Box sx={{ width: Mobile ? "100%" : "80%", margin: "0 auto", paddingRight: Mobile ? "1rem" : 0, paddingLeft: Mobile ? "1rem" : 0 }}>
                    <Typography variant="h5" sx={{ marginBottom: "1rem" }} fontSize={Mobile ? 14 : 24} fontWeight={Mobile ? "bold" : 500}>{webinarDetails?.member_heading}</Typography>
                    {Object.keys(webinarDetails).length > 0 && <ExpertPanel event_id={event_id} webinarDetails={webinarDetails} loading={loading} registered={registered} eventRegistered={eventRegistered} setRegistered={setRegistered} setEventRegistered={setEventRegistered}/>}
                </Box>
                {Mobile && !registered && (mode !== "past") && <Box sx={{ width: Mobile ? "100%" : '40%' }}>
                    <WebinarRegisterNow isMobile={true} event_id={event_id} mode={mode} webinarDetails={webinarDetails} handleWebinarEventsClick={handleWebinarEventsClick} registered={registered} eventRegistered={eventRegistered} setRegistered={setRegistered} setEventRegistered={setEventRegistered}/>
                </Box>}
            </Box>
        </>
    );
};

export default WebinarDetails;
