import React, { useEffect, useState } from "react";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import Carousel from "react-material-ui-carousel";

function AssessmentTests() {
  const [selectedTest, setSelectedTest] = useState(0);
  const Mobile = useMediaQuery("(max-width:800px)");

  const items = [
    {
      title: "Psychometric Tests",
      description: "Helps you gain self-awareness and understand your strengths, weaknesses, and personality traits, enabling you to make informed career choices and present yourself confidently during placements.",
      icon: selectedTest === 0 ? "https://du03hetco8xdw.cloudfront.net/cc_com_landing/critical-thinking-black.svg" : "https://du03hetco8xdw.cloudfront.net/cc_com_landing/critical-thinking 1.svg",
    },
    {
      title: "Logical Ability",
      description: "Gauges your' logical reasoning and pattern recognition skills, equipping you to tackle complex business problems and demonstrate your ability to think logically and analytically in high-pressure placement scenarios.",
      icon: selectedTest === 1 ? "https://du03hetco8xdw.cloudfront.net/cc_com_landing/strategicPlan-black.svg" : "https://du03hetco8xdw.cloudfront.net/cc_com_landing/strategicPlan.svg",
    },
    {
      title: "Role Preparedness",
      description: "Evaluates your knowledge, skills, and readiness for specific job roles, helping you to identify areas of improvement and bridge any gaps in your preparation, thereby enhancing your competitiveness during placements.",
      icon: selectedTest === 2 ? "https://du03hetco8xdw.cloudfront.net/cc_com_landing/admin-black.svg" : "https://du03hetco8xdw.cloudfront.net/cc_com_landing/admin.svg",
    },
    {
      title: "Aptitude Tests",
      description: "Assesses your cognitive abilities, problem-solving skills, and critical thinking, enabling you to showcase your analytical and quantitative capabilities to potential employers, increasing your chances of securing desirable placements.",
      icon: selectedTest === 3 ? "https://du03hetco8xdw.cloudfront.net/cc_com_landing/adaptation-black.svg" : "https://du03hetco8xdw.cloudfront.net/cc_com_landing/adaptation.svg",
    },
    {
      title: "Analytical Ability",
      description: "Assesses your data interpretation and analytical skills, enabling you to showcase your ability to analyze complex information, draw meaningful insights, and make data-driven decisions, positioning them as strong candidates during placements.",
      icon: selectedTest === 4 ? "https://du03hetco8xdw.cloudfront.net/cc_com_landing/creative-black.svg" : "https://du03hetco8xdw.cloudfront.net/cc_com_landing/creative.svg",
    },
  ];

  const MobileList = [
    {
      title: "Psychometric Tests",
      description: "Helps you gain self-awareness and understand your strengths, weaknesses, and personality traits, enabling you to make informed career choices and present yourself confidently during placements.",
      icon: "https://du03hetco8xdw.cloudfront.net/cc_com_landing/critical-thinking-black.svg",
    },
    {
      title: "Logical Ability",
      description: "Gauges your' logical reasoning and pattern recognition skills, equipping you to tackle complex business problems and demonstrate your ability to think logically and analytically in high-pressure placement scenarios.",
      icon: "https://du03hetco8xdw.cloudfront.net/cc_com_landing/strategicPlan-black.svg",
    },
    {
      title: "Role Preparedness",
      description: "Evaluates your knowledge, skills, and readiness for specific job roles, helping you to identify areas of improvement and bridge any gaps in your preparation, thereby enhancing your competitiveness during placements.",
      icon: "https://du03hetco8xdw.cloudfront.net/cc_com_landing/admin-black.svg",
    },
    {
      title: "Aptitude Tests",
      description: "Assesses your cognitive abilities, problem-solving skills, and critical thinking, enabling you to showcase your analytical and quantitative capabilities to potential employers, increasing your chances of securing desirable placements.",
      icon: "https://du03hetco8xdw.cloudfront.net/cc_com_landing/adaptation-black.svg",
    },
    {
      title: "Analytical Ability",
      description: "Assesses your data interpretation and analytical skills, enabling you to showcase your ability to analyze complex information, draw meaningful insights, and make data-driven decisions, positioning them as strong candidates during placements.",
      icon: "https://du03hetco8xdw.cloudfront.net/cc_com_landing/creative-black.svg",
    },
  ];
  const changeTest = () => {
    if (selectedTest == 4) {
      setSelectedTest(0);
    } else {
      setSelectedTest((prev) => prev + 1);
    }
  };
  useEffect(() => {
    if (!Mobile) {
      const timer = setInterval(() => {
        setTimeout(() => {
          setSelectedTest((prevIndex) => (prevIndex + 1) % items.length);
        });
      }, 3000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [selectedTest]);

  const RenderCrouselItem = ({ item, index }) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",

          height: "350px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            background: "linear-gradient(135deg, #F5A536 0%, #FFC87A 100%)",
            width: "148px",
            height: "148px",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="assessmentTest__icon"
        >
          <img src={item.icon} alt={item.title} />
        </Box>
        <Typography variant="h5" sx={{ fontSize: "20px" }}>
          {item.title}
        </Typography>
        <Box
          sx={{
            background: index % 2 === 0 ? "linear-gradient(0deg, rgba(245, 165, 54, 0.20) 0%, rgba(245, 165, 54, 0.20) 100%), #FFF" : "linear-gradient(0deg, rgba(56, 193, 223, 0.20) 0%, rgba(56, 193, 223, 0.20) 100%)",
            padding: "1rem",
            boxShadow: " 0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
            borderRadius: "10px",
            marginInline: "25px",
          }}
        >
          <Typography sx={{ fontSize: "12px", textAlign: "justify" }}>{item.description}</Typography>
        </Box>
      </Box>
    );
  };
  return (
    <Box>
      {Mobile ? (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              marginInline: "25px",
              alignItems: "center",
            }}
          >
            <Typography variant={Mobile ? "h4" : "h2"} sx={{ fontSize: Mobile ? "24px" : "45px", lineHeight: "36px", textAlign: "center" }}>
              Assessment Tests
            </Typography>
            <Box
              sx={{
                width: "60px",
                height: "2px",
                backgroundColor: "#000",
                marginTop: "-10px",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            <Carousel
              animation="slide"
              sx={{ width: "100%", height: "100%", alignItems: "center" }}
              navButtonsAlwaysVisible
              interval={3000}
              duration={1000}
              cycleNavigation={true}
              fullHeightHover={false}
              navButtonsProps={{
                style: {
                  backgroundColor: "#8B8B8B",
                  height: "43px",
                },
              }}
              navButtonsWrapperProps={{
                style: {
                  alignContent: "center",
                },
              }}
              activeIndicatorIconButtonProps={{
                style: {
                  color: "#1C8EA8",
                },
              }}
            >
              {MobileList.map((item, index) => (
                <RenderCrouselItem key={index} item={item} index={index} />
              ))}
            </Carousel>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <a href="https://www.careercarve.in" target="_blank" title="careercarve-mobile-app" rel="noreferrer">
              <button className="button-yellow-hover" style={{ width: "fit-content" }} href="https://www.careercarve.in" target="_blank">
                <Typography>Signup to give test</Typography>
              </button>
            </a>
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "3.5rem" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              // alignItems: "center",
              gap: "1rem",
            }}
          >
            <Typography variant="h2" sx={{ fontSize: "45px", lineHeight: "52px" }}>
              Assessment Tests
            </Typography>
            <Box sx={{ width: "131px", height: "1px", backgroundColor: "#000" }} />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            {items.map((item, index) => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                onClick={() => setSelectedTest(index)}
              >
                <Box
                  sx={{
                    background: selectedTest === index ? "linear-gradient(135deg, #F5A536 0%, #FFC87A 100%)" : "#fff",
                    width: "148px",
                    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.08)",
                    height: "148px",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                  className="assessmentTest__icon"
                >
                  <img src={item.icon} alt={item.title} />
                </Box>
                <Typography>{item.title}</Typography>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              background: selectedTest % 2 === 1 ? "linear-gradient(0deg, rgba(245, 165, 54, 0.20) 0%, rgba(245, 165, 54, 0.20) 100%), #FFF" : "linear-gradient(0deg, rgba(56, 193, 223, 0.20) 0%, rgba(56, 193, 223, 0.20) 100%)",
              padding: "1rem",
              boxShadow: " 0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
              borderRadius: "10px",
            }}
          >
            <Typography sx={{ fontSize: "16px", textAlign: "center" }}>{items[selectedTest].description}</Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <a href="https://www.careercarve.in" target="_blank" title="careercarve-mobile-app" rel="noreferrer">
              <button className="button-yellow-hover" style={{ width: "fit-content" }}>
                <Typography>Signup to give test</Typography>
              </button>
            </a>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default AssessmentTests;
