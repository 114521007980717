import { Box, Button, Grid, TextField, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";

import { Helmet } from "react-helmet-async";
import { useWebinarContext } from "../../WebinarContext";
import BottomNavbar from "../LandingPage/Footer/BottomNavbar";
import { color } from "framer-motion";
import axios from "axios";
import { Search } from "@mui/icons-material";
import ArrowOutwardOutlinedIcon from "@mui/icons-material/ArrowOutwardOutlined";
import MentorCard from "./MentorCard";
import { useNavigate } from "react-router-dom";
import RDService from "../../services/RDService";
import HorizontalScroll from "./HorizontalScroll";
import { useStateValue } from "../../components/StateProvider/stateProvider";

function Mentor() {
  const Mobile = useMediaQuery("(max-width:800px)");
  const [{ user_id }, dispatch] = useStateValue();

  const navigate = useNavigate();
  const service = new RDService();
  const companyLogos = ["https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/ericsson.jpg", "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/accenture.jpg", "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/aditya+birla.jpg", "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/american+express.jpg", "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/amazon.jpg", "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/atlassian.jpg", "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/bosch.jpg", "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/capgemini.jpg", "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/disney.jpg", "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/essar.jpg", "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/infosys.jpg", "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/flipkart.jpg", "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/morgan+stanley.jpg"];
  const { webinarTickerExists } = useWebinarContext();
  const collegeLogo = [
    "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/imt+Ghaziabad.jpg",
    "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/iim+ahmedabad.jpg",
    "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/iim+B.jpg",
    "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/iim+kolkata.jpg",
    "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/iim+lko.jpg",
    "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/iim+kozhikode.jpg",
    "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/fms.jpg",
    "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/iim+indore.jpg",
    // "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/xlri.jpg",
    "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/xlri.jpg",
    // "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/mdi+gurgaon.jpg",
    // "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/mdi-Gurgaon.jpg",
    "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/mdi_Gurgaon.jpg",
  ];
  const [allMentors, setAllMentors] = useState([]);
  const getMentors = async () => {
    const allmentors = await service.get("/superAdmin/randomFourMentors");
    let temp = allmentors.mentors;

    setAllMentors(temp);
  };
  useEffect(() => {
    getMentors();
  }, [user_id]);

  return (
    <>
      {/* this provides the meta description of each page separately */}
      {/* <Helmet>
        <title>CareerCarve Mentors: Your Guide to Successful Placements</title>
        <meta name="description" content="Discover the experienced mentors behind CareerCarve's success. Learn who are our mentors that guide you towards a brighter future." />
        <meta property="og:title" content="CareerCarve Mentors: Your Guide to Successful Placements" />
        <meta property="og:url" content="https://www.careercarve.com/Mentors" />
        <meta property="og:image" content="logo_welcome.png" />
        <link rel="canonical" href="/Mentors" />
      </Helmet> */}

      <Helmet>
        <title>CareerCarve Mentors: Your Guide to Successful Placements</title>
        <meta name="description" content="Discover the experienced mentors behind CareerCarve's success. Learn who are our mentors that guide you towards a brighter future." />
        <meta name="og:description" content="Discover the experienced mentors behind CareerCarve's success. Learn who are our mentors that guide you towards a brighter future." />
        <meta property="og:title" content="CareerCarve Mentors: Your Guide to Successful Placements" />
        <meta property="og:url" content={`${process.env.REACT_APP_HOST}/Mentors`} />
        <meta property="og:image" content="logo_welcome.png" />
        <link rel="canonical" href={`${process.env.REACT_APP_HOST}/Mentors`} />
      </Helmet>
      <Box sx={{ paddingBottom: "25px", marginTop: webinarTickerExists && Mobile ? "80px" : !webinarTickerExists && Mobile ? "50px" : webinarTickerExists ? "112px" : "70px" }}>
        <Box sx={{ marginBottom: Mobile ? "1.5rem" : "4.5rem" }}>
          <Box>
            <img
              // src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/MentorAbout.png"
              src="https://ccicons.s3.amazonaws.com/landing_page/careercarve-mentors.webp"
              alt="CareerCarve Mentors: Expert Guidance For MBA Placements"
              style={{
                height: Mobile ? "15vh" : "250px",
                width: "100%",
                marginBottom: "-2%",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h1"
              fontSize={Mobile ? "24px" : "36px"}
              sx={{
                zIndex: "20",
                bgcolor: "#FFF",
                fontWeight: "400",
                padding: "8px 46px",
                boxShadow: "0px 6px 49px 0px rgba(0, 0, 0, 0.08)",
              }}
            >
              About Mentors
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: Mobile ? "1.5rem" : "10vh",
          }}
        >
          <Typography
            fontSize={Mobile ? "14px" : "16px"}
            variant={Mobile ? "body2" : "body1"}
            color={"#545454"}
            sx={{
              paddingInline: Mobile ? "14px" : "9.5rem",
              textAlign: "justify",
            }}
          >
            At CareerCarve, we take immense pride in our extensive and highly diverse group of industry practitioners, responsible for delivering over 98% of our rigorous program. Our mentors, all of whom are exceptionally qualified and hold positions in the country's top organizations, bring a wealth of experience, including campus and off-campus recruitment. With expertise spanning across 42 different roles available to post-MBA students, our mentors are well-equipped to guide students. To ensure the best fit, CareerCarve diligently matches mentors to specific roles based on students' aspirations, maintaining a stringent selection process. Each mentor is handpicked from a wide pool of industry professionals following a rigorous screening process. Moreover, all mentors undergo training for each program they deliver, guaranteeing a high-quality learning experience.
          </Typography>

          <Box sx={{ paddingInline: Mobile ? "1rem" : "9rem", display: "flex", flexDirection: "column", gap: "16px" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Typography sx={{ fontSize: Mobile ? "18px" : "36px" }}>Top Active Mentors</Typography>
              <Button onClick={() => navigate("/AllMentors")} sx={{ textAlign: Mobile ? "center" : "right", color: "#1C8EA8", fontSize: Mobile ? "14px" : "16px", padding: "5px 8px", border: "1px solid #1C8EA8", borderRadius: "8px", textTransform: "none" }}>
                View all mentors <ArrowOutwardOutlinedIcon sx={{}} />
              </Button>
            </Box>
            {allMentors.length > 0 && (
              <Grid container rowSpacing={Mobile ? 2 : 6} columnSpacing={Mobile ? 2 : 6} alignItems="stretch">
                {allMentors.map((item) => (
                  <Grid item xs={12} sm={12} md={6}>
                    <MentorCard mentor={item} />
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              // alignItems: "center",
            }}
          >
            <Typography
              variant={Mobile ? "h5" : "h4"}
              fontSize={Mobile ? "20px" : "24px"}
              color={"#545454"}
              sx={{
                textAlign: "center",
                fontWeight: "400",
                paddingInline: Mobile ? "15px" : "9.5rem",
              }}
            >
              Years of
              <span style={{ color: "#1C8EA8" }}> Experience in Top Companies</span>
            </Typography>
            <Box>
              {/* <ImageDisplay
              imageUrl={
                "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/companyImage.png"
              }
              speed={"30s"}
            /> */}

              <HorizontalScroll images={companyLogos} size={Mobile ? "30px" : "40px"} />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img
                src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/chart.png"
                alt="CareerCarve 9-12 Years of Expertise Guiding Your MBA JourneyMentors: Your Guide to Successful Placements"
                style={{
                  height: Mobile ? "80%" : "45%",
                  width: Mobile ? "80%" : "45%",
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Typography
              variant={Mobile ? "h5" : "h4"}
              fontSize={Mobile ? "20px" : "24px"}
              color={"#545454"}
              sx={{
                fontWeight: "400",
                paddingInline: Mobile ? "15px" : "9.5rem",
                textAlign: "center",
              }}
            >
              Mentors from <span style={{ color: "#1C8EA8" }}>Top Universities</span>
            </Typography>

            <Box>
              {Mobile ? (
                <Grid container rowSpacing={4} columnSpacing={1}>
                  {collegeLogo.map((item) => (
                    <Grid
                      item
                      xs={6}
                      md={2.4}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={item}
                        style={{
                          width: Mobile ? "100px" : "",
                          height: Mobile ? "80px" : "",
                        }}
                        alt="Colleges"
                      />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "2rem 9.5rem",
                    }}
                  >
                    <img
                      src={collegeLogo[0]}
                      style={{
                        width: Mobile ? "80px" : "130px",
                        height: Mobile ? "80px" : "130px",
                      }}
                      alt="IMT Ghaziabad"
                    />
                    <img
                      src={collegeLogo[1]}
                      style={{
                        width: Mobile ? "80px" : "130px",
                        height: Mobile ? "80px" : "130px",
                      }}
                      alt="IIM Ahmedabad"
                    />
                    <img
                      src={collegeLogo[2]}
                      style={{
                        width: Mobile ? "80px" : "130px",
                        height: Mobile ? "80px" : "130px",
                      }}
                      alt="IIM Bangalore"
                    />
                    <img
                      src={collegeLogo[3]}
                      style={{
                        width: Mobile ? "80px" : "130px",
                        height: Mobile ? "80px" : "130px",
                      }}
                      alt="IIM Kolkata"
                    />
                    <img
                      src={collegeLogo[4]}
                      style={{
                        width: Mobile ? "80px" : "130px",
                        height: Mobile ? "80px" : "130px",
                      }}
                      alt="IIM Lucknow"
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "2rem 9.5rem",
                    }}
                  >
                    <img
                      src={collegeLogo[5]}
                      style={{
                        width: Mobile ? "80px" : "130px",
                        height: Mobile ? "80px" : "130px",
                      }}
                      alt="IIM Kozhikode"
                    />
                    <img
                      src={collegeLogo[6]}
                      style={{
                        width: Mobile ? "80px" : "130px",
                        height: Mobile ? "80px" : "130px",
                      }}
                      alt="alt"
                    />
                    <img
                      src={collegeLogo[7]}
                      style={{
                        width: Mobile ? "80px" : "130px",
                        height: Mobile ? "80px" : "130px",
                      }}
                      alt="IIM Indore"
                    />
                    <img
                      src={collegeLogo[8]}
                      style={{
                        width: Mobile ? "80px" : "",
                        height: Mobile ? "80px" : "",
                      }}
                      alt="XLRI"
                    />
                    <img
                      src={collegeLogo[9]}
                      style={{
                        width: Mobile ? "80px" : "",
                        height: Mobile ? "80px" : "",
                      }}
                      alt="MDI Gurgaon"
                    />
                  </Box>
                </Box>
              )}
            </Box>

            {/* <Button
            sx={{
              borderRadius: "8px",
              border: "1px solid #F5A536",
              backgroundColor: "#F5A536",
              padding: "8px 16px",
              width: "258px",
              fontSize: "14px",
              color: "#000",
              "&:hover": {
                background: "linear-gradient(135deg, #F5A536 0%, #FFC87A 100%)",
              },
            }}
          >
            Action Button
          </Button> */}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              paddingInline: Mobile ? "15px" : "9.5rem",
              flexDirection: "column  ",
              gap: "2rem",
            }}
          >
            <Typography variant={Mobile ? "h5" : "h4"} sx={{ fontSize: Mobile ? "20px" : "24px", fontWeight: "400" }}>
              Domains
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={4}>
                {" "}
                <Box
                  display={"flex"}
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    height: "300px",
                    bgcolor: "#fff",
                    flexDirection: "column",
                    padding: Mobile ? "10px" : "20px",
                    gap: "10px",
                    paddingBlock: "40px",
                    borderRadius: "10px",
                    // boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                  }}
                >
                  <img src="../../../MentorsSvg/salesAndMarketing.svg" alt="sales and marketing" />
                  <Typography variant={Mobile ? "h6" : "h5"} sx={{ fontSize: Mobile ? "16px" : "20px" }}>
                    Sale and Marketing
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: Mobile ? "14px" : "16px",
                      color: "#8B8B8B",
                      textAlign: "center",
                    }}
                  >
                    Founding Member | Head - Sales, Strategy & GTM, Performance Marketing Lead - International, Head Of Business Development, Head of Trade Marketing & GTM, Head of Global Payments Operations, etc
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  display={"flex"}
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    paddingBlock: "40px",
                    borderRadius: "10px",
                    padding: Mobile ? "10px" : "20px",
                    gap: "10px",
                    height: "300px",
                    bgcolor: "#fff",
                    // boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                  }}
                >
                  <img src="../../../MentorsSvg/analytics.svg" alt="sales and marketing" />
                  <Typography sx={{ fontSize: Mobile ? "16px" : "20px" }} variant={Mobile ? "h6" : "h5"}>
                    Analytics
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: Mobile ? "14px" : "16px",
                      color: "#8B8B8B",
                      textAlign: "center",
                    }}
                  >
                    Global Application Manager, Head of Credit & Portfolio Management, Senior Director Supply Chain Management, Senior Program Manager, Digital Marketing & Analytics Lead - EU Lubricants, etc
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  display={"flex"}
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    paddingBlock: "40px",
                    padding: Mobile ? "10px" : "20px",
                    gap: "10px",
                    borderRadius: "10px",
                    height: "300px",
                    bgcolor: "#fff",
                    // boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                  }}
                >
                  <img src="../../../MentorsSvg/consultation.svg" alt="sales and marketing" />
                  <Typography sx={{ fontSize: Mobile ? "14px" : "20px" }} variant={Mobile ? "body2" : "h5"}>
                    Consulting
                  </Typography>{" "}
                  <Typography
                    sx={{
                      fontSize: Mobile ? "14px" : "16px",
                      color: "#8B8B8B",
                      textAlign: "center",
                    }}
                  >
                    Strategy Head, Indonesia, Africa, Middle East and USA, Head - Channel Program Head - Channel Program, Assistant Vice President, Associate Director - BD, Strategic Partnerships and B2B CoE, Emerging Markets & Europe, etc
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  display={"flex"}
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    paddingBlock: "40px",
                    padding: Mobile ? "10px" : "20px",
                    gap: "10px",
                    borderRadius: "10px",
                    height: "300px",
                    bgcolor: "#fff",
                    // boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                  }}
                >
                  <img src="../../../MentorsSvg/technology.svg" alt="sales and marketing" />
                  <Typography sx={{ fontSize: Mobile ? "14px" : "20px" }} variant={Mobile ? "body2" : "h5"}>
                    Technology
                  </Typography>{" "}
                  <Typography
                    sx={{
                      fontSize: Mobile ? "14px" : "16px",
                      color: "#8B8B8B",
                      textAlign: "center",
                    }}
                  >
                    Sr. Program Manager - Transportation Engineering, Head of Credit & Portfolio Management, Senior Manager - Business Consulting Group, Head of Global Payments Operations, Intelligent automation & digital transformation, etc
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  display={"flex"}
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: Mobile ? "10px" : "20px",
                    gap: "10px",
                    paddingBlock: "40px",
                    borderRadius: "10px",
                    height: "300px",
                    bgcolor: "#fff",
                    // boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                  }}
                >
                  <img src="../../../MentorsSvg/human-resource.svg" alt="sales and marketing" />
                  <Typography sx={{ fontSize: Mobile ? "14px" : "20px" }} variant={Mobile ? "body2" : "h5"}>
                    Human Resource
                  </Typography>{" "}
                  <Typography
                    sx={{
                      fontSize: Mobile ? "14px" : "16px",
                      color: "#8B8B8B",
                      textAlign: "center",
                    }}
                  >
                    Team Lead - HRBP and Talent Acquisition, Vice President & Zonal Head HR, Senior Human Resource Business Partner, Founding Member | Head - Sales, Strategy & GTM, HRBP - Supply Chain, South Asia, etc
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  display={"flex"}
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    paddingBlock: "40px",
                    padding: Mobile ? "10px" : "20px",
                    gap: "10px",
                    borderRadius: "10px",
                    height: "300px",
                    bgcolor: "#fff",
                    // boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                  }}
                >
                  <img src="../../../MentorsSvg/coin.svg" alt="sales and marketing" />
                  <Typography sx={{ fontSize: Mobile ? "14px" : "20px" }} variant={Mobile ? "body2" : "h5"}>
                    Finance
                  </Typography>{" "}
                  <Typography
                    sx={{
                      fontSize: Mobile ? "14px" : "16px",
                      color: "#8B8B8B",
                      textAlign: "center",
                    }}
                  >
                    Head - Channel Program, Financial Planning and Analysis Manager, Deputy General Manager, Business Consultant & Product Manager, Financial System- FP&A, Assistant Vice President, etc
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={6} justifyContent="center">
              <Grid item xs={12} md={12}>
                <Box
                  display={"flex"}
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: Mobile ? "10px" : "20px",
                    gap: "10px",
                    paddingBlock: "40px",
                    borderRadius: "10px",
                    // boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                  }}
                >
                  <img src="../../../MentorsSvg/operation.svg" alt="sales and marketing" />
                  <Typography sx={{ fontSize: Mobile ? "14px" : "20px" }} variant={Mobile ? "body2" : "h5"}>
                    Operations
                  </Typography>{" "}
                  <Typography
                    sx={{
                      fontSize: Mobile ? "14px" : "16px",
                      color: "#8B8B8B",
                      textAlign: "center",
                    }}
                  >
                    Senior Manager Operations Excellence, Regional Lead-Control Tower, Director - Supply Chain Planning & Logistics, Program Lead - India Center of Excellence, Sr. Programme Manager - Global Trade
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: Mobile ? "15px" : "4rem",
          background: "linear-gradient(226deg, #1D2C30 0%, #020303 100%)",
        }}
      >
        <BottomNavbar />
      </Box>
    </>
  );
}

export default Mentor;
