import { Box, Grid, Typography, useMediaQuery, Button, CardActionArea, CardActions } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ShareIcon from "@mui/icons-material/Share";
import { Color } from "../../../GlobalStyles";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FolderIcon from "@mui/icons-material/Folder";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import RDService from "../../../services/RDService";
import { extractDateFromDateString, formatConvertermonthfirst } from "../../../formatCovertermonthfirst";
import { makeTextBold } from "./RichTextEditor";
import { get_specialCharReplace } from "../../../services/specialCharReplace";
import { BlogsCompleteDetailsLoader } from "./EntireBlogsLoader";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Helmet } from "react-helmet-async";
import { useWebinarContext } from "../../../WebinarContext";
const BlogsEntireDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { webinarTickerExists } = useWebinarContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const service = new RDService();
  const blog_id = searchParams.get("blog_id");
  const [loading, setLoading] = useState(true);
  console.log("blog id", blog_id);
  const Demo = styled("div")(({ theme }) => ({
    borderRadius: "20px",
    padding: "0px",
    marginBottom: ".5rem",
  }));
  const [blogsEntireData, setBlogsEntireData] = useState([]);
  const [blogsEntireSubSectionData, setBlogsEntireSubSectionData] = useState([]);
  const [authorName, setAuthorName] = useState();
  const Mobile = useMediaQuery("(max-width:800px)");
  const [tagsDetails, setTagDetails] = useState([]);

  const getAuthorNames = (authorIds, authorsList) => {
    return authorIds.map((authorId) => {
      // Find the author in the merged data array
      const author = authorsList.find((author) => author.id === authorId);

      if (author) {
        // Check if first_name and last_name properties exist
        if (author.first_name && author.last_name) {
          return `${author.first_name} ${author.last_name}`;
        } else {
          // Fallback to the name property if first_name and last_name aren't available
          return author.name || "Unknown Author";
        }
      } else {
        return "Unknown Author";
      }
    });
  };
  useEffect(() => {
    const handelEffect = async () => {
      const blogsEntireDetails = await blogsEntireDetailsDataGetter();
      const tagsData = await tagsDataGetter();
      const authorsData = await authorsDataGetter();
      const mentorAuthorData = await mentorUserDetailsGetter();
      mentorAuthorData.forEach((element) => {
        element.id = element.user_id;
        element.name = `${element.first_name} ${element.last_name}`;
      });
      const temp_setAuthorsData = [...authorsData, ...(mentorAuthorData || [])];
      const authorIds = blogsEntireDetails?.authorIds;
      const authorNames = getAuthorNames(authorIds, temp_setAuthorsData);
      console.log("author names", authorNames);
      setAuthorName(authorNames);

      setBlogsEntireData(blogsEntireDetails?.blogCategory[0]);
      setBlogsEntireSubSectionData(blogsEntireDetails?.subSections);

      setTagDetails(tagsData);
      console.log("bhoomika all data getter", blogsEntireDetails[0]);
      setLoading(false);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    };
    handelEffect();
  }, []);

  const blogsEntireDetailsDataGetter = async () => {
    try {
      const res = await service.get(`/blog/blog?id=${blog_id}`);
      console.log("blog data data response", res.blog_category);
      return {
        blogCategory: res.blog_category,
        subSections: res.sub_sections,
        authorIds: res.author_ids,
      };
    } catch (err) {
      console.log(err);
    }
  };

  const tagsDataGetter = async () => {
    try {
      const res = await service.get(`/blog/tag`);
      console.log("blogs data response", res.tags);
      return res.tags;
    } catch (err) {
      console.log(err);
    }
  };

  const authorsDataGetter = async () => {
    try {
      const res = await service.get(`/blog/author`);
      console.log("blogs data response", res.blog_author);
      return res.blog_author;
    } catch (err) {
      console.log(err);
    }
  };

  const mentorUserDetailsGetter = async () => {
    try {
      const res = await service.get(`/mentorModule/mentor/user`);
      console.log("mentorUserDetailsGetter", res.data);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const goBack = () => {
    console.log("Back Button is Clicked Now");
    navigate("/Blogs");
  };

  return (
    <>
      <Helmet>
        <title>Blogs | CareerCarve</title>
        <meta name="description" content="Discover the experienced mentors behind CareerCarve's success. Learn who are our mentors that guide you towards a brighter future." />
        <meta property="og:title" content="CareerCarve-Blogs" />
        <meta property="og:url" content="https://www.careercarve.com/Blogs" />
        <meta property="og:image" content="../../Career_Carve_Logo__1_-removebg-preview 2.png" />
        <link rel="canonical" href="/Blogs" />
      </Helmet>
      {loading ? (
        <BlogsCompleteDetailsLoader />
      ) : (
        <Box
          sx={{
            display: "flex",
            paddingLeft: Mobile ? "1rem" : "5rem",
            alignItems: "center",
            width: "100%",
            marginTop: webinarTickerExists && Mobile ? "50px" : !webinarTickerExists && Mobile ? "20px" : webinarTickerExists ? "80px" : "2rem",
            flexDirection: Mobile ? "column" : "row",
            gap: Mobile ? "2rem" : "0rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: Mobile ? "100%" : "60%",
              paddingTop: "5rem",
              paddingLeft: Mobile ? "1rem" : "3rem",
              // Removed the gap property
            }}
          >
            {!Mobile && <ArrowBackIosNewIcon style={{ cursor: "pointer", position: "fixed", left: 50, marginTop: ".2rem" }} onClick={() => goBack()} />}

            <Typography gutterBottom variant="h5" component="div" sx={{ marginBottom: ".8rem" }}>
              {blogsEntireData.heading}
            </Typography>
            {blogsEntireData.description && (
              <Typography variant="body2" color="text.secondary" gutterBottom sx={{ marginBottom: ".8rem" }}>
                {blogsEntireData.description}
              </Typography>
            )}
            {authorName && (
              <Typography variant="body2" color="text.secondary" sx={{ marginTop: 0, marginBottom: ".5rem" }}>
                by {authorName[0]}
              </Typography>
            )}
            <Box sx={{ display: "flex", marginBottom: ".8rem" }}>
              <Typography variant="body2" color={Color.neutralDarkGrey} style={{ marginRight: ".5rem" }}>
                {formatConvertermonthfirst(extractDateFromDateString(blogsEntireData.upload_time))}
              </Typography>
              <FiberManualRecordIcon style={{ width: ".3rem", paddingBottom: ".3rem", marginRight: ".5rem" }} />
              <Typography variant="body2" color={Color.neutralDarkGrey}>
                {blogsEntireData.reading_time} mins read
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-equally", flexDirection:"row",width: "100%", marginBottom: ".8rem", flexWrap:"wrap" }}>
              {tagsDetails
                .filter((tag) => blogsEntireData?.tag_ids?.tag_ids?.includes(tag.id))
                .map((tag, index) => (
                  <Typography
                    key={index}
                    size="small"
                    color={Color.neutralDarkGrey}
                    style={{
                      borderRadius: "61px",
                      border: "1px solid #1C8EA8",
                      background: "#EBF9FC",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      whiteSpace: "nowrap",
                      fontSize: "10px",
                      justifyContent: "center",
                      marginRight: ".2rem",
                      paddingTop: "2px",
                      alignItems: "center",
                      
                    }}
                    gutterBottom
                  >
                    {tag.name}
                  </Typography>
                ))}
            </Box>

            <Box>
              <img src={blogsEntireData.image} style={{ width: Mobile ? "100%" : "100%", height: "300px", marginBottom: ".8rem" }} alt="blogs" />
            </Box>
            {blogsEntireData.opening_para && (
              <Typography
                style={{
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "27px",
                  letterSpacing: "0.48px",
                }}
                gutterBottom
                sx={{ marginBottom: ".8rem" }}
              >
                {blogsEntireData.opening_para}
              </Typography>
            )}
            {blogsEntireSubSectionData?.map((section, index) => (
              <Box key={index} sx={{ marginBottom: ".8rem" }}>
                {section.sub_heading && (
                  <Typography
                    style={{
                      fontSize: "24px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "32px",
                    }}
                  >
                    {section.sub_heading}
                  </Typography>
                )}
                {section.description && (
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "27px",
                      letterSpacing: "0.48px",
                    }}
                  >
                    {makeTextBold(get_specialCharReplace(section.description))}
                  </Typography>
                )}
                <Box style={{ width: "100%", margin: "0 auto" }}>
                  {section.image && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img style={{ width: "50%", height: "300px" }} src={section.image} alt={`Image ${index}`} />
                    </div>
                  )}
                </Box>
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              width: Mobile ? "100%" : "40%",
              justifyContent: "flex-start", // Change justifyContent to flex-end
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              top: "25%",
              paddingRight: Mobile ? "0rem" : "3rem",
              position: Mobile ? "relative" : "fixed", // Change position to fixed
              right: "0",
              height: "100vh",
            }}
          >
            <Card
              style={{
                width: Mobile ? "100%" : "80%",
                borderRadius: "30px",
                justifyContent: "flex-end",
                background: "#FFF",
                marginLeft: Mobile ? "auto" : "20%",
                boxShadow: "-8px 0px 103.1px 0px rgba(0, 0, 0, 0.05)",
              }}
            >
              <CardContent>
                <Demo style={{ background: "linear-gradient(0deg, rgba(45, 88, 224, 0.10) 0%, rgba(45, 88, 224, 0.10) 100%), #FFF" }}>
                  <List sx={{ paddingTop: "0px", paddingBottom: "0px" }}>
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end" aria-label="delete">
                          {/* <KeyboardArrowRightOutlinedIcon /> */}
                        </IconButton>
                      }
                    >
                      <ListItemAvatar>
                        <img src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/image+16.jpg" alt="Profile" />
                      </ListItemAvatar>
                      <ListItemText primary="Book 1x1 with top mentors" />
                    </ListItem>
                  </List>
                </Demo>
                <Demo style={{ background: "linear-gradient(0deg, rgba(71, 48, 128, 0.10) 0%, rgba(71, 48, 128, 0.10) 100%), #FFF" }}>
                  <List sx={{ paddingTop: "0px", paddingBottom: "0px" }}>
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end" aria-label="delete">
                          {/* <KeyboardArrowRightOutlinedIcon /> */}
                        </IconButton>
                      }
                    >
                      <ListItemAvatar>
                        <img src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/image+17.jpg" alt="Profile" />
                      </ListItemAvatar>
                      <ListItemText primary="Listen to Podcasts" />
                    </ListItem>
                  </List>
                </Demo>
                <Demo style={{ background: "linear-gradient(0deg, rgba(45, 88, 224, 0.10) 0%, rgba(45, 88, 224, 0.10) 100%), #FFF" }}>
                  <List sx={{ paddingTop: "0px", paddingBottom: "0px" }}>
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end" aria-label="delete">
                          {/* <KeyboardArrowRightOutlinedIcon /> */}
                        </IconButton>
                      }
                    >
                      <ListItemAvatar>
                        <img src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/image+18.jpg" alt="Profile" />
                      </ListItemAvatar>
                      <ListItemText primary="Install Mobile App" />
                    </ListItem>
                  </List>
                </Demo>
                <Demo style={{ background: "linear-gradient(0deg, rgba(255, 126, 189, 0.10) 0%, rgba(255, 126, 189, 0.10) 100%), #FFF" }}>
                  <List sx={{ paddingTop: "0px", paddingBottom: "0px" }}>
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end" aria-label="delete">
                          {/* <KeyboardArrowRightOutlinedIcon /> */}
                        </IconButton>
                      }
                    >
                      <ListItemAvatar>
                        <img src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/image+19.jpg" alt="Profile" />
                      </ListItemAvatar>
                      <ListItemText primary="Take an aptitude test" />
                    </ListItem>
                  </List>
                </Demo>
                <Demo style={{ background: "linear-gradient(0deg, rgba(221, 99, 110, 0.14) 0%, rgba(221, 99, 110, 0.14) 100%), #FFF" }}>
                  <List sx={{ paddingTop: "0px", paddingBottom: "0px" }}>
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end" aria-label="delete">
                          {/* <KeyboardArrowRightOutlinedIcon /> */}
                        </IconButton>
                      }
                    >
                      <ListItemAvatar>
                        <img src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/image+20.jpg" alt="Profile" />
                      </ListItemAvatar>
                      <ListItemText primary="Apply for jobs" />
                    </ListItem>
                  </List>
                </Demo>
                <Demo style={{ background: "linear-gradient(0deg, rgba(255, 100, 26, 0.10) 0%, rgba(255, 100, 26, 0.10) 100%), #FFF" }}>
                  <List sx={{ paddingTop: "0px", paddingBottom: "0px" }}>
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end" aria-label="delete">
                          {/* <KeyboardArrowRightOutlinedIcon /> */}
                        </IconButton>
                      }
                    >
                      <ListItemAvatar>
                        <img src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/image+21.jpg" alt="Profile" />
                      </ListItemAvatar>
                      <ListItemText primary="Take a role test" />
                    </ListItem>
                  </List>
                </Demo>
              </CardContent>
            </Card>
          </Box>
        </Box>
      )}
    </>
  );
};

export default BlogsEntireDetails;
