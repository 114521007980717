import React, { useEffect, useRef } from "react";
// import backgroundImage from './path/to/image.png';
import useMediaQuery from "@mui/material/useMediaQuery";
import "./ImagesInfiniteScroll.css";

function ImageScroll() {
  const Mobile = useMediaQuery("(max-width:800px)");

  const containerRef = useRef(null);
  useEffect(() => {
    const imageContainer = document.getElementById("image-container");
    const image = document.querySelector("#image-container img");
    let intervalDuration = Mobile ? 20 : 20; // set the interval duration in milliseconds (lower value will make it scroll faster)

    // reset the scroll position to 0 and restart the interval when it reaches the end
    function resetScrollPosition() {
      if (imageContainer.scrollTop + imageContainer.offsetHeight >= image.offsetHeight) {
        imageContainer.scrollTop = 0;
      }
    }

    setInterval(() => {
      imageContainer.scrollTop += 1; // increase the scrollTop property to scroll down
      resetScrollPosition();
    }, intervalDuration);

    // stop the interval after 30 seconds (30000 milliseconds)
    // setTimeout(() => {
    //   clearInterval(scrollInterval);
    // }, 30000);
  }, [Mobile]);

  return (
    <div className="image-scroll-container" ref={containerRef}>
      <div
        id="image-container"
        style={{
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img style={{ width: Mobile ? "100%" : "" }} src="https://ccicons.s3.amazonaws.com/landing_page/CollegeTieUpsScrollDIAG.webp" alt="Unlock Your Potential: MBA Placement Training Trusted by Top B-Schools" />
      </div>
    </div>
  );
}

export default ImageScroll;
