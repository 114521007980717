import React, { useEffect, useRef } from "react";
import AssessmentTests from "./AssessmentTests/AssessmentTests";
import ResumesDigital from "./ResumesDigital/ResumesDigital";
import ContactUs from "./ContactUs/ContactUs";
import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import CollegeTieUps from "./CollegeTieUps/CollegeTieUps";
import Trainings from "./Trainings";

import StudentReviews from "./StudentReviews/StudentReviews";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import BottomNavbar from "./Footer/BottomNavbar";
import { useWebinarContext } from "../../WebinarContext";
function Home() {
  const Mobile = useMediaQuery("(max-width:800px)");
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const { webinarTickerExists } = useWebinarContext();
  useEffect(() => {
    const handleCanPlay = () => {
      if (videoRef.current) {
        videoRef.current.play();
      }
    };

    handleCanPlay();
  }, []);

  const scrollTOTraining = () => {
    const scrollOptions = {
      behavior: "smooth",
    };
    const targetDiv = document.getElementById("training");

    window.scrollTo(0, targetDiv.offsetTop);
  };

  return (
    <>
      {/* this provides the meta description of each page separately */}
      <Helmet>
        <title>The Ultimate Happy Journey: New Opportunities In MBA Placement Training.</title>
        <meta name="description" content="Gain an edge in the MBA placement process with our proven strategies and expert advice. Unleash your full potential and secure the job of your dreams today!" />

        <meta property="og:title" content="CareerCarve" />
        <meta property="og:description" content="Gain an edge in the MBA placement process with our proven strategies and expert advice. Unleash your full potential and secure the job of your dreams today!" />

        <meta property="og:url" content="https://www.careercarve.com" />
        <meta property="og:image" content="/logo_welcome.png" />

        <link rel="canonical" href="/" />
      </Helmet>
      {Mobile ? (
        <Box>
          {/* <Navbar /> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",

              marginTop:webinarTickerExists ? '80px' : "50px",
              width: "100%",
            }}
          >
            {/* From resume to final interview prep */}

            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "0.8rem",
                // alignItems: "center",
                padding: "20px 15px",
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  fontSize: "24px",
                  fontWeight: "400",
                  textAlign: "center",
                }}
              >
                From <span style={{ color: "#1C8EA8", fontWeight: "400" }}>resume</span> to <span style={{ color: "#1C8EA8" }}>final interview </span>
                preparation
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box sx={{ height: "1px", background: "#000", width: "30%" }} />
              </Box>
              <Typography variant="h6" sx={{ fontSize: "16px", textAlign: "center" }}>
                We've got you covered
              </Typography>
              <Box sx={{ display: "flex", gap: "1rem", width: "100%" }}>
                <Button
                  sx={{
                    borderRadius: "8px",
                    border: "1px solid #F5A536",
                    backgroundColor: "#F5A536",
                    width: "100%",
                    // padding: "8px 16px",
                    fontSize: Mobile ? "10px" : "14px",
                    fontWeight: "600",
                    color: "#000",
                    "&:hover": {
                      background: "linear-gradient(135deg, #F5A536 0%, #FFC87A 100%)",
                    },
                  }}
                  onClick={() => navigate("/Pricing")}
                >
                  Book 1X1
                </Button>
                <Button
                  sx={{
                    borderRadius: "8px",
                    fontWeight: "600",
                    border: "1px solid #F5A536",
                    backgroundColor: "#F5A536",
                    width: "100%",
                    padding: "8px 8px",
                    fontSize: Mobile ? "10px" : "14px",
                    color: "#000",
                    "&:hover": {
                      background: "linear-gradient(135deg, #F5A536 0%, #FFC87A 100%)",
                    },
                  }}
                  onClick={() => navigate("/Pricing")}
                >
                  Buy Training Plans
                </Button>
              </Box>
              <Box sx={{ width: "100%" }}>
                <img src="https://ccicons.s3.amazonaws.com/landing_page/LandingPageImg_1.webp" alt="MBA Placement Prep Made Easy: Follow the Path with CareerCarve" className="landing-page__img_1" style={{ width: "100%", height: "100%" }} />
              </Box>

              <Box
                sx={{
                  width: "65px",
                  height: "2px",
                  backgroundColor: "#000",
                  marginTop: "-10px",
                  alignSelf: "center",
                }}
              />

              <Typography
                variant="body2"
                sx={{
                  fontSize: "14px",
                  lineHeight: "24px",
                  fontWeight: "400",
                  color: "#545454",
                  textAlign: "justify",
                }}
              >
                Get ready for your MBA campus placements, summers, or finals with the guidance of the most experienced and renowned Placement Training Agency with over 12+ years of expertise in training top B-Schools across the country.
              </Typography>
            </Box>

            {/* BUbbles */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "20px 15px",
              }}
            >
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={6}
                  md={3}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      gap: "0px",
                      backgroundColor: "#38C1DF1A",
                      width: "142px",
                      height: "145px",
                      padding: "1rem",
                      borderRadius: "50%",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#1C8EA8",
                        fontSize: "26px",
                        textAlign: "center",
                      }}
                    >
                      1,50,000+
                    </Typography>
                    <Typography
                      sx={{
                        color: "#545454",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      1X1 Sessions Conducted
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={3}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "0px",
                      width: "142px",
                      height: "145px",
                      padding: "1rem",
                      backgroundColor: "#38C1DF1A",

                      borderRadius: "50%",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#1C8EA8",
                        fontSize: "26px",
                        textAlign: "center",
                      }}
                    >
                      600+
                    </Typography>
                    <Typography
                      sx={{
                        color: "#545454",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      Industry Mentors
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={3}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "0px",
                      width: "142px",
                      height: "145px",
                      padding: "1rem",
                      backgroundColor: "#38C1DF1A",

                      borderRadius: "50%",
                    }}
                  >
                    <Typography sx={{ color: "#1C8EA8", fontSize: "26px" }}>40+</Typography>
                    <Typography sx={{ color: "#545454", fontSize: "12px" }}>
                      Prestigious <br />
                      B-Schools
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={3}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "0px",
                      width: "142px",
                      height: "145px",
                      padding: "1rem",
                      backgroundColor: "#38C1DF1A",
                      borderRadius: "50%",
                    }}
                  >
                    <Typography sx={{ color: "#1C8EA8", fontSize: "26px" }}>52,000+</Typography>
                    <Typography
                      sx={{
                        color: "#545454",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      Students Trained
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            {/* Placement Prep */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "2rem",
                flexDirection: "column-reverse",
                padding: "20px 15px",
                background: "rgba(199, 211, 191, 0.40)",
              }}
            >
              <Box sx={{ width: "100%" }}>
                {" "}
                <video
                  autoPlay
                  muted
                  loop
                  preload="auto"
                  // controls
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "1rem",
                  }}
                >
                  <source src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/how-to-prepare-for-MBA-placements.mp4" type="video/mp4" />
                </video>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "1.5rem",
                }}
              >
                <Typography variant="h2" sx={{ fontSize: "24px", textAlign: "center" }}>
                  <span style={{ color: "#1C8EA8" }}>Placement Preparation</span>
                  <br /> in your Pocket
                </Typography>
                <Box
                  sx={{
                    width: "65px",
                    height: "2px",
                    backgroundColor: "#000",
                    marginTop: "-10px",
                    alignSelf: "center",
                  }}
                />

                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "14px",
                    color: "#545454",
                    textAlign: "justify",
                  }}
                >
                  Book a 1x1 session, discover captivating podcasts & sessions, take assessment test with our all in one placement guide-accessible anytime, anywhere.
                </Typography>
                <a href="https://play.google.com/store/apps/details?id=com.careercarve&hl=en&gl=US" target="_blank" title="careercarve-mobile-app" rel="noreferrer" style={{ textDecoration: "none", display: "flex", justifyContent: "center" }}>
                  <Button
                    sx={{
                      borderRadius: "8px",
                      border: "1px solid #F5A536",
                      backgroundColor: "#F5A536",
                      width: "fit-content",
                      padding: "8px 12px",
                      fontSize: "12px",
                      alignSelf: "center",
                      color: "#000",
                      textTransform: "none",
                      "&:hover": {
                        background: "linear-gradient(135deg, #F5A536 0%, #FFC87A 100%)",
                      },
                    }}
                    href="https://play.google.com/store/apps/details?id=com.careercarve&hl=en&gl=US"
                    target="_blank"
                  >
                    Download the app
                  </Button>
                </a>
              </Box>
            </Box>
            <Box sx={{ padding: "20px 15px" }}>
              <CollegeTieUps />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "0px",
                width: "100%",
                alignItems: "center",
                background: "linear-gradient(226deg, #1D2C30 0%, #020303 100%)",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "rgba(2, 2, 2,0.5)",
                  width: "100%",
                  height: "20vh",
                  marginBottom: "-60px",
                }}
              >
                {" "}
                <img src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/Get-trained-by-industry-experts.png" style={{ height: "20vh", width: "100%" }} alt="Level Up Your MBA Skills: Expert Mentorship at Resumes.digital" />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  alignItems: "center",
                  width: "100%",
                  // paddingBottom: "4rem",
                  padding: "20px 15px",
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: "24px",

                    lineHeight: "36px",
                    textAlign: "center",
                    color: "#FFF",
                  }}
                >
                  Get Trained by <span style={{ color: "#38C1DF" }}>Industry Experts</span>
                </Typography>
                <Box
                  sx={{
                    width: "101px",
                    height: "1px",
                    backgroundColor: "#E6E6E6",
                  }}
                />

                <Grid container spacing={2}>
                  <Grid item xs={6} sm={6} md={4}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: "rgba(84, 84, 84, 0.20)",
                        padding: "16px",
                        borderRadius: "10px",
                        alignItems: "center",
                        height: "100%",
                        gap: "1rem",
                        justifyContent: "center",
                      }}
                    >
                      <img src="https://ccicons.s3.amazonaws.com/cc_com_landing/Mentor.svg" alt="assistance from experienced mentors" className="landing-page_icons" />
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "14px",
                          color: "#fff",
                          textAlign: "center",
                        }}
                      >
                        <span style={{ color: "#38C1DF" }}>600+</span> Mentors
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: "rgba(84, 84, 84, 0.20)",
                        padding: "16px",
                        borderRadius: "10px",
                        height: "100%",

                        alignItems: "center",
                        gap: "1rem",
                        justifyContent: "center",
                      }}
                    >
                      <img src="https://ccicons.s3.amazonaws.com/cc_com_landing/domains.svg" alt="Domain experts" className="landing-page_icons" />

                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#fff",
                          textAlign: "center",
                        }}
                      >
                        <span style={{ color: "#38C1DF" }}>Functional and Domain</span> Experts
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: "rgba(84, 84, 84, 0.20)",
                        padding: "16px",
                        borderRadius: "10px",
                        height: "100%",
                        alignItems: "center",
                        gap: "1rem",
                        justifyContent: "center",
                      }}
                    >
                      <img src="https://ccicons.s3.amazonaws.com/cc_com_landing/Bschools.svg" alt="B-schools" className="landing-page_icons" />

                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "14px",
                          color: "#fff",
                          textAlign: "center",
                        }}
                      >
                        <span style={{ color: "#38C1DF" }}>Top B-Schools</span> Graduates
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={6} sm={6} md={4}>
                    {" "}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: "rgba(84, 84, 84, 0.20)",
                        padding: "16px",
                        height: "100%",

                        borderRadius: "10px",
                        alignItems: "center",
                        gap: "1rem",
                        justifyContent: "center",
                      }}
                    >
                      <img src="https://ccicons.s3.amazonaws.com/cc_com_landing/experience.svg" alt="11+ year Experienced " className="landing-page_icons" />

                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "14px",
                          color: "#fff",
                          textAlign: "center",
                        }}
                      >
                        <span style={{ color: "#38C1DF" }}>11+ years</span> of work experience
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    // gap: "200px",
                    width: "100%",
                  }}
                ></Box>
                <Button
                  sx={{
                    borderRadius: "8px",
                    border: "1px solid #F5A536",
                    backgroundColor: "#F5A536",
                    // width: "200px",
                    padding: "8px 16px",
                    fontSize: "14px",
                    color: "#000",
                    "&:hover": {
                      background: "linear-gradient(135deg, #F5A536 0%, #FFC87A 100%)",
                    },
                  }}
                  onClick={() => [navigate("/Mentors"), window.scroll(0, 0)]}
                >
                  Know More
                </Button>
              </Box>
            </Box>
            <Box sx={{ padding: "20px 15px", background: "#F2F2F2" }}>
              <Trainings />
            </Box>
            {/* aSSESMENT TEst */}
            <Box sx={{ paddingBlock: "20px" }}>
              <AssessmentTests />
            </Box>

            {/* <StudentReviews /> */}
          </Box>
          <Box
            sx={{
              marginTop: "10px",
              background: "linear-gradient(226deg, #1D2C30 0%, #020303 100%)",
            }}
          >
            <BottomNavbar />
          </Box>
        </Box>
      ) : (
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              // gap: "4.5rem",
              marginTop:webinarTickerExists && Mobile ? "80px" : !webinarTickerExists && Mobile ? "50px" : webinarTickerExists ? "112px" : "70px" 
            }}
          >
            {/* From resume to final interview prep */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "6px",
                marginBottom: "4.5rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "8rem",
                  padding: "8px 9.5rem",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  <Typography variant="h1" sx={{ fontSize: "45px", lineHeight: "50px" }}>
                    From <span style={{ color: "#1C8EA8" }}>resume</span> to <span style={{ color: "#1C8EA8" }}>final interview prep</span>
                  </Typography>
                  <Box
                    sx={{
                      width: "101px",
                      height: "2px",
                      backgroundColor: "#000",
                    }}
                  />

                  <Typography variant="h2" sx={{ fontSize: "32px" }}>
                    We've got you covered
                  </Typography>
                  <Box sx={{ display: "flex", gap: "1rem" }}>
                    <button className="button-yellow-hover" onClick={() => navigate("/Pricing")}>
                      <Typography>Book 1X1</Typography>
                    </button>
                    <button className="button-yellow-hover" onClick={() => navigate("/Pricing")}>
                      <Typography>Buy Training Plans</Typography>
                    </button>
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "#545454",
                    }}
                  >
                    Get ready for your MBA campus placements, summers, or finals with the guidance of the most experienced and renowned Placement Training Agency with over 12+ years of expertise in training top B-Schools across the country.
                  </Typography>
                </Box>

                <Box sx={{ width: "100%" }}>
                  <img src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/LandingPageImg_1.jpg" alt="MBA Placement Prep Made Easy: Follow the Path with CareerCarve" className="landing-page__img_1" style={{ width: "100%", height: "100%" }} />
                </Box>
              </Box>
              {/* BUbbles */}

              <Grid container spacing={2} sx={{ padding: "2rem 5rem" }}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "0px",
                      width: "205px",
                      height: "195px",
                      backgroundColor: "#38C1DF1A",
                      padding: "2.5rem",
                      borderRadius: "50%",
                    }}
                  >
                    <Typography
                      variant="h3"
                      sx={{
                        color: "#1C8EA8",
                        fontSize: "36px",
                        textAlign: "center",
                      }}
                    >
                      1,50,000+
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#545454",
                        fontSize: "16px",
                        textAlign: "center",
                      }}
                    >
                      1X1 Sessions Conducted
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "0px",
                      width: "205px",
                      height: "195px",
                      backgroundColor: "#38C1DF1A",
                      padding: "2.5rem",
                      borderRadius: "50%",
                    }}
                  >
                    <Typography variant="h3" sx={{ color: "#1C8EA8", fontSize: "36px" }}>
                      600+
                    </Typography>
                    <Typography variant="body1" sx={{ color: "#545454", fontSize: "16px" }}>
                      Industry Mentors
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",

                      gap: "0px",
                      width: "205px",
                      height: "195px",
                      backgroundColor: "#38C1DF1A",

                      padding: "2.5rem",
                      borderRadius: "50%",
                    }}
                  >
                    <Typography variant="h3" sx={{ color: "#1C8EA8", fontSize: "36px" }}>
                      40+
                    </Typography>
                    <Typography variant="body1" sx={{ color: "#545454", fontSize: "16px" }}>
                      Prestigious <br />
                      B-Schools
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "0px",
                      width: "205px",
                      height: "195px",
                      backgroundColor: "#38C1DF1A",

                      padding: "2.5rem",
                      borderRadius: "50%",
                    }}
                  >
                    <Typography variant="h3" sx={{ color: "#1C8EA8", fontSize: "36px" }}>
                      52,000+
                    </Typography>
                    <Typography variant="body1" sx={{ color: "#545454", fontSize: "16px" }}>
                      Students Trained
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "8rem",
                padding: "56px 9.5rem",
                background: "rgba(199, 211, 191, 0.40)",
              }}
            >
              <Box sx={{ width: "100%", height: "500px" }}>
                <video
                  autoPlay
                  muted
                  loop
                  preload="auto"
                  // controls
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "2rem",
                  }}
                >
                  <source src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/how-to-prepare-for-MBA-placements.mp4" type="video/mp4" />
                </video>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "2rem",
                }}
              >
                <Box display={"flex"} flexDirection={"column"} gap={"1rem"}>
                  <Typography variant="h2" sx={{ fontSize: "45px", lineHeight: "50px" }}>
                    <span style={{ color: "#1C8EA8" }}>Placement Preparation</span>
                    <br /> in your Pocket
                  </Typography>
                  <Box
                    sx={{
                      width: "131px",
                      height: "1px",
                      backgroundColor: "#000",
                    }}
                  />
                </Box>

                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#545454",
                  }}
                >
                  Book a 1x1 session, discover captivating podcasts & sessions, take assessment test with our all in one placement guide - accessible anytime, anywhere.
                </Typography>
                <a href="https://play.google.com/store/apps/details?id=com.careercarve&hl=en&gl=US" target="_blank" title="careercarve-mobile-app" rel="noreferrer">
                  <button className="button-yellow-hover" style={{ width: "fit-content" }}>
                    <Typography> Download the App</Typography>
                  </button>
                </a>
              </Box>
            </Box>
            <Box sx={{ padding: "56px 9.5rem" }}>
              <CollegeTieUps />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "0px",
                alignItems: "center",
                background: "linear-gradient(226deg, #1D2C30 0%, #020303 100%)",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "rgba(2, 2, 2,0.5)",
                  width: "100%",
                  height: "375px",
                  // marginBottom: "-60px",
                }}
              >
                <img src="https://ccicons.s3.amazonaws.com/landing_page/careercarve-mentors.webp" style={{ height: "100%", width: "100%" }} alt="Level Up Your MBA Skills: Expert Mentorship at Resumes.digital" />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2.5rem",
                  alignItems: "center",
                  width: "100%",
                  // paddingBottom: "4rem",
                  padding: "36px 9.5rem",
                }}
              >
                <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} gap={"10px"}>
                  <Typography
                    variant="h2"
                    sx={{
                      fontSize: "45px",
                      lineHeight: "52px",
                      color: "#FFF",
                    }}
                  >
                    Get Trained by <span style={{ color: "#38C1DF" }}>Industry Experts</span>
                  </Typography>
                  <Box
                    sx={{
                      width: "101px",
                      height: "1px",
                      backgroundColor: "#E6E6E6",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "2rem",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                      backgroundColor: "rgba(84, 84, 84, 0.20)",
                      padding: "16px",
                      borderRadius: "10px",
                      alignItems: "center",
                      gap: "1rem",
                      justifyContent: "center",
                    }}
                  >
                    <img src="https://ccicons.s3.amazonaws.com/cc_com_landing/Mentor.svg" alt="assistance from experienced Mentors" />
                    <Typography
                      sx={{
                        fontSize: "20px",
                        color: "#fff",
                        textAlign: "center",
                      }}
                    >
                      <span style={{ color: "#38C1DF" }}>600+</span> Mentors
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      backgroundColor: "rgba(84, 84, 84, 0.20)",
                      padding: "16px",
                      borderRadius: "10px",
                      alignItems: "center",
                      gap: "1rem",
                      justifyContent: "center",
                    }}
                  >
                    <img src="https://ccicons.s3.amazonaws.com/cc_com_landing/Bschools.svg" alt="top B-schools" className="landing-page_icons" />

                    <Typography
                      sx={{
                        fontSize: "20px",
                        color: "#fff",
                        textAlign: "center",
                      }}
                    >
                      Graduated from
                      <br />
                      <span style={{ color: "#38C1DF" }}>Top B-Schools</span> in India
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                      backgroundColor: "rgba(84, 84, 84, 0.20)",
                      padding: "16px",
                      borderRadius: "10px",
                      alignItems: "center",
                      gap: "1rem",
                      justifyContent: "center",
                    }}
                  >
                    <img src="https://ccicons.s3.amazonaws.com/cc_com_landing/domains.svg" alt="domain experts" className="landing-page_icons" />

                    <Typography
                      sx={{
                        fontSize: "20px",
                        color: "#fff",
                        textAlign: "center",
                      }}
                    >
                      Accomplished <span style={{ color: "#38C1DF" }}>Functional and Domain</span> Experts
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                      backgroundColor: "rgba(84, 84, 84, 0.20)",
                      padding: "16px",
                      borderRadius: "10px",
                      alignItems: "center",
                      gap: "1rem",
                      justifyContent: "center",
                    }}
                  >
                    <img src="https://ccicons.s3.amazonaws.com/cc_com_landing/experience.svg" alt="experienced mentors" className="landing-page_icons" />

                    <Typography
                      sx={{
                        fontSize: "20px",
                        color: "#fff",
                        textAlign: "center",
                      }}
                    >
                      Average Work Experience <br />
                      of <span style={{ color: "#38C1DF" }}>11+ years</span>
                    </Typography>
                  </Box>
                </Box>
                <button className="button-yellow-hover" style={{ width: "fit-content" }} onClick={() => [navigate("/Mentors"), window.scroll(0, 0)]}>
                  <Typography>Know More</Typography>
                </button>
              </Box>
            </Box>
            <div id="training" style={{ padding: "56px 9.5rem", background: "#F2F2F2" }}>
              <Trainings />
            </div>
            {/* aSSESMENT TEst */}
            <Box sx={{ padding: "56px 9.5rem" }}>
              <AssessmentTests />
            </Box>
            <Box>
              <ResumesDigital />
            </Box>

            {/* <StudentReviews /> */}

            {/* <Box sx={{ marginBlock: "5rem" }}>
              <ContactUs />
            </Box> */}
            <Box
              sx={{
                background: "linear-gradient(226deg, #1D2C30 0%, #020303 100%)",
              }}
            >
              <BottomNavbar />
            </Box>
          </Box>
          {/* <Box
            display={"flex"}
            justifyContent={"space-between"}
            sx={{ paddingInline: "9.5rem", paddingBottom: "15px" }}
          >
            <Typography sx={{ color: "#545454" }}>
              &copy; 2023 Parivartana Consulting Private Limited. All rights
              reserved.
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "#1C8EA8",
                  "&:hover": { cursor: "pointer" },
                }}
                onClick={() => navigate("/PrivacyPolicy")}
              >
                Privacy Policy
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "#1C8EA8",
                  "&:hover": { cursor: "pointer" },
                }}
                onClick={() => navigate("/TermsOfUse")}
              >
                Terms and Conditions
              </Typography>
            </Box>
          </Box> */}
        </Box>
      )}
    </>
  );
}

export default Home;
