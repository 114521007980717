import { createContext, useContext, useState } from 'react';

const WebinarContext = createContext();

export const useWebinarContext = () => useContext(WebinarContext);

export const WebinarProvider = ({ children }) => {
  const [webinarTickerExists, setWebinarTickerExists] = useState(false);

  return (
    <WebinarContext.Provider value={{ webinarTickerExists, setWebinarTickerExists }}>
      {children}
    </WebinarContext.Provider>
  );
};
