import React from 'react';

export const formatConvertermonthfirst = (date) => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    console.log("what might the date be" + date)
    const d = new Date(date);
    console.log("what might the date be month:" + monthNames[d.getMonth(date)])
    return (monthNames[d.getMonth(date)] === undefined ? " " : d.getDate(date) + " " + monthNames[d.getMonth(date)] + ", " + d.getFullYear(date));
}

export function extractDateFromDateString(dateString) {
    console.log("date passed", dateString);
    const inputDate = new Date(dateString);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0");
    const day = String(inputDate.getDate()).padStart(2, "0");
    console.log("what's returning", year, month, day);
    return `${year}-${month}-${day}`;
  }

