import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";

import { Helmet } from "react-helmet";
import { useWebinarContext } from "../../WebinarContext";
import BottomNavbar from "../LandingPage/Footer/BottomNavbar";
function AboutUs() {
  const Mobile = useMediaQuery("(max-width:800px)");
  const { webinarTickerExists } = useWebinarContext();
  return (
    <>
      {/* this provides the meta description of each page separately */}
      <Helmet>
        <title>Unique Placement Training Partner: Journey To Success Begins Here</title>
        <meta name="description" content="Transform your career with a professional placement training partner by your side. Gain valuable insights and skills to secure your dream job." />
        <meta property="og:title" content="CareerCarve-Placement Preparation Partner" />
        <meta property="og:image" content="/logo_welcome.png" />
        <meta property="og:url" content="https://www.careercarve.com/AboutUs" />
        <link rel="canonical" href={`${process.env.REACT_APP_HOST}/AboutUs`} />
      </Helmet>
      <Box sx={{ marginTop: webinarTickerExists && Mobile ? "80px" : !webinarTickerExists && Mobile ? "50px" : webinarTickerExists ? "112px" : "70px" }}>
        {/* <Navbar /> */}
        <Box sx={{ marginBottom: Mobile ? "1.5rem" : "4.5rem" }}>
          <Box>
            <img
              src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/AboutUsImage.png"
              alt=" A team driven with passion to empower dreams and enable success"
              style={{
                height: Mobile ? "15vh" : "100%",
                width: "100%",
                marginBottom: "-2%",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              variant={Mobile ? "h1" : "h1"}
              fontSize={Mobile ? "24px" : "36px"}
              sx={{
                zIndex: "20",
                bgcolor: "#FFF",
                fontWeight: "400",
                padding: "8px 46px",
                boxShadow: "0px 6px 49px 0px rgba(0, 0, 0, 0.08)",
              }}
            >
              About Us
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: Mobile ? "1.5rem" : "10vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "1rem",
              paddingInline: Mobile ? "15px" : "9.5rem",
            }}
          >
            <Typography variant="h2" fontSize={Mobile ? "20px" : "32px"} textAlign={Mobile ? "center" : ""} fontWeight={"400"}>
              Welcome to CareerCarve -<span style={{ color: "#1C8EA8" }}> Empowering Future Leaders!</span>
            </Typography>
            <Typography
              variant={Mobile ? "body2" : "body1"}
              sx={{
                color: "#545454",
                fontSize: Mobile ? "14px" : "16px",
                textAlign: "justify",
              }}
            >
              At CareerCarve, we believe in shaping the success stories of tomorrow's business leaders. We are a leading placement training company dedicated to unlocking the full potential of MBA students and paving the way for their remarkable career journeys.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: Mobile ? "column" : "row",
              justifyContent: "space-between",
              alignItems: "center",
              paddingInline: Mobile ? "15px" : "9.5rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0.7rem",
                width: "100%",
              }}
            >
              <Typography variant={Mobile ? "h5" : "h4"} sx={{ fontSize: Mobile ? "20px" : "24px" }} textAlign={Mobile ? "center" : ""} fontWeight={"400"}>
                Our <span style={{ color: "#1C8EA8" }}>Mission</span>{" "}
              </Typography>
              <Box
                sx={{
                  width: "50px",
                  height: "2px",
                  backgroundColor: "#000",
                  alignSelf: Mobile ? "center" : "",
                }}
              />
              {/* <Typography color={"#545454"} fontSize={"16px"}>
              Our mission is simple yet ambitious to achieve{" "}
            </Typography> */}
              <Typography variant={Mobile ? "h5" : "h4"} color={"#1C8EA8"} fontSize={Mobile ? "20px" : "24px"}>
                Placement Preparedness Perfected{" "}
              </Typography>
              <Typography fontSize={Mobile ? "14px" : "16px"} color={"#545454"} textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                Our mission is to prepare every MBA student to crack their dream job. We believe that with the right guidance, training, and support, every aspiring professional can excel in the fiercely competitive job market. We are committed to providing comprehensive and personalized training programs that prepare students to conquer the challenges of the corporate world.
              </Typography>
            </Box>
            <Box width={"100%"} display={"flex"} justifyContent={"center"}>
              <img
                src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/Team+goals.png"
                alt="CareerCarve - Your placement preparation partner"
                style={{
                  height: Mobile ? "100%" : "400px",
                  width: Mobile ? "200px" : "420px",
                }}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingInline: Mobile ? "15px" : "9.5rem",
              flexDirection: Mobile ? "column-reverse" : "row",
            }}
          >
            <Box width={"100%"} display={"flex"} justifyContent={"center"} sx={{ paddingInline: Mobile ? "15px" : "" }}>
              <img
                src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/ABoutus.jpg"
                alt="CareerCarve - Your Placement Prep Partner With Industry Experts"
                style={{
                  height: Mobile ? "100%" : "400px",
                  width: Mobile ? "200px" : "420px",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0.6rem",
                width: "100%",
              }}
            >
              <Typography variant={Mobile ? "h5" : "h4"} fontSize={Mobile ? "20px" : "24px"} fontWeight={"400"} textAlign={Mobile ? "center" : ""}>
                Who We Are
              </Typography>
              <Box
                sx={{
                  width: "50px",
                  height: "2px",
                  backgroundColor: "#000",
                  alignSelf: Mobile ? "center" : "",
                }}
              />
              <Typography
                variant={Mobile ? "body2" : "body1"}
                sx={{
                  textAlign: "justify",
                  fontSize: Mobile ? "14px" : "16px",
                  color: "#545454",
                }}
              >
                CareerCarve is a team that works with industry experts, seasoned mentors, and talented professionals who share a passion for mentoring and guiding young minds. With a wealth of experience in training B-School students, our team is adept at understanding the ever-evolving demands of the job market and tailoring our training programs to meet those needs.
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              alignItems: "center",
              justifyContent: "center",
              paddingInline: Mobile ? "15px" : "9.5rem",
            }}
          >
            <Typography fontSize={"24px"}>
              Our <span style={{ color: "#1C8EA8" }}>Legacy</span>
            </Typography>
            <Box
              sx={{
                width: "50px",
                height: "2px",
                backgroundColor: "#000",
              }}
            />

            <Grid container rowSpacing={Mobile ? 2 : 6} columnSpacing={Mobile ? 2 : 6}>
              <Grid item xs={6} sm={6} md={4}>
                <Box
                  display={"flex"}
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "100%",
                    paddingBlock: "40px",
                    borderRadius: "10px",
                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                  }}
                >
                  <Typography variant={Mobile ? "h4" : "h3"} sx={{ fontSize: Mobile ? "24px" : "36px" }} color={"#1C8EA8"}>
                    #1{" "}
                  </Typography>
                  <Typography fontSize={Mobile ? "16px" : "20px"} color={"#8B8B8B"} textAlign={"center"} variant={Mobile ? "h6" : "h5"}>
                    in the MBA Training Field
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} md={4}>
                <Box
                  display={"flex"}
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "100%",
                    paddingBlock: "40px",
                    borderRadius: "10px",
                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                  }}
                >
                  <Typography variant={Mobile ? "h4" : "h3"} sx={{ fontSize: Mobile ? "24px" : "36px" }} color={"#1C8EA8"}>
                    12+ Years
                  </Typography>
                  <Typography variant={Mobile ? "h6" : "h5"} fontSize={Mobile ? "16px" : "20px"} color={"#8B8B8B"}>
                    of Experience
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} md={4}>
                {" "}
                <Box
                  display={"flex"}
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: "40px",
                    height: "100%",
                    borderRadius: "10px",
                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                  }}
                >
                  <Typography
                    variant={Mobile ? "h4" : "h3"}
                    sx={{
                      fontSize: Mobile ? "24px" : "36px",

                      textAlign: "center",
                    }}
                    color={"#1C8EA8"}
                  >
                    52,000+
                  </Typography>
                  <Typography fontSize={Mobile ? "16px" : "20px"} variant={Mobile ? "h6" : "h5"} color={"#8B8B8B"} sx={{ textAlign: "center" }}>
                    B-Schools Students Trained
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} md={4}>
                <Box
                  display={"flex"}
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "100%",
                    padding: "40px",
                    borderRadius: "10px",
                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                  }}
                >
                  <Typography
                    variant={Mobile ? "h4" : "h3"}
                    sx={{
                      fontSize: Mobile ? "24px" : "36px",
                      textAlign: "center",
                    }}
                    color={"#1C8EA8"}
                  >
                    40+
                  </Typography>
                  <Typography variant={Mobile ? "h6" : "h5"} fontSize={Mobile ? "16px" : "20px"} color={"#8B8B8B"} sx={{ textAlign: "center" }}>
                    B-Schools and counting
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} md={4}>
                <Box
                  display={"flex"}
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: "40px",
                    height: "100%",
                    borderRadius: "10px",
                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                  }}
                >
                  <Typography variant={Mobile ? "h4" : "h3"} sx={{ fontSize: Mobile ? "24px" : "36px" }} color={"#1C8EA8"}>
                    1,25,000+
                  </Typography>
                  <Typography fontSize={Mobile ? "16px" : "20px"} color={"#8B8B8B"} textAlign={"center"} variant={Mobile ? "h6" : "h5"}>
                    1X1 Sessions
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} md={4}>
                <Box
                  display={"flex"}
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    flexDirection: "column",
                    padding: "40px",
                    borderRadius: "10px",
                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                  }}
                >
                  <Typography variant={Mobile ? "h4" : "h3"} sx={{ fontSize: Mobile ? "24px" : "36px" }} color={"#1C8EA8"}>
                    45,00,000+
                  </Typography>
                  <Typography fontSize={Mobile ? "16px" : "20px"} color={"#8B8B8B"} textAlign={"center"} variant={Mobile ? "h6" : "h5"}>
                    Minutes of Personal Interation
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              alignItems: "center",
              justifyContent: "center",
              paddingInline: Mobile ? "15px" : "9.5rem",
            }}
          >
            <Typography fontSize={Mobile ? "20px" : "24px"} variant={Mobile ? "h5" : "h4"}>
              What Sets Us <span style={{ color: "#1C8EA8" }}> Apart</span>
            </Typography>
            <Box sx={{ width: "50px", height: "2px", backgroundColor: "#000" }} />
            <Typography
              variant={Mobile ? "body2" : "body1"}
              sx={{
                fontSize: Mobile ? "14px" : "16px",
                color: "#8B8B8B",
                textAlign: "justify",
              }}
            >
              At CareerCarve, we stand apart from the crowd by offering more than just standard training modules. We prioritize individual growth and provide personalized attention to each student.
            </Typography>

            <Grid container spacing={2}>
              <Grid
                item
                xs={6}
                sm={6}
                md={3}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Box
                  display={"flex"}
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    borderRadius: "50%",
                    padding: "30px",
                    height: "110px",
                    border: "1px solid #1C8EA8",
                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                  }}
                >
                  <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/technology.svg" alt="sales and marketing" width="100%" height="100%" />
                </Box>
                <Typography fontSize={Mobile ? "14px" : "22px"} textAlign={"center"}>
                  Powered by Tech
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={3}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Box
                  display={"flex"}
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    borderRadius: "50%",
                    padding: "30px",
                    height: "110px",
                    border: "1px solid #1C8EA8",
                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                  }}
                >
                  <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/ai.svg" alt="sales and marketing" style={{ width: "100%", height: "100%" }} />
                </Box>
                <Typography fontSize={Mobile ? "14px" : "22px"} textAlign={"center"}>
                  AI Powered Resume Builder
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={3}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Box
                  display={"flex"}
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    borderRadius: "50%",
                    padding: "30px",
                    height: "110px",
                    border: "1px solid #1C8EA8",
                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                  }}
                >
                  <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/industryexperts.svg" alt="sales and marketing" style={{ width: "100%", height: "100%" }} />
                </Box>
                <Typography fontSize={Mobile ? "14px" : "22px"} textAlign={"center"}>
                  700+ industry experts
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={3}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Box
                  display={"flex"}
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    borderRadius: "50%",
                    padding: "30px",
                    height: "110px",
                    border: "1px solid #1C8EA8",
                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                  }}
                >
                  <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/initiative.svg" alt="sales and marketing" style={{ width: "100%", height: "100%" }} />
                </Box>
                <Typography fontSize={Mobile ? "14px" : "22px"} textAlign={"center"}>
                  In-app support
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={3}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Box
                  display={"flex"}
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    borderRadius: "50%",
                    padding: "30px",
                    height: "110px",
                    border: "1px solid #1C8EA8",
                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                  }}
                >
                  <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/smartphone.svg" alt="sales and marketing" style={{ width: "100%", height: "100%" }} />
                </Box>
                <Typography fontSize={Mobile ? "14px" : "22px"} textAlign={"center"}>
                  All in one Mobile App
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={3}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Box
                  display={"flex"}
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    borderRadius: "50%",
                    padding: "30px",
                    height: "110px",
                    border: "1px solid #1C8EA8",
                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                  }}
                >
                  <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/specialization.svg" alt="sales and marketing" style={{ width: "100%", height: "100%" }} />
                </Box>
                <Typography fontSize={Mobile ? "14px" : "22px"} textAlign={"center"}>
                  7 Domain 42+ specializations
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={3}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Box
                  display={"flex"}
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    borderRadius: "50%",
                    padding: "30px",
                    height: "110px",
                    border: "1px solid #1C8EA8",
                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                  }}
                >
                  <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/music.svg" alt="sales and marketing" style={{ width: "100%", height: "100%" }} />
                </Box>
                <Typography fontSize={Mobile ? "14px" : "22px"} textAlign={"center"}>
                  100+ podcasts & sessions
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={3}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Box
                  display={"flex"}
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    borderRadius: "50%",
                    padding: "30px",
                    height: "110px",
                    border: "1px solid #1C8EA8",
                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                  }}
                >
                  <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/target.svg" alt="sales and marketing" style={{ width: "100%", height: "100%" }} />
                </Box>
                <Typography fontSize={Mobile ? "14px" : "22px"} textAlign={"center"}>
                  MBA Focused
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem",
              paddingInline: Mobile ? "15px" : "9.5rem",
            }}
          >
            <Typography fontSize={Mobile ? "20px" : "24px"} fontWeight={"400"} variant={Mobile ? "h5" : "h4"}>
              How does it <span style={{ color: "#1C8EA8" }}>benefit</span> me?
            </Typography>
            <Typography
              variant={Mobile ? "body2" : "body1"}
              sx={{
                fontSize: Mobile ? "14px" : "16px",
                color: "#8B8B8B",
                textAlign: "justify",
              }}
            >
              We believe that success is not just about acing interviews or securing placements; it's about holistic growth. Our approach combines cutting-edge training techniques, personalized mentorship, and in-depth industry insights to help students build a strong foundation for their careers.
            </Typography>
            <Box display="flex" flexDirection="column" gap="2rem">
              <Box display="flex" gap="0.5rem" flexDirection="column" sx={{ borderLeft: "2px solid #1C8EA8", paddingLeft: "1rem" }}>
                <Typography variant={Mobile ? "h6" : "h5"} fontSize={Mobile ? "16px" : "20px"} textAlign={"left"}>
                  Comprehensive Training
                </Typography>
                <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                  Our meticulously designed training programs cover all aspects of the placement process, from resume building and group discussions to case interviews and personal interviews. We leave no stone unturned in preparing our students for success.
                </Typography>
              </Box>

              <Box display="flex" gap="0.5rem" flexDirection="column" sx={{ borderLeft: "2px solid #F5A536", paddingLeft: "1rem" }}>
                <Typography fontSize={Mobile ? "16px" : "20px"} variant={Mobile ? "h6" : "h5"}>
                  Personalized Mentorship
                </Typography>
                <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                  We understand that each student is unique, and so are their career aspirations. Our personalized mentorship ensures that every student receives guidance tailored to their goals and interests.
                </Typography>
              </Box>

              <Box display="flex" gap="0.5rem" flexDirection="column" sx={{ borderLeft: "2px solid #1C8EA8", paddingLeft: "1rem" }}>
                <Typography fontSize={Mobile ? "16px" : "20px"} variant={Mobile ? "h6" : "h5"} textAlign={"left"}>
                  Industry Insights
                </Typography>
                <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                  With our industry experts and seasoned mentors by your side, you gain access to invaluable insights into various sectors, making you well-informed and industry-ready.
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingInline: Mobile ? "15px" : "9.5rem",
              paddingBlock: "30px  50px",
              gap: "1rem",
              alignItems: "center",
              background: "linear-gradient(226deg, #1D2C30 0%, #020303 100%)",
            }}
          >
            <Typography variant={Mobile ? "h5" : "h4"} sx={{ fontSize: Mobile ? "20px" : "24px", color: "#38C1DF" }}>
              Join Us Today!
            </Typography>
            <Typography
              variant={Mobile ? "body2" : "body1"}
              sx={{
                fontSize: Mobile ? "14px" : "16px",
                color: "#E6E6E6",
                textAlign: "center",
              }}
            >
              At CareerCarve, we stand apart from the crowd by offering more than just standard training modules. We prioritize individual growth and provide personalized attention to each student.
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            background: "linear-gradient(226deg, #1D2C30 0%, #020303 100%)",
          }}
        >
          <BottomNavbar />
        </Box>
      </Box>
    </>
  );
}

export default AboutUs;
