import RDService from "../../services/RDService";

export const initialState = {
  basket: JSON.parse(localStorage.getItem("temp_quantity")) ? JSON.parse(localStorage.getItem("temp_quantity"))?.filter((item) => item.quantity > 0) : [],
  user_id: null,
  email: null,
  firstName: "",
  lastName: "",
  ph_no: null,
  country: "",
};

const services = new RDService();
//Selector
export const getTotalPrice = (basket) => {
  let totalPrice = 0;
  console.log("basket", basket);
  if (basket.length > 0) {
    totalPrice = basket.reduce((amount, item) => amount + item.total_price, 0);
  }

  return totalPrice;
};

export const getPayableAmount = async (basket, coupon_code) => {
  if (basket.length > 0) {
    let body;
    if (coupon_code === "") {
      body = {
        platform: "Web", // "Web"
        items: basket.map((item) => {
          return { item_id: item.id, quantity: item.quantity, search_tbl: item.search_tbl };
        }),
        quickBuy: 1,
      };

      //TODO: JUST TO CHECK  COUPON PRICE
      // body = {
      //   platform: "App", // "Web"
      //   items: [{ item_id: 1, quantity: 1, search_tbl: "bundle" }],
      //   quickBuy: 1,
      //   // coupon_code: coupon_code,
      // };
    } else {
      //TODO: JUST TO CHECK  DISCOUNTED PRICE
      // body = {
      //   platform: "App", // "Web"
      //   items: [{ item_id: 1, quantity: 1, search_tbl: "bundle" }],
      //   quickBuy: 1,
      //   coupon_code: coupon_code,
      // };
      body = {
        platform: "Web", // "Web"
        items: basket.map((item) => {
          return { item_id: item.id, quantity: item.quantity, search_tbl: item.search_tbl };
        }),
        quickBuy: 1,
        coupon_code: coupon_code,
      };
    }

    const data = await services.post("/purchase/app/payableAmount", body);
    return data.data;
  } else {
    return {
      actualAmount: 0,
      amountWithoutDiscount: 0,
      couponDiscount: 0,
      couponStatus: "Coupon code is not provided",
      itemAmount: 0,
      success: true,
      taxAmount: 0,
      totalBillingAmount: 0,
    };
  }
};

export const getTotalItems = (basket) => {
  let totalQuantity = 0;

  if (basket.length > 0) {
    totalQuantity = basket.reduce((quant, item) => quant + item.quantity, 0);
  }

  return totalQuantity;
};

const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_TO_BASKET":
      return {
        ...state,
        basket: [...state.basket, action.item],
      };
    case "REMOVE":
      const index = state.basket.findIndex((basketitem) => basketitem.id === action.item);
      let newbasket = [...state.basket];
      newbasket.splice(index, 1);
      return {
        ...state,
        basket: [...newbasket],
      };

    case "UPDATE_BASKET":
      let temp_basket = action.basket.filter((item) => item.quantity > 0);
      return {
        ...state,
        basket: temp_basket,
      };

    case "UPDATE_USER":
      console.log("UPDATING USER", action);
      return {
        ...state,
        user_id: action.data.user_id,
        email: action.data.email,
        firstName: action.data.firstname,
        lastName: action.data.lastname,
        ph_no: action.data.ph_no,
        country: action.data.country,
      };

      break;

    case "EMPTY_BASKET":
      return {
        ...state,
        basket: [],
      };

    default:
      return state;
  }
};

export default reducer;
