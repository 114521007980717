import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Close } from "@mui/icons-material";
// import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import ServiceCM from "../../services/cartmoduleServices";
import "../Pricing/OurPackages/OurPackages.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Typography } from "@mui/material";
import BottomNavbar from "../LandingPage/Footer/BottomNavbar";
import { getPayableAmount } from "../../components/StateProvider/reducer";
import { useStateValue } from "../../components/StateProvider/stateProvider";

export default function CartOrderCancelled({ props }) {
  const location = useLocation();
  const transcationResponce = location.state.data;
  const sku_id = location.state.sku ? location.state.sku : null;
  const coupon_code = location.state?.couponCode;
  const [{ basket }, dispatch] = useStateValue();
  const Mobile = useMediaQuery("(max-width:800px)");

  const [tempQuantity, setTempQuantity] = useState({});
  const serviceCM = new ServiceCM();

  const tempBundleInfo = location.state && location.state.temp_quantity;
  console.log("temp quantoity in chat screen", tempBundleInfo);

  const heading = [
    {
      tableHeader: "Item",
    },
    {
      tableHeader: "Price",
    },
    {
      tableHeader: "Quantity",
    },
    {
      tableHeader: "Total",
    },
  ];
  const [subTotal, setSubTotal] = useState(0);
  const [taxes, setTaxes] = useState(subTotal * 0.18);
  const [GrandTotal, setGrandTotal] = useState(0);
  const [amountWithoutDiscount, setAmountWithOutDiscount] = useState(0);
  const [itemAmount, setItemAmount] = useState(0);
  const [couponDiscount, setCouponDiscount] = useState(0);

  const bottomBillingSections = [
    {
      title: "Total value",
      value: subTotal,
    },
    {
      title: "Discount",
      value: subTotal - amountWithoutDiscount,
    },
    {
      title: "coupon discount",
      value: couponDiscount,
    },
    {
      title: "Subtotal",
      value: subTotal,
    },

    {
      title: "GST (18%)",
      value: taxes,
    },
    {
      title: "Total Paid",
      value: "Payment Cancelled",
    },
  ];
  const navigate = useNavigate();

  // get bundle and sku data
  const [skuData, setSkuData] = useState([]);
  const [bundleData, setBundleData] = useState([]);

  const [cartBundleData, setCartBundleData] = useState([]);
  const [cartSKUData, setCartSKUData] = useState([]);

  // const [disableCheckOut, setDisableCheckOut] = useState(true);
  // const [subTotal, setSubTotal] = useState(0);
  // useEffect for pricing
  const today = new Date().toLocaleDateString();
  useEffect(() => {
    const handleEffect = async () => {
      if (sku_id === null) {
        const storedTempQuantity = JSON.parse(localStorage.getItem("temp_quantity"));
        console.log("storedTempQuantity", storedTempQuantity);
        if (storedTempQuantity) {
          setTempQuantity(storedTempQuantity);
        }

        let bundle = await bundleGetter();
        bundle?.forEach((element) => {
          const storedItem = storedTempQuantity.find((item) => item.id === element.id && item.search_tbl === "bundle");
          if (storedItem) {
            element.quantity = storedItem.quantity;
            element.total_price = storedItem.total_price;
          }
        });
        let sku = await SKUGetter();
        sku?.forEach((element) => {
          const storedItem = storedTempQuantity.find((item) => item.id === element.id && item.search_tbl === "sku");
          if (storedItem) {
            element.quantity = storedItem.quantity;
            element.total_price = storedItem.total_price;
          }
        });
        // bundle info
        let temp_cart_bundle = bundle?.map((testing) => ({
          id: testing.id,
          name: testing.name,
          price: testing.actual_price,
          quantity: testing.quantity,
          total_price: testing.total_price,
        }));
        console.log("local storage temp bundle", temp_cart_bundle);

        // sku info
        let temp_cart_skus = sku.map((testing) => ({
          id: testing.id,
          name: testing.name,
          price: testing.actual_price,
          quantity: testing.quantity,
          total_price: testing.total_price,
        }));
        let temp_cart_filtering = temp_cart_bundle.filter((item, i) => storedTempQuantity.some((sq) => sq.id === item.id && sq.search_tbl === "bundle"));
        setCartBundleData(temp_cart_filtering);
        let temp_sku_cart_filtering = temp_cart_skus.filter((item, i) => storedTempQuantity.some((sq) => sq.id === item.id && sq.search_tbl === "sku"));
        setCartSKUData(temp_sku_cart_filtering);

        let subTotal = 0;
        bundle.forEach((item) => {
          if (typeof item.total_price === "number") {
            subTotal += item.total_price;
          }
        });
        sku.forEach((item) => {
          if (typeof item.total_price === "number") {
            subTotal += item.total_price;
          }
        });
        const data = await getPayableAmount(basket, coupon_code);

        setSubTotal(data.actualAmount);

        setTaxes(data.taxAmount);

        setGrandTotal(data.totalBillingAmount);

        setItemAmount(data.itemAmount);
        setCouponDiscount(data.couponDiscount);
        setAmountWithOutDiscount(data.amountWithoutDiscount);

        data_manupulator_bundle(bundle);
      } else {
        let sku = await SKUGetter();
        let temp_cart_skus = sku.map((testing) => ({
          id: testing.id,
          name: testing.name,
          price: testing.price,
          quantity: testing.quantity,
          total_price: testing.total_price,
        }));
        let temp_sku_cart_filtering = temp_cart_skus.filter((item, i) => item.id === sku_id);
        temp_sku_cart_filtering = temp_sku_cart_filtering.map((item) => {
          return {
            ...item,
            quantity: 1,
            total_price: 1 * item.price,
          };
        });
        setCartSKUData(temp_sku_cart_filtering);

        const data = await getPayableAmount([{ id: sku_id, quantity: 1, search_tbl: "sku" }]);
        setSubTotal(data.amountWithoutDiscount);
        setTaxes(data.taxAmount);
        setGrandTotal(data.totalBillingAmount);
        // setItemAmount(data.itemAmount);
        // setActualAmount(data.actualAmount);
        // setTaxAmount(data.taxAmount);
        // setTotalBillingAmount(data.totalBillingAmount);
        // setCouponDiscount(data.couponDiscount);
        // setAmountWithOutDiscount(data.amountWithoutDiscount);
      }
    };
    // subtotal
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    handleEffect();
  }, []);

  const bundleGetter = async () => {
    try {
      const res = await serviceCM.get("/bundle");
      console.log("Duration RESPONSE:", res.data);
      setBundleData([...res.data]);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const SKUGetter = async () => {
    try {
      const res = await serviceCM.get("/sku?type=1 X 1");
      console.log("Duration RESPONSE:", res.data);
      setSkuData([...res.data]);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const data_manupulator_bundle = (bundle) => {
    console.log("SKU data:", bundle);
    let temp_rowsData = JSON.parse(JSON.stringify(bundle));
    temp_rowsData = temp_rowsData.map((row) => {
      delete row.is_deleted;
      delete row.skus;
      delete row.footer_desc;
      delete row.header_desc;
      return row;
    });
    console.log("temp rows:", temp_rowsData);
  };

  return (
    <div className="login-Container" style={{ height: "auto" }}>
      <div style={{ marginTop: "100px", justifyContent: "center" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              padding: Mobile ? "8px" : "8px",
              background: "rgba(255, 0, 0, 1)",
              borderRadius: "50%",
            }}
          >
            <Close
              style={{
                color: "#FFF",
                height: "70px",
                width: "70px",
                justifyContent: "center",
                margin: "0 auto",
              }}
            />
          </Box>
        </Box>
        <Typography
          sx={{
            fontSize: Mobile ? "24px" : "36px",
            color: "#F00",
            textAlign: "center",
          }}
        >
          Payment Cancelled
        </Typography>

        <Typography
          sx={{
            fontSize: Mobile ? "14px" : "16px",
            color: "#8B8B8B",
            textAlign: "center",
          }}
        >
          Unfortunately, the bank has declined your transaction.
          <br /> If funds were debited, they will be refunded in 3-5 business days
        </Typography>

        <button
          onClick={() => navigate("/CartScreen")}
          className="StartSEssionbutton-outline"
          style={{
            justifyContent: "center",
            width: "200px",
            margin: "0 auto",
            marginTop: "2rem",
          }}
        >
          Retry Payment
        </button>
      </div>
      {/* <div className="login-Container" style={{ padding: "2rem" }}> */}
      <p className={Mobile ? "ordersummarytextMObile" : "billingCheckout"}>Order Summary</p>

      <div
        // className="login-Main-content"
        style={{
          margin: "0 auto 2rem auto",
          width: Mobile ? "90%" : "60%",
          left: "20%",
          right: "20%",
        }}
      >
        <TableContainer className={Mobile ? "login-form-billing-mobile" : "login-form-billing"}>
          <Table
            sx={{
              minWidth: Mobile ? "100%" : 600,
            }}
            aria-label="spanning table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  className="billingCheckout"
                  align="left"
                  colSpan={3}
                  style={{
                    fontSize: "16px",
                    justifyContent: "center",
                    borderBottom: "none",
                  }}
                >
                  Order Summary
                  <br />
                  Order# : {transcationResponce.txnid}
                  <br />
                  Order Date : {today}
                </TableCell>
              </TableRow>

              <TableRow>
                {heading.map((names) => (
                  <TableCell style={{ color: "#545454" }}>{names.tableHeader}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {cartBundleData.map((row, i) => (
                <TableRow className="tableBodyHeight" key={row.id}>
                  <TableCell style={{ fontSize: "18px" }}>{row.name}</TableCell>
                  <TableCell style={{ padding: Mobile ? "0" : "16px" }}>&#x20b9; {row.price}</TableCell>
                  <TableCell style={{ padding: Mobile ? "0" : "16px" }} align={Mobile ? "center" : "left"}>
                    {row.quantity}
                  </TableCell>
                  <TableCell>&#x20b9; {row.price * row.quantity}</TableCell>
                </TableRow>
              ))}
              {cartSKUData.map((row, i) => (
                <TableRow key={row.id}>
                  <TableCell style={{ fontSize: "18px" }}>{row.name}</TableCell>
                  <TableCell style={{ padding: Mobile ? "0" : "16px" }}>&#x20b9; {row.price}</TableCell>
                  <TableCell style={{ padding: Mobile ? "0" : "16px" }} align={Mobile ? "center" : "left"}>
                    {row.quantity}
                  </TableCell>
                  <TableCell style={{ padding: Mobile ? "0" : "16px" }}>&#x20b9; {row.total_price}</TableCell>
                </TableRow>
              ))}

              {bottomBillingSections.map((names, i) =>
                names.title === "coupon discount" ? (
                  names.value > 0 && (
                    <>
                      {Mobile ? (
                        <>
                          <TableRow>
                            <TableCell>{names.title}</TableCell>
                            <TableCell colSpan={2} style={{ padding: Mobile ? "0" : "16px" }} />

                            <TableCell
                              align="left"
                              style={{
                                padding: Mobile ? "0" : "16px",
                                color: "#08AB3F",
                                fontSize: names.title === "Total Paid" ? "20px" : "16px",
                              }}
                            >
                              -&#x20b9; {names.value}
                            </TableCell>
                          </TableRow>
                        </>
                      ) : (
                        <TableRow>
                          <TableCell colSpan={Mobile ? 0 : 2} />
                          <TableCell>{names.title}</TableCell>
                          <TableCell
                            style={{
                              color: names.title === "Total Paid" ? "#F00" : "text.primary",
                              fontSize: names.title === "Total Paid" ? "18px" : "16px",
                            }}
                          >
                            -&#x20b9;{names.value}
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  )
                ) : (
                  <>
                    {Mobile ? (
                      <>
                        <TableRow>
                          <TableCell>{names.title}</TableCell>
                          <TableCell colSpan={2} style={{ padding: Mobile ? "0" : "16px" }} />

                          <TableCell
                            align="left"
                            style={{
                              padding: Mobile ? "0" : "16px",
                              color: names.title === "Total Paid" ? "#08AB3F" : "text.primary",
                              fontSize: names.title === "Total Paid" ? "20px" : "16px",
                            }}
                          >
                            {names.title === "Discount" && "-"}&#x20b9; {names.value}
                          </TableCell>
                        </TableRow>
                      </>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={Mobile ? 0 : 2} />
                        <TableCell>{names.title}</TableCell>
                        <TableCell
                          style={{
                            color: names.title === "Total Paid" ? "#F00" : "text.primary",
                            fontSize: names.title === "Total Paid" ? "18px" : "16px",
                          }}
                        >
                          {names.title !== "Total Paid" ? <> {names.title === "Discount" && "-"}&#x20b9;</> : <></>}
                          {names.value}
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {/* </div> */}
      <Box
        sx={{
          marginTop: Mobile ? "10px" : "",
          background: "linear-gradient(226deg, #1D2C30 0%, #020303 100%)",
        }}
      >
        <BottomNavbar />
      </Box>
    </div>
  );
}
