import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../../components/StateProvider/stateProvider";
import ServiceCM from "../../../services/cartmoduleServices";
import BottomNavbar from "../Footer/BottomNavbar";
import { useWebinarContext } from "../../../WebinarContext";
function NewResumeDiscussion() {
  const [{ basket }, dispatch] = useStateValue();
  const env = `${process.env.REACT_APP_ENVIRONMENT}`;
  const services = new ServiceCM();
  const [sku, setSku] = useState([]);
  const { webinarTickerExists } = useWebinarContext();
  const navigate = useNavigate();
  const handleNavigate = (value) => {
    window.scroll(0, 0);
    navigate(value);
  };
  const Mobile = useMediaQuery("(max-width:800px)");
  const addToCart = () => {
    let temp_cart = JSON.parse(localStorage.getItem("temp_quantity")) ?? [];
    console.log("cart item", temp_cart);
    const id = env === "dev" ? 5 : 5;
    let item = temp_cart.filter((item) => item.id === id);
    console.log("Item", item, temp_cart);
    if (item.length > 0) {
      temp_cart = temp_cart.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            quantity: item.quantity + 1,
            total_price: item.total_price + item.total_price / item.quantity,
          };
        } else {
          return item;
        }
      });
    } else {
      let find_sku = sku.find((item) => item.id === id);
      console.log("find_sku", find_sku);
      let data = {
        search_tbl: "sku",
        id: id,
        quantity: 1,
        total_price: find_sku.actual_price,
      };
      temp_cart = [...temp_cart, data];
    }

    console.log("cart item", temp_cart);
    dispatch({ type: "UPDATE_BASKET", basket: temp_cart });
    localStorage.setItem("temp_quantity", JSON.stringify(temp_cart));
  };

  const getSku = async () => {
    const res = await services.get("/sku?type=1 X 1");
    setSku(res.data);
  };

  useEffect(() => {
    getSku();
  }, []);
  return (
    <>
      {/* this provides the meta description of each page separately */}
      <Helmet>
        <title>Join the Discussion: Expert Resume Review for MBA Placements</title>
        <meta name="description" content="Elevate your resume with expert feedback and a collaborative discussion amongst aspiring MBAs. Secure your dream job!" />
        <link rel="canonical" href="/ResumeDiscussion" />

        <meta property="og:title" content="CareerCarve-Resume Discussion Session" />
        <meta property="og:image" content="./public/logo_welcome.png" />
        <meta property="og:url" content="https://www.careercarve.com/ResumeDiscussion" />
      </Helmet>
      <Box sx={{ marginTop: webinarTickerExists && Mobile ? "80px" : !webinarTickerExists && Mobile ? "50px" : webinarTickerExists ? "112px" : "70px" }}>
        {/* <Navbar /> */}
        <Box sx={{ marginBottom: Mobile ? "1.5rem" : "4.5rem" }}>
          <Box>
            <img
              src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/ResumeDiscussion.png"
              alt="Master Your MBA Resume: Get Expert Feedback with Resume Discussions"
              style={{
                height: Mobile ? "15vh" : "100%",
                width: "100%",
                marginBottom: Mobile ? "-5%" : "-2%",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              fontSize={Mobile ? "24px" : "36px"}
              variant="h1"
              sx={{
                zIndex: "20",
                bgcolor: "#FFF",
                padding: "8px 46px",
                boxShadow: "0px 6px 49px 0px rgba(0, 0, 0, 0.08)",
              }}
            >
              Resume Discussion
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingInline: Mobile ? "15px" : "9.5rem",
            gap: Mobile ? "4rem" : "4.5rem",
            paddingBottom: "4.5rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: Mobile ? "0.5rem" : "1.5rem",
            }}
          >
            <Typography variant={Mobile ? "h2" : "h2"} fontSize={Mobile ? "20px" : "24px"} textAlign={Mobile ? "center" : ""}>
              Resume Review by <span style={{ color: "#1C8EA8" }}>Experts</span>
            </Typography>
            <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
              Welcome to CareerCarve's Resume Discussion Session, a personalized and in-depth experience that will help you craft a winning resume tailored for the competitive job market. Your resume is your first impression on potential employers, and our expert-led sessions are designed to make it stand out from the crowd.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: Mobile ? "column" : "row",
            }}
          >
            <Box width="100%" display="flex" flexDirection="column" gap={Mobile ? "0.5rem" : "1.5rem"}>
              <Typography fontSize={Mobile ? "20px" : "24px"} variant={Mobile ? "h5" : "h4"}>
                Why take a <span style={{ color: "#1C8EA8" }}>Resume Discussion</span> Session?
              </Typography>
              <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                A Resume Discussion Session with CareerCarve is an interactive and one-on-one consultation with industry experts who have extensive experience in their domain. During the session, our mentors will review your resume with a keen eye for detail, providing valuable feedback and recommendations to optimize your document. We focus on creating a resume that not only highlights your skills and achievements but also aligns with the specific requirements of your target job or industry.
              </Typography>
              <button
                className="button-yellow-hover"
                style={{
                  width: "fit-content",
                  padding: "8px 2rem",
                  alignSelf: Mobile ? "center" : "",
                }}
                onClick={() => {
                  addToCart();
                }}
              >
                <Typography>Add to cart</Typography>
              </button>
            </Box>
            <Box width="100%" display={"flex"} justifyContent={"center"} alignItems={"center"}>
              <img
                src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/Resume+Discussion+picart.png"
                alt="MBA Resume Review: Get Expert Feedback & Boost Your Applications"
                style={{
                  width: Mobile ? "250px" : "396px",
                  height: Mobile ? "100%" : "325px",
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: Mobile ? "0.5rem" : "1.5rem",
            }}
          >
            <Typography fontSize={Mobile ? "20px" : "24px"} variant={Mobile ? "h5" : "h4"}>
              How does it <span style={{ color: "#1C8EA8" }}>benefit</span> me?
            </Typography>
            <Box display="flex" flexDirection="column" gap="2rem">
              <Box display="flex" gap="0.5rem" flexDirection="column" sx={{ borderLeft: "2px solid #1C8EA8", paddingLeft: "1rem" }}>
                <Typography fontSize={Mobile ? "16px" : "20px"}>Tailored resume</Typography>
                <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                  Tailor your resume to showcase your strengths and accomplishments in a way that resonates with potential employers.
                </Typography>
              </Box>

              <Box display="flex" gap="0.5rem" flexDirection="column" sx={{ borderLeft: "2px solid #F5A536", paddingLeft: "1rem" }}>
                <Typography fontSize={Mobile ? "16px" : "20px"} variant={Mobile ? "h6" : "h5"}>
                  Key achievements
                </Typography>
                <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                  Our experts know what employers look for in a resume & will help you emphasize your most significant achievements, making your resume impactful and compelling.
                </Typography>
              </Box>

              <Box display="flex" gap="0.5rem" flexDirection="column" sx={{ borderLeft: "2px solid #1C8EA8", paddingLeft: "1rem" }}>
                <Typography fontSize={Mobile ? "16px" : "20px"} variant={Mobile ? "h6" : "h5"}>
                  Personalized Feedback
                </Typography>
                <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                  Each Resume Discussion Session is tailored to your unique background & you will receive personalized feedback and recommendations to make your resume stand out.
                </Typography>
              </Box>

              <Box display="flex" gap="0.5rem" flexDirection="column" sx={{ borderLeft: "2px solid #F5A536", paddingLeft: "1rem" }}>
                <Typography fontSize={Mobile ? "16px" : "20px"} variant={Mobile ? "h6" : "h5"}>
                  Professional Touch
                </Typography>
                <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                  One cannot be sure if the resume they made fits their industry requirement. The mentors will guide you to make a professional resume suited for your domain.
                </Typography>
              </Box>

              <Box display="flex" gap="0.5rem" flexDirection="column" sx={{ borderLeft: "2px solid #1C8EA8", paddingLeft: "1rem" }}>
                <Typography fontSize={Mobile ? "16px" : "20px"} variant={Mobile ? "h6" : "h5"}>
                  Resume Tips 101
                </Typography>
                <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                  Along with a Resume Discussion session with an industry expert, also get access to 100's of podcasts and sessions to help you prepare better.
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" flexDirection={Mobile ? "column" : "row"} alignItems="center" gap={"8rem"}>
            <Box display="flex" justifyContent="space-between" gap="1.5rem" flexDirection="column">
              <Typography fontSize={Mobile ? "20px" : "24px"} variant={Mobile ? "h5" : "h4"}>
                Where will the <span style={{ color: "#1C8EA8" }}>session happen</span>?
              </Typography>
              <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                Our Resume Discussion Sessions are conducted online, providing you with the flexibility to participate from anywhere. During a 30 mins session our mentor will read through your resume line-by-line. To schedule a session, head to the CareerCarve App now and select a convinient slot!
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: Mobile ? "center" : "",
                }}
              >
                {!Mobile && <img src="../../QR_code_sign_up.jpg" style={{ height: "78px", width: "78px" }} alt="careercarve" />}
                <a href="https://play.google.com/store/apps/details?id=com.careercarve&hl=en&gl=US" target="_blank" title="careercarve-mobile-app" rel="noreferrer" style={{ textDecoration: "none" }}>
                  <button className="button-yellow-hover" style={{ width: "fit-content" }}>
                    <Typography> Download the App</Typography>
                  </button>
                </a>
              </Box>
            </Box>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                width: Mobile ? "200px" : "385px",
                height: Mobile ? "350px" : "385px",
                background: "rgba(56, 193, 223, 0.20)",
              }}
              paddingInline={"10rem"}
              borderRadius={"50%"}
            >
              <img
                src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/1+on+1+Mobile.png"
                alt="Fast & Effective: Expert Online Review for Your MBA Resume"
                style={{
                  width: Mobile ? "220px" : "243px",
                  height: Mobile ? "380px" : "480px",
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: Mobile ? "1.5rem" : "2.5rem",
            }}
          >
            <Typography fontSize={Mobile ? "24px" : "32px"} color="#1C8EA8" textAlign="center">
              View Other 1X1
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "100%",
                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                    padding: "1rem",
                    height: "272px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                    }}
                  >
                    <Typography fontSize={Mobile ? "20px" : "24px"} variant={Mobile ? "h5" : "h4"}>
                      PI Questions from Resume
                    </Typography>
                    <Typography fontSize={Mobile ? "14px" : "16px"} color="#8B8B8B" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                      The Personal Interview Questions from Resume Session at CareerCarve is a one-on-one consultation with experienced industry mentors.
                    </Typography>
                  </Box>
                  <button className="button-yellow-hover" onClick={() => handleNavigate("/PIQR")}>
                    <Typography>View More</Typography>
                  </button>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                {" "}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "100%",
                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                    padding: "1rem",
                    height: "272px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                    }}
                  >
                    <Typography fontSize={Mobile ? "20px" : "24px"} variant={Mobile ? "h5" : "h4"}>
                      Mock Interview
                    </Typography>
                    <Typography fontSize={Mobile ? "14px" : "16px"} color="#8B8B8B" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                      A Resume Discussion Session with CareerCarve is an interactive and one-on-one consultation with industry experts who have extensive experience in their domain.
                    </Typography>
                  </Box>
                  <button className="button-yellow-hover" onClick={() => handleNavigate("/MockInterview")}>
                    <Typography>View More</Typography>
                  </button>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "100%",
                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                    padding: "1rem",
                    height: "272px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                    }}
                  >
                    <Typography fontSize={Mobile ? "20px" : "24px"} variant={Mobile ? "h5" : "h4"}>
                      SIP Success
                    </Typography>
                    <Typography fontSize={Mobile ? "14px" : "16px"} color="#8B8B8B" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                      The SIP Success Session at CareerCarve is a comprehensive and personalized program aimed at helping you excel in your Summer Internship Program.
                    </Typography>
                  </Box>
                  <button className="button-yellow-hover" onClick={() => handleNavigate("/SipSuccess")}>
                    <Typography>View More</Typography>
                  </button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          background: "linear-gradient(226deg, #1D2C30 0%, #020303 100%)",
        }}
      >
        <BottomNavbar />
      </Box>
    </>
  );
}

export default NewResumeDiscussion;
