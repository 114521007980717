import axios from "axios";
import Cookies from "js-cookie";
const api = axios.create({
  // baseURL: "https://backend.resumes.digital/api",
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

class RDService {
  post = async (endpoint, data) => {
    let res = await api.post(endpoint, data);
    return res;
  };

  get = async (endpoint) => {
    let res = await api.get(endpoint);
    return res.data;
  };

  delete = async (endpoint) => {
    let res = await api.delete(endpoint);
    return res.data;
  };

  put = async (endpoint, data) => {
    let res = await api.put(endpoint, data);
    return res.data;
  };
}

export default RDService;
