import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../../components/StateProvider/stateProvider";
import ServiceCM from "../../../services/cartmoduleServices";
import BottomNavbar from "../Footer/BottomNavbar";
import { useWebinarContext } from "../../../WebinarContext";

function NewCaseInterview() {
  const navigate = useNavigate();
  const [{ basket }, dispatch] = useStateValue();
  const services = new ServiceCM();
  const { webinarTickerExists } = useWebinarContext();
  const env = `${process.env.REACT_APP_ENVIRONMENT}`;
  const [sku, setSku] = useState([]);
  const handleNavigate = (value) => {
    window.scroll(0, 0);
    navigate(value);
  };
  const Mobile = useMediaQuery("(max-width:800px)");
  const addToCart = () => {
    let temp_cart = JSON.parse(localStorage.getItem("temp_quantity")) ?? [];
    console.log("cart item", temp_cart);
    const id = env === "dev" ? 3 : 3;
    let item = temp_cart.filter((item) => item.id === id);
    if (item.length > 0) {
      temp_cart = temp_cart.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            quantity: item.quantity + 1,
            total_price: item.total_price + item.total_price / item.quantity,
          };
        } else {
          return item;
        }
      });
    } else {
      let find_sku = sku.find((item) => item.id === id);
      console.log("find_sku", find_sku);
      let data = {
        search_tbl: "sku",
        id: id,
        quantity: 1,
        total_price: find_sku.actual_price,
      };
      temp_cart = [...temp_cart, data];
    }

    console.log("cart item", temp_cart);
    dispatch({ type: "UPDATE_BASKET", basket: temp_cart });
    localStorage.setItem("temp_quantity", JSON.stringify(temp_cart));
  };

  const getSku = async () => {
    const res = await services.get("/sku?type=1 X 1");
    setSku(res.data);
  };

  useEffect(() => {
    getSku();
  }, []);
  return (
    <>
      {/* this provides the meta description of each page separately */}
      <Helmet>
        <title>Master the Case Interview: Unlock Your Potential for Top MBA Jobs</title>
        <meta name="description" content="Gain the confidence to excel in your MBA interview with proven case interview strategies from CareerCarve" />

        <meta property="og:title" content="CareerCarve-Case Interview Preparation" />
        <meta property="og:image" content="/public/logo_welcome.png" />
        <meta property="og:url" content="https://www.careercarve.com/CaseInterview" />
        <link rel="canonical" href="/CaseInterview" />
      </Helmet>
      <Box sx={{ marginTop: webinarTickerExists && Mobile ? "80px" : !webinarTickerExists && Mobile ? "50px" : webinarTickerExists ? "112px" : "70px" }}>
        {/* <Navbar /> */}
        <Box sx={{ marginBottom: Mobile ? "1.5rem" : "4.5rem" }}>
          <Box>
            <img
              src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/caseInterview.png"
              alt="Conquer Case Interviews: See a Breakdown for MBA Placements"
              style={{
                height: Mobile ? "15vh" : "100%",
                width: "100%",
                marginBottom: Mobile ? "-5%" : "-2%",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h1"
              fontSize={Mobile ? "24px" : "36px"}
              sx={{
                zIndex: "20",
                bgcolor: "#FFF",
                fontWeight: "400",
                padding: "8px 30px",
                boxShadow: "0px 6px 49px 0px rgba(0, 0, 0, 0.08)",
              }}
            >
              Case Interview
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingInline: Mobile ? "15px" : "9.5rem",
            gap: "4.5rem",
            paddingBottom: "4.5rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: Mobile ? "0.5rem" : "1.5rem",
            }}
          >
            <Typography variant={Mobile ? "h2" : "h2"} fontSize={Mobile ? "20px" : "24px"} textAlign={Mobile ? "center" : ""}>
              Case Interview
            </Typography>
            <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
              Welcome to CareerCarve's Case Interview Session, a comprehensive and interactive experience designed to help you master the challenging world of case interviews. Case interviews are a critical component of the recruitment process for many top consulting and business firms, and with our expert-led sessions, you'll be well-prepared to tackle any case with confidence.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: Mobile ? "column" : "row",
            }}
          >
            <Box width="100%" display="flex" flexDirection="column" gap={Mobile ? "0.5rem" : "1.5rem"}>
              <Typography fontSize={Mobile ? "20px" : "24px"} variant={Mobile ? "h5" : "h4"}>
                Why take a <span style={{ color: "#1C8EA8" }}>Case Interview</span> Session?
              </Typography>
              <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                The Case Interview Session at CareerCarve is a focused and personalized consultation with experienced industry mentors who have a strong background in consulting and business strategy. During this session, our mentors will guide you through solving case scenarios, simulating real-world business problems that candidates often encounter during case interviews. Through hands-on practice and feedback, you'll learn how to analyze and approach complex business challenges effectively.
              </Typography>
              <button
                className="button-yellow-hover"
                style={{
                  width: "fit-content",
                  padding: "8px 2rem",
                  alignSelf: Mobile ? "center" : "",
                }}
                onClick={() => {
                  addToCart();
                }}
              >
                <Typography>Add to cart</Typography>
              </button>
            </Box>
            <Box width="100%" display={"flex"} justifyContent={"center"} alignItems={"center"}>
              <img
                src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/caseinterview+picart.png"
                alt="MBA Case Interview Edge: Approach, Skills, Support & More"
                style={{
                  width: Mobile ? "200px" : "396px",
                  height: Mobile ? "100%" : "325px",
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: Mobile ? "0.5rem" : "1.5rem",
            }}
          >
            <Typography fontSize={Mobile ? "20px" : "24px"} variant={Mobile ? "h5" : "h4"}>
              How does it <span style={{ color: "#1C8EA8" }}>benefit</span> me?
            </Typography>
            <Box display="flex" flexDirection="column" gap="2rem">
              <Box display="flex" gap="0.5rem" flexDirection="column" sx={{ borderLeft: "2px solid #1C8EA8", paddingLeft: "1rem" }}>
                <Typography fontSize={Mobile ? "16px" : "20px"} textAlign={"left"} variant={Mobile ? "h6" : "h5"}>
                  Approach Strategy
                </Typography>
                <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                  In this session, you'll learn the nuances of each case type and how to approach them with a structured and analytical mindset.
                </Typography>
              </Box>

              <Box display="flex" gap="0.5rem" flexDirection="column" sx={{ borderLeft: "2px solid #F5A536", paddingLeft: "1rem" }}>
                <Typography fontSize={Mobile ? "16px" : "20px"} textAlign={"left"} variant={Mobile ? "h6" : "h5"}>
                  Problem-Solving Skills
                </Typography>
                <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                  Case interviews assess your problem-solving abilities. By working with our mentors, you'll enhance your critical thinking and analytical skills, making you a more competent candidate.
                </Typography>
              </Box>

              <Box display="flex" gap="0.5rem" flexDirection="column" sx={{ borderLeft: "2px solid #1C8EA8", paddingLeft: "1rem" }}>
                <Typography fontSize={Mobile ? "16px" : "20px"} textAlign={"left"} variant={Mobile ? "h6" : "h5"}>
                  Mid-way through Support
                </Typography>
                <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                  After 2-3 weeks in your internship, you'll have a 1X1 session with an industry mentor to discuss the aim, actions taken so far, future plans, and expected outcomes based on guidelines provided in the kit.
                </Typography>
              </Box>

              <Box display="flex" gap="0.5rem" flexDirection="column" sx={{ borderLeft: "2px solid #F5A536", paddingLeft: "1rem" }}>
                <Typography fontSize={Mobile ? "16px" : "20px"} textAlign={"left"} variant={Mobile ? "h6" : "h5"}>
                  Communication & Presentation
                </Typography>
                <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                  Effectively communicating your thought process and solutions is crucial in case interviews. This session will ensure you can convey your ideas clearly and persuasively.
                </Typography>
              </Box>

              <Box display="flex" gap="0.5rem" flexDirection="column" sx={{ borderLeft: "2px solid #1C8EA8", paddingLeft: "1rem" }}>
                <Typography fontSize={Mobile ? "16px" : "20px"} textAlign={"left"} variant={Mobile ? "h6" : "h5"}>
                  Case Interview Guide
                </Typography>
                <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                  Along with a Resume Discussion session with an industry expert, also get access to 100's of podcasts and sessions to help you prepare better.
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" flexDirection={Mobile ? "column" : "row"} alignItems="center" gap={"8rem"}>
            <Box display="flex" justifyContent="space-between" gap={Mobile ? "0.5rem" : "1.5rem"} flexDirection="column">
              <Typography fontSize={Mobile ? "20px" : "24px"} variant={Mobile ? "h5" : "h4"}>
                Where will the <span style={{ color: "#1C8EA8" }}>session happen</span>?
              </Typography>
              <Typography fontSize={Mobile ? "14px" : "16px"} color="#545454" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                Our Case Interview Session is conducted online, offering you the convenience of participating from anywhere. During the 30 min session, our mentor will present you with a case scenario and guide you through the problem-solving process. Book your Case Interview Session now!
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: Mobile ? "center" : "",
                }}
              >
                {!Mobile && <img src="../../QR_code_sign_up.jpg" style={{ height: "78px", width: "78px" }} alt="careercarve" />}
                <a href="https://play.google.com/store/apps/details?id=com.careercarve&hl=en&gl=US" target="_blank" title="careercarve-mobile-app" rel="noreferrer" style={{ textDecoration: "none" }}>
                  <button
                    className="button-yellow-hover"
                    style={{
                      width: "fit-content",
                      alignSelf: Mobile ? "center" : "",
                    }}
                    href="https://play.google.com/store/apps/details?id=com.careercarve&hl=en&gl=US"
                    target="_blank"
                  >
                    <Typography> Download the App</Typography>
                  </button>
                </a>
              </Box>
            </Box>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                width: Mobile ? "200px" : "385px",
                height: Mobile ? "350px" : "385px",
                background: "rgba(56, 193, 223, 0.20)",
              }}
              paddingInline={"10rem"}
              borderRadius={"50%"}
            >
              <img
                src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/1+on+1+Mobile.png"
                alt="Online Case Interview Prep: Get Expert Guidance at Your Fingertips"
                style={{
                  width: Mobile ? "220px" : "243px",
                  height: Mobile ? "380px" : "480px",
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: Mobile ? "1.5rem" : "2.5rem",
            }}
          >
            <Typography fontSize={Mobile ? "24px" : "32px"} color="#1C8EA8" textAlign="center">
              View Other 1X1
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "100%",
                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                    padding: "1rem",
                    height: "272px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                    }}
                  >
                    <Typography fontSize={Mobile ? "20px" : "24px"} variant={Mobile ? "h5" : "h4"}>
                      Mock Interview
                    </Typography>
                    <Typography fontSize={Mobile ? "14px" : "16px"} color="#8B8B8B" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                      Mock interviews are simulated job interviews that closely resemble real-life interview scenarios.
                    </Typography>
                  </Box>
                  <button className="button-yellow-hover" onClick={() => handleNavigate("/MockInterview")}>
                    <Typography>View More</Typography>
                  </button>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "100%",
                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                    padding: "1rem",
                    height: "272px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                    }}
                  >
                    <Typography fontSize={Mobile ? "20px" : "24px"} variant={Mobile ? "h5" : "h4"}>
                      Expert Connect
                    </Typography>
                    <Typography fontSize={Mobile ? "14px" : "16px"} color="#8B8B8B" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                      The Expert Connect Session at CareerCarve is an open and flexible discussion platform that puts you in touch with a mentor who shares your area of interest.
                    </Typography>
                  </Box>
                  <button className="button-yellow-hover" onClick={() => handleNavigate("/ExpertConnect")}>
                    <Typography>View More</Typography>
                  </button>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "100%",
                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                    padding: "1rem",
                    height: "272px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                    }}
                  >
                    <Typography fontSize={Mobile ? "20px" : "24px"} variant={Mobile ? "h5" : "h4"}>
                      PI Questions from Resume
                    </Typography>
                    <Typography fontSize={Mobile ? "14px" : "16px"} color="#8B8B8B" textAlign={"justify"} variant={Mobile ? "body2" : "body1"}>
                      The Personal Interview Questions from Resume Session at CareerCarve is a one-on-one consultation with experienced industry mentors.
                    </Typography>
                  </Box>
                  <button className="button-yellow-hover" onClick={() => handleNavigate("/PIQR")}>
                    <Typography>View More</Typography>
                  </button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          background: "linear-gradient(226deg, #1D2C30 0%, #020303 100%)",
        }}
      >
        <BottomNavbar />
      </Box>
    </>
  );
}

export default NewCaseInterview;
