import { useMediaQuery } from "@mui/system";
import React, { useEffect, useState } from "react";
import RDService from "../../services/RDService";
import { useStateValue } from "../../components/StateProvider/stateProvider";
import { useWebinarContext } from "../../WebinarContext";
import { Box, FormControl, Grid, IconButton, InputLabel, MenuItem, OutlinedInput, Select, Skeleton, TextField, Typography } from "@mui/material";
import MentorCard from "./MentorCard";
import { Search } from "@mui/icons-material";
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
};
function NewAllMentorPage() {
  const Mobile = useMediaQuery("(max-width:800px)");
  const service = new RDService();
  const [{ user_id }, dispatch] = useStateValue();
  const [selectedCollege, setSelectedCollege] = useState([]);
  const [selectedDesigination, setSelectedDesigination] = useState([]);
  const [selectedCompanyname, setSelectedCompanyName] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [allCollege, setAllCollege] = useState([]);
  const [alldegigination, setAllDesigination] = useState([]);
  const [allCommpany, setAllCompany] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [allMentors, setAllMentors] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [fetchMore, setFetchMore] = useState(true);
  const [firstTime, setFirstTime] = useState(true);
  const [fetchData, setFetchData] = useState(0);
  const { webinarTickerExists, setWebinarTickerExists } = useWebinarContext();

  const getMentorData = async () => {
    setLoading(true);
    const getMentors = await service.get(`/superAdmin/allmentors?limit=10&page=${page}&college_name=${JSON.stringify(selectedCollege)}&company_name=${JSON.stringify(selectedCompanyname)}&role_name=${JSON.stringify(selectedRoles)}&designation=${JSON.stringify(selectedDesigination)}&searchQuery=${searchQuery}`);
    console.log("THIS IS 1");

    let temp = getMentors.mentors;
    setFirstTime(false);
    let previous_user_ids = allMentors.map((item) => item.user_id);
    if (user_id === null) {
      temp = temp.filter((item) => item.is_public === 1 && item.active_flag === 1);
    }
    temp = temp.filter((item) => !previous_user_ids.includes(item.user_id));
    setAllMentors((prev) => {
      let data = [...prev, ...temp];
      let uniqueArray = data.filter((obj, index, self) => index === self.findIndex((t) => t.user_id === obj.user_id));
      return uniqueArray;
    });

    if (temp.length === 0) {
      setFetchMore(false);
    }

    setPage(page + 1);
    window.scrollBy(0, -30);
    setLoading(false);
  };

  const getMentorBySearch = async (p = page) => {
    setLoading(true);
    const getMentors = await service.get(`/superAdmin/allmentors?limit=12&page=${p}&college_name=${JSON.stringify(selectedCollege)}&company_name=${JSON.stringify(selectedCompanyname)}&role_name=${JSON.stringify(selectedRoles)}&designation=${JSON.stringify(selectedDesigination)}&searchQuery=${searchQuery}`);

    let temp = getMentors.mentors;

    console.log("THIS IS 2");
    if (user_id === null) {
      temp = temp.filter((item) => item.is_public === 1 && item.active_flag === 1);
    }

    setAllMentors(temp);
    setPage(1);
    setFetchMore(true);
    setLoading(false);
  };

  const getOtherData = async () => {
    const collegeData = await service.get(`/hoa/mentorColleges`);
    const designationData = await service.get(`/superAdmin/mentors/allDesignations`);
    const companyData = await service.get(`/superAdmin/mentors/allCompanies`);
    const roleData = await service.get(`/superAdmin/role`);
    setAllCollege(collegeData.data);
    setAllDesigination(designationData.data);
    setAllCompany(companyData.data);
    setAllRoles(roleData.data);
  };

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop = document.documentElement.scrollTop;

      // Check if the user has reached the bottom of the window scroll
      console.log("THis is callledb", windowHeight + scrollTop + 10 >= documentHeight, windowHeight, documentHeight, scrollTop);

      if (windowHeight + scrollTop + 10 >= documentHeight) {
        console.log("THis is callledb1", windowHeight + scrollTop + 10);
        setFetchData((prev) => prev + 1);
      }
    };

    // Attach the scroll event listener to the window
    window.addEventListener("scroll", () => handleScroll());

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("scroll", () => handleScroll());
    };
  }, []);

  useEffect(() => {
    getOtherData();
  }, []);

  useEffect(() => {
    if (searchQuery === "" && !firstTime) {
      setAllMentors([]);
      getMentorData();
      setPage(1);
      setFetchMore(true);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (!firstTime) {
      getMentorBySearch(1);
      setPage(1);
      setFetchMore(true);
    }
  }, [selectedCollege, selectedRoles, selectedDesigination, selectedCompanyname, user_id]);
  console.log("FetchData", fetchData);
  useEffect(() => {
    console.log("ANKJFKJhdkjf", loading, fetchMore);
    if (!loading && fetchMore) {
      getMentorData();
    }
  }, [fetchData]);

  const handleEnter = () => {
    setPage(1);
    getMentorBySearch(1);
  };
  return (
    <div style={{ padding: Mobile ? "4rem 1rem" : "5rem 9rem", display: "flex", flexDirection: "column", gap: "16px", marginTop: Mobile ? "48px" : webinarTickerExists ? "3rem" : "56px" }}>
      <TextField
        placeholder="Search for a mentor"
        fullWidth
        onKeyDown={(e) => e.key === "Enter" && handleEnter()}
        InputProps={{
          endAdornment: (
            <IconButton onClick={() => searchQuery !== "" && handleEnter()}>
              <Search sx={{ color: "#1C8EA8" }} />
            </IconButton>
          ),
          style: {
            borderRadius: "8px",
          },
        }}
        inputProps={{ style: { height: "10px" } }}
        value={searchQuery}
        onChange={(e) => {
          setSearchQuery(e.target.value);
        }}
      />

      <Grid container rowSpacing={Mobile ? 2 : 6} columnSpacing={Mobile ? 2 : 6}>
        <Grid item xs={6} sm={2} md={2}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel sx={{ mr: 5 }}>College</InputLabel>
            <Select
              multiple
              value={selectedCollege}
              label
              onChange={(e) =>
                setSelectedCollege(
                  // On autofill we get a stringified value.
                  typeof value === "string" ? e.target.value.split(",") : e.target.value
                )
              }
              input={<OutlinedInput label="College" />}
              MenuProps={MenuProps}
            >
              {allCollege.map((name) => (
                <MenuItem key={name} value={name.college_name}>
                  {name.college_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={2} md={2}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel>Company</InputLabel>
            <Select
              multiple
              value={selectedCompanyname}
              onChange={(e) =>
                setSelectedCompanyName(
                  // On autofill we get a stringified value.
                  typeof value === "string" ? e.target.value.split(",") : e.target.value
                )
              }
              input={<OutlinedInput label="Company" />}
              MenuProps={MenuProps}
            >
              {allCommpany.map((name) => (
                <MenuItem key={name} value={name.company}>
                  {name.company}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6} sm={2} md={2}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel>Designation</InputLabel>
            <Select
              multiple
              value={selectedDesigination}
              onChange={(e) =>
                setSelectedDesigination(
                  // On autofill we get a stringified value.
                  typeof value === "string" ? e.target.value.split(",") : e.target.value
                )
              }
              input={<OutlinedInput label="Designation" />}
              MenuProps={MenuProps}
            >
              {alldegigination.map((name) => (
                <MenuItem key={name} value={name.designation}>
                  {name.designation}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6} sm={2} md={2}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel>Roles</InputLabel>
            <Select
              multiple
              value={selectedRoles}
              onChange={(e) =>
                setSelectedRoles(
                  // On autofill we get a stringified value.
                  typeof value === "string" ? e.target.value.split(",") : e.target.value
                )
              }
              input={<OutlinedInput label="Roles" />}
              MenuProps={MenuProps}
            >
              {allRoles.map((name) => (
                <MenuItem key={name} value={name.name}>
                  {name.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {allMentors.length > 0 ? (
        <Grid container rowSpacing={Mobile ? 2 : 6} columnSpacing={Mobile ? 2 : 6}>
          {allMentors.map((item) => (
            <Grid item xs={12} sm={12} md={6}>
              <MentorCard mentor={item} />
            </Grid>
          ))}
        </Grid>
      ) : (
        !loading && <Typography sx={{ fontSize: Mobile ? "14px" : "20px", textAlign: "center", color: "#1C8EA8", fontStyle: "italic", marginTop: "10px" }}>Sorry, no results found for your search</Typography>
      )}
      {loading && <Loader />}
    </div>
  );
}

export default NewAllMentorPage;

const Loader = () => {
  const Mobile = useMediaQuery("(max-width:800px)");
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "30px" }}>
      <Box sx={{ display: "flex", flexDirection: Mobile ? "column" : "row", justifyContent: "space-between", gap: "30px" }}>
        <Skeleton variant="rectangular" width={"100%"} height={Mobile ? 150 : 200} />
        <Skeleton variant="rectangular" width={"100%"} height={Mobile ? 150 : 200} />
      </Box>
      <Box sx={{ display: "flex", flexDirection: Mobile ? "column" : "row", justifyContent: "space-between", gap: "30px" }}>
        <Skeleton variant="rectangular" width={"100%"} height={Mobile ? 150 : 200} />
        <Skeleton variant="rectangular" width={"100%"} height={Mobile ? 150 : 200} />
      </Box>
    </Box>
  );
};
