import { Box, Button, Grid, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../components/StateProvider/stateProvider";
import ServiceCM from "../../services/cartmoduleServices";
function Trainings() {
  const [{ basket }, dispatch] = useStateValue();
  const env = `${process.env.REACT_APP_ENVIRONMENT}`;
  const [tabItems, setTabItems] = useState([
    {
      name: "Mock Interview",
      index: 0,
      url: "/MockInterview",
      id: 17,
      isAdded: false,
    },
    {
      name: "SIP Success",
      index: 1,
      url: "/SipSuccess",
      id: 1,
      isAdded: false,
    },
    {
      name: "Resume Discussion",
      index: 2,
      url: "/ResumeDiscussion",
      id: 5,
      isAdded: false,
    },
    {
      name: "PIQR",
      index: 3,
      url: "/PIQR",
      id: 4,
      isAdded: false,
    },
    {
      name: "Case Interview",
      index: 4,
      url: "/CaseInterview",
      id: 3,
      isAdded: false,
    },
    {
      name: "Expert Connect",
      index: 5,
      url: "/ExpertConnect",
      id: 16,
      idAdded: false,
    },
  ]);

  const services = new ServiceCM();
  const [sku, setSku] = useState([]);
  const [itemIndex, setIndex] = useState(0);
  const [selectedItem, setSelectedItem] = useState(tabItems[itemIndex]);

  const navigate = useNavigate();
  const Mobile = useMediaQuery("(max-width:800px)");

  const handleTabChange = (event, newValue) => {
    if (newValue === "Resume Discussion") {
      setSelectedItem(tabItems[2]);
      setIndex(2);
    } else if (newValue === "PIQR") {
      setSelectedItem(tabItems[3]);
      setIndex(3);
    } else if (newValue === "Mock Interview") {
      setSelectedItem(tabItems[0]);
      setIndex(0);
    } else if (newValue === "Case Interview") {
      setSelectedItem(tabItems[4]);
      setIndex(4);
    } else if (newValue === "SIP Success") {
      setSelectedItem(tabItems[1]);
      setIndex(1);
    } else if (newValue === "Expert Connect") {
      setSelectedItem(tabItems[5]);
      setIndex(5);
    }
  };
  const handleNavigate = () => {
    window.scroll(0, 0);
    navigate(selectedItem.url);
  };

  const handleAddtoCart = () => {
    let id;
    if (selectedItem.name === "Resume Discussion") {
      id = env === "dev" ? 5 : 5;
    } else if (selectedItem.name === "PIQR") {
      id = env === "dev" ? 4 : 4;
    } else if (selectedItem.name === "Mock Interview") {
      id = env === "dev" ? 17 : 6;
    } else if (selectedItem.name === "Case Interview") {
      id = env === "dev" ? 3 : 3;
    } else if (selectedItem.name === "SIP Success") {
      id = env === "dev" ? 1 : 1;
    } else if (selectedItem.name === "Expert Connect") {
      id = env === "dev" ? 16 : 2;
    }

    let temp_cart = JSON.parse(localStorage.getItem("temp_quantity"));
    if (temp_cart) {
      let item = temp_cart.filter((item) => item.id === id);
      if (item.length > 0) {
        temp_cart = temp_cart.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              quantity: item.quantity + 1,
              total_price: item.total_price + item.total_price / item.quantity,
            };
          } else {
            return item;
          }
        });
      } else {
        let find_sku = sku.find((item) => item.id === id);
        console.log("find_sku", find_sku);
        let data = {
          search_tbl: "sku",
          id: id,
          quantity: 1,
          total_price: find_sku.actual_price,
        };
        temp_cart = [...temp_cart, data];
      }

      console.log("cart item", temp_cart);
      dispatch({ type: "UPDATE_BASKET", basket: temp_cart });
      localStorage.setItem("temp_quantity", JSON.stringify(temp_cart));
    } else {
      let data = {
        search_tbl: "sku",
        id: id,
        quantity: 1,
        total_price: 1999,
      };
      let new_cart = [data];
      dispatch({ type: "UPDATE_BASKET", basket: new_cart });
      localStorage.setItem("temp_quantity", JSON.stringify(new_cart));
    }
  };

  const ShowIcons = () => {
    if (selectedItem.name === "Resume Discussion") {
      return (
        <Grid container spacing={2}>
          <Grid item xs={6} sm={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: Mobile ? "1rem" : "2rem",
                width: "100%",
              }}
            >
              <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/timer.svg" alt="timer" style={{ height: Mobile ? "56px" : "100px" }} />
              <Typography fontSize={Mobile ? "12px" : "16px"} textAlign={"center"}>
                30 mins role aligned session
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // justifyContent: "space-between",
                gap: Mobile ? "1rem" : "2rem",
                width: "100%",
              }}
            >
              <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/feedback.svg" alt="timer" style={{ height: Mobile ? "56px" : "100px" }} />
              <Typography fontSize={Mobile ? "12px" : "16px"} textAlign={"center"}>
                Written actionable feedback to make improvements.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // justifyContent: "space-between",
                gap: Mobile ? "1rem" : "2rem",
                alignItems: "center",
                width: "100%",
              }}
            >
              <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/resumeReview.svg" alt="resumeReview" style={{ height: Mobile ? "56px" : "100px" }} />
              <Typography fontSize={Mobile ? "12px" : "16px"} textAlign={"center"}>
                Line-by-line analysis of each resume section and bullet point
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: Mobile ? "1rem" : "2rem",
                width: "100%",
              }}
            >
              <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/focusArea.svg" alt="Focus Area" style={{ height: Mobile ? "56px" : "100px" }} />
              <Typography fontSize={Mobile ? "12px" : "16px"} textAlign={"center"}>
                Match your resume to your job profile
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: Mobile ? "1rem" : "2rem",
                width: "100%",
              }}
            >
              <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/industryExperts.svg" alt="timer" style={{ height: Mobile ? "56px" : "100px" }} />
              <Typography fontSize={Mobile ? "12px" : "16px"} textAlign={"center"}>
                Additional suggestions on actions to improve role competency
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: Mobile ? "1rem" : "2rem",
                width: "100%",
              }}
            >
              <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/podcasts.svg" alt="timer" style={{ height: Mobile ? "56px" : "100px" }} />
              <Typography fontSize={Mobile ? "12px" : "16px"} textAlign={"center"}>
                Get access to assessment test & 100+ podcasts & sessions
              </Typography>
            </Box>
          </Grid>
        </Grid>
      );
    } else if (selectedItem.name === "PIQR") {
      return (
        <Grid container spacing={3}>
          <Grid item xs={6} sm={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: Mobile ? "1rem" : "2rem",
                width: "100%",
                opacity: "1",
                transition: "opacity 2s ease-in-out",
              }}
            >
              <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/timer.svg" alt="timer" style={{ height: Mobile ? "56px" : "100px" }} />
              <Typography fontSize={Mobile ? "12px" : "16px"} textAlign={"center"}>
                30 mins resume targeted preparation
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: Mobile ? "1rem" : "2rem",
                width: "100%",
              }}
            >
              <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/qna.svg" alt="Qna" style={{ height: Mobile ? "56px" : "100px" }} />
              <Typography fontSize={Mobile ? "12px" : "16px"} textAlign={"center"}>
                Get a detailed question bank of possible questions from resume
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: Mobile ? "1rem" : "2rem",
                width: "100%",
              }}
            >
              <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/resumeReview.svg" alt="resumeReview" style={{ height: Mobile ? "56px" : "100px" }} />
              <Typography fontSize={Mobile ? "12px" : "16px"} textAlign={"center"}>
                Confidence to justify resume content
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: Mobile ? "1rem" : "2rem",
                width: "100%",
              }}
            >
              <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/podcasts.svg" alt="timer" style={{ height: Mobile ? "56px" : "100px" }} />
              <Typography fontSize={Mobile ? "12px" : "16px"} textAlign={"center"}>
                Get access to assessment test & 100+ podcasts & sessions
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: Mobile ? "1rem" : "2rem",
                width: "100%",
              }}
            >
              <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/interview.svg" alt="Interview" style={{ height: Mobile ? "56px" : "100px" }} />
              <Typography fontSize={Mobile ? "12px" : "16px"} textAlign={"center"}>
                Enhance your preparedness to response to interview questions
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: Mobile ? "1rem" : "2rem",
                width: "100%",
              }}
            >
              <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/industryExperts.svg" alt="timer" style={{ height: Mobile ? "56px" : "100px" }} />
              <Typography fontSize={Mobile ? "12px" : "16px"} textAlign={"center"}>
                Bullet wise question identification from each section
              </Typography>
            </Box>
          </Grid>
        </Grid>
      );
    } else if (selectedItem.name === "Mock Interview") {
      return (
        <Grid container spacing={2}>
          <Grid item xs={6} sm={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: Mobile ? "1rem" : "2rem",
                width: "100%",
              }}
            >
              <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/timer.svg" alt="timer" style={{ height: Mobile ? "56px" : "100px" }} />
              <Typography fontSize={Mobile ? "12px" : "16px"} textAlign={"center"}>
                30 mins role and JD aligned session
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: Mobile ? "1rem" : "2rem",
                width: "100%",
              }}
            >
              <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/evaluateInterview.svg" alt="evaluate Interview" style={{ height: Mobile ? "56px" : "100px" }} />
              <Typography fontSize={Mobile ? "12px" : "16px"} textAlign={"center"}>
                Realistic job interveiw simulation
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6} sm={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: Mobile ? "1rem" : "2rem",
                width: "100%",
              }}
            >
              <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/industryExperts.svg" alt="timer" style={{ height: Mobile ? "56px" : "100px" }} />
              <Typography fontSize={Mobile ? "12px" : "16px"} textAlign={"center"}>
                Present your technical achievements from a <br /> business perspective
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: Mobile ? "1rem" : "2rem",
                width: "100%",
              }}
            >
              <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/interview.svg" alt="Interview" style={{ height: Mobile ? "56px" : "100px" }} />
              <Typography fontSize={Mobile ? "12px" : "16px"} textAlign={"center"}>
                Assess your preparedness for the chosen role
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6} sm={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: Mobile ? "1rem" : "2rem",
                width: "100%",
              }}
            >
              <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/podcasts.svg" alt="timer" style={{ height: Mobile ? "56px" : "100px" }} />
              <Typography fontSize={Mobile ? "12px" : "16px"} textAlign={"center"}>
                Get access to assessment test & 100+ <br />
                podcasts & sessions
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // justifyContent: "space-between",
                gap: Mobile ? "1rem" : "2rem",
                width: "100%",
              }}
            >
              <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/feedback.svg" alt="timer" style={{ height: Mobile ? "56px" : "100px" }} />
              <Typography fontSize={Mobile ? "12px" : "16px"} textAlign={"center"}>
                Receive constructive written feedback from the industry mentor
              </Typography>
            </Box>
          </Grid>
        </Grid>
      );
    } else if (selectedItem.name === "Case Interview") {
      return (
        <Grid container spacing={2}>
          <Grid item xs={6} sm={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: Mobile ? "1rem" : "2rem",
                width: "100%",
              }}
            >
              <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/timer.svg" alt="timer" style={{ height: Mobile ? "56px" : "100px" }} />
              <Typography fontSize={Mobile ? "12px" : "16px"} textAlign={"center"}>
                30 mins case related preparation
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6} sm={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: Mobile ? "1rem" : "2rem",
                width: "100%",
              }}
            >
              <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/feedback.svg" alt="timer" style={{ height: Mobile ? "56px" : "100px" }} />
              <Typography fontSize={Mobile ? "12px" : "16px"} textAlign={"center"}>
                Detailed written feedback on key areas to improve
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6} sm={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: Mobile ? "1rem" : "2rem",
                width: "100%",
              }}
            >
              <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/analyzeCase.svg" alt="timer" style={{ height: Mobile ? "56px" : "100px" }} />
              <Typography fontSize={Mobile ? "12px" : "16px"} textAlign={"center"}>
                Identify cases from past achievements
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6} sm={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: Mobile ? "1rem" : "2rem",
                width: "100%",
              }}
            >
              <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/focusArea.svg" alt="Focus Area" style={{ height: Mobile ? "56px" : "100px" }} />
              <Typography fontSize={Mobile ? "12px" : "16px"} textAlign={"center"}>
                Explore possible on-spot cases from your past achievements
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6} sm={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: Mobile ? "1rem" : "2rem",
                width: "100%",
              }}
            >
              <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/industryExperts.svg" alt="timer" style={{ height: Mobile ? "56px" : "100px" }} />
              <Typography fontSize={Mobile ? "12px" : "16px"} textAlign={"center"}>
                Learn to approach the solution strategically
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6} sm={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: Mobile ? "1rem" : "2rem",
                width: "100%",
              }}
            >
              <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/podcasts.svg" alt="timer" style={{ height: Mobile ? "56px" : "100px" }} />
              <Typography fontSize={Mobile ? "12px" : "16px"} textAlign={"center"}>
                Get access to assessment tests & 100+ podcasts & sessions
              </Typography>
            </Box>
          </Grid>
        </Grid>
      );
    } else if (selectedItem.name === "SIP Success") {
      return (
        <Grid container spacing={2}>
          <Grid item xs={6} sm={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: Mobile ? "1rem" : "2rem",
                width: "100%",
              }}
            >
              <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/podcasts.svg" alt="timer" style={{ height: Mobile ? "56px" : "100px" }} />
              <Typography fontSize={Mobile ? "12px" : "16px"} textAlign={"center"}>
                Get access to assessment test & 100+ podcasts & sessions
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: Mobile ? "1rem" : "2rem",
                width: "100%",
              }}
            >
              <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/timer.svg" alt="timer" style={{ height: Mobile ? "56px" : "100px" }} />
              <Typography fontSize={Mobile ? "12px" : "16px"} textAlign={"center"}>
                30 Mins high impact discussion
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: Mobile ? "1rem" : "2rem",
                width: "100%",
              }}
            >
              <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/guidance.svg" alt="timer" style={{ height: Mobile ? "56px" : "100px" }} />
              <Typography fontSize={Mobile ? "12px" : "16px"} textAlign={"center"}>
                Get guidance on behavioral aspects to achieve PPO/PPI
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: Mobile ? "1rem" : "2rem",
                width: "100%",
              }}
            >
              <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/industryExperts.svg" alt="timer" style={{ height: Mobile ? "56px" : "100px" }} />
              <Typography fontSize={Mobile ? "12px" : "16px"} textAlign={"center"}>
                Set expected outcomes from your SIP project
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: Mobile ? "1rem" : "2rem",
                width: "100%",
              }}
            >
              <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/starterKit.svg" alt="starter Kit" style={{ height: Mobile ? "56px" : "100px" }} />
              <Typography fontSize={Mobile ? "12px" : "16px"} textAlign={"center"}>
                Starter kit to prepare for SIP
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: Mobile ? "1rem" : "2rem",
                width: "100%",
              }}
            >
              <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/chat.svg" alt="timer" style={{ height: Mobile ? "56px" : "100px" }} />
              <Typography fontSize={Mobile ? "12px" : "16px"} textAlign={"center"}>
                Suggested approach & course correction to maximize learning
              </Typography>
            </Box>
          </Grid>
        </Grid>
      );
    } else if (selectedItem.name === "Expert Connect") {
      return (
        <Grid container spacing={2}>
          <Grid item xs={6} sm={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: Mobile ? "1rem" : "2rem",
                width: "100%",
              }}
            >
              <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/timer.svg" alt="timer" style={{ height: Mobile ? "56px" : "100px" }} />
              <Typography fontSize={Mobile ? "12px" : "16px"} textAlign={"center"}>
                30 Mins open-ended discussion
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: Mobile ? "1rem" : "2rem",
                width: "100%",
              }}
            >
              <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/careergoals.svg" alt="Career goals" style={{ height: Mobile ? "56px" : "100px" }} />
              <Typography fontSize={Mobile ? "12px" : "16px"} textAlign={"center"}>
                Address all career concerns
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: Mobile ? "1rem" : "2rem",
                width: "100%",
              }}
            >
              <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/chat.svg" alt="timer" style={{ height: Mobile ? "56px" : "100px" }} />
              <Typography fontSize={Mobile ? "12px" : "16px"} textAlign={"center"}>
                Talk to someone from the similar background before an interview
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: Mobile ? "1rem" : "2rem",
                width: "100%",
              }}
            >
              <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/guidance.svg" alt="timer" style={{ height: Mobile ? "56px" : "100px" }} />
              <Typography fontSize={Mobile ? "12px" : "16px"} textAlign={"center"}>
                Get assistance beyond the placement training
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: Mobile ? "1rem" : "2rem",
                width: "100%",
              }}
            >
              <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/industryExperts.svg" alt="timer" style={{ height: Mobile ? "56px" : "100px" }} />
              <Typography fontSize={Mobile ? "12px" : "16px"} textAlign={"center"}>
                Analyze & discuss long term career prospects
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: Mobile ? "1rem" : "2rem",
                width: "100%",
              }}
            >
              <img src="https://du03hetco8xdw.cloudfront.net/cc_com_landing/podcasts.svg" alt="timer" style={{ height: Mobile ? "56px" : "100px" }} />
              <Typography fontSize={Mobile ? "12px" : "16px"} textAlign={"center"}>
                Get access to assessment test & 100+ podcasts & sessions
              </Typography>
            </Box>
          </Grid>
        </Grid>
      );
    }
  };

  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const [isHovered, setisHovered] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeout(() => {
        if (!isHovered) {
          let temp_index = itemIndex;
          temp_index = (itemIndex + 1) % tabItems.length;
          setIndex((prevIndex) => (prevIndex + 1) % tabItems.length);
          setSelectedItem(tabItems[temp_index]);
        }
      });
    }, 3000);

    if (isHovered) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [itemIndex, isHovered]);

  const getSku = async () => {
    const res = await services.get("/sku?type=1 X 1");
    setSku(res.data);
  };

  useEffect(() => {
    getSku();
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"} gap={"1rem"}>
        <Typography variant={Mobile ? "h4" : "h2"} fontSize={Mobile ? "24px" : "45px"} fontWeight="400" textAlign={"center"}>
          1X1 Trainings
        </Typography>
        <Box sx={{ backgroundColor: "#000", width: "101px", height: "2px" }}></Box>
      </Box>

      {/* <Box
        display={"flex"}
        // justifyContent={"space-between"}
        gap={"5.5rem"}
        sx={{
          borderRadius: "15px",
          borderBottom: "1px solid #FFF",
          alignItems: "center",
          boxShadow: " 0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
          padding: "0.5rem",
        }}
      >
        <Typography
          fontSize={"14px"}
          sx={{
            // background:
            //   selectedItem.name === "Resume Discussion"
            //     ? "linear-gradient(135deg, #F5A536 0%, #FFC87A 100%)"
            //     : "none",
            width: "100%",
            textAlign: "center",
            "&:hover": { cursor: "pointer" },
            color:
              selectedItem.name === "Resume Discussion" ? "black" : "#8B8B8B",
          }}
          onClick={() =>
            setSelectedItem({
              name: "Resume Discussion",
              index: 0,
              url: "/ResumeDiscussion",
            })
          }
        >
          Resume Discussion
        </Typography>
        <Typography
          fontSize={"14px"}
          sx={{
            width: "100%",
            textAlign: "center",
            "&:hover": { cursor: "pointer" },
            color: selectedItem.name === "PIQR" ? "black" : "#8B8B8B",
          }}
          onClick={() =>
            setSelectedItem({ name: "PIQR", index: 1, url: "/PIQR" })
          }
        >
          PI Questions
          <br /> from Resume
        </Typography>
        <Typography
          fontSize={"14px"}
          sx={{
            width: "100%",
            textAlign: "center",

            "&:hover": { cursor: "pointer" },
            color: selectedItem.name === "Mock Interview" ? "black" : "#8B8B8B",
          }}
          onClick={() =>
            setSelectedItem({
              name: "Mock Interview",
              index: 2,
              url: "/MockInterview",
            })
          }
        >
          Mock Interview
        </Typography>
        <Typography
          fontSize={"14px"}
          sx={{
            width: "100%",
            textAlign: "center",
            "&:hover": { cursor: "pointer" },
            color: selectedItem.name === "Case Interview" ? "black" : "#8B8B8B",
          }}
          onClick={() =>
            setSelectedItem({
              name: "Case Interview",
              index: 3,
              url: "/CaseInterview",
            })
          }
        >
          Case Interview
        </Typography>
        <Typography
          fontSize={"14px"}
          sx={{
            width: "100%",
            textAlign: "center",

            "&:hover": { cursor: "pointer" },
            color: selectedItem.name === "SIP Success" ? "black" : "#8B8B8B",
          }}
          onClick={() =>
            setSelectedItem({
              name: "SIP Success",
              index: 4,
              url: "/SipSuccess",
            })
          }
        >
          SIP Success
        </Typography>
        <Typography
          fontSize={"14px"}
          sx={{
            width: "100%",
            textAlign: "center",
            "&:hover": { cursor: "pointer" },
            color: selectedItem.name === "Expert Connect" ? "black" : "#8B8B8B",
          }}
          onClick={() =>
            setSelectedItem({
              name: "Expert Connect",
              index: 5,
              url: "/ExpertConnect",
            })
          }
        >
          Expert Connect
        </Typography>
        <Box
          sx={{
            position: "absolute",
            height: "46.6px",
            zIndex: -1,
            background: "linear-gradient(135deg, #F5A536 0%, #FFC87A 100%)",
            borderRadius: "10px",
            width: `calc(calc(calc(100% - 9.5rem) - ${5 * 6.6}rem)/6)`,
            left: `calc(${
              selectedItem.index
            } * calc(calc(calc(100% - 9.5rem) - ${5 * 5.3}rem)/6) + 9.8rem + ${
              selectedItem.index * 3.5
            }rem)`,
            transition: "left 0.5s ease-in-out",
          }}
        ></Box>
      </Box> */}

      <Box display={"flex"} flexDirection={"column"} gap={"2.5rem"}>
        {!Mobile ? (
          <Box
            sx={{
              boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
              borderRadius: "15px",
              padding: "10px",
              background: "#FFF",
            }}
          >
            {" "}
            {/* <Tabs
              value={selectedItem.name}
              onChange={handleTabChange}
              indicatorColor="#fff"
              variant="fullWidth"
            >
              <Tab
                value="Mock Interview"
                label="Mock Interview"
                sx={{
                  textTransform: "none",
                  height: "100%",
                  alignContent: "center",
                  "&.Mui-selected": {
                    color: "#000",
                    borderRadius: "10px",
                    background:
                      "linear-gradient(135deg, #F5A536 0%, #FFC87A 100%)",
                  },
                }}
              />
              <Tab
                value="SIP Success"
                label="SIP Success"
                sx={{
                  textTransform: "none",
                  height: "100%",
                  "&.Mui-selected": {
                    color: "#000",
                    borderRadius: "10px",
                    background:
                      "linear-gradient(135deg, #F5A536 0%, #FFC87A 100%)",
                  },
                }}
              />
              <Tab
                value="Resume Discussion"
                label="Resume Discussion"
                sx={{
                  textTransform: "none",
                  height: "100%",
                  "&.Mui-selected": {
                    transition: "width 10s ease-in-out",
                    color: "#000",
                    borderRadius: "10px",
                    background:
                      "linear-gradient(135deg, #F5A536 0%, #FFC87A 100%)",
                  },
                }}
              />
              <Tab
                value="PIQR"
                label="PIQR"
                sx={{
                  textTransform: "none",
                  height: "100%",
                  "&.Mui-selected": {
                    color: "#000",
                    borderRadius: "10px",
                    background:
                      "linear-gradient(135deg, #F5A536 0%, #FFC87A 100%)",
                  },
                }}
              />

              <Tab
                value="Case Interview"
                label="Case Interview"
                sx={{
                  textTransform: "none",
                  height: "100%",
                  "&.Mui-selected": {
                    color: "#000",
                    borderRadius: "10px",
                    background:
                      "linear-gradient(135deg, #F5A536 0%, #FFC87A 100%)",
                  },
                }}
              />

              <Tab
                value="Expert Connect"
                label="Expert Connect"
                sx={{
                  textTransform: "none",
                  height: "100%",
                  "&.Mui-selected": {
                    color: "#000",
                    borderRadius: "10px",
                    background:
                      "linear-gradient(135deg, #F5A536 0%, #FFC87A 100%)",
                  },
                }}
              />
            </Tabs> */}
            <Tabs
              value={selectedItem.name}
              onChange={handleTabChange}
              variant="fullWidth"
              textColor="inherit"
              sx={{
                "& .MuiTabs-indicator": {
                  background: "linear-gradient(135deg, #F5A536 0%, #FFC87A 100%)",
                  height: "100%",
                  color: "#000",
                  zIndex: "1",
                  borderRadius: "10px",
                },
              }}
            >
              <Tab
                value="Mock Interview"
                label="Mock Interview"
                sx={{
                  textTransform: "none",
                  color: "#000",
                  height: "100%",
                  alignContent: "center",
                  zIndex: 10,
                }}
              />
              <Tab
                value="SIP Success"
                label="SIP Success"
                sx={{
                  textTransform: "none",
                  height: "100%",
                  zIndex: 10,
                }}
              />
              <Tab
                value="Resume Discussion"
                label="Resume Discussion"
                sx={{
                  textTransform: "none",
                  height: "100%",
                  zIndex: 10,
                }}
              />
              <Tab
                value="PIQR"
                label="PIQR"
                sx={{
                  textTransform: "none",
                  height: "100%",
                  zIndex: 10,
                }}
              />

              <Tab
                value="Case Interview"
                label="Case Interview"
                sx={{
                  textTransform: "none",
                  height: "100%",
                  zIndex: 10,
                }}
              />

              <Tab
                value="Expert Connect"
                label="Expert Connect"
                sx={{
                  textTransform: "none",
                  height: "100%",
                  zIndex: 10,
                }}
              />
            </Tabs>
          </Box>
        ) : (
          <>
            <Box
              sx={{
                boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                borderRadius: "15px",
                padding: "0.5rem",
                background: "#FFF",
              }}
            >
              <Tabs
                value={[0, 1].includes(itemIndex) ? selectedItem.name : false}
                onChange={handleTabChange}
                textColor="inherit"
                variant="fullWidth"
                sx={{
                  "& .MuiTabs-indicator": {
                    background: "linear-gradient(135deg, #F5A536 0%, #FFC87A 100%)",
                    height: "100%",
                    color: "#000",
                    zIndex: "1",
                    borderRadius: "10px",
                  },
                }}
              >
                <Tab
                  value="Mock Interview"
                  label="Mock Interview"
                  sx={{
                    textTransform: "none",
                    height: "100%",
                    zIndex: 10,
                  }}
                />

                <Tab
                  value="SIP Success"
                  label="SIP Success"
                  sx={{
                    textTransform: "none",
                    height: "100%",
                    zIndex: 10,
                  }}
                />
              </Tabs>

              <Tabs
                value={[2, 3].includes(itemIndex) ? selectedItem.name : false}
                onChange={handleTabChange}
                textColor="inherit"
                variant="fullWidth"
                sx={{
                  "& .MuiTabs-indicator": {
                    background: "linear-gradient(135deg, #F5A536 0%, #FFC87A 100%)",
                    height: "100%",
                    color: "#000",
                    zIndex: "1",
                    borderRadius: "10px",
                  },
                }}
              >
                <Tab
                  value="Resume Discussion"
                  label="Resume Discussion"
                  sx={{
                    textTransform: "none",
                    height: "100%",
                    zIndex: 10,
                  }}
                />
                <Tab
                  value="PIQR"
                  label="PIQR"
                  sx={{
                    textTransform: "none",
                    height: "100%",
                    zIndex: 10,
                  }}
                />
              </Tabs>

              <Tabs
                value={[4, 5].includes(itemIndex) ? selectedItem.name : false}
                onChange={handleTabChange}
                textColor="inherit"
                variant="fullWidth"
                sx={{
                  "& .MuiTabs-indicator": {
                    background: "linear-gradient(135deg, #F5A536 0%, #FFC87A 100%)",
                    height: "100%",
                    color: "#000",
                    zIndex: "1",
                    borderRadius: "10px",
                  },
                }}
              >
                <Tab
                  value="Case Interview"
                  label="Case Interview"
                  sx={{
                    textTransform: "none",
                    height: "100%",
                    zIndex: 10,
                  }}
                />

                <Tab
                  value="Expert Connect"
                  label="Expert Connect"
                  sx={{
                    textTransform: "none",
                    height: "100%",
                    zIndex: 10,
                  }}
                />
              </Tabs>
            </Box>
          </>
        )}
        <Box onMouseOver={() => setisHovered(true)} onMouseLeave={() => setisHovered(false)}>
          <ShowIcons />
        </Box>
      </Box>
      <Box sx={{ display: "flex", gap: "1rem", justifyContent: "center" }}>
        <button onClick={() => handleNavigate()} className="button-yellow-hover" style={{ width: Mobile ? "100%" : "fit-content" }}>
          <Typography>Know More</Typography>
        </button>
        <button
          className="button-yellow-hover"
          style={{ width: Mobile ? "100%" : "fit-content" }}
          onClick={() => handleAddtoCart()}
          // onClick={() => {
          //   navigate("/Pricing");
          //   window.scroll(0, 0);
          // }}
        >
          <Typography>Add to Cart</Typography>
        </button>
      </Box>
    </Box>
  );
}

export default Trainings;
